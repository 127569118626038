import React from 'react';
import { ReactComponent as UpIcon } from '../../../../assets/icons/up.svg';
import { BarChart } from '../../../../horizon-components/BarChart/BarChart';
import { Card } from '../../../../horizon-components/Card/Card';
import { Performance } from '../../../../models/Performance';
import './CoursePerfomanceDaily.scss';
import { Loader } from '../../../../components/Loader';
import { useTranslation } from 'react-i18next';

type Props = {
  data?: Performance;
  isLoading?: boolean;
};

export const CoursePerfomanceDaily: React.FC<Props> = ({
  data,
  isLoading = false,
}) => {
  const { t } = useTranslation(['translation']);
  const barChartDataDailyTraffic = {
    name: t('edu.Monthly_Traffic'),
    data: data?.monthly_enrollments.map((item) => item.count),
  };

  const barChartOptionsDailyTraffic = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      onDatasetHover: {
        style: {
          fontSize: '12px',
        },
      },
      theme: 'dark',
    },
    xaxis: {
      categories: data?.monthly_enrollments.map((item) => item.month),
      show: false,
      labels: {
        show: true,
        style: {
          colors: '#A3AED0',
          fontSize: '14px',
          fontWeight: '500',
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
      color: 'black',
      labels: {
        show: true,
        style: {
          colors: '#CBD5E0',
          fontSize: '14px',
        },
      },
    },
    grid: {
      show: false,
      strokeDashArray: 5,
      yaxis: {
        lines: {
          show: true,
        },
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        type: 'vertical',
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        colorStops: [
          [
            {
              offset: 0,
              color: '#4318FF',
              opacity: 1,
            },
            {
              offset: 100,
              color: '#4318FF',
              opacity: 0.0,
            },
          ],
        ],
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 6,
        columnWidth: '12px',
      },
    },
  };

  return (
    <Card className="CoursePerfomanceDaily">
      <div className="CoursePerfomanceDaily__head">
        <div>
          <p>{t('edu.Monthly_Traffic')}</p>
          <h2>
            {data?.monthly_total_enrollments}
            <span>{t('edu.Visitors')}</span>
          </h2>
        </div>
        <div
          className={`flex items-center gap-1 text-sm ${
            data?.monthly_percentage_change && data?.monthly_percentage_change > 0
              ? 'text-primary-green-500'
              : data?.monthly_percentage_change &&
                data?.monthly_percentage_change < 0
              ? 'text-primary-red-500'
              : 'text-secondary-dark-grey-500'
          }`}
        >
          {data?.monthly_percentage_change !== 0 && (
            <UpIcon
              className={
                data?.monthly_percentage_change &&
                data?.monthly_percentage_change > 0
                  ? 'fill-primary-green-500'
                  : 'rotate-180 fill-primary-red-500'
              }
            />
          )}
          <p className="font-bold">
            {data?.monthly_percentage_change && data?.monthly_percentage_change > 0
              ? '+'
              : ''}
            {data?.monthly_percentage_change}%
          </p>
        </div>
      </div>

      <div className="h-[300px] w-full pb-0 pt-10">
        {isLoading ? (
          <Loader />
        ) : (
          barChartDataDailyTraffic.data?.length && (
            <BarChart
              chartData={[barChartDataDailyTraffic]}
              chartOptions={barChartOptionsDailyTraffic}
            />
          )
        )}
      </div>
    </Card>
  );
};
