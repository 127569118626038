import React, { useEffect, useMemo, useState } from 'react';
import { MCarousel } from '../../../horizon-components/MCarousel/MCarousel';
import { ActualCarouselItem } from './ActualCarouselItem';
import './styles.scss';
import { Auction, BidStatusEnum } from '../../../models/Auction';

type Props = {
  auctions: Auction[];
};

export const ActualCarousel: React.FC<Props> = ({ auctions }) => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.onresize = () => setWidth(window.innerWidth);
    return () => {
      window.onresize = null;
    };
  }, []);

  const items = useMemo(
    () =>
      auctions
        ?.filter((auction) => auction.status === BidStatusEnum.ACTIVE)
        .map((auction) => <ActualCarouselItem auction={auction} />) || [],
    [auctions]
  );

  if (width > 640) return null;

  return <MCarousel className={'b-actual-carousel'} items={items} />;
};
