import { PropsWithChildren, useEffect, useRef } from 'react';
import SEO from './SEO';
import { useTranslation } from 'react-i18next';

function SEOProvider({ children }: PropsWithChildren) {
  const { t } = useTranslation();
  const removedFlag = useRef(false);

  // We are deleting default meta tags from index.html
  // in order to make it work with react-helmet
  useEffect(() => {
    if (removedFlag.current) return;
    removedFlag.current = true;

    const selectors = [
      'meta[name="apple-mobile-web-app-title"]',
      'meta[property="og:title"]',
      'meta[name="twitter:title"]',
      'meta[name="description"]',
      'meta[property="og:description"]',
      'meta[name="twitter:description"]',
      'meta[property="og:image"]',
      'meta[name="twitter:image"]',
    ];
    for (const selector of selectors) {
      const elements = document.querySelectorAll(selector);
      elements.forEach((el) => {
        if (el instanceof HTMLElement && !el.dataset.reactHelmet) {
          el.remove();
        }
      });
    }
  }, []);

  return (
    <>
      <SEO
        title={t('pages.main.meta.title')}
        desc={t('pages.main.meta.desc')}
        hideTitleSuffix
      />

      {children}
    </>
  );
}

export default SEOProvider;
