import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { getPrivateGetCoursebyAccordingProgramV3 } from '../../../api/courseV3';
import { ReactComponent as PlayIcon } from '../../../assets/icons/play.svg';
import useFetch from '../../../hooks/useFetch';
import { useNavigate } from '../../../hooks/useNavigate';
import {
  AuthRoutes,
  EduRoutes,
  MainRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';
import { useUserContext } from '../../../providers';
import './style.scss';

interface StartLearnProps {
  className?: string;
  subjectId?: number;
}

export const StartLearn = ({ className, subjectId }: StartLearnProps) => {
  const navigate = useNavigate();
  const { fetchData: fetchCourse } = useFetch((subject_id: number) =>
    getPrivateGetCoursebyAccordingProgramV3(subject_id)
  );
  const { t } = useTranslation(['translation']);
  const { user } = useUserContext();

  const handleCourse = () => {
    if (!user) {
      navigate(MainRoutes.auth + AuthRoutes.signin);
      return;
    }
    subjectId &&
      fetchCourse(Number(subjectId)).then((res) =>
        navigate(MainRoutes.edu + EduRoutes.course + '/' + res?.id)
      );
  };

  return (
    <div className={classNames('StartLearn', className)} onClick={handleCourse}>
      <div>{t('course.Start_the_training_program')}</div>
      <PlayIcon />
    </div>
  );
};
