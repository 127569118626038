import React, { useEffect } from 'react';

const useInfiniteScroll = (
  targetRef: React.RefObject<HTMLDivElement>,
  callback: () => void
) => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          callback();
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 1.0,
      }
    );

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, [callback, targetRef]);
};

export default useInfiniteScroll;
