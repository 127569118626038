import classNames from 'classnames';
import { FeedCommentCard } from './FeedCommentCard';
import { TFeedComment } from '../../../models/Feed';

type Props = {
  comments: TFeedComment[];
  className?: string;
  onReplyCreated?: () => Promise<void> | void;
  isChildComment?: boolean;
};

export const FeedCommentList: React.FC<Props> = ({
  comments,
  className = '',
  isChildComment,
}) => {
  return (
    <div className={classNames('flex flex-col gap-[16px]', className)}>
      {comments?.map((comment) => (
        <FeedCommentCard
          key={comment?.id}
          comment={comment}
          isChildComment={isChildComment}
        />
      ))}
    </div>
  );
};
