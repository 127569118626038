import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { joinGroup } from '../../../api/weber/communities';
import { GroupType, JoinStatus } from '../../../models/Community';
import { MButton } from '../../../horizon-components/MButton/ui/MButton';
import { ReactComponent as ChatIcon } from '../../../assets/icons/mail.svg';

type Props = {
  type: string;
  join_status: JoinStatus;
  communityId: number;
  onClick?: () => void;
  onChat?: () => void;
  onFollow?: () => void;
  showChat?: boolean;
};

export const CommunityView: React.FC<Props> = ({
  type,
  join_status,
  communityId,
  onClick = () => {},
  onChat = () => {},
  onFollow = () => {},
  showChat = false,
}) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [status, setStatus] = useState<JoinStatus>(join_status);
  const { t } = useTranslation(['translation']);

  const buttonMapping: {
    [key: string]: {
      label: string;
      action: any;
      afterActionStatus: JoinStatus | null;
    };
  } = {
    [`${GroupType.PUBLIC}${JoinStatus.NOT_JOINED}`]: {
      label: t('buttons.follow'),
      action: joinGroup,
      afterActionStatus: JoinStatus.JOINED,
    },
    [`${GroupType.PUBLIC}${JoinStatus.JOINED}`]: {
      label: t('buttons.view'),
      action: onClick,
      afterActionStatus: JoinStatus.NOT_JOINED,
    },
    [`${GroupType.PUBLIC}${JoinStatus.OWNED}`]: {
      label: t('buttons.view'),
      action: onClick,
      afterActionStatus: JoinStatus.OWNED,
    },
    [`${GroupType.PRIVATE}${JoinStatus.JOINED}`]: {
      label: t('buttons.view'),
      action: onClick,
      afterActionStatus: JoinStatus.NOT_JOINED,
    },
    [`${GroupType.PRIVATE}${JoinStatus.OWNED}`]: {
      label: t('buttons.view'),
      action: onClick,
      afterActionStatus: JoinStatus.OWNED,
    },
  };

  const {
    label: buttonLabel,
    action: buttonAction,
    afterActionStatus,
  } = buttonMapping[`${type}${status}`];

  const onCommunityButtonClicked = async () => {
    if (!buttonAction || !afterActionStatus || isButtonDisabled) return;
    setIsButtonDisabled(true);
    try {
      await buttonAction(communityId);
      setStatus(afterActionStatus);
      onFollow?.();
    } finally {
      setIsButtonDisabled(false);
    }
  };

  return (
    <div className="flex gap-[12px]">
      {status === JoinStatus.JOINED && showChat && (
        <MButton onClick={onChat} color="primary" variant="outline">
          {t('buttons.chat')} <ChatIcon />
        </MButton>
      )}
      <MButton
        onClick={onCommunityButtonClicked}
        color={'primary'}
        variant={
          status === JoinStatus.OWNED || status === JoinStatus.JOINED
            ? 'secondary'
            : 'highlighted'
        }
      >
        {buttonLabel}
      </MButton>
    </div>
  );
};
