import classNames from 'classnames';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getMaxTokenAmountByCourseV3 } from '../../api/courseV3';
import { ReactComponent as ERIcon } from '../../assets/icons/new-er.svg';
import useFetch from '../../hooks/useFetch';
import './style.scss';

interface EarnForEndProps {
  className?: string;
}

export const EarnForEnd = ({ className }: EarnForEndProps) => {
  const { id } = useParams();
  const { t } = useTranslation(['translation']);
  const { data, fetchData: fetchAmount } = useFetch((course_id: number) =>
    getMaxTokenAmountByCourseV3(course_id)
  );

  useEffect(() => {
    id && fetchAmount(Number(id));
  }, [id, t]);

  if (!data?.max_token_amount) return null;

  return (
    <div className={classNames('EarnForEnd', className)}>
      <span>
        {t('edu.For_completing_the_course_you_will_receive_200', {
          max_token_amount: data?.max_token_amount,
        })}
      </span>
      <ERIcon />
    </div>
  );
};
