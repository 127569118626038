function LandingInstagramProfile({
  profile,
  onClick,
}: {
  profile: string;
  onClick: () => void;
}) {
  const title = `${profile} Instagram Profile`;
  const src = `https://www.instagram.com/${encodeURIComponent(profile)}/embed/`;

  return (
    <iframe
      title={title}
      src={src}
      className="LandingPage__socials-embed"
      onClick={onClick}
    />
  );
}

export default LandingInstagramProfile;
