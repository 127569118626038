import { useTranslation } from 'react-i18next';
import { CourseAsideCard } from '../../../../widgets/CourseAsideCard';

interface CourseCountProps {
  className?: string;
  rating?: number;
  enrolls?: number;
}

export const CourseCount = ({ className, enrolls, rating }: CourseCountProps) => {
  const { t } = useTranslation(['translation']);

  return (
    <CourseAsideCard className="p-5">
      <div className="flex items-start justify-between gap-3 self-stretch rounded-[30px] bg-white text-xs font-medium text-secondary-dark-grey-900 md:text-sm">
        <div>
          <span className="text-sm font-semibold text-primary-purple-blue-500 md:text-base">
            {rating}
          </span>{' '}
          {t('course.course_rating')}
        </div>
        <div>
          <span className="text-sm font-semibold text-primary-purple-blue-500 md:text-base">
            {enrolls}
          </span>{' '}
          {t('course.Enrolls')}
        </div>
      </div>
    </CourseAsideCard>
  );
};
