import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Eye } from '../../../assets/icons/eye.svg';
import { ReactComponent as Plus } from '../../../assets/icons/plus.svg';
import { useNavigate } from '../../../hooks/useNavigate';
import { MButton } from '../../../horizon-components/MButton/ui/MButton';
import {
  EduRoutes,
  MainRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';
import { CourseCardItem, CourseCardStatus } from '../../../models/Course';
import './StartedCard.scss';

interface StartedCardProps {
  className?: string;
  course?: CourseCardItem;
  status?: CourseCardStatus;
}

export const StartedCard = ({ className, course, status }: StartedCardProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation(['translation']);

  if (!course) return null;

  const goToView = () =>
    navigate(MainRoutes.edu + EduRoutes.course + `/${course?.id}`);

  return (
    <div className={classNames('StartedCard', className)}>
      <div className="StartedCard__img">
        <img src={course.cover_image} alt="" />
      </div>
      <div className="StartedCard__content">
        <div>
          <h4>{course.title}</h4>
          {!!course?.digital_pass?.level && (
            <span>
              {t('course.Grade')}: {course?.digital_pass?.level}
            </span>
          )}
        </div>
        <MButton color="primary" variant="outline" onClick={goToView}>
          {status === CourseCardStatus.ENROLLED ? (
            <>{t('btnsText.continue')}</>
          ) : status === CourseCardStatus.PREVIEW ? (
            <>
              {t('course.Preview')} <Eye />
            </>
          ) : (
            <>
              {t('btnsText.enroll')} <Plus />
            </>
          )}
        </MButton>
      </div>
    </div>
  );
};
