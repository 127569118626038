import { Logout } from '../../../../features/Logout';
import { ProfileAccountSettings } from '../../../../features/ProfileAccountSettings';
import { ProfileCard } from '../../../../features/ProfileCard';
import { ProfileChangePassword } from '../../../../features/ProfileChangePassword';
import { Card } from '../../../../horizon-components/Card/Card';
import { useUserContext } from '../../../../providers';
import { InfoHeader } from './InfoHeader';
import './InfoUser.scss';
import { DeleteAccount } from '../../../../features/DeleteAccount';

export const InfoUser: React.FC = () => {
  const { user } = useUserContext();
  return (
    <div className="InfoUser m-auto max-w-6xl">
      <InfoHeader />
      <div className={'InfoUser__content'}>
        <div className={'InfoUser__col lg:col-span-5'}>
          <ProfileCard guid={user?.guid} userMeta={user?.user_meta} edit />
        </div>
        <div className={'InfoUser__col lg:col-span-3'}>
          <Card extra={'InfoUser__card'}>
            <ProfileAccountSettings />
          </Card>
        </div>
        <div className={'InfoUser__col lg:col-span-2'}>
          <Card extra={'InfoUser__card'}>
            <ProfileChangePassword />
          </Card>
          <Card extra={'InfoUser__card'}>
            <Logout />
          </Card>
          <Card extra={'InfoUser__card'}>
            <DeleteAccount />
          </Card>
        </div>
      </div>
    </div>
  );
};
