import { useCallback, useEffect, useState } from 'react';
import { getCoursePerformanceById } from '../../../../../api/course';
import { Performance } from '../../../../../models/Performance';

export const useCoursePerformance = (
  courseId: number,
  from?: string,
  to?: string
) => {
  const [data, setData] = useState<Performance>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchData = useCallback(() => {
    if (courseId && from && to) {
      setIsLoading(true);
      getCoursePerformanceById(courseId, from, to)
        .then((res) => setData(res))
        .catch(() => {})
        .finally(() => setIsLoading(false));
    }
  }, [courseId, from, to]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { data, isLoading };
};
