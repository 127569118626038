import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  getGroupMembersRemoveRole,
  getGroupMembersSetRole,
} from '../../../api/weber/friendship';
import NoAvatar from '../../../assets/images/no-avatar.png';
import { NetworkingDots } from '../../../features/NetworkingDots';
import { Link } from '../../../horizon-components/Link';
import { MButton } from '../../../horizon-components/MButton';
import {
  AuthRoutes,
  MainRoutes,
  ProfileRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';
import { UserCommunity, UserCommunityRole } from '../../../models';
import { useUserContext } from '../../../providers';
import { getUserData } from '../../../utils/getUserData';
import './MemberCard.scss';

export type Props = {
  className?: string;
  member?: UserCommunity;
  isEdit?: boolean;
};

export const MemberCard: React.FC<Props> = ({
  className,
  member,
  isEdit = false,
}) => {
  const { user: currentUser } = useUserContext();
  const { t } = useTranslation(['translation']);
  const [mem, setMem] = useState<UserCommunity>();
  const [role, setRole] = useState<string | null>(null);
  const user = getUserData(member?.user);

  useEffect(() => {
    switch (mem?.role) {
      case UserCommunityRole.ADMIN:
        setRole(t('communities.Admin'));
        break;
      case UserCommunityRole.EDITOR:
        setRole(t('communities.Editor'));
        break;
      case UserCommunityRole.MODERATOR:
        setRole(t('communities.Moderator'));
        break;

      default:
        setRole(null);
        break;
    }
  }, [mem, t]);

  useEffect(() => {
    member && setMem(member);
  }, [member]);

  const hndlRole = (role: UserCommunityRole) => {
    member?.id &&
      getGroupMembersSetRole(member?.id, role)
        .then(() => {
          toast(t('communities.Role_updated_successfully'), { type: 'success' });
          member && setMem({ ...member, role });
        })
        .catch(() =>
          toast(t('communities.The_role_could_not_be_updated'), { type: 'error' })
        );
  };

  const hndlDemote = () => {
    member?.id &&
      getGroupMembersRemoveRole(member?.id)
        .then(() => {
          toast(t('communities.Role_updated_successfully'), { type: 'success' });
          member && setMem({ ...member, role: null });
        })
        .catch(() =>
          toast(t('communities.The_role_could_not_be_updated'), { type: 'error' })
        );
  };

  return (
    <div className={classNames('MemberCard', className)}>
      <div className={`MemberCard__wrapper`}>
        <Link
          to={
            MainRoutes.auth +
            AuthRoutes.profile +
            ProfileRoutes.user +
            `/${user.guid}`
          }
        >
          <div className="MemberCard__avatar">
            <img src={user.image || NoAvatar} alt={user.username} />
          </div>
        </Link>
        <div className="MemberCard__user truncate">
          <Link
            to={
              MainRoutes.auth +
              AuthRoutes.profile +
              ProfileRoutes.user +
              `/${user.guid}`
            }
          >
            <h4 className={`truncate`}>{user.username}</h4>
          </Link>
          <p className={`truncate`}>
            {user.first_name} {user.last_name}
          </p>
        </div>
      </div>
      <div
        className={
          'ml-auto mt-[8px] flex items-center justify-end gap-[8px] lg:mt-0'
        }
      >
        {role && (
          <MButton variant={'transparent'} color={'primary'} disabled>
            {role}
          </MButton>
        )}
        {isEdit ? (
          user.guid !== currentUser?.guid && (
            <NetworkingDots
              user={user}
              member={mem}
              onRole={hndlRole}
              onDemote={hndlDemote}
            />
          )
        ) : (
          <Link
            to={
              MainRoutes.auth +
              AuthRoutes.profile +
              ProfileRoutes.user +
              `/${user.guid}`
            }
          >
            <MButton variant={'secondary'} color={'primary'}>
              {t('profile.view')}
            </MButton>
          </Link>
        )}
      </div>
    </div>
  );
};
