import React from 'react';
import classNames from 'classnames';

type Props = {
  color?: 'light' | 'primary' | 'danger' | 'success' | 'secondary' | 'dark';
  variant?: 'default' | 'outline';
  size?: 'lg' | 'sm';
  className?: string;
  children: React.ReactNode;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  shape?: 'rounded-circle' | 'default';
  id?: string;
};

export const HButton: React.FC<Props> = ({
  color = 'primary',
  variant = 'default',
  size = 'sm',
  className = '',
  children,
  disabled,
  onClick = (e) => {},
  shape = 'default',
  id,
}) => {
  return (
    <button
      id={id}
      onClick={onClick}
      disabled={disabled}
      style={{
        ...(variant === 'outline'
          ? {
              borderWidth: 1,
              borderStyle: 'solid',
            }
          : {}),
      }}
      className={classNames(
        `linear rounded-xl text-base font-medium transition duration-200 ${className}`,
        {
          'px-5 py-3': size === 'lg',
          'px-3 py-2': size === 'sm',
          'rounded-full': shape === 'rounded-circle',
          'opacity-[0.3]': disabled,
          ...(variant === 'default'
            ? {
                'bg-brand-500 text-black hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200':
                  color === 'light',
                'bg-gray-100 text-black hover:bg-gray-200 active:bg-gray-300 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30':
                  color === 'secondary',
                'bg-navy-700 text-white hover:bg-navy-800 active:bg-navy-900 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30':
                  color === 'dark' || color === 'primary',
                'bg-red-500 text-white hover:bg-red-600 active:bg-red-700 dark:bg-red-400 dark:text-white dark:hover:bg-red-300 dark:active:bg-red-200':
                  color === 'danger',
                'bg-green-500 text-white hover:bg-green-600 active:bg-green-700 dark:bg-green-400 dark:text-white dark:hover:bg-green-300 dark:active:bg-green-200':
                  color === 'success',
              }
            : {
                'hover:bg-brand-600/5 active:bg-brand-700/5 dark:bg-brand-400/10 dark:hover:bg-brand-300/10 dark:active:bg-brand-200/10 border-brand-500 text-brand-500 dark:border-brand-400 dark:text-white':
                  color === 'light',
                'border-gray-200 text-navy-700 hover:bg-gray-200/10 active:bg-gray-300/10 dark:border-white/20 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20':
                  color === 'secondary',
                'border-navy-700 text-navy-700 hover:bg-navy-800/5 active:bg-navy-900/5 dark:border-white/20 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20':
                  color === 'dark' || color === 'primary',
                'border-red-500 text-red-500 hover:bg-red-600/5 active:bg-red-700/5 dark:border-red-400 dark:bg-red-400/10 dark:text-white dark:hover:bg-red-300/10 dark:active:bg-red-200/10':
                  color === 'danger',
                'border-green-500 text-green-500 hover:bg-green-600/5 active:bg-green-700/5 dark:border-green-400 dark:bg-green-400/10 dark:text-white dark:hover:bg-green-300/10 dark:active:bg-green-200/10':
                  color === 'success',
              }),
        }
      )}
    >
      {children}
    </button>
  );
};
