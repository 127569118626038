import { ReactComponent as LikeEmpty } from '../../../assets/icons/like-empty.svg';
import { ReactComponent as LikeFull } from '../../../assets/icons/like-full.svg';
import { Loader } from '../../../components/Loader';
import './Like.scss';

interface LikeProps {
  likes?: number;
  active?: boolean;
  loading?: boolean;
  left?: boolean;
  onLike?: () => void;
}

export const Like = ({
  likes = 0,
  active = false,
  loading = false,
  left = false,
  onLike,
}: LikeProps) => {
  return (
    <div className="Like">
      {left && likes > 0 && likes}
      {loading ? (
        <Loader className="!h-[25px] !w-[25px]" />
      ) : active ? (
        <LikeFull className="cursor-pointer" onClick={onLike} />
      ) : (
        <LikeEmpty className="cursor-pointer" onClick={onLike} />
      )}{' '}
      {!left && likes > 0 && likes}
    </div>
  );
};
