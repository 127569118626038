import { useCallback, useEffect, useState } from 'react';
import { TBanner, getBanners } from '../../../../../api/banner';

export const useBanners = ({
  place_name_slug,
  order,
  ordering,
}: {
  place_name_slug?: string;
  order?: number;
  ordering?: string;
}) => {
  const [data, setData] = useState<TBanner[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchData = useCallback(() => {
    setIsLoading(!isLoading);
    getBanners({ order, ordering, place_name_slug })
      .then((res) => {
        setData(res);
        res !== undefined && setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, [order, ordering, place_name_slug]);

  useEffect(() => fetchData(), [fetchData]);

  return { data, isLoading };
};
