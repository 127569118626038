export const replaceImageSrc = (
  htmlString: string,
  newSrc: string,
  imageId: number
): string => {
  // Создаем регулярное выражение для поиска всех src атрибутов изображений
  const regex =
    /(<img.*?src=")(data:image\/\w+;base64,.*?)(".*?)( style=".*?")?( class=".*?")?(.*?>)/;

  // Заменяем все вхождения src и style атрибутов в HTML-строке на новые значения newSrc
  const newHtmlString = htmlString.replace(
    regex,
    (match, p1, p2, p3, p4, p5, p6) => {
      // Сохраняем стили и классы, если они есть
      const styleAttr = p4 ? p4 : '';
      const classAttr = p5 ? p5 : '';
      return `${p1}${newSrc}" alt="${imageId}"${styleAttr}${classAttr}${p6}`;
    }
  );

  return newHtmlString;
};
