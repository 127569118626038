import classNames from 'classnames';
import { Fragment } from 'react';
import { NoAvatar } from '../../NoAvatar';
import './AvatarTitleTextCard.scss';

interface AvatarTitleTextCardProps {
  className?: string;
  title?: string;
  text?: string[];
  img?: string;
  children?: React.ReactNode;
  onClick?: () => void;
}

export const AvatarTitleTextCard = ({
  className,
  img,
  text,
  title,
  children,
  onClick,
}: AvatarTitleTextCardProps) => {
  return (
    <div className={classNames('AvatarTitleTextCard', className)}>
      <div
        className={classNames('AvatarTitleTextCard__img', {
          'cursor-pointer': onClick,
        })}
        onClick={onClick}
      >
        <NoAvatar src={img} alt={title} />
      </div>
      <div className="AvatarTitleTextCard__content">
        {!!title && (
          <h6
            className={classNames({
              'cursor-pointer': onClick,
            })}
            onClick={onClick}
          >
            {title}
          </h6>
        )}
        {!!text?.length && (
          <div>
            {text?.map((item, idx) => (
              <Fragment key={idx}>
                <span>{item}</span>
                {text?.length !== idx + 1 && (
                  <span className="!font-semibold">•</span>
                )}
              </Fragment>
            ))}
          </div>
        )}
      </div>
      {children && <div className="AvatarTitleTextCard__children">{children}</div>}
    </div>
  );
};
