import classNames from 'classnames';
import {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from '../../../components/Loader';
import { useNotifications } from '../../../entities/Notifications';
import useInfiniteScroll from '../../../hooks/useInfiniteScroll';
import {
  AuthRoutes,
  EduRoutes,
  MainRoutes,
  ProfileRoutes,
  WeberRoutes,
  WizRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';
import { NotificationType } from '../../../models/Notifications';
import { useModal } from '../../../providers/ModalProvider';
import { NotificationCard } from '../../../widgets/NotificationCard';
import { ReactComponent as AddUserIcon } from '../assets/icons/add-user.svg';
import { ReactComponent as BeakerIcon } from '../assets/icons/beaker.svg';
import { ReactComponent as BookIcon } from '../assets/icons/book.svg';
import { ReactComponent as ChatIcon } from '../assets/icons/chat.svg';
import { ReactComponent as CommentIcon } from '../assets/icons/comment.svg';
import { ReactComponent as GroupIcon } from '../assets/icons/group.svg';
import { ReactComponent as NewspaperIcon } from '../assets/icons/newspaper.svg';
import { ReactComponent as RewiewIcon } from '../assets/icons/review.svg';
import { ReactComponent as StarIcon } from '../assets/icons/star.svg';
import './Notifications.scss';
import { useNavigate } from 'react-router-dom';

interface NotificationsProps {
  className?: string;
  onClose?: () => void;
}

const icons: Record<NotificationType, React.FC> = {
  FRIENDSHIP_REQUEST: AddUserIcon,
  MESSAGE: ChatIcon,
  JOINED_GROUP: GroupIcon,
  JOIN_GROUP_REQUEST: GroupIcon,
  LEFT_GROUP: GroupIcon,
  POST_REACTION: RewiewIcon,
  ER_POINTS_INCOME: StarIcon,
  PURCHASE_COMPLETION: BookIcon,
  NEW_REVIEW: RewiewIcon,
  COURSE_PUBLISHED: BookIcon,
  COURSE_REJECTED: BookIcon,
  DAO_MEMBER_SHIP_GROWTH: BeakerIcon,
  POST_PUBLICATION: NewspaperIcon,
  NEW_MESSAGE_RECEIVED: ChatIcon,
  FRIEND_REQUEST_RECEIVED: AddUserIcon,
  COMMENT_RESPONSE: CommentIcon,
  FRIEND_REQUEST_ACCEPTED: AddUserIcon,
  POST_COMMENT_GROWTH: RewiewIcon,
  RESTORING_LIVES: StarIcon,
  REFERRAL_OWNER_REWARD: StarIcon,
  REFERRAL_ACTIVATOR_REWARD: StarIcon,
};

interface UrlConfig {
  url: string;
  needsId: boolean;
}

const urls: Record<NotificationType, UrlConfig> = {
  // Posts
  POST_REACTION: { url: MainRoutes.weber + WeberRoutes.feed, needsId: true },
  POST_COMMENT_GROWTH: { url: MainRoutes.weber + WeberRoutes.feed, needsId: true },
  POST_PUBLICATION: { url: MainRoutes.weber + WeberRoutes.feed, needsId: true },

  // Courses
  PURCHASE_COMPLETION: { url: MainRoutes.edu + EduRoutes.course, needsId: true },
  NEW_REVIEW: { url: MainRoutes.edu + EduRoutes.course, needsId: true },
  COURSE_PUBLISHED: { url: MainRoutes.edu + EduRoutes.course, needsId: true },
  COURSE_REJECTED: { url: MainRoutes.edu + EduRoutes.course, needsId: true },
  COMMENT_RESPONSE: { url: MainRoutes.edu + EduRoutes.course, needsId: true },

  // Chats
  MESSAGE: {
    url: MainRoutes.auth + AuthRoutes.profile + ProfileRoutes.chat,
    needsId: false,
  },
  NEW_MESSAGE_RECEIVED: {
    url: MainRoutes.auth + AuthRoutes.profile + ProfileRoutes.chat,
    needsId: false,
  },

  // Communities
  JOINED_GROUP: { url: MainRoutes.weber + WeberRoutes.community, needsId: true },
  JOIN_GROUP_REQUEST: {
    url: MainRoutes.weber + WeberRoutes.community,
    needsId: true,
  },
  LEFT_GROUP: { url: MainRoutes.weber + WeberRoutes.community, needsId: true },

  // Wallet
  ER_POINTS_INCOME: {
    url: '',
    needsId: false,
  },

  // DAO
  DAO_MEMBER_SHIP_GROWTH: { url: '', needsId: false },

  // Friends
  FRIENDSHIP_REQUEST: {
    url: MainRoutes.auth + AuthRoutes.profile + ProfileRoutes.networking,
    needsId: false,
  },
  FRIEND_REQUEST_RECEIVED: {
    url: MainRoutes.auth + AuthRoutes.profile + ProfileRoutes.networking,
    needsId: false,
  },
  FRIEND_REQUEST_ACCEPTED: {
    url: MainRoutes.auth + AuthRoutes.profile + ProfileRoutes.networking,
    needsId: false,
  },

  RESTORING_LIVES: {
    url: MainRoutes.wiz + WizRoutes.quiz,
    needsId: false,
  },
  REFERRAL_OWNER_REWARD: {
    url: MainRoutes.auth + AuthRoutes.profile + ProfileRoutes.user,
    needsId: true,
  },
  REFERRAL_ACTIVATOR_REWARD: {
    url: MainRoutes.auth + AuthRoutes.profile + ProfileRoutes.user,
    needsId: true,
  },
};

export const Notifications = forwardRef<HTMLDivElement, NotificationsProps>(
  ({ className, onClose }, ref) => {
    const { t } = useTranslation(['translation']);
    const { fetchData, isLoading, totalPagesCount, notifications } =
      useNotifications();
    const navigate = useNavigate();
    const [currentPage, setPage] = useState(1);

    useEffect(() => {
      fetchData();
    }, [t]);

    const getNotificationIcon = (type: NotificationType) => {
      const IconComponent = icons[type];
      return <IconComponent />;
    };

    const handleNotificationClick = (url: string | null) => {
      if (url) {
        navigate(url);
      }
    };

    const getNotificationUrl = (
      constant: NotificationType,
      id?: number | string
    ) => {
      const { url, needsId } = urls[constant];
      if (url === '') {
        return null;
      }
      return needsId && id ? `${url}/${id}` : url;
    };

    const loadComments = useCallback(() => {
      if (totalPagesCount > currentPage) {
        setPage(currentPage + 1);
        fetchData(currentPage + 1);
      }
    }, [totalPagesCount, currentPage]);

    const targetRef = useRef<HTMLDivElement>(null);
    useInfiniteScroll(targetRef, loadComments);

    const notificationsRendered = useMemo(() => {
      if (!notifications) return [];

      return notifications.map((notify) => {
        const url = getNotificationUrl(notify.type, notify.related_object_id);
        return (
          <span
            key={notify.id}
            onClick={() => {
              handleNotificationClick(url);
              onClose?.();
            }}
            style={{ cursor: 'pointer' }} // Optional: Add pointer cursor to make it look clickable
          >
            <NotificationCard
              icon={getNotificationIcon(notify.type)}
              title={notify.title}
            />
          </span>
        );
      });
    }, [notifications]);

    return (
      <div className={classNames('Notifications', className)} ref={ref}>
        <h3>{t('notifications.title')}</h3>
        <div className="Notifications__wrapper">
          {notificationsRendered}
          <div ref={targetRef} />
          {isLoading && <Loader />}
        </div>
      </div>
    );
  }
);
