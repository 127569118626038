import classNames from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DotsIcons } from '../../../assets/icons/feed/dots.svg';
import { DropdownButton, IDropdownButton } from '../../../widgets/DropdownButton';
import { Course } from '../../../models/Course';

interface CourseDotsProps {
  className?: string;
  course: Course;
  onRemove?: (id: number) => void;
}

export const CourseDots = ({ className, course, onRemove }: CourseDotsProps) => {
  const { t } = useTranslation(['translation']);

  const buttons: IDropdownButton[] = useMemo(() => {
    const arr = [
      {
        title: t('buttons.Delete'),
        onClick: () => onRemove?.(course.id),
      },
    ];
    return arr;
  }, [t]);

  return (
    <DropdownButton
      variant="highlighted"
      color="white"
      className={classNames('!h-auto !w-[42px] !px-0 !py-0 md:!py-1', className)}
      buttons={buttons}
    >
      <DotsIcons />
    </DropdownButton>
  );
};
