import React from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ReactComponent as Document } from '../../assets/icons/document.svg';
import { ReactComponent as Drag } from '../../assets/icons/drag.svg';
import { ReactComponent as Trash } from '../../assets/icons/trash.svg';
import { ReactComponent as Upload } from '../../assets/icons/upload.svg';
import { ImageItem } from '../../models/Course';
import { Loader } from '../Loader';
import './styles.scss';

export type Props = {
  onChange?: (files: File[]) => void;
  uploadedFiles: ImageItem[];
  removeItem?: (fileId: number, isRemote?: boolean) => void;
  uploading?: boolean;
  deleting?: boolean;
  multi?: boolean;
  fileTypes?: string[];
  fileSize?: number;
  title?: string;
  text?: string;
  optional?: boolean;
  className?: string;
};

export const DragAndDrop: React.FC<Props> = ({
  onChange,
  uploadedFiles,
  removeItem,
  uploading,
  deleting,
  multi = true,
  fileTypes,
  fileSize,
  title,
  text,
  optional = true,
  className,
}) => {
  const { t } = useTranslation(['translation']);
  const handleChange = (value: FileList | File) => {
    if (multi) {
      const _files: File[] = [];
      for (let i = 0; i < (value as FileList).length; i++) {
        if (fileSize && (value as FileList)[i].size / (1024 * 1024) > fileSize) {
          toast(t('course.maximum_file_size') + ' ' + fileSize + ' MB', {
            type: 'error',
          });
        } else {
          _files.push((value as FileList)[i]);
        }
      }
      onChange?.(_files);
    } else {
      onChange?.([value as File]);
    }
  };

  return (
    <div className={`b-dad ${className}`}>
      {!!uploadedFiles.length ? (
        <>
          <p className={'b-dad__text'}>{t('attachedFiles')}</p>
          <p className={'b-dad__shadow-text'}>{t('fileThatHaveBeenAttached')}</p>
        </>
      ) : (
        <>
          {title && (
            <p className={'g-title-18 mb-2 text-[16px] lg:!text-[18px]'}>
              {title}{' '}
              {optional && (
                <span className={'b-visual__shadow'}>({t('course.optional')})</span>
              )}
            </p>
          )}
          <FileUploader
            handleChange={handleChange}
            multiple={multi}
            types={fileTypes}
          >
            <div className={'b-dad__area px-[15px]'}>
              {uploading ? <Loader /> : <Upload />}
              <p className={'b-dad__area__text text-center'}>{t('course.dad')}</p>
              <p className={'b-dad__area__shadow-text text-center'}>
                {text ? (
                  text
                ) : (
                  <>
                    {t('course.dadSupportedFormats')}:{' '}
                    {fileTypes
                      ? fileTypes.reduce((acc, curr) => `${acc} ${curr}`, '')
                      : 'JPEG, PNG, GIF, MP4, PDF, PSD, AI, Word, PPT'}
                  </>
                )}
              </p>
              {!!fileSize && (
                <p className={'b-dad__area__shadow-text text-center'}>
                  {t('course.maximum_file_size')}: {fileSize} MB
                </p>
              )}
            </div>
          </FileUploader>
        </>
      )}

      {deleting ? (
        <div className={'mt-4 flex items-center justify-center'}>
          <Loader />
        </div>
      ) : (
        <div className={'b-dad__list'}>
          {uploadedFiles.map((item, key) => {
            const arrayPath = item?.file?.split('/');
            const text = arrayPath?.[arrayPath.length - 1].split('?')[0] || '';
            return (
              <div
                key={key}
                className={'b-dad__list__item flex items-center justify-between'}
              >
                <div className={'flex items-center gap-x-3'}>
                  <Drag className={'b-dad__list__item__drag'} />
                  <Document />
                  <p className={'b-dad__list__item__text'}>{decodeURI(text)}</p>
                </div>
                {!!removeItem && (
                  <Trash
                    className={'b-dad__list__item__trash'}
                    onClick={() => removeItem?.(item.id, item.isRemote)}
                  />
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
