import classNames from 'classnames';
import { useState } from 'react';
import Avatar from '../../../assets/images/no-avatar.png';
import LinkPostImg from '../../../assets/images/link-post.jpeg';
import './NoAvatar.scss';

interface NoAvatarProps {
  className?: string;
  alt?: string;
  src?: string;
  isPost?: boolean;
}

export const NoAvatar = ({
  className,
  alt = '',
  src,
  isPost = false,
}: NoAvatarProps) => {
  const [avatar, setAvatar] = useState(src);
  const handleImageError = () => setAvatar(isPost ? LinkPostImg : Avatar);
  return (
    <img
      src={avatar || (isPost ? LinkPostImg : Avatar)}
      onError={handleImageError}
      alt={alt}
      className={classNames('NoAvatar', className)}
    />
  );
};
