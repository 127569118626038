import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ShareIcon } from '../../../assets/icons/feed/share.svg';
import { MButton } from '../../../horizon-components/MButton';
import { SimpleModal } from '../../../horizon-components/SimpleModal/SimpleModal';
import { Share } from '../../../components/Share/Share';

interface ShareModalProps {
  url: string;
  className?: string;
}

export const ShareModal = ({ url, className }: ShareModalProps) => {
  const { t } = useTranslation(['translation']);
  const [isShowShare, setIsShowShare] = useState(false);
  return (
    <>
      <MButton
        color="primary"
        variant="secondary"
        className={classNames('!border-0 !opacity-100', className)}
        onClick={() => {
          setIsShowShare(true);
        }}
      >
        <span>{t('wiz.Share')}</span> <ShareIcon />
      </MButton>

      <SimpleModal isOpen={isShowShare} onClose={() => setIsShowShare(false)}>
        <div>
          <h3 className={'text-sm font-bold text-secondary-grey-900 md:text-lg'}>
            {t('wiz.Share_via')}
          </h3>
          <Share url={url} loading={!url} />
        </div>
      </SimpleModal>
    </>
  );
};
