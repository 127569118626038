import { useCallback, useEffect, useState } from 'react';
import {
  deleteLikeFeedReview,
  setLikeFeedReview,
} from '../../../../../api/weber/feed';

export const useCommentLikeFeed = (
  commentId: number,
  userReaction: boolean,
  likesCount: number
) => {
  const [likes, setLikes] = useState<number>(0);
  const [isLiked, setIsLiked] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onLike = useCallback(() => {
    setIsLoading(true);
    setLikeFeedReview(commentId)
      .then(() => {
        setLikes(!isLiked ? likes + 1 : likes - 1);
        setIsLiked(!isLiked);
      })
      .finally(() => setIsLoading(false));
  }, [isLiked, likes, commentId]);

  const onDeleteLike = useCallback(() => {
    setIsLoading(true);
    deleteLikeFeedReview(commentId)
      .then(() => {
        setLikes(!isLiked ? likes + 1 : likes - 1);
        setIsLiked(!isLiked);
      })
      .finally(() => setIsLoading(false));
  }, [isLiked, likes, commentId]);

  useEffect(() => {
    setIsLiked(userReaction);
    setLikes(likesCount);
  }, [likesCount, userReaction]);

  return {
    onLike,
    isLoading,
    isLiked,
    likes,
    onDeleteLike,
    setIsLiked,
  };
};
