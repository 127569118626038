import { useCommentLike } from '../../../entities/Review';
import { Like } from '../../../widgets/Like';

interface LikeProps {
  likesCount?: number;
  userReaction?: boolean;
  reviewId: number;
}

export const LikeCourseComment = ({
  likesCount = 0,
  userReaction = false,
  reviewId,
}: LikeProps) => {
  const { isLiked, onLike, likes, isLoading } = useCommentLike(
    reviewId,
    userReaction,
    likesCount
  );
  return <Like active={isLiked} likes={likes} loading={isLoading} onLike={onLike} />;
};
