import { useCallback, useState } from 'react';
import { ResponsePagination } from '../../../../../models/Response';
import { DEFAULT_LIMIT } from '../../../../../shared/lib/const/limit';
import { getFeedComments } from '../../../../../api/weber/feed';
import { TFeedComments } from '../../../../../models/Feed';
import { useTranslation } from 'react-i18next';

export const usePostComments = () => {
  const { t } = useTranslation(['translation']);
  const [data, setData] = useState<ResponsePagination<TFeedComments> | null>(null);
  const [dataScroll, setDataScroll] = useState<TFeedComments[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentPage, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const total = data ? Math.ceil(data?.count / DEFAULT_LIMIT) : 0;

  const fetchData = useCallback(
    (postId: number, page: number) => {
      setIsLoading(true);
      getFeedComments(postId, page)
        .then((res) => {
          page && setPage(page);
          setData(res);
          res?.results?.length &&
            setDataScroll(
              page !== 1 ? [...dataScroll, ...res.results] : res.results
            );
          res?.count && setCount(res.count);
        })
        .finally(() => setIsLoading(false));
    },
    [dataScroll, t]
  );

  return {
    dataScroll,
    data,
    currentPage,
    total,
    isLoading,
    count,
    fetchData,
    setPage,
    setDataScroll,
  };
};
