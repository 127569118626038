import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { getDomainName } from '../../../utils/getDomainName';
import LinkPostImg from '../../../assets/images/link-post.jpeg';
import './FeedThumb.scss';

interface FeedThumbProps {
  className?: string;
  title?: string;
  thumb?: string;
  onClick?: () => void;
  link?: string;
  minRead?: number;
}

export const FeedThumb = ({
  className,
  title,
  thumb,
  onClick,
  link,
  minRead,
}: FeedThumbProps) => {
  const { t } = useTranslation(['translation']);
  return (
    <div
      className={classNames('FeedThumb', className, { 'cursor-pointer': onClick })}
      onClick={onClick}
    >
      <img src={thumb || LinkPostImg} alt={title} />
      <div className="FeedThumb__content">
        {!!title && <h3>{title}</h3>}
        <div className="flex gap-[6px] md:gap-2">
          {!!link && <span>{getDomainName(link)}</span>}
          {!!(link && minRead) && <span className="!font-semibold">•</span>}
          {!!minRead && (
            <span>
              {minRead} {t('feed.min_read')}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
