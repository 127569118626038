import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { MButton } from '../../../horizon-components/MButton';
import { ReactComponent as FavoriteIcon } from '../../../assets/icons/feed/favorite-folder.svg';
import { ReactComponent as CheckIcon } from '../../../assets/icons/nft/check.svg';
import { ReactComponent as Plus } from '../../../assets/icons/plus.svg';
import { TFavoriteFolders } from '../../../models/Feed';
import { useEffect, useState } from 'react';
import './FavoritePostModal.scss';
import { useParams } from 'react-router-dom';

interface FavoritePostModalProps {
  className?: string;
  folderId?: number;
  folders?: TFavoriteFolders[];
  onCreateFolder?: () => void;
  onSave?: (folderId?: number | null) => void;
}

export const FavoritePostModal = ({
  className,
  folderId,
  folders,
  onCreateFolder,
  onSave,
}: FavoritePostModalProps) => {
  const { id } = useParams();
  const { t } = useTranslation(['translation']);
  const [current, setCurrent] = useState<number | null>(folderId || null);

  const hndlSave = () => {
    onSave?.(current);
  };

  useEffect(() => {
    id &&
      setCurrent(folders?.find((folder) => folder.id === Number(id))?.id || null);
  }, [folders]);

  return (
    <div className={classNames('FavoritePostModal', className)}>
      <div
        className={classNames('FavoritePostModal__item')}
        onClick={() => setCurrent(null)}
      >
        <div className="FavoritePostModal__img">
          <FavoriteIcon />
        </div>
        <h3>{t('feed.all_favorites')}</h3>
        {current === null && <CheckIcon className="selected" />}
      </div>
      {folders?.map((folder) => (
        <div
          className={classNames('FavoritePostModal__item')}
          onClick={() => setCurrent(folder.id)}
          key={folder.id}
        >
          <div className="FavoritePostModal__img">
            {folder.image ? (
              <img src={folder.image} alt={folder.title} />
            ) : (
              <FavoriteIcon />
            )}
          </div>
          <h3>{folder.title}</h3>
          {current === folder.id && <CheckIcon className="selected" />}
        </div>
      ))}
      <div className="FavoritePostModal__btns">
        <MButton variant="outline" color="primary" onClick={onCreateFolder}>
          <Plus /> {t('buttons.Create_a_folder')}
        </MButton>
        <MButton variant="highlighted" color="primary" onClick={hndlSave}>
          {t('buttons.Save')}
        </MButton>
      </div>
    </div>
  );
};
