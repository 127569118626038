import classNames from 'classnames';
import { Answer } from '../../../../api/wiz/wiz';
import { MButton } from '../../../../horizon-components/MButton/ui/MButton';
import './QuizzesAnswers.scss';

interface QuizzesAnswersProps {
  answers: Answer[];
  onSelect: (id: number) => void;
  selected?: number | null;
}

export const QuizzesAnswers = ({
  answers,
  onSelect,
  selected,
}: QuizzesAnswersProps) => {
  return (
    <div className="QuizzesAnswers">
      {answers.map((answer) => (
        <MButton
          className={classNames({ active: selected === answer.id })}
          onClick={() => onSelect(answer.id)}
          key={answer.id}
        >
          {answer.text}
        </MButton>
      ))}
    </div>
  );
};
