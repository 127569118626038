import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DotsIcons } from '../../../assets/icons/feed/dots.svg';
import { ReactComponent as UserIcons } from '../../../assets/icons/feed/user.svg';
import { Modal } from '../../../horizon-components/Modal';
import { DropdownButton, IDropdownButton } from '../../../widgets/DropdownButton';
import { EditRoleModal } from '../../../widgets/EditRoleModal';
import { GetUserData } from '../../../utils/getUserData';
import { UserCommunity, UserCommunityRole } from '../../../models';

interface NetworkingDotsProps {
  className?: string;
  user?: GetUserData;
  member?: UserCommunity;
  onRole?: (role: UserCommunityRole) => void;
  onDemote?: () => void;
}

export const NetworkingDots = ({
  className,
  user,
  member,
  onRole,
  onDemote,
}: NetworkingDotsProps) => {
  const { t } = useTranslation(['translation']);
  const [buttons, setButtons] = useState<IDropdownButton[]>([]);
  const [isEditRole, setIsEditRole] = useState(false);

  useEffect(() => {
    const arr: IDropdownButton[] = [
      {
        title: t('buttons.Edit_a_role'),
        icon_right: <UserIcons />,
        onClick: () => hndlOpenEditRoleModal(),
      },
    ];
    setButtons(arr.filter(Boolean) as IDropdownButton[]);
  }, [t]);

  const hndlOpenEditRoleModal = () => {
    setIsEditRole(!isEditRole);
  };

  const hndlEditRole = (role: UserCommunityRole) => {
    setIsEditRole(!isEditRole);
    onRole?.(role);
  };

  const hndlDemote = () => {
    setIsEditRole(!isEditRole);
    onDemote?.();
  };

  return (
    <DropdownButton
      variant="highlighted"
      color="white"
      className={classNames('!w-[42px] !px-0', className)}
      buttons={buttons}
    >
      <DotsIcons />
      <Modal
        isOpen={isEditRole}
        onClose={hndlOpenEditRoleModal}
        title="Назначить роль"
      >
        <EditRoleModal
          onSave={hndlEditRole}
          onDemote={hndlDemote}
          user={user}
          role={member?.role}
          className="max-w-96"
        />
      </Modal>
    </DropdownButton>
  );
};
