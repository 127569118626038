import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Bread, Breadcrumbs } from '../../../components/Breadcrumbs';
import {
  AuctionRoutes,
  MainRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { MGallery } from '../../../horizon-components/MGallery/MGallery';
import { MButton } from '../../../horizon-components/MButton';
import { ReactComponent as AddBidIcon } from '../../../assets/icons/add-bid.svg';
import { ReactComponent as SubBidIcon } from '../../../assets/icons/sub-bid.svg';
import { ReactComponent as ChevronRightPurpleIcon } from '../../../assets/icons/chevron.right.purple.svg';
import classNames from 'classnames';
import {
  deleteAuctionNotifications,
  getAuctionById,
  getBids,
  placeBid,
  setAuctionNotifications,
} from '../../../api/bid';
import { Modal } from '../../../horizon-components/Modal';
import { Rules } from '../About/Rules';
import { WinnerForm } from './WinnerForm';
import { Winner } from './Winner';
import { Auction, Bid, BidStatusEnum } from '../../../models/Auction';
import { Loader } from '../../../components/Loader';
import { toast } from 'react-toastify';
import { useUserContext } from '../../../providers';
import moment from 'moment';
import { MInput } from '../../../horizon-components/MInput/MInput';
import { useTitleBackContext } from '../../../providers/TitleBackProvider/TitleBackProvider';
import { CoinIcon } from '../../../components/CoinIcon';
import { useBidContext } from '../../../providers/BidProvider/BidProvider';
import useFetch from '../../../hooks/useFetch';
import getErrorMessages from '../../../utils/getErrorMessages';

export const AuctionView: React.FC = () => {
  const { t } = useTranslation(['translation']);
  const { id } = useParams();
  const { getMyBidForAuction, getWonAuctionInfo, fetchMyBids } = useBidContext();
  const [auction, setAuction] = useState<Auction | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSendBidMode, setIsSendBidMode] = useState(false);
  const [isDescriptionShowMoreMode, setIsDescriptionShowMoreMode] = useState(false);
  const [rulesModalIsOpen, setRulesModalIsOpen] = useState(false);
  const [winnerFormModalIsOpen, setWinnerFormModalIsOpen] = useState(false);
  const [winnerModalIsOpen, setWinnerModalIsOpen] = useState(false);
  const [actualBid, setActualBid] = useState(0);
  const [isSending, setIsSending] = useState(false);
  const [isAllBidsMode, setIsAllBidsMode] = useState(false);
  const [isChecked, setIsChecked] = useState<number | null>(null);
  const { user, wallet, updateWallet } = useUserContext();
  const spanRef = useRef<HTMLSpanElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const { useTitleBack } = useTitleBackContext();
  const myBid = id ? getMyBidForAuction(parseInt(id)) : 0;
  const { isAuctionWon, isRewardClaimed } = id
    ? getWonAuctionInfo(parseInt(id))
    : { isAuctionWon: false, isRewardClaimed: false };

  const er = wallet ? Math.floor(parseFloat(wallet.value)) : 0;

  const getBidsCb = useCallback(async () => {
    return id ? await getBids(id) : undefined;
  }, [id]);

  const {
    data: bidsData,
    isLoading: isBidsLoading,
    fetchData: fetchBids,
  } = useFetch(getBidsCb);

  useEffect(() => {
    if (spanRef.current && inputRef.current) {
      // Обновляем ширину input на основе ширины span
      const spanWidth = spanRef.current.offsetWidth;
      inputRef.current.style.width = `${spanWidth * 2}px`; // Добавляем немного дополнительного пространства
    }
  }, [actualBid, isSendBidMode]);

  const getAuction = useCallback(() => {
    setIsLoading(true);
    getAuctionById(id || '')
      .then((data) => {
        setAuction(data);
        !!data.highest_bid && setActualBid(Math.round(parseFloat(data.highest_bid)));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  useEffect(() => {
    getAuction();
    fetchBids();
  }, [getAuction]);

  useEffect(() => {
    if (
      !isRewardClaimed &&
      auction?.status === BidStatusEnum.COMPLETED &&
      isAuctionWon
    ) {
      setWinnerFormModalIsOpen(true);
    }
  }, [auction, user?.guid]);

  const breads: Bread[] = useMemo(
    () => [
      { title: t('bid.Bid'), url: MainRoutes.bid + AuctionRoutes.actual },
      {
        title:
          auction?.status === BidStatusEnum.COMPLETED && auction?.number_winners
            ? t('menu.Winnings')
            : t('menu.Actual'),
        url:
          MainRoutes.bid +
          (auction?.status === BidStatusEnum.COMPLETED && auction?.number_winners
            ? AuctionRoutes.winnings
            : AuctionRoutes.actual),
      },
      {
        title: t('bid.Auction'),
        url: MainRoutes.bid + AuctionRoutes.auctionView + `/:${id}`,
      },
    ],
    [id, t, auction]
  );

  const sendBid = () => {
    if (isSendBidMode) {
      setIsSending(true);
      placeBid(Number(id || '0'), actualBid.toString())
        .then(() => {
          getAuction();
          fetchMyBids();
          fetchBids();
          updateWallet();
        })
        .catch((error) => {
          toast(getErrorMessages(error?.response?.data), {
            type: 'error',
          });
        })
        .finally(() => {
          setIsSendBidMode(false);
          setIsSending(false);
        });
    } else {
      setIsSendBidMode(true);
    }
  };

  const toggleAllBids = useCallback(() => {
    setIsAllBidsMode(!isAllBidsMode);
  }, []);

  useTitleBack(
    isAllBidsMode ? t('bid.allBids') : null,
    isAllBidsMode ? null : MainRoutes.bid + AuctionRoutes.actual,
    isAllBidsMode ? toggleAllBids : null
  );

  const toggleDescriptionShowMoreMode = () => {
    setIsDescriptionShowMoreMode(!isDescriptionShowMoreMode);
  };

  const add = () => setActualBid((v) => v + 1);
  const sub = () =>
    actualBid - 1 > parseFloat(auction?.highest_bid || '0') &&
    setActualBid((v) => v - 1);

  const images = useMemo(
    () => auction?.images.map((item) => item.image) || [],
    [auction]
  );

  const bids = useMemo(() => {
    if (isAllBidsMode) return bidsData?.results || [];
    return bidsData?.results.filter((_, i) => i < 3) || [];
  }, [bidsData, isAllBidsMode]);

  const renderBidItem = useCallback(
    (bid: Bid, index: number, arr: Bid[]) => (
      <div
        key={index}
        className={classNames('mt-[12px] flex justify-between pb-[12px]', {
          'border-b-[1px] border-solid border-purple-100': index !== arr.length - 1,
        })}
      >
        <div className={'flex items-center gap-[10px]'}>
          <img
            src={
              bid.user.user_meta.profile_image ||
              require('../../../assets/images/no-avatar.png')
            }
            className={'h-[40px] w-[40px] min-w-[40px] rounded-full object-cover'}
            alt={'av'}
          />
          <div>
            <p className={'text-[16px] font-bold text-secondaryGrey-900'}>
              {bid.user.user_meta.username}
            </p>
            <p className={'font-500 text-[14px] text-gray-500'}>
              {moment(bid.created_at).fromNow()}
            </p>
          </div>
        </div>
        <div className={'flex flex-col items-end'}>
          <p className={'text-[16px] font-[600] text-secondaryGrey-800'}>
            {bid.bid_amount}
          </p>
          <p
            className={`text-[12px] font-bold ${
              index + 1 <= (auction?.number_winners || 0)
                ? 'text-purple-500'
                : 'text-gray-400'
            }`}
          >
            {auction?.status === BidStatusEnum.ACTIVE ||
            auction?.status === BidStatusEnum.COMPLETED
              ? bid.is_winner
                ? t('bid.Winner')
                : index === 0
                  ? t('bid.TopBid').toUpperCase()
                  : `${index + 1} ${t('bid.place')}`
              : ''}
          </p>
        </div>
      </div>
    ),
    [auction?.status, t]
  );

  const RenderBids = useMemo(() => bids.map(renderBidItem), [bids, renderBidItem]);
  // const RenderPlaces = useMemo(
  //   () => places?.map(renderBidItem),
  //   [places, renderBidItem]
  // );

  const winners = useMemo(
    () => bidsData?.results.filter((item) => item.is_winner) || [],
    [bidsData]
  );
  const others = useMemo(
    () => bidsData?.results.filter((item) => !item.is_winner) || [],
    [bidsData]
  );

  const isMultiWinners = useMemo(() => winners?.length > 1, [winners?.length]);

  const RenderWinners = useMemo(
    () =>
      auction?.status === BidStatusEnum.COMPLETED
        ? (
            <div onClick={() => setWinnerModalIsOpen(true)}>
              {winners?.map(renderBidItem)}
            </div>
          ) || null
        : null,
    [auction?.status, renderBidItem, winners]
  );
  const RenderOthers = useMemo(
    () =>
      auction?.status === BidStatusEnum.COMPLETED
        ? others?.map(renderBidItem) || null
        : null,
    [auction?.status, others, renderBidItem]
  );

  const hndlCheck = () => {
    if (auction?.id) {
      if (!isChecked) {
        setAuctionNotifications(auction?.id).then((res) => setIsChecked(res?.id));
      } else {
        isChecked &&
          deleteAuctionNotifications(isChecked).then(() => setIsChecked(null));
      }
    }
  };

  return (
    <div>
      <div className="container mb-[10px] hidden md:block">
        <Breadcrumbs breads={breads} />
      </div>
      {isLoading && <Loader />}
      {window.innerWidth <= 768 && isAllBidsMode ? (
        auction?.status === BidStatusEnum.COMPLETED && isMultiWinners ? (
          <>
            <div
              className={
                'rounded-[16px] p-[8px] !shadow-[14px_17px_40px_4px_#7090B014]'
              }
            >
              <p className={'text-[14px] font-[600] text-gray-900'}>
                {t('bid.winningBids')}
              </p>
              {RenderWinners}
            </div>
            <div
              className={
                'rounded-[16px] p-[8px] !shadow-[14px_17px_40px_4px_#7090B014]'
              }
            >
              <p className={'text-[14px] font-[600] text-gray-900'}>
                {t('bid.otherBids')}
              </p>
              {RenderOthers}
            </div>
          </>
        ) : (
          <div
            className={
              'rounded-[16px] p-[8px] !shadow-[14px_17px_40px_4px_#7090B014]'
            }
          >
            {isBidsLoading ? <Loader /> : RenderBids}
          </div>
        )
      ) : (
        <div className={'flex flex-col gap-[20px] md:flex-row'}>
          <div className={'w-full md:w-[355px] lg:w-[555px]'}>
            {!!images.length && <MGallery sources={images} />}
          </div>
          <div
            className={'w-full md:w-[calc(100%_-_335px)] lg:w-[calc(100%_-_535px)]'}
          >
            <p className={'mb-[16px] text-[24px] font-[600] text-gray-900'}>
              {auction?.title}
            </p>
            {!!auction && auction?.status === BidStatusEnum.COMPLETED && (
              <div
                className={'mb-[16px] rounded-[16px] bg-secondaryGrey-300 p-[16px]'}
              >
                <p className={'text-center text-[24px] font-[600] text-gray-800'}>
                  {t('bid.finished')}
                </p>
              </div>
            )}
            {auction?.status === BidStatusEnum.ACTIVE && (
              <MButton
                variant={'highlighted'}
                color={'primary'}
                size={'sm'}
                className={'mb-[16px] w-full md:!hidden'}
                onClick={sendBid}
                disabled={isSending}
              >
                {t('bid.placeBid')}
              </MButton>
            )}
            {auction?.status !== 'planned' && (
              <div
                className={
                  'mb-[16px] rounded-[24px] p-[16px] !shadow-[4px_4px_24px_4px_#7090B014]'
                }
              >
                <div
                  className={
                    'mb-[10px] flex items-center justify-between gap-[20px]'
                  }
                >
                  {isSendBidMode && window.innerWidth >= 768 ? (
                    <div className={'flex w-full items-center justify-between'}>
                      <div
                        onClick={sub}
                        className={
                          'flex h-[34px] min-w-[34px] cursor-pointer items-center justify-center rounded-full bg-secondaryGrey-300'
                        }
                      >
                        <SubBidIcon />
                      </div>
                      <div className={'flex items-center gap-[6px]'}>
                        <CoinIcon />
                        <div ref={inputRef}>
                          <MInput
                            value={`${actualBid}`}
                            onChange={(value) => setActualBid(Number(value))}
                            type="number"
                            inputClassName="!border-none text-[30px] font-[600] text-purple-500 w-full !p-0"
                          />
                        </div>
                        <span
                          ref={spanRef}
                          style={{
                            position: 'absolute',
                            visibility: 'hidden',
                            whiteSpace: 'pre',
                          }}
                        >
                          {actualBid}
                        </span>
                      </div>
                      <div
                        onClick={add}
                        className={
                          'flex h-[34px] min-w-[34px] cursor-pointer items-center justify-center rounded-full bg-purple-100'
                        }
                      >
                        <AddBidIcon />
                      </div>
                    </div>
                  ) : (
                    <div className={'flex w-full items-center justify-between'}>
                      <div className={'flex w-[49%] flex-col items-center'}>
                        <div className={'flex items-center gap-[4px]'}>
                          <CoinIcon />
                          <p className={'text-[18px] font-[600] text-gray-800'}>
                            {auction?.highest_bid || 0}
                          </p>
                        </div>
                        <p className={'text-[12px] font-[500] text-gray-600'}>
                          {t('bid.TopBid')}
                        </p>
                      </div>
                      <div className={'h-[42px] w-[1px] bg-purple-200'} />
                      <div className={'flex w-[49%] flex-col items-center'}>
                        <div className={'flex items-center gap-[4px]'}>
                          {auction?.status !== BidStatusEnum.COMPLETED && (
                            <CoinIcon />
                          )}
                          <p className={'text-[18px] font-[600] text-gray-800'}>
                            {auction?.status === BidStatusEnum.COMPLETED
                              ? auction?.bidders_count || 0
                              : myBid || 0}
                          </p>
                        </div>
                        <p className={'text-[12px] font-[500] text-gray-600'}>
                          {auction?.status === BidStatusEnum.COMPLETED
                            ? t('bid.biddersCount')
                            : t('bid.YourBid')}
                        </p>
                      </div>
                    </div>
                  )}
                  {auction?.status === BidStatusEnum.ACTIVE && (
                    <MButton
                      variant={'highlighted'}
                      color={'primary'}
                      size={'sm'}
                      className={'!hidden min-w-[120px] md:!flex'}
                      onClick={sendBid}
                      disabled={isSending}
                    >
                      {t('bid.placeBid')}
                    </MButton>
                  )}
                </div>
                {!!myBid && auction?.status === BidStatusEnum.ACTIVE && (
                  <div
                    className={
                      'mb-[10px] flex flex-col items-center rounded-[12px] bg-green-100 p-[8px]'
                    }
                  >
                    <p className={'text-12px font-500 mb-[4px] text-green-500'}>
                      {t('bid.placeYourBid')}
                    </p>
                    <p className={'text-14px font-500 text-secondaryGrey-900'}>
                      <span>{t('bid.placeYourBidBottom')}</span> <b>{er || 0}</b>
                    </p>
                  </div>
                )}
                <div
                  className={
                    'rounded-[16px] p-[8px] !shadow-[14px_17px_40px_4px_#7090B014]'
                  }
                >
                  {auction?.status === BidStatusEnum.COMPLETED && isMultiWinners ? (
                    <>
                      <div className={'flex items-center justify-between'}>
                        <p className={'text-[16px] font-[600] text-gray-900'}>
                          {t('bid.winningBids')}
                        </p>
                        <div
                          className={
                            'rounded-full bg-purple-100 px-[8px] py-[2px] text-[12px] font-[600] text-purple-400'
                          }
                        >
                          {winners?.length}
                        </div>
                      </div>
                      {RenderWinners}
                      <div className={'flex items-center justify-between'}>
                        <p className={'text-[16px] font-[600] text-gray-900'}>
                          {t('bid.otherBids')}
                        </p>
                        <div
                          className={
                            'rounded-full bg-purple-100 px-[8px] py-[2px] text-[12px] font-[600] text-purple-400'
                          }
                        >
                          {others?.length}
                        </div>
                      </div>
                      {RenderOthers}
                    </>
                  ) : (
                    <>
                      <div className={'flex items-center justify-between'}>
                        <p className={'text-[16px] font-[600] text-gray-900'}>
                          {t('bid.Bids')}
                        </p>
                        <div
                          className={
                            'rounded-full bg-purple-100 px-[8px] py-[2px] text-[12px] font-[600] text-purple-400'
                          }
                        >
                          {bidsData?.count || 0}
                        </div>
                      </div>
                      {RenderBids}
                    </>
                  )}
                </div>
                {!!bidsData?.results.length && bidsData.results.length > 3 && (
                  <div className={'flex w-full justify-end'}>
                    <MButton
                      variant={'transparent'}
                      color={'primary'}
                      onClick={toggleAllBids}
                    >
                      {t('bid.ShowAll')}
                    </MButton>
                  </div>
                )}
              </div>
            )}
            <div
              className={
                'mb-[16px] rounded-[24px] p-[16px] !shadow-[4px_4px_24px_4px_#7090B014]'
              }
            >
              <p className={'text-[16px] font-[600] text-gray-900'}>
                {t('bid.aboutThisLot')}
              </p>
              <p
                className={`text-[14px] font-[500] text-gray-900 ${
                  !isDescriptionShowMoreMode && 'line-clamp-3'
                }`}
              >
                {auction?.about}
              </p>
              <div className={'flex w-full justify-end'}>
                <MButton
                  variant={'transparent'}
                  color={'primary'}
                  onClick={toggleDescriptionShowMoreMode}
                >
                  {isDescriptionShowMoreMode ? t('bid.ShowLess') : t('bid.ShowMore')}
                </MButton>
              </div>
            </div>
            <div
              className={
                'flex cursor-pointer items-center justify-between rounded-[24px] p-[16px] !shadow-[4px_4px_24px_4px_#7090B014]'
              }
              onClick={() => setRulesModalIsOpen(true)}
            >
              <p className={'text-[16px] font-[600] text-gray-900'}>
                {t('bid.Rules')}
              </p>
              <ChevronRightPurpleIcon />
            </div>
            <Modal
              modalId={'rules-modal'}
              isOpen={rulesModalIsOpen}
              onClose={() => setRulesModalIsOpen(false)}
              title={t('bid.Rules')}
            >
              <Rules />
            </Modal>
            {auction && (
              <Modal
                modalId={'winner-form-modal'}
                isOpen={winnerFormModalIsOpen}
                onClose={() => setWinnerFormModalIsOpen(false)}
                showClose={false}
                bodyClassName={`max-w-[520px]`}
              >
                <WinnerForm
                  auctionId={Number(id || '0')}
                  auction={auction}
                  onClose={() => setWinnerFormModalIsOpen(false)}
                />
              </Modal>
            )}
            {auction && (
              <Modal
                modalId={'winned-modal'}
                isOpen={winnerModalIsOpen}
                onClose={() => setWinnerModalIsOpen(false)}
                title={isMultiWinners ? 'Our winners!' : 'Our winner!'}
                bodyClassName={`max-w-[390px]`}
              >
                <Winner
                  isMultiWinners={winners?.length > 1}
                  winners={winners}
                  auction={auction}
                />
              </Modal>
            )}
            {window.innerWidth > 768 && (
              <Modal
                modalId={'bids-full-modal'}
                isOpen={isAllBidsMode}
                onClose={() => setIsAllBidsMode(false)}
                title={t('bid.allBids')}
                bodyClassName={`max-w-[390px]`}
              >
                <div className={'max-h-[70vh] overflow-y-auto pr-4'}>
                  {isBidsLoading ? <Loader /> : RenderBids}
                </div>
              </Modal>
            )}
            {window.innerWidth <= 768 && (
              <Modal
                isOpen={isSendBidMode}
                onClose={() => setIsSendBidMode(false)}
                bodyClassName={'bottom-[10px] fixed left-0 !h-fit'}
                title={t('bid.placeBid')}
              >
                <div>
                  {bids?.[0] && (
                    <div
                      className={
                        'flex justify-between rounded-[12px] bg-secondaryGrey-200 p-[8px]'
                      }
                    >
                      <div className={'flex items-center gap-[10px]'}>
                        <img
                          src={
                            bids[0].user.user_meta.profile_image ||
                            require('../../../assets/images/no-avatar.png')
                          }
                          className={
                            'h-[40px] w-[40px] min-w-[40px] rounded-full object-cover'
                          }
                          alt={'av'}
                        />
                        <div>
                          <p
                            className={
                              'text-[16px] font-bold text-secondaryGrey-900'
                            }
                          >
                            {bids[0].user.user_meta.username}
                          </p>
                          {/*<p className={'text-gray-500 font-500 text-[14px]'}>12min ago</p>*/}
                        </div>
                      </div>
                      <div className={'flex flex-col items-end'}>
                        <p
                          className={'text-[16px] font-[600] text-secondaryGrey-800'}
                        >
                          {bids[0].bid_amount}
                        </p>
                        <p className={'text-[12px] font-bold text-gray-400'}>
                          {t('bid.TopBid').toUpperCase()}
                        </p>
                      </div>
                    </div>
                  )}
                  <p
                    className={
                      'mb-[6px] mt-[16px] text-center text-[12px] font-[500] text-secondaryGrey-600'
                    }
                  >
                    {t('bid.MinimumBid')}
                  </p>
                  <div
                    className={'mb-[16px] flex w-full items-center justify-between'}
                  >
                    <div
                      onClick={sub}
                      className={
                        'flex h-[34px] min-w-[34px] cursor-pointer items-center justify-center rounded-full bg-secondaryGrey-300'
                      }
                    >
                      <SubBidIcon />
                    </div>
                    <div className={'flex items-center gap-[6px]'}>
                      <CoinIcon />
                      <p className={'text-[30px] font-[600] text-purple-500'}>
                        {actualBid}
                      </p>
                    </div>
                    <div
                      onClick={add}
                      className={
                        'flex h-[34px] min-w-[34px] cursor-pointer items-center justify-center rounded-full bg-purple-100'
                      }
                    >
                      <AddBidIcon />
                    </div>
                  </div>
                  {!!myBid && (
                    <div
                      className={
                        'mb-[16px] flex flex-col items-center rounded-[12px] bg-green-100 p-[8px]'
                      }
                    >
                      <p className={'text-12px font-500 mb-[4px] text-green-500'}>
                        {t('bid.placeYourBid')}
                      </p>
                      <p className={'text-14px font-500 text-secondaryGrey-900'}>
                        {t('bid.placeYourBidBottom')} <b>{er || '0.00'}</b>
                      </p>
                    </div>
                  )}
                  <MButton
                    variant={'highlighted'}
                    color={'primary'}
                    size={'sm'}
                    className={'w-full'}
                    onClick={sendBid}
                    disabled={isSending}
                  >
                    {t('bid.placeBid')}
                  </MButton>
                </div>
              </Modal>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
