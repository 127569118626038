import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './style.scss';

interface MainPageLearnMoreProps {
  className?: string;
}

export const MainPageLearnMore = ({ className }: MainPageLearnMoreProps) => {
  const { t } = useTranslation(['translation']);
  return (
    <Link to={'https://t.me/EdRes_edu_bot'} target="_blank">
      <div className={classNames('MainPageLearnMore', className)}>
        <div className={classNames('MainPageLearnMore__content')}>
          <h4>{t('main.learn_more_about_edres')}</h4>
          <p>
            {t('main.learn_more_about_edres_description')}{' '}
            <span>{t('main.learn_more_about_edres_link')}</span>
          </p>
        </div>
      </div>
    </Link>
  );
};
