import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { creatChat } from '../../../../api/weber/chat';
import { ReactComponent as ChatIcon } from '../../../../assets/icons/profile/chat.svg';
import { ReactComponent as EditIcon } from '../../../../assets/icons/profile/edit.svg';
import { ReactComponent as ShareIcon } from '../../../../assets/icons/profile/share.svg';
import { Bread, Breadcrumbs } from '../../../../components/Breadcrumbs';
import { useNavigate } from '../../../../hooks/useNavigate';
import { useShareReferal } from '../../../../hooks/useShareReferal';
import { MButton } from '../../../../horizon-components/MButton/ui/MButton';
import {
  AuthRoutes,
  EduRoutes,
  MainRoutes,
  ProfileRoutes,
} from '../../../../horizon-layout/MainLayout/Routes/types/routes';
import { useUserContext } from '../../../../providers';
import { useTitleBackContext } from '../../../../providers/TitleBackProvider/TitleBackProvider';
import { useRewardContext } from '../../../../providers/RewardProvider';

export const PublicProfileHeader: React.FC = () => {
  const { t } = useTranslation(['translation']);
  const navigate = useNavigate();
  const { user } = useUserContext();
  const { id: anotherUserGuid } = useParams();
  const { useTitleBack } = useTitleBackContext();
  const { handleClick } = useShareReferal();
  const { isLoading } = useRewardContext();

  const goToChat = () => {
    creatChat(anotherUserGuid || '').finally(() => {
      navigate(MainRoutes.auth + AuthRoutes.profile + ProfileRoutes.chat);
    });
  };

  const isCurrentUser = useMemo(
    () =>
      anotherUserGuid && user?.guid ? anotherUserGuid === user?.guid : !!user?.guid,
    [user?.guid, anotherUserGuid]
  );

  const buttons = useMemo(() => {
    return (
      <div className="flex flex-row justify-between gap-2 lg:gap-6">
        {!!isCurrentUser ? (
          <>
            <MButton
              color="white"
              variant="highlighted"
              className="shadow-md shadow-shadow-600 sm-max:!h-[42px] sm-max:!w-[42px] md-max:!px-2.5"
              onClick={() =>
                navigate(MainRoutes.auth + AuthRoutes.profile + ProfileRoutes.edit)
              }
            >
              <span className="hidden md:block">{t('edit')}</span>
              <EditIcon />
            </MButton>
            <MButton
              color="white"
              variant="highlighted"
              className="shadow-md shadow-shadow-600 sm-max:!h-[42px] sm-max:!w-[42px] md-max:!px-2.5"
              onClick={handleClick}
              loading={isLoading}
              disabled={isLoading}
              isLoaderCenter
            >
              <span className="hidden md:block">{t('buttons.Share')}</span>
              <ShareIcon />
            </MButton>
          </>
        ) : (
          <>
            <MButton
              color="white"
              variant="highlighted"
              className="block !h-[42px] !w-[42px] !px-0 shadow-md shadow-shadow-600 md:!hidden"
              onClick={goToChat}
            >
              <ChatIcon />
            </MButton>
          </>
        )}
      </div>
    );
  }, [isCurrentUser, isLoading]);

  useTitleBack(isCurrentUser ? 'Profile' : 'User Profile', null, null, buttons);

  const breads: Bread[] = useMemo(
    () => [
      {
        title: t('Edu'),
        url: MainRoutes.edu + EduRoutes.courses,
      },
      {
        title: t('User Profile'),
        url:
          MainRoutes.auth +
          AuthRoutes.profile +
          ProfileRoutes.user +
          `/${anotherUserGuid}`,
      },
    ],
    [anotherUserGuid, t]
  );

  return (
    <div className="flex flex-row justify-end gap-3 md:justify-between">
      {!isCurrentUser ? (
        <Breadcrumbs breads={breads} className="hidden md:block" />
      ) : (
        <h1 className="g-main-title hidden md:block">{t('menu.Profile')}</h1>
      )}
      <div className="hidden md:block">{buttons}</div>
    </div>
  );
};
