import { AxiosError } from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import * as yup from 'yup';
import { saveAssesTokenStorage, saveRefreshTokenStorage } from '../../../api';
import { authMe, createJWT, resetPasswordConfirm } from '../../../api/auth';
import useForm, { ErrorMessages } from '../../../hooks/useForm';
import { useNavigate } from '../../../hooks/useNavigate';
import { MButton } from '../../../horizon-components/MButton/ui/MButton';
import { MInput } from '../../../horizon-components/MInput/MInput';
import {
  AuthRoutes,
  MAIN_PAGE_ROUTE,
  MainRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';
import { useUserContext } from '../../../providers';
import { AuthLayout } from '../../../widgets/AuthLayout';

const ResetPassword = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const { state }: { state: { email: string; code: string } } = useLocation();
  const { saveUser } = useUserContext();
  const { t } = useTranslation(['translation']);

  const { values, onChange, errors, validate } = useForm<{
    password: string;
    rePassword: string;
  }>({
    password: '',
    rePassword: '',
  });

  const schema = useMemo(
    () =>
      yup.object().shape({
        password: yup.string().required(ErrorMessages.required),
        rePassword: yup.string().required(ErrorMessages.required),
      }),
    []
  );

  useEffect(() => {
    if (!state.email || !state.code) {
      navigate(MainRoutes.auth + AuthRoutes.signin);
    }
  }, [navigate, state]);

  const changePasswordHandler = async () => {
    setError('');
    const hasErrors = await validate(schema);

    if (hasErrors) {
      return;
    }

    if (values.password !== values.rePassword) {
      setError(t('profile.compareError'));
      return;
    }

    setIsLoading(true);
    resetPasswordConfirm(state.email, state.code, values.password)
      .then(() =>
        createJWT(state.email, values.password || '').then((data) => {
          saveAssesTokenStorage(data.access);
          saveRefreshTokenStorage(data.refresh);

          return authMe().then((data) => {
            saveUser(data);
            navigate(MAIN_PAGE_ROUTE);
          });
        })
      )
      .catch((error: AxiosError<any>) => {
        Object.keys(error.response?.data).forEach((key) => {
          if (error.response?.data?.[key]) {
            setError(error.response?.data?.[key]);
          }
        });
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <AuthLayout title={t('profile.resetPass')} desc={t('profile.newPassP')}>
      <div className="flex flex-col gap-[16px]">
        <MInput
          label={t('profile.newPass')}
          placeholder={t('profile.newPassP')}
          id="password"
          type="password"
          name={'password'}
          bottomText={errors.password}
          state={errors.password ? 'error' : 'default'}
          value={values.password}
          onChange={onChange}
          inputClassName={'w-full leading-[18px]'}
          wrapperClassName={'w-full'}
        />
        <MInput
          label={t('profile.repeatNewPass')}
          placeholder={t('profile.repeatNewPassP')}
          id="re_password"
          type="password"
          name={'rePassword'}
          bottomText={errors.rePassword}
          state={errors.rePassword ? 'error' : 'default'}
          value={values.rePassword}
          onChange={onChange}
          inputClassName={'w-full leading-[18px]'}
          wrapperClassName={'w-full'}
        />
        {!!error && (
          <p className={'text-center text-xs text-red-600 md:text-sm'}>{error}</p>
        )}
        <MButton
          variant="highlighted"
          color="primary"
          onClick={changePasswordHandler}
          disabled={isLoading}
          loading={isLoading}
          size={'md'}
        >
          {t('profile.continue')}
        </MButton>
      </div>
    </AuthLayout>
  );
};

export default ResetPassword;
