import { useCallback, useEffect, useState } from 'react';
import { getCommunity } from '../../../../../api/weber/communities';
import { TCommunity } from '../../../../../models/Community';

export const useCommunity = (groupId: number) => {
  const [data, setData] = useState<TCommunity | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchData = useCallback(() => {
    setIsLoading(true);
    getCommunity(groupId).then((res) => {
      setData(res);
      res?.id && setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    data,
    isLoading,
  };
};
