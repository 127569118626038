import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ChainIcon } from '../../assets/icons/share/chain.svg';
import { useShareReferal } from '../../hooks/useShareReferal';
import { MButton } from '../../horizon-components/MButton';
import { useRewardContext } from '../../providers/RewardProvider';
import './style.scss';

interface ShareFriendsProps {
  className?: string;
}

export const ShareFriends = ({ className }: ShareFriendsProps) => {
  const { t } = useTranslation(['translation']);
  const { handleClick } = useShareReferal();
  const { rewards, isLoading } = useRewardContext();

  const rewardAmountRaw =
    (rewards &&
      rewards
        .find((reward) => reward.tokenomic_type === 'user_invitation')
        ?.value.toLocaleString()) ||
    '0';
  const rewardAmount = parseInt(rewardAmountRaw).toLocaleString();

  return (
    <div className={classNames('ShareFriends', className)} onClick={handleClick}>
      <div className="ShareFriends__content">
        <h4>{t('main.Share_it_with_your_friends')}</h4>
        <p>
          {t('main.Invite_your_friends_and_get_ERP_for_each_new_user', {
            rewardAmount,
          })}
        </p>
      </div>
      <MButton
        variant="highlighted"
        color="primary"
        className="w-full"
        loading={isLoading}
        disabled={isLoading}
      >
        <span className="ShareFriends__btn-text">
          {t('buttons.Send_a_referral_link')}
        </span>{' '}
        <ChainIcon />
      </MButton>
    </div>
  );
};
