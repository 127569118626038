import { SelectOptions } from '../horizon-components/MMultSelect/MMultiSelect';
import instance, { AUTH_API_URL } from './instance';

export function getFeedbackTypes(): Promise<SelectOptions[]> {
  return instance()
    .get(`${AUTH_API_URL}/feedback/get_feedback_types/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function createFeedback(
  type: string,
  text: string
): Promise<SelectOptions[]> {
  return instance()
    .post(`${AUTH_API_URL}/feedback/`, {
      type,
      text,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}
