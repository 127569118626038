import React from 'react';
import { Card } from '../../../horizon-components/Card/Card';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

export enum NetworkingNavigationTab {
  MyConnections = 'MyConnections',
  Incoming = 'Incoming',
  Outcoming = 'Outcoming',
  FindConnections = 'FindConnections',
}

type NetworkingNavigationTabItem = {
  tab: NetworkingNavigationTab;
  title: string;
};

type Props = {
  tab: NetworkingNavigationTab;
  setTab: (v: NetworkingNavigationTab) => void;
};

export const NetworkingNavigation: React.FC<Props> = ({ tab, setTab }) => {
  const { t } = useTranslation();
  const tabs: NetworkingNavigationTabItem[] = [
    {
      tab: NetworkingNavigationTab.MyConnections,
      title: t('networking.myConnections'),
    },
    {
      tab: NetworkingNavigationTab.Incoming,
      title: t('networking.incomingRequests'),
    },
    {
      tab: NetworkingNavigationTab.Outcoming,
      title: t('networking.outcomingRequests'),
    },
    {
      tab: NetworkingNavigationTab.FindConnections,
      title: t('networking.findConnections'),
    },
  ];

  return (
    <Card
      extra={
        '!shadow-[14px_17px_40px_4px_#7090B014] p-[16px] rounded-[30px] mb-[20px] gap-[4px]'
      }
    >
      {tabs.map((item) => (
        <div
          key={item.tab}
          onClick={() => setTab(item.tab)}
          className={classNames(
            `flex cursor-pointer items-center justify-between rounded-full p-[8px] pl-[12px]`,
            {
              'bg-[#F4F7FE]': tab === item.tab,
            }
          )}
        >
          <p
            className={classNames(`text-[16px] font-[600] text-[#485585]`, {
              'font-bold text-[#1B2559]': tab === item.tab,
            })}
          >
            {item.title}
          </p>
          {/*<div className={'flex items-center justify-center h-[18px] w-[18px] min-w-[18px] bg-[#4318FF] text-white'}>*/}
          {/*  4*/}
          {/*</div>*/}
        </div>
      ))}
    </Card>
  );
};
