// @ts-nocheck

import React from 'react';

export const FacebookPixel: React.FC = () => {
  const pixelId = process.env.REACT_APP_FACEBOOK_PIXEL_ID;

    if (!pixelId){ // || window.hasOwnProperty('Telegram')) {
        return null;
    }

    (function(f,b,e,v,n,t,s) {
        if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)
    })(window, document, 'script', `${process.env.REACT_APP_API_URL}/analytics/fbevents`);
    if (window.fbq){
        window.fbq('init', pixelId);
        window.fbq.disablePushState = true;
        // fbq('track', 'PageView');
    }
    return (

        <noscript>
            <img height="1" width="1" 
            style={{ display: 'none', visibility: 'hidden', height: 1, width: 1 }} 
            alt="pixel"
            src={`https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1"`}
            />
        </noscript>

    );
};
