export const convertBase64ToFile = (base64String: string) => {
  // Extract data URL prefix (e.g., "data:image/jpeg;base64,")
  const prefix = base64String.substring(0, base64String.indexOf(';base64,'));
  // Extract file type from prefix (e.g., "image/jpeg")
  const fileType = prefix.substring(prefix.indexOf('/') + 1);
  // Разбиваем строку base64 на две части: метаданные и данные изображения
  const base64Data = base64String.split(';base64,').pop();
  // Преобразуем строку base64 в бинарные данные
  const byteCharacters = atob(base64Data!);
  // Создаем буфер для хранения бинарных данных
  const arrayBuffer = new ArrayBuffer(byteCharacters.length);
  const uint8Array = new Uint8Array(arrayBuffer);
  // Заполняем буфер бинарными данными
  for (let i = 0; i < byteCharacters.length; i++) {
    uint8Array[i] = byteCharacters.charCodeAt(i);
  }
  // Создаем Blob из буфера с указанием MIME-типа изображения
  const blob = new Blob([uint8Array], { type: fileType });
  return blob;
};
