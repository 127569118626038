import { ResourceType } from '../api/weber/feed';
import { UserProfile } from './User';

export enum EFeedReaction {
  DOUBT_THAT = 'DOUBT_THAT',
  INTERESTED_IN = 'INTERESTED_IN',
  SUPPORT_BY = 'SUPPORT_BY',
}

export type TFeedAuthor = {
  guid: string;
  user_type: string;
  email: string;
  is_staff?: boolean;
  is_active: boolean;
  institution_meta: {
    areas_of_expertise: string[];
    ac_files: [
      {
        id: number;
        file: string;
      },
    ];
    name: string;
    website: string;
    logo: string;
    type: string;
    created_at: string;
    nobel_laureates: string;
    is_verified: string;
  };
  social_meta: {
    linked_in: string;
    instagram: string;
  };
  user_meta: {
    username: string;
    profile_image: string;
    joined_at: string;
    last_name: string;
    first_name: string;
  };
};

export type TFeedComment = {
  id: number;
  author: TFeedAuthor;
  author_guid: string;
  created_at: string;
  text: string;
  replies_count: number;
  comment_like_count: number;
  is_like: boolean;
  reply_comment_author: string;
};

export type TFeedComments = {
  id: number;
  post: number;
  comment: TFeedComment;
};

export type TAttachedFiles = { id: number; file: string; post: number };

export type FeedType = 'posts' | 'articles';
export type FeedStatus = 'draft' | 'published' | 'favorites';

export type Feed = {
  tags: string[];
  id: number;
  author?: UserProfile;
  title: string;
  link: string;
  language: string;
  created_at: string;
  description: string;
  html_body: string;
  subject: string;
  min_read: number;
  group: number;
  preview_image: string;
  reaction_count: {
    [key in EFeedReaction]: number;
  };
  comments_count: number;
  my_reaction: EFeedReaction | null;
  altmetrics: {
    altmetric_val: number;
    access_nft_status: boolean;
  };
  last_comment: TFeedComment;
  friendship: {
    id: number;
    from_user_guid: string;
    to_user_guid: string;
    created_at: string;
    accepted: boolean;
    status: string;
    another_user_key: string;
    from_user: TFeedAuthor;
    to_user: TFeedAuthor;
  };
  resourcetype: ResourceType;
  attached_files?: TAttachedFiles[];
  status: FeedStatus;
  is_favourite: boolean;
  post_favourite_folder: number;
  favourite_folder: number;
  is_published_in_feed: boolean;
  doi: string;
  doi_link: string;
};

export type TLikeFeedReview = {
  user_guid: string;
  comment: number;
};

export type TFavoriteFolders = {
  id: number;
  title: string;
  author_guid: string;
  posts_count: number;
  favourite_folder_image: number;
  image: string;
};
