import { FC } from 'react';
import { ShareSocialButton } from './ShareSocialButton';
import { ReactComponent as Icon } from '../../../assets/icons/share/facebook.svg';
import { ButtonsProps } from '../types';
import { shareOnSocial } from '../utils';

const ShareFacebookButton: FC<ButtonsProps> = ({ loading, onSocialClick }) => {
  const onClick = async (url: string) => {
    return await shareOnSocial({
      web: {
        baseUrl: 'https://www.facebook.com/sharer/sharer.php',
        // Facebook web share API doesn't supports text
        params: { u: url },
      },
      native: { baseUrl: 'fb://share', params: { link: url } },
      androidPackageName: 'com.facebook.katana',
    });
  };

  return (
    <ShareSocialButton
      text="Facebook"
      Icon={Icon}
      loading={loading}
      onClick={onSocialClick('Facebook', onClick)}
    />
  );
};

export default ShareFacebookButton;
