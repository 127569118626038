import React from 'react';
import { ReactComponent as ERIcon } from '../../assets/icons/new-er.svg';

type Props = {
  className?: string;
};

export const CoinIcon = ({ className }: Props) => {
  return (
    <ERIcon
      className={className || 'h-[18px] w-[18px] fill-primary-purple-blue-500'}
    />
  );
};
