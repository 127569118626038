import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as FaChevronUp } from '../../../../assets/icons/chevron-up.svg';
import { ReactComponent as MdClose } from '../../../../assets/icons/close.svg';
import { ReactComponent as TrashSmall } from '../../../../assets/icons/trash.18px.svg';
import useForm from '../../../../hooks/useForm';
import { MButton } from '../../../../horizon-components/MButton';
import { MCheckbox } from '../../../../horizon-components/MCheckbox/MCheckbox';
import { MInput } from '../../../../horizon-components/MInput/MInput';
import { MSelect } from '../../../../horizon-components/MSelect/MSelect';
import { MTextArea } from '../../../../horizon-components/MTextArea/MTextArea';
import {
  TAnswerItem,
  TQuestionItem,
  TTopicQuestionItem,
} from '../../../../models/Course';

export type Props = {
  index: number;
  question: TQuestionItem | TTopicQuestionItem;
  updateQuestion: (question: TQuestionItem | TTopicQuestionItem) => void;
  isTopicAnswer?: boolean;
  removeAnswerFromBack: (id: number) => Promise<void>;
  removeQuestion?: () => void;
};

export type MultiCorrect = 'oneCorrect' | 'multiCorrect';

export type QuestionItemForm = {
  text: string;
  type: MultiCorrect;
};

export const QuestionItem: React.FC<Props> = ({
  index,
  question,
  updateQuestion,
  isTopicAnswer,
  removeAnswerFromBack,
  removeQuestion,
}) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isAutoFocus, setIsAutoFocus] = useState(false);
  const [answers, setAnswers] = useState<TAnswerItem[]>(
    (question as TQuestionItem)?.unit_answers ||
      (question as TTopicQuestionItem)?.topic_answers ||
      []
  );
  const { t } = useTranslation(['translation']);
  const { values, errors, onChange } = useForm<QuestionItemForm>({
    text: question.text || '',
    type: 'oneCorrect',
  });

  useEffect(() => {
    if (answers.length && answers.filter((answer) => answer.correct).length > 1) {
      onChange('multiCorrect', 'type');
    }
  }, [question]);

  const addAnswers = () => {
    setIsAutoFocus(true);
    const _answers = [
      ...answers,
      {
        text: '',
        correct: false,
      },
    ];
    setAnswers(_answers);
    updateQuestion({
      ...question,
      ...(isTopicAnswer ? { topic_answers: _answers } : { unit_answers: _answers }),
    });
  };

  const onChangeAnswerText = (index: number, text: string) => {
    answers.splice(index, 1, {
      ...answers[index],
      text,
    });
    setAnswers([...answers]);
    updateQuestion({
      ...question,
      ...(isTopicAnswer ? { topic_answers: answers } : { unit_answers: answers }),
    });
  };

  const onChangeAnswerCorrect = (index: number, correct: boolean) => {
    let _answers = answers;
    if (values.type === 'oneCorrect') {
      _answers = answers.map((item) => {
        item.correct = false;
        return item;
      });
    }
    _answers.splice(index, 1, {
      ..._answers[index],
      correct,
    });
    setAnswers([..._answers]);
    updateQuestion({
      ...question,
      ...(isTopicAnswer ? { topic_answers: _answers } : { unit_answers: _answers }),
    });
  };

  const onChangeText = (value: string) => {
    updateQuestion({ ...question, text: value });
  };

  const toggleCollapse = () => setIsCollapsed(!isCollapsed);

  const removeAnswer = (answer: TAnswerItem, index: number) => {
    if (isDisabled) return;

    if (answer.id) {
      setIsDisabled(true);
      removeAnswerFromBack(answer.id)
        .then(() => {
          answers.splice(index, 1);
          setAnswers([...answers]);
          updateQuestion({
            ...question,
            ...(isTopicAnswer
              ? { topic_answers: answers }
              : { unit_answers: answers }),
          });
        })
        .finally(() => setIsDisabled(false));
    } else {
      answers.splice(index, 1);
      setAnswers([...answers]);
      updateQuestion({
        ...question,
        ...(isTopicAnswer ? { topic_answers: answers } : { unit_answers: answers }),
      });
    }
  };

  return (
    <div className={'b-question__item mb-[16px] w-full'}>
      <div className={'mb-[12px] flex items-center justify-between gap-2'}>
        <p className={'min-w-fit text-[16px] font-[500] text-secondaryGrey-900'}>
          {index}. {t('course.question')}
        </p>
        <div className={'flex w-full items-center justify-end'}>
          <div className={'h-[2px] w-full bg-purple-100'} />
          <MButton
            size={'xs'}
            variant={'outline'}
            color={'primary'}
            onClick={removeQuestion}
          >
            <TrashSmall />
            {t('buttons.Delete')}
          </MButton>
          <div className={'h-[2px] w-[20px] bg-purple-100'} />
          <MButton
            variant={'secondary'}
            color={'primary'}
            size={'xs'}
            onClick={toggleCollapse}
            className={'!h-[42px] !w-[42px] !p-3.5'}
          >
            {isCollapsed ? (
              <FaChevronUp className="!h-[14px] !w-[14px]" />
            ) : (
              <FaChevronUp
                className="!h-[14px] !w-[14px]"
                style={{ transform: 'rotate(180deg)' }}
              />
            )}
          </MButton>
        </div>
      </div>
      {isCollapsed && (
        <div className={'w-full'}>
          <div className={'mb-4 flex w-full flex-col gap-2 md:flex-row'}>
            <MTextArea
              placeholder={t('course.newQuestion')}
              wrapperClassName={'w-full md:w-3/5 md:min-w-3/5'}
              value={values.text}
              bottomText={errors.text}
              state={!!errors.text ? 'error' : 'default'}
              inputClassName={'w-full'}
              rows={3}
              onChange={(v) => {
                onChangeText(v);
                onChange(v, 'text');
              }}
            />
            <MSelect
              placeholder={t('course.newQuestion')}
              wrapperClassName={'w-full md:w-2/5 md:min-w-2/5'}
              inputClassName={'w-full'}
              size={'sm'}
              bottomText={errors.type}
              state={!!errors.type ? 'error' : 'default'}
              value={values.type}
              onChange={onChange}
              name={'type'}
              options={[
                {
                  label: t('course.oneCorrectAnswers'),
                  value: 'oneCorrect',
                },
                {
                  label: t('course.someCorrectAnswers'),
                  value: 'multiCorrect',
                },
              ]}
            />
          </div>
          <div className={'b-question__item__choices w-full'}>
            <div
              className={'mb-[16px] flex w-full items-center justify-between gap-2'}
            >
              <p
                className={'min-w-fit text-[16px] font-[500] text-secondaryGrey-900'}
              >
                {t('course.choices')}
              </p>
              <div className={'flex w-full items-center justify-end'}>
                <div className={'h-[2px] w-full bg-green-100'} />
                <MButton
                  size={'xs'}
                  variant={'secondary'}
                  color={'success'}
                  onClick={addAnswers}
                  className={'!h-[42px] !w-[42px] !p-3.5'}
                >
                  +
                </MButton>
              </div>
            </div>
            {answers.map((answer, key) => (
              <div key={key} className={'relative mb-3 flex items-center gap-2'}>
                <MCheckbox
                  checked={answer.correct}
                  onChange={(e) => onChangeAnswerCorrect(key, e.target.checked)}
                />
                <MInput
                  wrapperClassName={'w-full'}
                  placeholder={t('course.newAnswer')}
                  value={answer.text}
                  onChange={(v) => onChangeAnswerText(key, v)}
                  size={'sm'}
                  inputClassName={'w-full !py-3 !pl-4'}
                  rightIconWrapperClassName={'!top-3 -mr-1'}
                  rightIcon={
                    <MdClose
                      className="w h-[16px] w-[16px] cursor-pointer"
                      onClick={() => removeAnswer(answer, key)}
                    />
                  }
                  onKeyUp={(e) => e.key === 'Enter' && addAnswers()}
                  autoFocus={isAutoFocus}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
