import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { claimRewardFromUnauthorizedTest } from '../../../api/wiz/wiz';
import { Loader } from '../../../components/Loader';
import { useUserContext } from '../../../providers';
import { useWizContext } from '../../../providers/WizProvider/WizProvider';
import getErrorMessages from '../../../utils/getErrorMessages';
import { QuizCategories } from '../../../widgets/QuizCategories';
import { QuizHeader } from '../../../widgets/QuizHeader';
import { QuizStart } from '../../../widgets/QuizStart';
import { QuizTest } from '../../../widgets/QuizTest';
import './style.scss';
import SEO from '../../../components/SEO/SEO';

interface QuizProps {
  className?: string;
}

export const Quiz = ({ className }: QuizProps) => {
  const { t } = useTranslation(['translation']);
  const { user, updateWallet } = useUserContext();
  const [isStarted, setIsStarted] = useState(false);
  const [isTest, setIsTest] = useState(false);
  const { currentTest, fetchCurrentAuthTest, isCurrentAuthTestLoading } =
    useWizContext();

  const [urlSearchParams] = useSearchParams();
  const claimRewardTestId = urlSearchParams.get('claimReward');

  useEffect(() => {
    if (user?.guid) {
      fetchCurrentAuthTest();
    }
    if (claimRewardTestId) {
      claimRewardFromUnauthorizedTest(claimRewardTestId)
        .then(() => {
          updateWallet();
          toast('Награда за тест успешно получена', { type: 'success' });
        })
        .catch((error) => {
          toast(getErrorMessages(error?.response?.data), {
            type: 'error',
          });
        });
    }
  }, [user?.guid]);

  useEffect(() => {
    setIsTest(!!currentTest);
    setIsStarted(!!currentTest);
  }, [currentTest]);

  const hndlStart = () => {
    setIsStarted(!isStarted);
  };

  const hndlStartMore = () => {
    setIsStarted(!isStarted);
    setIsTest(!isTest);
  };

  return (
    <div className={classNames('Quiz', className, { '!max-w-[1064px]': isTest })}>
      <SEO title={t('pages.wiz.meta.title')} desc={t('pages.wiz.meta.desc')} />

      <QuizHeader />
      {isCurrentAuthTestLoading ? (
        <Loader />
      ) : !isStarted ? (
        <QuizStart onStart={hndlStart} />
      ) : !isTest ? (
        <QuizCategories />
      ) : (
        <QuizTest
          onStart={() => (!user?.guid ? setIsTest(!isTest) : hndlStartMore())}
        />
      )}
    </div>
  );
};
