import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { deleteMyCours, getMyCourses } from '../../../../../api/course';
import { Course, CourseStatus } from '../../../../../models/Course';

export const useMyCourses = () => {
  const { t } = useTranslation(['translation']);
  const [data, setData] = useState<Course[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchData = useCallback(
    (status: CourseStatus) => {
      setIsLoading(true);
      getMyCourses(status)
        .then((res) => setData(res))
        .finally(() => setIsLoading(false));
    },
    [t]
  );

  // Функция для удаления элемента из массива по его id
  const removeItemById = (array: Course[], id: number) => {
    const index = array.findIndex((item) => item.id === id);
    if (index !== -1) {
      // Создаем новый массив без удаленного элемента
      return [...array.slice(0, index), ...array.slice(index + 1)];
    }
    return array; // Возвращаем исходный массив, если элемент не найден
  };

  const fetchDelete = (id: number) => {
    deleteMyCours(id).then(() => {
      setData((prevData) => removeItemById(prevData, id));
    });
  };

  return {
    data,
    isLoading,
    fetchData,
    fetchDelete,
  };
};
