import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { creatChat } from '../../../api/weber/chat';
import { getFriendships } from '../../../api/weber/friendship';
import { ReactComponent as AddUser } from '../../../assets/icons/add-user.svg';
import { ReactComponent as Mail } from '../../../assets/icons/mail.svg';
import { ReactComponent as Search } from '../../../assets/icons/search.16px.svg';
import { Loader } from '../../../components/Loader';
import { NotFound } from '../../../components/NotFound/NotFound';
import { useNavigate } from '../../../hooks/useNavigate';
import { Card } from '../../../horizon-components/Card/Card';
import { MButton } from '../../../horizon-components/MButton/ui/MButton';
import { MInput } from '../../../horizon-components/MInput/MInput';
import {
  AuthRoutes,
  MainRoutes,
  ProfileRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';
import { Friendship } from '../../../models/Friendship';
import { getUserData } from '../../../utils/getUserData';
import { NetworkingCard } from './NetworkingCard';

export type Props = {
  onAddUser?: () => void;
  isLargeWidth?: boolean;
};

export type MyConnectionsRef = {
  update: () => void;
};

const PAGE_SIZE = 12;

export const MyConnections = forwardRef<MyConnectionsRef, Props>(
  ({ onAddUser, isLargeWidth }, ref) => {
    const [search, setSearch] = useState('');
    const [connections, setConnections] = useState<Friendship[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [total, setTotal] = useState(0);
    const [, setUploadedPage] = useState(0);
    const { t } = useTranslation(['translation']);

    const getData = useCallback((page: number, search: string) => {
      setIsLoading(true);
      getFriendships(true, search, undefined, undefined, page)
        .then((data) => {
          data?.results?.length &&
            setConnections((_data) => [
              ...(page === 0 ? [] : _data),
              ...data.results,
            ]);
          data?.count && setTotal(data.count);
          setUploadedPage((v) => ++v);
        })
        .finally(() => setIsLoading(false));
    }, []);

    useEffect(() => {
      getData(0, search);
    }, [search, getData]);

    useImperativeHandle(
      ref,
      () => ({
        update: () => getData(0, ''),
      }),
      [getData]
    );

    const goToChat = (guid: string) => {
      setIsLoading(true);
      creatChat(guid)
        .then((data) =>
          navigate(
            MainRoutes.auth +
              AuthRoutes.profile +
              ProfileRoutes.chat +
              `?chat=${data.id}`
          )
        )
        .finally(() => {
          setIsLoading(false);
        });
    };

    const scrollBottom = useCallback(
      (page: number) => {
        if (page * PAGE_SIZE < total) {
          getData(page, search);
        }
      },
      [total, getData, search]
    );

    const goToUser = (guid: string) =>
      navigate(
        MainRoutes.auth + AuthRoutes.profile + ProfileRoutes.user + `/${guid}`
      );

    useLayoutEffect(() => {
      if (window) {
        window.onscroll = () => {
          if (
            !isLoading &&
            window.innerHeight + window.scrollY >= document.body.offsetHeight
          ) {
            setUploadedPage((v) => {
              scrollBottom(v);
              return v;
            });
          }
        };
      }

      return () => {
        if (window) {
          window.onscroll = null;
        }
      };
    }, [isLoading, scrollBottom]);

    return (
      <Card
        extra={
          'shadow-none lg:!shadow-[14px_17px_40px_4px_#7090B014] mt-[20px] lg:mt-0 !p-0 lg:!p-[16px] rounded-[30px]'
        }
      >
        <div className={'mb-[12px] flex items-center justify-between'}>
          <h3 className={'text-[16px] font-[600] text-[#2B3674] lg:text-[24px]'}>
            {t('networking.myConnections')}{' '}
            <span className={'text-[#8F9BBA]'}>{total}</span>
          </h3>
        </div>
        <div className={'mb-[12px] flex items-center justify-between'}>
          <MInput
            wrapperClassName={'w-full lg:max-w-[340px]'}
            placeholder={t('nft.search')}
            value={search}
            onChange={setSearch}
            size={'sm'}
            inputClassName={
              'w-full !bg-[#F4F7FE] !border-[#F4F7FE] !placeholder-[#8F9BBA]'
            }
            leftIcon={<Search className={'mt-[-10px]'} />}
          />
          {isLargeWidth && (
            <MButton
              variant={'highlighted'}
              color={'primary'}
              size={'xs'}
              onClick={onAddUser}
            >
              {t('networking.findConnections')}
              <AddUser />
            </MButton>
          )}
        </div>
        <div className={'flex flex-wrap gap-[12px]'}>
          {isLoading ? (
            <Loader />
          ) : !!connections?.length ? (
            connections.map((item) => {
              const anotherUser = item?.[item.another_user_key];
              const user = getUserData(anotherUser);

              return (
                <NetworkingCard
                  key={item.id}
                  guid={user?.guid || ''}
                  username={user.username}
                  name={`${user.first_name} ${user.last_name}`}
                  wrapperClassName={'!w-full lg:flex-row lg:items-center'}
                  avatarInfoSize={'lg'}
                  img={user.image}
                  actions={
                    <div
                      className={
                        'ml-auto mt-[8px] flex items-center justify-end gap-[8px] lg:mt-0'
                      }
                    >
                      <MButton
                        variant={'outline'}
                        color={'primary'}
                        onClick={() => goToChat(user?.guid || '')}
                      >
                        {t('networking.chat')} <Mail />
                      </MButton>
                      <MButton
                        variant={'secondary'}
                        color={'primary'}
                        onClick={() => goToUser(user?.guid || '')}
                      >
                        {t('profile.view')}
                      </MButton>
                    </div>
                  }
                />
              );
            })
          ) : (
            <NotFound isRequest />
          )}
        </div>
      </Card>
    );
  }
);
