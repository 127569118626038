import { UserProfile } from '../models';
import { fileToBase64 } from '../utils/fileToBase64';

const ACCESS_TOKEN = 'accessToken';
const REFRESH_TOKEN = 'refreshToken';
const USER = 'user';

export function saveAssesTokenStorage(accessToken: string) {
  localStorage.setItem(ACCESS_TOKEN, accessToken);
}

export function getAssesTokenStorage() {
  return localStorage.getItem(ACCESS_TOKEN) ?? null;
}

export function saveRefreshTokenStorage(refreshToken: string) {
  localStorage.setItem(REFRESH_TOKEN, refreshToken);
}

export function getRefreshTokenStorage() {
  return localStorage.getItem(REFRESH_TOKEN) ?? null;
}

export async function saveUserStorage(user: UserProfile) {
  if (
    user?.user_meta?.profile_image &&
    !user?.user_meta?.profile_image.startsWith('data:image/jpeg')
  ) {
    user.user_meta.profile_image = await fileToBase64(
      user?.user_meta?.profile_image
    );
  }
  if (
    user?.user_meta?.cover_image &&
    !user?.user_meta?.cover_image.startsWith('data:image/jpeg')
  ) {
    user.user_meta.cover_image = await fileToBase64(user?.user_meta?.cover_image);
  }
  localStorage.setItem(USER, JSON.stringify(user));
}

export function clearStorage() {
  localStorage.clear();
}

export function removeUserStorage() {
  localStorage.removeItem(USER);
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
}

export function getUserStorage() {
  const userString = localStorage.getItem(USER);
  if (!userString || userString === 'undefined') {
    return null;
  } else {
    return JSON.parse(userString) as UserProfile;
  }
}

export function getStorage(key: string) {
  return localStorage.getItem(key) ?? null;
}

export function setStorage(key: string, value: string) {
  localStorage.setItem(key, value);
}

export function removeStorage(key: string) {
  localStorage.removeItem(key);
}
