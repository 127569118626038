import { ResponsePagination } from '../models/Response';
import { TCourseReview, TRating } from '../models/Review';
import instance from './instance';

export function getPublicCourseReviewV3(
  params: TCourseReview
): Promise<ResponsePagination<TRating>> {
  return instance(false, false)
    .get(`/v3/course-review/public/course-review/`, { params: { ...params } })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getPrivateCourseReviewLikedV3(course: number): Promise<number[]> {
  return instance()
    .get(`/v3/course-review/private/liked-reviews/`, { params: { course } })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getPrivateMyCourseReviewsV3(course: number): Promise<number[]> {
  return instance()
    .get(`/v3/course-review/private/my-course-reviews/`, { params: { course } })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}
