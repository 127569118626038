import React from 'react';
import classNames from 'classnames';

export type Props = {
  children: React.ReactNode;
  isVisible?: boolean;
};

export const DynamicTabWrapper: React.FC<Props> = ({ children, isVisible }) => {
  return (
    <div
      className={classNames('', {
        'd-hidden': !isVisible,
      })}
    >
      {children}
    </div>
  );
};
