import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import React, { useEffect, useMemo } from 'react';
import { DatePicker } from '../../../../components/DatePicker';
import { Card } from '../../../../horizon-components/Card/Card';
import { UnitReport } from '../../../../models/Performance';
import './CoursePerfomanceTable.scss';
import { Loader } from '../../../../components/Loader';
import { useTranslation } from 'react-i18next';

const columnHelper = createColumnHelper<UnitReport>();

type Props = {
  dataTable?: UnitReport[];
  onDates: (date: Date[]) => void;
  dates: Date[];
  isLoading?: boolean;
};

export const CoursePerfomanceTable: React.FC<Props> = ({
  dataTable = [],
  onDates,
  dates,
  isLoading = false,
}) => {
  const { t } = useTranslation(['translation']);
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [data, setData] = React.useState(() => [...dataTable]);

  useEffect(() => {
    setData(dataTable);
  }, [dataTable]);

  const columns = useMemo(
    () => [
      columnHelper.accessor('unit', {
        id: 'unit',
        header: () => t('edu.Unit_Name'),
        cell: (info) => (
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {info.getValue()}
          </p>
        ),
      }),
      columnHelper.accessor('passes', {
        id: 'passes',
        header: () => t('edu.Passes'),
        cell: (info) => (
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {info.getValue()}
          </p>
        ),
      }),
      columnHelper.accessor('successful_passes', {
        id: 'successful_passes',
        header: () => t('edu.Successful_passes'),
        cell: (info) => (
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {info.getValue()}
          </p>
        ),
      }),
      columnHelper.accessor('unit_effectiveness', {
        id: 'unit_effectiveness',
        header: () => t('edu.Unit_effectiveness'),
        cell: (info) => (
          <div
            className={`text-sm font-bold ${
              info.getValue()[0] === '-'
                ? 'font-medium text-red-500'
                : 'font-medium text-green-500'
            }`}
          >
            {info.getValue()}
          </div>
        ),
      }),
    ],
    []
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Card className="CoursePerfomanceTable">
      <div className="flex items-center justify-between">
        <h3>{t('edu.Most_Passed_Units')}</h3>

        <DatePicker
          selectedDates={dates}
          onDateChange={onDates}
          configs={{ dateFormat: 'dd.MM' }}
        />
      </div>

      <div className="CoursePerfomanceTable__table">
        {isLoading ? (
          <Loader />
        ) : (
          <table className="w-full">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id} className="!border-px !border-gray-400">
                  {headerGroup.headers.map((header) => {
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: '',
                          desc: '',
                        }[header.column.getIsSorted() as string] ?? null}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {!!table?.getRowModel()?.rows.length &&
                table?.getRowModel()?.rows?.map((row) => {
                  return (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <td key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        )}
      </div>
    </Card>
  );
};
