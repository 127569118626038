import {
  useNavigate as useRouterNavigate,
  NavigateOptions,
  To,
} from 'react-router-dom';
import { useLanguage } from '../providers/LanguageProvider';

export const useNavigate = (): ((
  to: To | number,
  options?: NavigateOptions
) => void) => {
  const navigate = useRouterNavigate();
  const { language } = useLanguage();

  return (to: To | number, options?: NavigateOptions) => {
    if (typeof to === 'number') {
      // Если `to` является числом, например `-1`, для возврата назад
      navigate(to);
    } else {
      // Если `to` является строкой или объектом
      const path =
        typeof to === 'string'
          ? `/${language}${to}`
          : { ...to, pathname: `/${language}${to.pathname}` };
      navigate(path, options);
    }
  };
};
