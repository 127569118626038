import { FC } from 'react';
import { ShareSocialButton } from './ShareSocialButton';
import { ReactComponent as Icon } from '../../../assets/icons/share/telegram.svg';
import { ButtonsProps } from '../types';
import { shareTelegram } from '../utils';

const ShareTelegramButton: FC<ButtonsProps> = ({ loading, onSocialClick }) => {
  return (
    <ShareSocialButton
      text="Telegram"
      Icon={Icon}
      loading={loading}
      onClick={onSocialClick('Telegram', shareTelegram)}
    />
  );
};

export default ShareTelegramButton;
