import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useLocation, useParams } from 'react-router-dom';
import { Bread, Breadcrumbs } from '../../../components/Breadcrumbs';
import { useNavigate } from '../../../hooks/useNavigate';
import { Card } from '../../../horizon-components/Card/Card';
import {
  EduRoutes,
  MainRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';
import { useTitleBackContext } from '../../../providers/TitleBackProvider/TitleBackProvider';
import Stepper from '../../AuthNew/InstitutionForm/components/Stepper';
import { CourseProvider } from './CourseContext';
import { CourseContent } from './Steps/CourseContent';
import { Learners } from './Steps/Learners';
import { UploadContent } from './Steps/UploadContent';
import { Visual } from './Steps/Visual';
import './styles.scss';

export enum Steps {
  UploadContent = 'upload-content',
  CourseContent = 'course-content',
  Visual = 'visual',
  Learners = 'learners',
  Preview = 'preview',
}

export const STEP_NAMES: { [s in Steps]: string } = {
  [Steps.UploadContent]: 'Upload Content',
  [Steps.CourseContent]: 'Course Content',
  [Steps.Visual]: 'Visual',
  [Steps.Learners]: 'Learners',
  [Steps.Preview]: 'Preview',
};

export const CourseCreation: React.FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { id } = useParams();
  const { t } = useTranslation(['translation']);
  const [currentStep, setCurrentStep] = useState(1);
  const stepsRecord: Record<string, Steps> = Steps;
  const { useTitleBack } = useTitleBackContext();

  useTitleBack(t('course.createCourse'));

  const steps = useMemo(
    () =>
      Object.keys(STEP_NAMES).map((key, i) => ({
        stepNo: i + 1,
        name: t(`course.${STEP_NAMES[key as Steps]}`),
        key,
      })),
    [t]
  );

  useEffect(() => {
    const step = steps.find((s) => pathname.indexOf(s.key) > -1);
    if (step) {
      setCurrentStep(step.stepNo);
    }
  }, [pathname, steps]);

  const title = useMemo(() => {
    const step = steps.find((s) => s.stepNo === currentStep);
    return step?.name || '';
  }, [currentStep, steps]);

  const breads: Bread[] = useMemo(
    () => [
      { title: 'EDU', url: MainRoutes.edu },
      {
        title: t('course.course_creator'),
        url: MainRoutes.edu + EduRoutes.courses,
      },
      {
        title: Number(id) ? t('course.courseEdit') : t('course.createCourse'),
        url: MainRoutes.edu + EduRoutes.edit + `/${id}/upload-content`,
      },
    ],
    [id, t]
  );

  const handleStep = (step: number) => {
    if (Number(id) !== 0) {
      setCurrentStep(step);
      if (step === 5) {
        navigate(MainRoutes.edu + EduRoutes.preview + `/${id}`);
      } else {
        navigate(
          MainRoutes.edu +
            EduRoutes.edit +
            `/${id}/` +
            stepsRecord[Object.keys(Steps)[step - 1]]
        );
      }
    }
  };

  return (
    <CourseProvider courseId={id}>
      <div className="container hidden lg:block">
        <Breadcrumbs breads={breads} />
      </div>
      <div className="mt-3 h-full w-full">
        <div className="px-0 lg:mx-0 lg:px-3">
          <Card
            extra={
              'lg:h-[300px] h-[260px] !-mx-2 lg:!mx-auto pb-3 !bg-[#F6F8FD] lg:rounded-[30px] rounded-[0px] !z-0'
            }
          >
            <div className="w-full overflow-x-auto py-3 [-ms-overflow-style:none] [scrollbar-width:none] lg:!mx-auto lg:w-2/3 [&::-webkit-scrollbar]:hidden">
              <div
                className={
                  'lg:min-w-auto min-h-[60px] px-[100px] sm-max:min-w-[600px] md:px-0 lg:min-h-[75px]'
                }
              >
                <Stepper
                  action={handleStep}
                  steps={steps}
                  currentStep={currentStep}
                />
              </div>
            </div>
            <h3 className={'text-center text-[24px] font-bold lg:text-[30px]'}>
              {title}
            </h3>
          </Card>
          <div className={'mx-auto mt-[-140px] h-full w-[100%] pb-3 lg:w-[67%]'}>
            <Routes>
              <Route path={`/${Steps.UploadContent}`} element={<UploadContent />} />
              <Route path={`/${Steps.CourseContent}`} element={<CourseContent />} />
              <Route path={`/${Steps.Visual}`} element={<Visual />} />
              <Route path={`/${Steps.Learners}`} element={<Learners />} />
            </Routes>
          </div>
        </div>
      </div>
    </CourseProvider>
  );
};
