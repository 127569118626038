import './styles.scss';

interface WalletCardProps {
  text: string;
  amount: number;
}

export const WalletCard = ({ text, amount }: WalletCardProps) => {
  return (
    <div className="WalletCard">
      <div>
        <h3>{Math.round(amount)}</h3>
        <p>{text}</p>
      </div>
    </div>
  );
};
