import { RangeDatepicker, RangeDatepickerProps } from 'chakra-dayzed-datepicker';
import classNames from 'classnames';
import React, { useState } from 'react';
import { ReactComponent as CalendarIcon } from '../../../assets/icons/datepicker.svg';
import './DatePicker.scss';

interface IDatePicker extends RangeDatepickerProps {
  className?: string;
  placeholder?: string;
}

export const DatePicker: React.FC<IDatePicker> = (props) => {
  const { onDateChange, className, maxDate, minDate, placeholder, ...otherProps } =
    props;
  const [isShow, setIsShow] = useState(false);

  const handleSelect = (date: Date[]) => {
    onDateChange(date);
  };

  return (
    <div className={classNames('DatePicker', className)}>
      {(maxDate && minDate) || (placeholder ? isShow : true) ? (
        <RangeDatepicker onDateChange={handleSelect} {...otherProps} />
      ) : (
        <span onClick={() => setIsShow(true)}>{placeholder}</span>
      )}
      <CalendarIcon />
    </div>
  );
};
