import { useEffect, useState } from 'react';
import { DEFAULT_LIMIT } from '../../../../shared/lib/const/limit';
import { TNotifications } from '../../../../models/Notifications';
import { getNotifications } from '../../../../api/auth';
import useFetch from '../../../../hooks/useFetch';

export const useNotifications = () => {
  const [notifications, setNotifications] = useState<TNotifications[]>([]);
  const [count, setCount] = useState(0);

  const {
    data: fetchedNotifications,
    isLoading,
    fetchData,
  } = useFetch(getNotifications);
  const totalPagesCount = fetchedNotifications
    ? Math.ceil(fetchedNotifications?.count / DEFAULT_LIMIT)
    : 1;

  useEffect(() => {
    if (fetchedNotifications) {
      setNotifications([...notifications, ...fetchedNotifications.results]);
      fetchedNotifications?.count && setCount(fetchedNotifications.count);
    }
  }, [fetchedNotifications]);

  return {
    notifications,
    totalPagesCount,
    isLoading,
    count,
    fetchData,
  };
};
