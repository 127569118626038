import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import BgImg from '../../assets/images/pages/profile/create-bg.webp';
import { Link } from '../../horizon-components/Link';
import { MButton } from '../../horizon-components/MButton';
import {
  EduRoutes,
  MainRoutes,
} from '../../horizon-layout/MainLayout/Routes/types/routes';
import './style.scss';

interface ProfileCreateCourseProps {
  className?: string;
  hasIcon?: boolean;
}

export const ProfileCreateCourse = ({
  className,
  hasIcon,
}: ProfileCreateCourseProps) => {
  const { t } = useTranslation(['translation']);

  return (
    <Link to={MainRoutes.edu + EduRoutes['my-courses']}>
      <div
        className={classNames('ProfileCreateCourse', className)}
        style={{ backgroundImage: `url(${BgImg})` }}
      >
        <div className={classNames('ProfileCreateCourse__content', { hasIcon })}>
          <span>{t('main.Teach')}</span>

          <p>{t('main.Get_rewarded_for_creating_new_courses')}</p>
        </div>

        <MButton variant="secondary" color="primary" className="ml-auto">
          {t('buttons.Create_a_course')}
        </MButton>
      </div>
    </Link>
  );
};
