import React from 'react';
import classNames from 'classnames';
import noAvatarSrc from '../../../../assets/images/no-avatar.png';

type Props = {
  photo: string;
  isActive?: boolean;
  name: string;
  time: string;
  message: string;
  last?: boolean;
  unreadMessageCount?: number;
  file?: string;
};

const ChatItem: React.FC<Props> = (props) => {
  const { photo, isActive, name, time, message, last, unreadMessageCount, file } =
    props;

  return (
    <div
      className={classNames(
        `flex w-full items-center gap-3 px-3 py-[16px] ${
          last ? 'pb-3' : 'border-t border-gray-100'
        }  cursor-pointer rounded-lg lg:cursor-pointer`,
        {
          'bg-navy-400': isActive,
        }
      )}
    >
      <div className="relative h-[34px] !w-[60px] rounded-full">
        <img
          className="h-[34px] !w-[34px] min-w-[34px] rounded-full object-cover"
          src={photo || noAvatarSrc}
          alt=""
        />
      </div>

      <div className="w-full">
        <div className="flex w-full items-center justify-between">
          <h1
            className={classNames(
              'truncate text-[14px] font-bold capitalize text-secondaryGrey-900',
              {
                'text-color-white': isActive,
              }
            )}
          >
            {name}
          </h1>
          <p className="text-[12px] font-medium text-secondaryGrey-600">{time}</p>
        </div>
        <div className="flex w-full items-center justify-between">
          <p
            className={classNames(
              'max-w-[170px] truncate text-[12px] font-normal tracking-normal text-gray-500',
              {
                'text-color-white': isActive,
              }
            )}
          >
            {file ? 'File' : message}
          </p>
          {!!unreadMessageCount && (
            <p className="text-[12px] font-bold text-purple-500">
              +{unreadMessageCount}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatItem;
