import { Carousel } from 'react-responsive-carousel';
import React from 'react';

type Props = {
  items: React.ReactChild[];
  className?: string;
};

export const MCarousel: React.FC<Props> = ({ items, className }) => {
  return (
    <Carousel
      autoPlay={true}
      showArrows={false}
      showStatus={false}
      showThumbs={false}
      className={`m-carousel ${className}`}
    >
      {items.map((item) => item)}
    </Carousel>
  );
};
