import React, { ReactNode, useState } from 'react';
import { createContext, useContext } from 'react';

interface ModalContextType {
  openModal: (modalId: string) => void;
  closeModal: (modalId: string) => void;
  isModalOpen: (modalId: string) => boolean;
}

export const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const useModal = (): ModalContextType => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};

interface ModalProviderProps {
  children: ReactNode;
}

export const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
  const [modalState, setModalState] = useState<Record<string, boolean>>({});

  const openModal = (modalId: string) => {
    setModalState((prev) => ({
      ...prev,
      [modalId]: true,
    }));
  };

  const closeModal = (modalId: string) => {
    setModalState((prev) => ({
      ...prev,
      [modalId]: false,
    }));
  };

  const isModalOpen = (modalId: string) => {
    return modalState[modalId] || false;
  };

  return (
    <ModalContext.Provider value={{ isModalOpen, openModal, closeModal }}>
      {children}
    </ModalContext.Provider>
  );
};
