import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getCommunity, joinGroup, leaveGroup } from '../../../api/weber/communities';
import { FeedRequest } from '../../../api/weber/feed';
import { ReactComponent as Chat } from '../../../assets/icons/chat.svg';
import { ReactComponent as ChevronLeft } from '../../../assets/icons/chevron-left.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/profile/edit.svg';
import BgCoverPlaceholder from '../../../assets/images/bg-cover-placeholder.png';
import { Bread, Breadcrumbs } from '../../../components/Breadcrumbs';
import { useNavigate } from '../../../hooks/useNavigate';
import { Card } from '../../../horizon-components/Card/Card';
import { MButton } from '../../../horizon-components/MButton/ui/MButton';
import {
  AuthRoutes,
  MainRoutes,
  ProfileRoutes,
  WeberRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';
import { GroupType, JoinStatus, TCommunity } from '../../../models/Community';
import { Feeds } from '../Feed/FeedList/components/Feeds';
import { CommunityAbout } from './components/CommunityAbout';
import { CommunityContacts } from './components/CommunityContacts';
import { CommunityMembers } from './components/CommunityMembers';
import {
  CommunityNavigation,
  CommunityNavigationTab,
} from './components/CommunityNavigation';
import { CommunityPostFilter } from './components/CommunityPostFilter';
import { CommunityTab } from './components/CommunityTab';

export const CommunityDetail = () => {
  const { communityId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation(['translation']);
  const [filters, setFilters] = useState<FeedRequest>({
    group: Number(communityId || '0'),
  });
  const [community, setCommunity] = useState<null | TCommunity>(null);
  const [joinIsLoading, setJoinIsLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState<CommunityNavigationTab>(
    CommunityNavigationTab.Posts
  );
  const [breads, setBreads] = useState<Bread[]>([]);

  useEffect(() => {
    if (community?.id) {
      setBreads([
        { title: 'WEBER', url: MainRoutes.weber },
        {
          title: t('communities.title'),
          url: MainRoutes.weber + WeberRoutes.communities,
        },
        {
          title: community.name,
          url: MainRoutes.weber + WeberRoutes.community + `/${communityId}`,
        },
      ]);
    }
  }, [community, communityId, t]);

  const fetchCommunity = useCallback(() => {
    if (!communityId || Number.isNaN(parseInt(communityId))) {
      navigate(MainRoutes.weber + WeberRoutes.communities);
      return;
    }

    return getCommunity(parseInt(communityId)).then((data) => setCommunity(data));
  }, [communityId, navigate]);

  useEffect(() => {
    fetchCommunity();
  }, [fetchCommunity]);

  if (!community) return null;

  const handleFilter = (filter: FeedRequest) => {
    setFilters({ ...filters, ...filter, page: 1 });
  };

  const goToEdit = () =>
    navigate(MainRoutes.weber + WeberRoutes['community-edit'] + `/${communityId}`);
  const goToChat = () =>
    navigate(
      MainRoutes.auth +
        AuthRoutes.profile +
        ProfileRoutes.chat +
        `?chat=${community.chat}`
    );
  const goToBack = () => navigate(-1);

  const join = () => {
    setJoinIsLoading(true);
    joinGroup(Number(communityId || '0'))
      .then(() => fetchCommunity())
      .finally(() => setJoinIsLoading(false));
  };

  const leave = () => {
    setJoinIsLoading(true);
    leaveGroup(Number(communityId || '0'))
      .then(() => fetchCommunity())
      .finally(() => setJoinIsLoading(false));
  };

  const bgImage = community.cover_image
    ? `url(${community.cover_image})`
    : `url(${BgCoverPlaceholder})`;

  return (
    <div>
      <div className="col-span-11 mb-[16px] hidden lg:block">
        <Breadcrumbs breads={breads} />
      </div>
      <div className={'mb-[10px] flex items-center justify-between lg:hidden'}>
        <MButton
          variant={'highlighted'}
          color={'white'}
          className={'!p-[12px] !shadow-[14px_17px_40px_4px_#7090B014]'}
          onClick={goToBack}
        >
          <ChevronLeft />
        </MButton>
        <div className={'flex justify-end gap-2'}>
          {(community.join_status === JoinStatus.JOINED ||
            community.join_status === JoinStatus.OWNED) && (
            <MButton
              variant={'highlighted'}
              color={'white'}
              className={'!p-[12px] !shadow-[14px_17px_40px_4px_#7090B014]'}
              onClick={goToChat}
            >
              <Chat />
            </MButton>
          )}
          {community.type === GroupType.PUBLIC &&
            community.join_status === JoinStatus.NOT_JOINED && (
              <MButton
                onClick={join}
                color={'primary'}
                size={'xs'}
                variant={'highlighted'}
                disabled={joinIsLoading}
              >
                {t('btnsText.Join')}
              </MButton>
            )}
          {community.type === GroupType.PUBLIC &&
            community.join_status === JoinStatus.JOINED && (
              <MButton
                onClick={leave}
                color={'primary'}
                size={'xs'}
                variant={'secondary'}
                disabled={joinIsLoading}
              >
                {t('btnsText.Leave')}
              </MButton>
            )}
        </div>
      </div>
      <div
        className={'ml-[-0.9rem] h-[88px] w-[102vw] bg-cover bg-no-repeat lg:hidden'}
        style={{ backgroundImage: bgImage }}
      />
      <Card
        extra={
          'w-full lg:rounded-[30px] rounded-[24px] lg:mt-0 mt-[-24px] !shadow-[14px_17px_40px_4px_#7090B014] p-[12px] lg:p-[24px] mb-[20px]'
        }
      >
        <div
          className={
            'hidden h-[168px] w-full rounded-[16px] bg-cover bg-no-repeat lg:block'
          }
          style={{ backgroundImage: bgImage }}
        />
        <div
          className={
            'mt-[0px] flex items-center justify-between lg:mt-[-25px] lg:items-end'
          }
        >
          <div className={'flex items-end gap-[16px]'}>
            <img
              src={community.custom_image || community.predefined_image.image}
              alt={'av'}
              className={
                'ml-0 h-[40px] w-[40px] min-w-[40px] rounded-full bg-white object-cover lg:ml-[24px] lg:h-[120px] lg:w-[120px] lg:min-w-[120px]'
              }
            />
            <div>
              <p
                className={
                  'text-[18px] font-bold text-secondaryGrey-900 lg:text-[30px]'
                }
              >
                {community.name}
              </p>
              <div className={'flex items-center gap-[5px]'}>
                <p className={'text-[12px] text-purple-500 lg:text-[16px]'}>
                  {community.subject}
                </p>
                <div
                  className={'h-[4px] w-[4px] min-w-[4px] rounded-full bg-gray-500'}
                />
                <p className={'text-[12px] text-gray-500 lg:text-[16px]'}>{`${
                  community.members_count
                } ${t('members')}`}</p>
              </div>
            </div>
          </div>
          <div className={'flex justify-end gap-2'}>
            {community.join_status === JoinStatus.OWNED && (
              <div
                className={'mr-2 block cursor-pointer lg:hidden'}
                onClick={goToEdit}
              >
                <EditIcon />
              </div>
            )}
          </div>
          <div className={'hidden items-center justify-end gap-[8px] lg:flex'}>
            {community.type === GroupType.PUBLIC &&
              community.join_status === JoinStatus.NOT_JOINED && (
                <MButton
                  onClick={join}
                  color={'primary'}
                  size={'sm'}
                  variant={'highlighted'}
                  disabled={joinIsLoading}
                >
                  {t('btnsText.Join')}
                </MButton>
              )}
            {(community.join_status === JoinStatus.OWNED ||
              community.join_status === JoinStatus.JOINED) && (
              <MButton
                onClick={goToChat}
                color={'primary'}
                size={'sm'}
                variant={'highlighted'}
              >
                {t('chatting')}&nbsp;
                <Chat />
              </MButton>
            )}
            {community.type === GroupType.PUBLIC &&
              community.join_status === JoinStatus.JOINED && (
                <MButton
                  onClick={leave}
                  color={'primary'}
                  size={'sm'}
                  variant={'secondary'}
                  disabled={joinIsLoading}
                >
                  {t('btnsText.Leave')}
                </MButton>
              )}
            {community.join_status === JoinStatus.OWNED && (
              <MButton
                onClick={goToEdit}
                color={'primary'}
                size={'sm'}
                variant={'secondary'}
              >
                {t('edit')}
              </MButton>
            )}
          </div>
        </div>
      </Card>
      <CommunityTab tab={currentTab} setTab={setCurrentTab} />
      <div className={'flex w-full justify-between gap-2 md:gap-5'}>
        <div
          className={
            'flex w-full flex-col justify-between gap-2 md:gap-5 lg:w-[70%]'
          }
        >
          {currentTab === CommunityNavigationTab.Posts && (
            <>
              <CommunityPostFilter
                filters={filters}
                onFilter={handleFilter}
                showBtn={community.join_status === JoinStatus.OWNED}
              />
              <Feeds filters={filters} status={community.join_status} />
            </>
          )}
          {currentTab === CommunityNavigationTab.Member && (
            <CommunityMembers showBtn={community.join_status === JoinStatus.OWNED} />
          )}
          {currentTab === CommunityNavigationTab.About && (
            <div className={'flex flex-col gap-[10px]'}>
              <CommunityAbout
                about={community.description}
                goal={community.goal}
                date={moment(community.created_at).format('DD.MM.yyyy')}
              />
              <div className={'!flex flex-col gap-[10px] lg:!hidden'}>
                <CommunityContacts community={community} />
              </div>
            </div>
          )}
        </div>
        <div className={'!hidden w-[30%] flex-col gap-2 md:gap-5 lg:!flex'}>
          <CommunityNavigation tab={currentTab} setTab={setCurrentTab} />
          <CommunityContacts community={community} />
        </div>
      </div>
    </div>
  );
};
