import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import { Loader } from '../Loader';
import { useTranslation } from 'react-i18next';
import './styles.scss';

// reset old icons
const icons = Quill.import('ui/icons');
icons['undo'] = '';
icons['redo'] = '';
icons['bold'] = '';
icons['italic'] = '';
icons['underline'] = '';
icons['list'] = '';
icons['link'] = '';
icons['image'] = '';
icons['clean'] = '';

export type Props = {
  placeholder?: string;
  className?: string;
  wrapperClassName?: string;
  label?: string;
  labelRequiredChar?: boolean;
  errorText?: string;
  value?: any;
  name?: string;
  onChange?: (value: any, name?: string) => void;
  loading?: boolean;
  image?: boolean;
  isBar?: boolean;
  height?: number | 'auto';
  text?: string;
};

const style = document.createElement('style');
document.head.appendChild(style);

export const CustomQuill: React.FC<Props> = ({
  placeholder,
  className,
  wrapperClassName,
  label,
  labelRequiredChar,
  errorText,
  name,
  value,
  onChange,
  height = 'auto',
  image = false,
  loading = false,
  isBar = true,
  text,
}) => {
  const ref = useRef<ReactQuill>(null);
  const observerRef = useRef<MutationObserver | null>(null);
  const { i18n, t } = useTranslation();

  useEffect(() => {
    const quill = ref.current?.getEditor();

    const handleMutations = (mutationsList: MutationRecord[]) => {
      for (let mutation of mutationsList) {
        if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
          // console.log('Node inserted', mutation.addedNodes[0]);
        }
      }
    };

    if (quill) {
      observerRef.current = new MutationObserver(handleMutations);
      observerRef.current.observe(quill.root, { childList: true, subtree: true });
    }

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, []);

  const handleChange = (v: string) => {
    if (image) {
      onChange?.(v, name);
    } else {
      const removeFile = (str: string) => {
        if (str.indexOf('<img') > -1) {
          const arr = str.split('');
          arr.splice(
            str.indexOf('<img'),
            str.indexOf('">') - str.indexOf('<img') + 2
          );
          const newStr = arr.join('');
          removeFile(newStr);
        } else {
          onChange?.(str, name);
        }
      };
      removeFile(v);
    }
  };

  const undo = useCallback(() => {
    const myEditor = ref.current?.getEditor();
    // @ts-ignore
    myEditor?.history.undo();
  }, []);

  const redo = useCallback(() => {
    const myEditor = ref.current?.getEditor();
    // @ts-ignore
    myEditor?.history.redo();
  }, []);

  useEffect(() => {
    if (ref.current && ref.current.editor) {
      const quillEditor = ref.current.editor;
      const quillContainer = quillEditor.root;

      if (quillContainer) {
        const container = quillContainer as HTMLElement;

        if (container) {
          if (height === 'auto') {
            container.style.minHeight = '150px';
          } else {
            container.style.minHeight = `${height}px`;
            container.style.maxHeight = `${height}px`;
            container.style.overflowY = 'scroll';
          }
        }
      }
    }
  }, [height]);

  useEffect(() => {
    const css = `
      .lang-${
        i18n.language
      } .ql-picker.ql-header .ql-picker-item[data-value="1"]:before, .lang-${
        i18n.language
      } .ql-picker.ql-header .ql-picker-label[data-value="1"]:before {
        content: "${t('Heading 1')}" !important;
      }
      .lang-${
        i18n.language
      } .ql-picker.ql-header .ql-picker-item[data-value="2"]:before, .lang-${
        i18n.language
      } .ql-picker.ql-header .ql-picker-label[data-value="2"]:before {
        content: "${t('Heading 2')}" !important;
      }
      .lang-${
        i18n.language
      } .ql-picker.ql-header .ql-picker-item[data-value="3"]:before, .lang-${
        i18n.language
      } .ql-picker.ql-header .ql-picker-label[data-value="3"]:before {
        content: "${t('Heading 3')}" !important;
      }
      .lang-${
        i18n.language
      } .ql-picker.ql-header .ql-picker-item[data-value="4"]:before, .lang-${
        i18n.language
      } .ql-picker.ql-header .ql-picker-label[data-value="4"]:before {
        content: "${t('Heading 4')}" !important;
      }
      .lang-${
        i18n.language
      } .ql-picker.ql-header .ql-picker-item[data-value="5"]:before, .lang-${
        i18n.language
      } .ql-picker.ql-header .ql-picker-label[data-value="5"]:before {
        content: "${t('Heading 5')}" !important;
      }
      .lang-${i18n.language} .ql-picker.ql-header .ql-picker-item:before, .lang-${
        i18n.language
      } .ql-picker.ql-header .ql-picker-label:before {
        content: "${t('Normal')}" !important;
      }
    `;
    style.appendChild(document.createTextNode(css));
  }, [i18n.language, t]);

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          ['undo'],
          ['redo'],
          [{ header: [1, 2, 3, 4, 5, false] }],
          ['bold', 'italic', 'underline', 'clean'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          ['link'],
          image ? ['image'] : undefined,
        ],
        handlers: {
          undo: undo,
          redo: redo,
        },
      },
    }),
    [image, undo, redo]
  );

  return (
    <div className={`relative ${wrapperClassName} lang-${i18n.language}`}>
      {!!label && (
        <p
          className={`mb-[4px] ml-[16px] text-[14px] font-[500] text-gray-900 ${
            !!errorText ? 'text-red-700' : ''
          }`}
        >
          {label}&nbsp;
          {labelRequiredChar && <span className={'g-label--required'}>*</span>}
        </p>
      )}
      <ReactQuill
        ref={ref}
        value={value}
        placeholder={placeholder}
        className={`b-quill ${className} ${!!errorText ? 'b-quill__error' : ''}`}
        onChange={handleChange}
        scrollingContainer={'true'}
        modules={isBar ? modules : { toolbar: false }}
      />
      {errorText && <p className={'g-error mt-1 pl-4'}>{errorText}</p>}
      {text && <p className={'g-error mt-1 pl-4 text-[#8F9BBA]'}>{text}</p>}
      {!!loading && (
        <Loader className="absolute top-11 h-[calc(100%-44px)] rounded-b-xl bg-shadow-700" />
      )}
    </div>
  );
};
