import React from 'react';
import { ReactComponent as TokenIcon } from '../../../assets/icons/token.svg';
import { MButton } from '../../../horizon-components/MButton';
import { Auction, Bid } from '../../../models/Auction';
import { useTranslation } from 'react-i18next';

type Props = {
  isMultiWinners: boolean;
  winners: Bid[];
  auction: Auction;
};

export const Winner: React.FC<Props> = ({ isMultiWinners, winners, auction }) => {
  const { t } = useTranslation();

  const congratulations = () => {
    // @ts-ignore
    confetti({
      particleCount: 150,
      spread: 60,
    });
  };

  return (
    <div>
      <p className={'mb-[10px] text-center text-[18px] font-bold text-purple-500'}>
        {auction?.title}
      </p>
      {!isMultiWinners ? (
        <div className={'flex flex-col items-center'}>
          <img
            src={require('../../../assets/images/noImageUser/anonymous1.jpg')}
            alt={'av'}
            className={'h-[130px] w-[130px] min-w-[130px] rounded-full'}
          />
          <p
            className={
              'mb-[16px] mt-[16px] text-[20px] font-bold text-secondaryGrey-900'
            }
          >
            {winners?.[0].user.user_meta.username}
          </p>
          <div className={'flex w-full items-center justify-between'}>
            <p className={'text-[14px] font-bold text-secondaryGrey-900'}>
              {t('bid.TopBid').toUpperCase()}
            </p>
            <div className={'flex items-center justify-end gap-[4px]'}>
              <TokenIcon />
              <p className={'text-[14px] font-bold text-purple-500'}>
                {winners?.[0]?.bid_amount}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className={'flex w-full justify-center'}>
          {winners
            ?.splice(0, 4)
            ?.map((winner, i) => (
              <img
                key={i}
                src={
                  winner.user.user_meta.username ||
                  require('../../../assets/images/no-avatar.png')
                }
                className={
                  'ml-[-5px] h-[64px] w-[64px] overflow-hidden rounded-full border-[1px] border-white object-cover'
                }
                alt={'av'}
              />
            ))}
          {winners.length > 4 && (
            <div
              className={
                'ml-[-5px] flex h-[64px] w-[64px] items-center justify-center overflow-hidden rounded-full border-[1px] border-white bg-purple-100'
              }
            >
              <p
                className={
                  'text-center text-[16px] font-bold leading-[19px] text-purple-500'
                }
              >
                +{winners.length}
                <br />
                {t('bid.more')}
              </p>
            </div>
          )}
        </div>
      )}
      <div className={'mt-[16px] flex flex-col items-center justify-center'}>
        <MButton variant={'highlighted'} color={'primary'} onClick={congratulations}>
          {'bid.Congratulations'}
        </MButton>
      </div>
    </div>
  );
};
