import React from 'react';
import { useTranslation } from 'react-i18next';
import { getDocs } from '../../../../api/course';
import { ReactComponent as Close } from '../../../../assets/icons/close.18px.svg';
import { ReactComponent as Document } from '../../../../assets/icons/document.svg';
import { ReactComponent as Download } from '../../../../assets/icons/share.download.svg';
import { ReactComponent as Checking } from '../../../../assets/images/checking.sm.svg';
import { HButton } from '../../../../horizon-components/Button/HButton';
import './styles.scss';
import { MButton } from '../../../../horizon-components/MButton/ui/MButton';

export type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const DocsModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const { t } = useTranslation(['translation']);

  const getDocuments = () => {
    getDocs().then((data) => {
      if (data?.results?.[0]?.file) {
        const link = document.createElement('a');
        link.setAttribute('download', '');
        link.setAttribute('target', '_blank');
        link.href = data.results[0].file;
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    });
  };

  if (!isOpen) return null;

  return (
    <div>
      <div className={'b-modal__overlay'} onClick={onClose} />
      <div className={'b-modal'}>
        <div className={'b-modal__close'} onClick={onClose}>
          <Close />
        </div>
        <Checking className={'b-modal__img'} />
        <p className={'b-modal__text mb-5'}>{t('course.plsRead')}</p>
        <div className={'mb-4 flex items-center'}>
          <Document />
          <p className={'b-modal__file mx-2'}>{t('course.readInfo')}</p>
        </div>
        <div className={'flex w-full justify-between'}>
          <MButton
            color={'primary'}
            size={'lg'}
            onClick={getDocuments}
            variant={'outline'}
          >
            {t('course.download')} &nbsp;&nbsp;
            <Download />
          </MButton>
          <MButton color={'primary'} size={'lg'} onClick={onClose}>
            {t('course.start')}
          </MButton>
        </div>
      </div>
    </div>
  );
};
