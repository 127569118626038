import React, { useEffect, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { useTranslation } from 'react-i18next';
import { textContentImage } from '../../../../api/course';
import { ReactComponent as MdClose } from '../../../../assets/icons/close.svg';
import { ReactComponent as IoMdDocument } from '../../../../assets/icons/document.svg';
import { ReactComponent as Upload } from '../../../../assets/icons/upload.svg';
import { CustomQuill } from '../../../../components/CustomQuill/CustomQuill';
import '../../../../components/DragAndDrop/styles.scss';
import { Loader } from '../../../../components/Loader';
import { TopicContent } from '../../../../models/Course';
import { convertBase64ToFile } from '../../../../shared/utils/convertBase64ToFile';
import { generateUniqueName } from '../../../../shared/utils/generateUniqueName';
import { getAllImages } from '../../../../shared/utils/getAllImages';
import { getBase64Extension } from '../../../../shared/utils/getBase64Extension';
import { replaceImageSrc } from '../../../../shared/utils/replaceImageSrc';

export type Props = {
  content: TopicContent[];
  onChangeFile?: (files: File[]) => void;
  onChangeText?: (text: string) => void;
  uploading?: boolean;
  deletingContent?: boolean;
  removeContent?: (id: number) => void;
  isUnitText?: boolean;
  topicId: number;
};

export const TopicContentUploader: React.FC<Props> = ({
  content = [],
  onChangeFile,
  onChangeText,
  uploading,
  removeContent,
  deletingContent,
  isUnitText,
  topicId,
}) => {
  const [text, setText] = useState('');
  const [lastFile, setLastFile] = useState<TopicContent | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation(['translation']);

  useEffect(() => {
    handleChangeContent?.(content?.find((item) => !!item?.text)?.text || '');
  }, []);

  const handleChange = (value: File) => {
    onChangeFile?.([value]);
  };

  const removeItem = (contentId: number) => {
    removeContent?.(contentId);
    setLastFile(null);
  };

  const handleChangeContent = (str: string) => {
    let content = str;
    const images = getAllImages(str);

    if (images?.length) {
      setText(str);
      const img = convertBase64ToFile(images[0]);
      const expansion = getBase64Extension(images[0]);
      setIsLoading(true);
      textContentImage(img, generateUniqueName(expansion || 'jpeg'), topicId)
        .then((res) => {
          content = replaceImageSrc(str, res.file, res.id);

          onChangeText?.(content);
          setText(content);
        })
        .finally(() => setIsLoading(false));
    } else {
      onChangeText?.(content);
      setText(content);
    }
  };

  useEffect(() => {
    findFile();
  }, [content.length]);

  const findFile = () => {
    const arr = content.filter((item) => item.resourcetype === 'FileContent');
    return arr.length > 0
      ? setLastFile(
          arr.reduceRight((acc: TopicContent, item) => {
            if (acc === null && !!item.cf_video_stream?.cf_uid) {
              return item;
            }
            return acc;
          })
        )
      : setLastFile(null);
  };

  return (
    <div className={'b-dad'}>
      <div className={'mt-3'}>
        <CustomQuill
          placeholder={isUnitText ? t('edu.Unit_text') : t('edu.Text_content')}
          onChange={handleChangeContent}
          loading={isLoading}
          value={text}
          image
        />
      </div>
      {deletingContent ? (
        <div className={'mt-5 flex items-center justify-center'}>
          <Loader />
        </div>
      ) : !!lastFile ? (
        <div className={'b-dad__list'}>
          <div className={'b-dad__list__item flex items-center justify-between'}>
            <div className={'flex w-full items-center gap-x-3'}>
              <IoMdDocument className="h-[20px] w-[20px]" />
              {lastFile?.cf_video_stream?.cf_uid && (
                <p className={'b-dad__list__item__text'}>
                  {decodeURI(lastFile?.cf_video_stream?.cf_uid || '')}
                </p>
              )}
            </div>
            <MdClose
              className={'b-dad__list__item__trash'}
              onClick={() => removeItem(lastFile?.id)}
            />
          </div>
        </div>
      ) : (
        <div className={'mt-2 w-full overflow-hidden md:mt-6'}>
          <FileUploader
            handleChange={handleChange}
            types={['MP4', 'AVI', 'MOV', 'WMV', 'FLV', 'MKV', 'WEBM', 'MPEG', '3GP']}
          >
            <div className={'b-dad__area px-[15px]'}>
              {uploading ? <Loader /> : <Upload />}
              <p
                className={
                  'b-dad__area__text text-center text-[12px] md:text-[15px]'
                }
              >
                {t('course.dad')}
              </p>
              <p
                className={
                  'b-dad__area__shadow-text text-center text-[10px] md:text-[12px]'
                }
              >
                {t('course.dadInfo')}
              </p>
            </div>
          </FileUploader>
        </div>
      )}
    </div>
  );
};
