import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { getPublicUnits, getUnitsProgress } from '../../../../api/digitalPassV3';
import { Loader } from '../../../../components/Loader';
import { useNavigate } from '../../../../hooks/useNavigate';
import {
  EduRoutes,
  MainRoutes,
} from '../../../../horizon-layout/MainLayout/Routes/types/routes';
import { PublicUnit, UnitProgress } from '../../../../models/Unit';
import { useUserContext } from '../../../../providers';
import { useTitleBackContext } from '../../../../providers/TitleBackProvider/TitleBackProvider';
import { GradeCard } from '../../../../widgets/GradeCard';
import './style.scss';

interface SubjectClassesProps {
  className?: string;
}

export const SubjectClasses = ({ className }: SubjectClassesProps) => {
  const { id: sibjectId, schoolId, classId } = useParams();
  const navigate = useNavigate();
  const { user } = useUserContext();
  const { t } = useTranslation(['translation']);
  const [units, setUnits] = useState<PublicUnit[]>([]);
  const [unitsProgress, setUnitsProgress] = useState<UnitProgress[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { state }: { state: { title: string } } = useLocation();
  const { useTitleBack } = useTitleBackContext();

  useTitleBack(state.title);

  const fetchUnitsProgress = async () => {
    if (!user) return;
    const unitsProgress_ = await getUnitsProgress();
    setUnitsProgress(unitsProgress_);
  };

  const fetchUnits = async () => {
    if (!classId) return;
    const units_ = await getPublicUnits({ grade: parseInt(classId) });
    setUnits(units_);
  };

  useEffect(() => {
    setIsLoading(true);
    Promise.all([fetchUnits(), fetchUnitsProgress()]).finally(() =>
      setIsLoading(false)
    );
  }, [t]);

  const unitsProgressMapping = useMemo(() => {
    const unitsProgressMapping: Record<number, UnitProgress> = {};
    for (const unitProgress of unitsProgress) {
      unitsProgressMapping[unitProgress.id] = unitProgress;
    }
    return unitsProgressMapping;
  }, [unitsProgress]);

  const goTo = (categoryId: number, title: string) =>
    navigate(
      MainRoutes.edu +
        EduRoutes.nft +
        `/${sibjectId}` +
        EduRoutes.shcools +
        `/${schoolId}` +
        EduRoutes.classes +
        `/${classId}` +
        EduRoutes.courses +
        `/${categoryId}`,
      { state: { title } }
    );

  return (
    <div className={classNames('SubjectClasses', className)}>
      {t('edu.subject.Select_a_section')}
      {isLoading ? (
        <Loader />
      ) : (
        <div className="SubjectClasses__content">
          {units?.map((unit) => (
            <GradeCard
              key={unit.id}
              title={unit.title}
              isCompleted={!!unitsProgressMapping[unit.id]?.grade}
              onActive={() => goTo(unit.id, unit.title)}
              isEnrolled={!!unitsProgressMapping[unit.id]?.grade}
              className={`order-${unit.id}`}
              grade={unitsProgressMapping[unit.id]?.grade}
              courseCount={unit.courses_count}
            />
          ))}
        </div>
      )}
    </div>
  );
};
