import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Languages, getLanguagesList } from '../../../../api/dictionaries';
import { ResponsePagination } from '../../../../models/Response';

export const useLanguages = () => {
  const { t } = useTranslation(['translation']);
  const [data, setData] = useState<ResponsePagination<Languages> | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchData = useCallback(() => {
    setIsLoading(true);
    getLanguagesList().then((res) => {
      setData(res);
      res !== undefined && setIsLoading(false);
    });
  }, [t]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    data,
    isLoading,
  };
};
