import React from 'react';
import { Card } from '../../../../horizon-components/Card/Card';
import { useTranslation } from 'react-i18next';

type Props = {
  date: string;
  about: string;
  goal: string;
};

export const CommunityAbout: React.FC<Props> = ({ date, about, goal }) => {
  const { t } = useTranslation();

  return (
    <Card
      extra={
        'w-full rounded-[24px] lg:rounded-[30px] !shadow-[14px_17px_40px_4px_#7090B014] p-[12px] lg:p-[24px]'
      }
    >
      <div className={'mb-[8px] flex items-center justify-between'}>
        <p className={'text-[16px] font-[600] text-secondaryGrey-900'}>
          {t('communities.About')}
        </p>
        <p className={'text-[10px] font-[600] text-gray-500'}>
          {t('communities.ActiveSince')} {date}
        </p>
      </div>
      <p className={'text-[12px] text-gray-700'}>{about}</p>
      <Card
        extra={
          'w-full rounded-[16px] !shadow-[14px_17px_40px_4px_#7090B014] p-[8px] mt-[10px]'
        }
      >
        <p className={'mb-[4px] text-[14px] font-[600] text-purple-500'}>
          Our goal:{' '}
        </p>
        <p className={'text-[12px] text-gray-700'}>{goal}</p>
      </Card>
    </Card>
  );
};
