import Landing from './Landing';
import { ReactComponent as TokenIcon } from '../../assets/students-landing/token.svg';

export function StudentsLanding() {
  return (
    <Landing
      analyticsPrefix="stl"
      title="Смотри уроки за токены"
      subtitle={
        <>
          Изучай школьную программу и получай токены. Присоединяйся сегодня и
          начни&nbsp;генерить <TokenIcon />. Копи их, чтобы
          поменять&nbsp;на&nbsp;USDT.
        </>
      }
      heroVideoId="4a807688e0b59f31609f21446d4b1f75"
      howItWorks="Выбор курса и просмотр обучающего&nbsp;видео"
      whatYouGet1="Школьные уроки в видео формате без скучных учебников"
      whatYouGet2="Получение токенов по результатам и ежедневные награды"
    />
  );
}

export function WizLanding() {
  return (
    <Landing
      analyticsPrefix="wzl"
      title="Пройди тесты за токены"
      subtitle={
        <>
          Получай токены за каждый правильный ответ. Учись и пополняй свой кошелек
          EdRes <TokenIcon />, чтобы обменять их на USDT. Награда за ежедневную
          активность!
        </>
      }
      heroVideoId="7816fb5d1afbc400bf4121072ac50344"
      howItWorks="Прохождение квиза по школьным предметам и&nbsp;логике"
      whatYouGet1="Проверку своих знаний по&nbsp;всем предметам"
      whatYouGet2="Постоянное пополнение твоего кошелька EdRes токенами"
    />
  );
}
