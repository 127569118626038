import classNames from 'classnames';
import './ProgressBar.scss';

interface ProgressBarProps {
  rating?: number;
  size?: 'sm' | 'md' | 'lg' | 'xl';
}

export const ProgressBar = ({ rating = 0, size = 'sm' }: ProgressBarProps) => {
  return (
    <div className={classNames('ProgressBar', size)}>
      <div className="ProgressBar__progress" style={{ width: `${rating}%` }}></div>
    </div>
  );
};
