import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  getPrivateEnrolledCoursesV3,
  getPrivateFavouriteCoursesV3,
  getPublicCoursesV3,
} from '../../../../api/courseV3';
import { Loader } from '../../../../components/Loader';
import { NotFound } from '../../../../components/NotFound/NotFound';
import useFetch from '../../../../hooks/useFetch';
import { Link } from '../../../../horizon-components/Link';
import { MButton } from '../../../../horizon-components/MButton';
import { Pagination } from '../../../../horizon-components/Pagination';
import {
  EduRoutes,
  MainRoutes,
} from '../../../../horizon-layout/MainLayout/Routes/types/routes';
import { CourseCardStatus } from '../../../../models/Course';
import { useUserContext } from '../../../../providers';
import { CourseCard } from '../../../../widgets/CourseCard';

type Props = {
  selectedUnits?: number[];
  selectedDPId?: number;
  selectedLevel?: number;
};

export const SchoolCourses: React.FC<Props> = ({ selectedUnits, selectedLevel }) => {
  const { id } = useParams();
  const { t } = useTranslation(['translation']);
  const { user } = useUserContext();
  const [page, setPage] = useState(1);

  const { data, isLoading, fetchData: fetchCourses } = useFetch(getPublicCoursesV3);

  const { data: enrolled, fetchData: fetchEnrolled } = useFetch(
    getPrivateEnrolledCoursesV3
  );
  const { data: favorites, fetchData: fetchFavourite } = useFetch(
    getPrivateFavouriteCoursesV3
  );

  useEffect(() => {
    if (user?.guid) {
      fetchEnrolled();
      fetchFavourite();
    }
  }, [t, user?.guid]);

  useEffect(() => {
    fetchCourses({
      digital_pass_category: Number(id),
      subject_level: selectedLevel || undefined,
      subject_level_units: selectedUnits,
      page: page,
    });
  }, [id, page, selectedLevel, selectedUnits, t]);

  return isLoading ? (
    <Loader />
  ) : (
    <div className="flex flex-col gap-[20px]">
      {!!data?.results.length ? (
        <div className={'grid grid-cols-1 gap-x-6 gap-y-3 md:grid-cols-2'}>
          {data?.results.map((course) => (
            <CourseCard
              course={course}
              showBtns={false}
              status={
                !!enrolled?.find((enroll) => enroll === course.id)
                  ? CourseCardStatus.ENROLLED
                  : CourseCardStatus.CAN_ENROLL
              }
              favorite={!!favorites?.includes(course?.id)}
              onFavorite={fetchFavourite}
              key={course.id}
            />
          ))}
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center gap-6 md:px-36">
          <NotFound text={t('course.There_are_no_courses_in_the_section_yet')} />
          <Link to={MainRoutes.edu + EduRoutes['my-courses']}>
            <MButton variant="highlighted" color="primary">
              {t('buttons.Add_course')}
            </MButton>
          </Link>
        </div>
      )}
      {!!data?.count && (
        <div className={'flex w-full items-center justify-center'}>
          <Pagination
            totalPages={Math.ceil(data?.count / 12)}
            currentPage={page}
            showPrevNext={true}
            onPageChange={setPage}
          />
        </div>
      )}
    </div>
  );
};
