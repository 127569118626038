import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FeedRequest, OrderBy } from '../../../../../api/weber/feed';
import { ReactComponent as SearchIcon } from '../../../../../assets/icons/search.16px.svg';
import useDebounce from '../../../../../hooks/useDebounce';
import { Filter, FilterItems, FilterOption } from '../../../../../widgets/Filter';
import { useLanguages } from '../../../../../entities/Languages';
import useHistoryParams from '../../../../../hooks/useHistoryParams';
import { SelectOptions } from '../../../../../horizon-components/MMultSelect/MMultiSelect';
import { getTagsList } from '../../../../../api/weber/tags';
import { MultiSearch } from '../../../../../horizon-components/MultiSearch';
import { useLocation } from 'react-router-dom';
import { FeedType } from '../../../../../models/Feed';

type FeedFilterProps = {
  isMy: boolean;
  onFilter?: (mode: any) => void;
  onSearch?: (mode: any) => void;
};

export const FeedFilter: React.FC<FeedFilterProps> = ({
  isMy,
  onFilter,
  onSearch,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { t } = useTranslation(['translation']);
  const { data: languages } = useLanguages();
  const [search, setSearch] = useState<string>('');
  const [tags, setTags] = useState<SelectOptions[]>([]);
  const [options, setOptions] = useState<SelectOptions[]>([]);
  const debounced = useDebounce(search, 500);
  const defaultFilter: FeedRequest = { order_by: '-created_at' };
  const {
    getParamFromUrl,
    getAllParamsFromUrl,
    updateAllParamsInUrl,
    removeParamFromUrl,
  } = useHistoryParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => onSearch?.({ title: search }), [debounced]);
  useEffect(() => {
    if (getParamFromUrl('title')) {
      setSearch(getParamFromUrl('title') || '');
      onSearch?.({ title: search });
    }
  }, [t]);

  useEffect(() => {
    const newFilters: FeedRequest = {
      ...defaultFilter,
      ...getAllParamsFromUrl(),
      from_me: searchParams.get('from_me') === 'true' || isMy,
      feed_type: (getAllParamsFromUrl()['feed_type'] as FeedType) || 'posts',
    };
    if (newFilters.from_me) {
      delete newFilters.following;
      delete newFilters.feed_type;
      removeParamFromUrl('feed_type');
      removeParamFromUrl('following');
    } else {
      delete newFilters.status;
      removeParamFromUrl('status');
    }
    onFilter?.(newFilters);

    const arr = getParamFromUrl('tags_or_title')?.split(',');
    if (arr?.filter(Boolean)?.length) {
      setTags(
        arr?.map((tag) => {
          return {
            label: tag,
            value: tag,
          };
        })
      );
    }
  }, [t]);

  const orderByOptions: FilterItems[] = useMemo(
    () =>
      Object.entries(OrderBy)?.map((key, index) => ({
        id: index + 1,
        title: t('sort.' + key[1].toLowerCase()),
        slug: key[0].toLowerCase(),
      })),
    [t]
  );

  const languagesOptions: FilterItems[] = useMemo(
    () =>
      languages?.results?.length
        ? languages?.results?.map((language) => ({
            id: language.id,
            title: language.title,
            slug: language.language_iso_code,
          }))
        : [],
    [t, languages]
  );

  const filterOption: FilterOption[] = useMemo(
    () => [
      {
        id: 1,
        title: t('buttons.sort_by'),
        items: orderByOptions,
        variant: 'outline',
        type: 'single',
        component: 'badge',
        key: ['order_by'],
      },
      {
        id: 2,
        title: t('buttons.tags'),
        items: options.map((tag, idx) => {
          return { id: idx, title: tag.label, slug: tag.value };
        }),
        variant: 'primary',
        type: 'array',
        component: 'badge',
        key: ['tags'],
      },
      {
        id: 3,
        title: t('buttons.language'),
        items: [
          { id: 0, title: t('buttons.all'), slug: 'all' },
          ...languagesOptions,
        ],
        variant: 'outline',
        type: 'single',
        component: 'badge',
        key: ['language'],
      },
    ],
    [languagesOptions, orderByOptions, options, t]
  );

  useEffect(() => {
    getTagsList().then(
      (data) =>
        data?.results?.length &&
        setOptions([
          ...data?.results?.map((item) => ({
            label: item.name,
            value: item.name,
            count: item?.posts_count
              ? `${item.posts_count} ${t('posts')}`
              : undefined,
          })),
        ])
    );
  }, [t]);

  const hndlTags = (tags: SelectOptions[]) => {
    setTags(tags);
    updateAllParamsInUrl({ tags_or_title: tags.map((tag) => tag.label) });
    onFilter?.({ tags_or_title: tags.map((tag) => tag.label) });
  };

  return (
    <div
      className={
        'relative flex w-full flex-row items-center justify-between gap-2 md:gap-6'
      }
    >
      <div className="flex w-full flex-row items-center gap-1 rounded-2xl !border-[#F4F7FE] !bg-[#F4F7FE] pl-3.5 !placeholder-[#8F9BBA] lg:w-auto lg:min-w-[340px]">
        <SearchIcon />
        <MultiSearch
          options={options}
          onSelect={hndlTags}
          onChange={setSearch}
          inputValue={search}
          placeholder={`${t('buttons.search')}...`}
          selectedOptions={tags}
        />
      </div>
      <Filter
        options={filterOption}
        onFilter={onFilter}
        isOpen={isOpen}
        onClose={() => setIsOpen(!isOpen)}
        defaultFilter={defaultFilter}
        showInMobile
      />
    </div>
  );
};
