import React, { useState } from 'react';
import { ReactComponent as EyeIcon } from '../../assets/icons/eye.svg';
import { ReactComponent as EyeOffIcon } from '../../assets/icons/eye-off.svg';
import ReactInputMask from 'react-input-mask';

type Props = {
  id?: string;
  label?: string;
  extra?: string;
  placeholder?: string;
  variant?: string;
  disabled?: boolean;
  type?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  errorText?: string;
  name?: string;
  autoComplete?: string;
  labelClassName?: string;
  mask?: string;
  autoFocus?: boolean;
  onBlur?: (e: any) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
};

export const InputField: React.FC<Props> = (props) => {
  const {
    label,
    id,
    extra,
    type,
    placeholder,
    variant,
    errorText,
    disabled,
    onChange,
    value,
    name,
    autoComplete,
    labelClassName,
    mask = '',
    autoFocus,
    onBlur,
    onKeyUp,
  } = props;

  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={`${extra}`}>
      {label && (
        <label
          htmlFor={id}
          className={`mb-1 text-xs font-medium text-secondary-dark-grey-900 md:text-sm ${labelClassName} ${
            variant === 'auth' ? 'ml-1.5 font-medium' : 'ml-1 font-bold md:ml-3'
          }`}
        >
          {label}
        </label>
      )}
      <div className="relative">
        <ReactInputMask
          mask={mask}
          onChange={onChange}
          disabled={disabled}
          type={type === 'password' ? (showPassword ? 'text' : 'password') : type}
          value={value}
          id={id}
          placeholder={placeholder}
          name={name}
          autoComplete={autoComplete}
          className={`flex h-12 w-full items-center justify-center rounded-xl border border-secondary-grey-500 bg-white/0 p-[12px] text-xs font-medium text-secondary-dark-grey-900 outline-none md:px-[18px] md:text-sm ${
            disabled === true
              ? '!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]'
              : !!errorText
              ? 'border-red-500 dark:!border-red-400'
              : type === 'password'
              ? 'pr-9 md:pr-10'
              : ''
          }`}
          autoFocus={autoFocus}
          onKeyUp={(e) => onKeyUp?.(e)}
          onBlur={(e) => onBlur?.(e)}
        />
        {type === 'password' && (
          <button
            onClick={togglePasswordVisibility}
            className="absolute right-3 top-[15px] md:right-[18px] md:top-3"
          >
            {showPassword ? <EyeIcon /> : <EyeOffIcon />}
          </button>
        )}
      </div>
      {!!errorText && <p className={'text-sm text-red-500'}>{errorText}</p>}
    </div>
  );
};

export default InputField;
