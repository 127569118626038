function LandingTikTokProfile({
  profile,
  onClick,
}: {
  profile: string;
  onClick: () => void;
}) {
  const title = `${profile} TikTok Profile`;
  const handle = `@${encodeURIComponent(profile)}`;
  const lang = encodeURIComponent(
    navigator.language || (navigator as any).userLanguage
  );
  const referrer = encodeURIComponent(window.location.href);
  const src = `https://www.tiktok.com/embed/${handle}?lang=${lang}&amp;referrer=${referrer}&amp;embedFrom=embed_page`;

  return (
    <iframe
      title={title}
      sandbox="allow-popups allow-popups-to-escape-sandbox allow-scripts allow-top-navigation allow-same-origin"
      src={src}
      className="LandingPage__socials-embed"
      onClick={onClick}
    />
  );
}

export default LandingTikTokProfile;
