import { useCallback, useEffect, useState } from 'react';
import { getFeedById } from '../../../../../api/weber/feed';
import { Feed } from '../../../../../models/Feed';
import { useTranslation } from 'react-i18next';

export const usePost = (postId: number) => {
  const { t } = useTranslation(['translation']);
  const [data, setData] = useState<Feed | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchData = useCallback(() => {
    setIsLoading(true);
    getFeedById(postId).then((res) => {
      setData(res);
      res?.id && setIsLoading(false);
    });
  }, [postId, t]);

  useEffect(() => fetchData(), [fetchData]);

  return { data, isLoading };
};
