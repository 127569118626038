import classNames from 'classnames';
import './style.scss';
import { DailyRewardStatusEnum } from '../../models/Reward';

interface QuizDayCardProps {
  className?: string;
  title?: string;
  earn?: string;
  status?: DailyRewardStatusEnum;
}

export const DailyRewardCard = ({
  className,
  title,
  earn,
  status,
}: QuizDayCardProps) => {
  return (
    <div className={classNames('DailyRewardCard', className, status)}>
      <h3>{title}</h3>
      <p>{earn}</p>
    </div>
  );
};
