import { Capacitor } from '@capacitor/core';
import { PlatformsEnum } from '../entities/Platforms/platforms';
import { TelegramAppWindow } from '../components/TelegramApp';

function getPlatform() {
  const platform = Capacitor.getPlatform() as PlatformsEnum;
  const telegramObject = (window as TelegramAppWindow).Telegram?.WebApp;

  return !!telegramObject ? PlatformsEnum.TELEGRAM : platform;
}

export default getPlatform;
