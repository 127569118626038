import classNames from 'classnames';
import { ProgressBar } from '../../../horizon-components/ProgressBar';
import './AltmetricBar.scss';

interface AltmetricBarProps {
  className?: string;
  title?: string;
  isBar?: boolean;
  total?: number;
  percent?: number;
}

export const AltmetricBar = ({
  className,
  title,
  isBar = false,
  total,
  percent,
}: AltmetricBarProps) => {
  return (
    <div className={classNames('AltmetricBar', className)}>
      <div className="AltmetricBar__head">
        <span>{title}</span>
        <span className="text-secondary-dark-grey-900">{total}</span>
      </div>
      {isBar && <ProgressBar rating={percent} size="sm" />}
    </div>
  );
};
