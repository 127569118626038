import React from 'react';
import { useTranslation } from 'react-i18next';
import { CourseDots } from '../../features/CourseDots';
import { useNavigate } from '../../hooks/useNavigate';
import { Link } from '../../horizon-components/Link';
import { MButton } from '../../horizon-components/MButton/ui/MButton';
import {
  EduRoutes,
  MainRoutes,
} from '../../horizon-layout/MainLayout/Routes/types/routes';
import { Course, CourseStatus } from '../../models/Course';
import './styles.scss';

export type Props = {
  course: Course;
  onRemove?: (id: number) => void;
};

export const ProfileTeacherMyCourseCard: React.FC<Props> = ({
  course,
  onRemove,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation(['translation']);

  const goTo = () => navigate(getLink());

  const getLink = () => {
    return course.course_status === CourseStatus.Rejected
      ? MainRoutes.edu + EduRoutes.rejected + `/${course.id}`
      : course.course_status === CourseStatus.Draft
        ? MainRoutes.edu + EduRoutes.edit + `/${course.id}/upload-content`
        : MainRoutes.edu + EduRoutes.preview + `/${course.id}`;
  };

  return (
    <div className={'b-profile-teacher-my-course-card'}>
      <Link
        to={getLink()}
        className={'b-profile-teacher-my-course-card__img'}
        style={{
          ...(course.cover_image
            ? {
                backgroundImage: `url(${course.cover_image})`,
              }
            : { backgroundColor: '#F2F2F2' }),
        }}
      />
      <div className={'flex !h-[126px] w-full flex-col justify-between'}>
        <div className="flex justify-between gap-2">
          <Link to={getLink()}>
            <p className={'b-profile-teacher-my-course-card__name text-[20px]'}>
              {course.title}
            </p>
            <span>{t('course.Grade') + ' ' + course.level}</span>
          </Link>
          {(course.course_status === CourseStatus.Draft ||
            course.course_status === CourseStatus.Unverified) && (
            <CourseDots course={course} onRemove={onRemove} />
          )}
        </div>
        <MButton
          size={'sm'}
          className={'w-full'}
          color={'primary'}
          variant={
            course.course_status === CourseStatus.Rejected ||
            course.course_status === CourseStatus.Unverified
              ? 'outline'
              : 'highlighted'
          }
          onClick={goTo}
        >
          {course.course_status === CourseStatus.Rejected
            ? t('btnsText.edit')
            : course.course_status === CourseStatus.Unverified
              ? t('buttons.view')
              : t('btnsText.editDraft')}
        </MButton>
      </div>
    </div>
  );
};
