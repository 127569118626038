import { useTranslation } from 'react-i18next';
import { MButton } from '../../horizon-components/MButton';
import './style.scss';
import { useUserContext } from '../../providers';

export const DeleteAccountModal = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation(['translation']);
  const { deleteAccount } = useUserContext();

  return (
    <div className={'DeleteAccountModal'}>
      <div>{t('profile.delete_account_modal_description')}</div>
      <div className={'flex flex-wrap justify-center gap-3'}>
        <MButton
          variant="highlighted"
          color="grey"
          className="md:!px-8 md:!py-6 md:!text-lg"
          onClick={onClose}
        >
          {t('buttons.cancel')}
        </MButton>
        <MButton
          variant="highlighted"
          color="danger"
          className="md:!px-8 md:!py-6 md:!text-lg"
          onClick={deleteAccount}
        >
          {t('buttons.delete_account_submit')}
        </MButton>
      </div>
    </div>
  );
};
