import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import {
  CreateFeedForm,
  ResourceType,
  articleTextImage,
  attachArticleFeedImage,
  attachFeedImage,
  createFeed,
  deleteFeedImage,
  getFeed,
  updateFeed,
} from '../../../../api/weber/feed';
import { getTagsList } from '../../../../api/weber/tags';
import { ReactComponent as TrashIcon } from '../../../../assets/icons/form/trash.svg';
import { Bread, Breadcrumbs } from '../../../../components/Breadcrumbs';
import { CustomQuill } from '../../../../components/CustomQuill/CustomQuill';
import { DragAndDrop } from '../../../../components/DragAndDrop/DragAndDrop';
import { Loader } from '../../../../components/Loader';
import { useLanguages } from '../../../../entities/Languages';
import useForm, { ErrorMessages } from '../../../../hooks/useForm';
import { useNavigate } from '../../../../hooks/useNavigate';
import { HSelect } from '../../../../horizon-components/HSelect/HSelect';
import { InputField } from '../../../../horizon-components/Input/InputField';
import { MButton } from '../../../../horizon-components/MButton';
import {
  MMultiSelect,
  SelectOptions,
} from '../../../../horizon-components/MMultSelect/MMultiSelect';
import { Modal } from '../../../../horizon-components/Modal';
import { MTextArea } from '../../../../horizon-components/MTextArea/MTextArea';
import {
  MainRoutes,
  WeberRoutes,
} from '../../../../horizon-layout/MainLayout/Routes/types/routes';
import { UserProfile } from '../../../../models';
import { FeedStatus } from '../../../../models/Feed';
import { useUserContext } from '../../../../providers';
import { useTitleBackContext } from '../../../../providers/TitleBackProvider/TitleBackProvider';
import { convertBase64ToFile } from '../../../../shared/utils/convertBase64ToFile';
import { generateUniqueName } from '../../../../shared/utils/generateUniqueName';
import { getAllImages } from '../../../../shared/utils/getAllImages';
import { getBase64Extension } from '../../../../shared/utils/getBase64Extension';
import { getFileName } from '../../../../shared/utils/getFileName';
import { replaceImageSrc } from '../../../../shared/utils/replaceImageSrc';
import getErrorMessages from '../../../../utils/getErrorMessages';
import { AttachementCard } from '../../../../widgets/AttachementCard';
import { PutFeedModal } from '../../../../widgets/PutFeedModal';
import './EditFeed.scss';

type Props = {
  isEdit?: boolean;
  resourcetype?: ResourceType;
  isArticle?: boolean;
};

export const EditFeed: React.FC<Props> = ({ isEdit, resourcetype }) => {
  const location: {
    state?: { communityIdFeed?: number; publicate?: boolean };
  } = useLocation();

  const communityIdFeed = location?.state?.communityIdFeed;
  const publicate = location?.state?.publicate;

  const { t } = useTranslation(['translation']);
  const navigate = useNavigate();
  const { id } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [articleImageLoading, setArticleImageLoading] = useState(false);
  const [isArticle, setIsArticle] = useState(
    resourcetype === ResourceType.ArticlePost
  );
  const [isDeleting, setIsDeleting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [preview, setPreview] = useState<File | string | null>();
  const [attach, setAttach] = useState<({ file: string; id: number } | File)[]>([]);
  const [previewImageFile, setPreviewImageFile] = useState<File | null | string>();
  const [tags, setTags] = useState<SelectOptions[]>([]);
  const [resource, setResource] = useState<ResourceType>();
  const [author, setAuthor] = useState<UserProfile>();
  const [remainingChars, setRemainingChars] = useState(200);
  const [isPutFeed, setIsPutFeed] = useState(false);
  const { values, errors, onChange, validate, setValues } = useForm<CreateFeedForm>({
    title: '',
    link: '',
    description: '',
    language: '',
    html_body: '',
    tags: [],
  });
  const { user } = useUserContext();
  const { data: languages } = useLanguages();
  const { useTitleBack } = useTitleBackContext();

  useTitleBack(
    Number(id) ? t('feed.editPost') : t('feed.createPost'),
    MainRoutes.weber + WeberRoutes.feeds
  );

  useEffect(() => {
    if (isEdit && id) {
      setIsLoading(true);
      getFeed(Number(id))
        .then((data) => {
          setValues({
            title: data.title,
            link: data.link,
            doi: data.doi,
            doi_link: data.doi_link,
            description: data.description,
            language: data.language,
            html_body: data.html_body,
            tags: data.tags?.map((tag: string) => {
              return { label: tag, value: tag };
            }),
          });
          data.resourcetype !== ResourceType.ArticlePost &&
            data?.preview_image &&
            setPreview(data.preview_image);
          data?.attached_files?.length && setAttach(data?.attached_files);
          setResource(data.resourcetype);
          if (data.preview_image) setPreviewImageFile(data.preview_image);
          setIsArticle(data.resourcetype === ResourceType.ArticlePost);
          setAuthor(data.author);
        })
        .finally(() => setIsLoading(false));
    }
  }, [isEdit, id, setValues, publicate]);

  useEffect(() => {
    author?.guid && publicate && handlePost('published');
  }, [author, publicate]);

  useEffect(() => {
    getTagsList().then(
      (data) =>
        data?.results?.length &&
        setTags([
          ...data?.results?.map((item) => ({
            label: item.name,
            value: item.name,
            count: item?.posts_count
              ? `${item.posts_count} ${t('posts')}`
              : undefined,
          })),
        ])
    );
  }, [t]);

  useEffect(() => {
    resourcetype && setResource(resourcetype);
  }, [resourcetype]);

  const schema = useMemo(
    () =>
      yup.object().shape({
        title: yup
          .string()
          .max(199, ErrorMessages.max200)
          .required(ErrorMessages.required),
        description: yup
          .string()
          .max(200, ErrorMessages.max200)
          .required(ErrorMessages.required),
        ...(resource !== ResourceType.DoiPost
          ? {
              html_body: isArticle
                ? yup.string().required(ErrorMessages.required)
                : yup.string(),
            }
          : {}),
        ...(!communityIdFeed
          ? {
              tags: yup
                .array()
                .min(1, ErrorMessages.required)
                .required(ErrorMessages.required),
            }
          : {}),
        ...(resource !== ResourceType.DoiPost
          ? {
              language: yup.string().required(ErrorMessages.required),
            }
          : {}),
        ...(resource !== ResourceType.DoiPost
          ? {
              link:
                resource === ResourceType.LinkPost
                  ? yup
                      .string()
                      .matches(
                        /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/,
                        t('feed.edit.enter_correct_url')
                      )
                      .required(t('feed.edit.paste_your_link'))
                  : yup.string(),
            }
          : {}),
      }),
    [communityIdFeed, isArticle, resource, t]
  );

  const replaceImageSrcToId = (htmlString: string): string => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const images = doc.querySelectorAll('img');

    images.forEach((img: HTMLImageElement) => {
      const alt = img.getAttribute('alt');
      const style = img.getAttribute('style');

      if (alt) {
        img.src = `[[${alt}]]`; // Заменяем src на alt
        img.removeAttribute('srcset'); // Удаляем атрибут srcset
        img.removeAttribute('sizes'); // Удаляем атрибут sizes
        if (style) {
          img.setAttribute('style', style); // Сохраняем стили
        }
      }
    });

    return doc.body.innerHTML;
  };

  const handlePost = async (status: FeedStatus) => {
    if (status === 'published') {
      const hasErrors = await validate(schema);
      if (hasErrors) return;
    }

    const body: CreateFeedForm = {
      title: values.title,
      description: values.description,
      html_body: replaceImageSrcToId(values.html_body || ''),
      language: values.language,
      link: values.link,
      tags: values.tags,
      resourcetype: resource,
      status,
    };

    if (!isEdit && communityIdFeed) {
      body.group = communityIdFeed;
    }
    if (preview && preview instanceof File) {
      body.preview_image = preview;
    }
    if (isArticle && typeof previewImageFile !== 'string') {
      body.preview_image = previewImageFile || undefined;
    }
    body.is_published_in_feed = status === 'published';
    if (resource === ResourceType.DoiPost) {
      body.doi_link = values.doi_link;
    }

    setIsSubmitting(true);
    const request = isEdit ? updateFeed(body, Number(id)) : createFeed(body);
    request
      .then((res) => {
        if (resource === ResourceType.LinkPost) {
          successPostToast(res.id, res.status);
        } else {
          uploadFilesSequentially(res.id, res.status);
        }
      })
      .catch((error) => {
        toast(getErrorMessages(error?.response?.data), {
          type: 'error',
        });
        setIsSubmitting(false);
      });
  };

  const successPostToast = (postId: number, status: FeedStatus) => {
    setIsSubmitting(false);
    toast(t('feed.post_saved'), { type: 'success' });
    status === 'published'
      ? navigate(MainRoutes.weber + WeberRoutes.feed + `/${postId}`)
      : navigate(MainRoutes.weber + WeberRoutes.feeds);
  };

  const uploadFile = async (id: number, file: File) => {
    try {
      if (resource === ResourceType.ArticlePost) {
        await attachArticleFeedImage(id, file);
      } else {
        await attachFeedImage(id, file);
      }
    } catch (error) {
      toast(t('feed.file_upload_error'), { type: 'error' });
    }
  };

  const uploadFilesSequentially = async (id: number, status: FeedStatus) => {
    for (const item of attach) {
      if (item instanceof File) {
        await uploadFile(id, item);
      }
    }
    successPostToast(id, status);
  };

  const changeFile = (file: File) => setPreview(file);

  const changeAttachFile = (files: ({ file: string; id: number } | File)[]) => {
    const newFiles = [...attach, ...files].slice(0, 5);
    setAttach(newFiles);
  };

  const removeImage = (id?: number, isRemote?: boolean) => {
    if (isRemote && id) {
      setIsDeleting(true);
      deleteFeedImage(id)
        .then(() => setPreview(null))
        .catch(() =>
          toast(t('feed.deleteError'), {
            type: 'error',
          })
        )
        .finally(() => setIsDeleting(false));
    } else {
      setPreview(null);
    }
  };

  const removePreviewImageFile = () => setPreviewImageFile(null);

  const languagesOptions: SelectOptions[] = useMemo(
    () =>
      languages?.results.length
        ? languages?.results.map((language) => ({
            label: language.title,
            value: language.language_iso_code,
          }))
        : [],
    [t, languages]
  );

  const breads: Bread[] = useMemo(
    () => [
      { title: 'WEBER', url: MainRoutes.weber },
      { title: t('feed.title'), url: MainRoutes.weber + WeberRoutes.feeds },
      {
        title:
          resource === ResourceType.LinkPost
            ? t('feed.post_with_link')
            : resource === ResourceType.ArticlePost
              ? t('feed.post_with_article')
              : t('feed.post_with_attachements'),
        url: Number(id)
          ? MainRoutes.weber + WeberRoutes['feed-edit'] + `/${id}`
          : MainRoutes.weber + (resource === ResourceType.LinkPost)
            ? WeberRoutes['feed-create-link']
            : resource === ResourceType.ArticlePost
              ? WeberRoutes['feed-create-article']
              : WeberRoutes['feed-create-attachments'],
      },
    ],
    [id, resource, t]
  );

  if (isLoading) return <Loader />;

  if (author?.guid !== user?.guid && !!id) {
    navigate(MainRoutes.weber + WeberRoutes.feeds);
  }

  const handleRemove = (idx: number, id?: number) => {
    const newFiles = attach.filter((_, index) => index !== idx);
    setAttach(newFiles);
    id && deleteFeedImage(id);
  };

  const handleChangeHtmlBody = (str: string) => {
    let content = str;
    const images = getAllImages(str);

    if (images?.length) {
      onChange(str, 'html_body');
      const img = convertBase64ToFile(images[0]);
      const expansion = getBase64Extension(images[0]);
      setArticleImageLoading(true);
      articleTextImage(img, generateUniqueName(expansion || 'jpeg'))
        .then((res) => {
          content = replaceImageSrc(content, res.file, res.id);
          onChange(content, 'html_body');
        })
        .finally(() => setArticleImageLoading(false));
    } else {
      onChange(content, 'html_body');
    }
  };

  const renderDescription = (isShortDescription?: boolean) => (
    <>
      <MTextArea
        id="description"
        name="description"
        value={values.description}
        placeholder={
          isShortDescription
            ? t('feed.edit.shortDescription')
            : t('feed.edit.description')
        }
        label={
          isShortDescription
            ? t('feed.edit.shortDescription')
            : t('feed.edit.description')
        }
        onChange={(value, name) => {
          const remaining = Math.max(0, 200 - value.length);
          setRemainingChars(remaining);
          onChange(value, name);
        }}
        inputClassName="w-full min-h-28"
      />
      {errors.description ? (
        <span className="g-error mt-1 pl-4">{errors.description}</span>
      ) : values?.description && values?.description?.length <= 200 ? (
        <span className="g-error mt-1 pl-4 text-secondary-dark-grey-500">
          {remainingChars} {t('feed.edit.symbols')}
        </span>
      ) : (
        <span className="g-error mt-1 pl-4">
          {values?.description &&
            values?.description?.length -
              200 +
              ' ' +
              t('feed.edit.extra_characters')}
        </span>
      )}
    </>
  );

  const hndlOpenPutFeedModal = () => {
    setIsPutFeed(!isPutFeed);
  };

  return (
    <div className="EditFeed">
      <Breadcrumbs breads={breads} className="hidden md:block" />
      <div className="EditFeed__wrapper">
        <h4>{t('feed.info')}</h4>

        <div className="EditFeed__flex">
          <InputField
            value={values.title}
            onChange={(e) => onChange(e.target.value, 'title')}
            errorText={errors.title}
            label={
              resource === ResourceType.LinkPost
                ? t('feed.edit.link_title')
                : t('feed.edit.title')
            }
            placeholder={
              resource === ResourceType.LinkPost
                ? t('feed.edit.link_title')
                : t('feed.edit.title')
            }
            extra={
              resource === ResourceType.DoiPost ? 'order-1 col-span-2' : 'order-1'
            }
          />
          {resource === ResourceType.DoiPost && (
            <InputField
              value={'DOI: ' + values.doi}
              label={t('feed.edit.DOI_or_Link')}
              extra="order-2 col-span-2"
              disabled
            />
          )}
          <MMultiSelect
            placeholder={t('course.select')}
            label={t('buttons.tags')}
            value={values.tags}
            errorText={errors.tags}
            onChange={(value) => onChange(value, 'tags')}
            options={tags}
            wrapperClassName={
              resource === ResourceType.AttachmentsPost ||
              resource !== ResourceType.LinkPost
                ? 'order-6 col-span-2'
                : 'order-6'
            }
          />
          {resource === ResourceType.LinkPost && (
            <InputField
              value={values.link}
              onChange={(e) => onChange(e.target.value, 'link')}
              errorText={errors.link}
              label={t('feed.edit.link')}
              placeholder={t('feed.edit.paste_your_link') + ' https://example.com'}
              extra="order-4"
            />
          )}
          {resource !== ResourceType.DoiPost && (
            <HSelect
              options={languagesOptions}
              onChange={onChange}
              name={'language'}
              errorText={errors.language}
              value={values.language}
              className={'w-[256px]'}
              label={t('feed.edit.language')}
              leftLabel
              wrapperClassName="order-5"
            />
          )}
        </div>

        <div>
          {!isArticle ? (
            renderDescription()
          ) : (
            <CustomQuill
              image
              name="html_body"
              value={values.html_body}
              errorText={errors.html_body}
              onChange={handleChangeHtmlBody}
              wrapperClassName={'w-full'}
              loading={articleImageLoading}
            />
          )}
        </div>

        {resource !== ResourceType.DoiPost && (
          <div className="EditFeed__preview">
            <h4>
              {resource === 'LinkPost' ? (
                <>
                  {t('feed.edit.preview_image')} <span>({t('optional')})</span>
                </>
              ) : (
                <>{t('feed.edit.attachements')}</>
              )}
            </h4>
            {preview && (
              <div className="EditFeed__file">
                <img
                  src={
                    preview instanceof File ? URL.createObjectURL(preview) : preview
                  }
                  alt="Uploaded File"
                />
                <TrashIcon onClick={() => removeImage()} />
              </div>
            )}
            {!!attach.length &&
              attach.map((file, idx) => (
                <AttachementCard
                  key={idx}
                  title={file instanceof File ? file.name : getFileName(file.file)}
                  onRemove={() =>
                    handleRemove(idx, !(file instanceof File) ? file?.id : undefined)
                  }
                />
              ))}
            {((resource === ResourceType.LinkPost && !preview) ||
              attach.length < 5) && (
              <>
                <DragAndDrop
                  onChange={(files) =>
                    resource === ResourceType.LinkPost
                      ? changeFile(files[0])
                      : changeAttachFile(files)
                  }
                  multi={resource !== ResourceType.LinkPost}
                  uploadedFiles={[]}
                  deleting={isDeleting}
                  fileTypes={
                    resource === ResourceType.LinkPost
                      ? ['JPG', 'JPEG', 'PNG', 'GIF']
                      : ['JPG', 'JPEG', 'PNG', 'GIF', 'DOC', 'DOCX', 'PDF']
                  }
                  fileSize={8}
                />
                {resource !== ResourceType.LinkPost && (
                  <span className="g-error -mt-3 pl-4 text-secondary-dark-grey-500">
                    {t('feed.edit.5_files_allowed')}
                  </span>
                )}
              </>
            )}
          </div>
        )}

        {isArticle && (
          <div
            className={
              'border-t-solid border-t-[2px] border-t-secondaryGrey-500 pt-[20px]'
            }
          >
            {renderDescription(true)}
            <div className="EditFeed__preview mt-[10px] !gap-0">
              <h4 className={'mb-0 ml-[16px] !text-[14px]'}>
                {t('feed.edit.article_preview_image')} <span>({t('optional')})</span>
              </h4>
              {!!previewImageFile && (
                <div className="EditFeed__file">
                  <img
                    src={
                      previewImageFile instanceof File
                        ? URL.createObjectURL(previewImageFile)
                        : previewImageFile
                    }
                    alt="Uploaded File"
                  />
                  <TrashIcon onClick={removePreviewImageFile} />
                </div>
              )}
              <DragAndDrop
                className={'!mt-[8px]'}
                onChange={(files) => setPreviewImageFile(files[0])}
                uploadedFiles={[]}
                fileTypes={['JPG', 'JPEG', 'PNG']}
                fileSize={8}
              />
            </div>
          </div>
        )}

        <div className="flex items-center justify-end gap-2 md:gap-4">
          {resource !== ResourceType.DoiPost && (
            <MButton
              color="primary"
              variant="secondary"
              onClick={() => handlePost('draft')}
              disabled={isSubmitting}
            >
              {t('buttons.Save to draft')}
            </MButton>
          )}
          <MButton
            color="primary"
            variant="highlighted"
            disabled={isSubmitting}
            onClick={() =>
              !communityIdFeed ? handlePost('published') : hndlOpenPutFeedModal()
            }
          >
            {t('buttons.Publish')}
          </MButton>
        </div>
      </div>
      <Modal isOpen={isPutFeed} onClose={hndlOpenPutFeedModal} showClose={false}>
        <PutFeedModal
          onCancel={hndlOpenPutFeedModal}
          onPut={() => handlePost('published')}
        />
      </Modal>
    </div>
  );
};
