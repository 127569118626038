import { useCallback, useEffect, useState } from 'react';
import { getOwlWallet } from '../../../../../api/wallet';

export const useOwlWallet = () => {
  const [data, setData] = useState<{ id: number; value: number }[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchData = useCallback(() => {
    setIsLoading(true);
    getOwlWallet()
      .then((res) => setData(res))
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { data, isLoading };
};
