import classNames from 'classnames';
import { Card } from '../../../horizon-components/Card/Card';
import './AuthLayout.scss';

interface AuthLayoutProps {
  className?: string;
  children: React.ReactNode;
  title?: string;
  desc?: string;
  wrapperClassName?: string;
  bgImage?: 'sign-in' | 'sign-up';
}

export const AuthLayout = ({
  className,
  children,
  title,
  desc,
  wrapperClassName,
  bgImage = 'sign-in',
}: AuthLayoutProps) => {
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };
  return (
    <>
      <div
        className={`flex h-full w-full items-center justify-center ${wrapperClassName}`}
      >
        {/* TODO: Is form element redundant? */}
        <form
          className={classNames('AuthLayout', className)}
          onSubmit={handleSubmit}
        >
          <Card className="AuthLayout__wrapper">
            <div className="AuthLayout__title">
              <h1>{title}</h1>
              <span>{desc}</span>
            </div>
            {children}
          </Card>
          <img
            src={require(`../../../assets/images/auth-bg-${bgImage}.png`)}
            className="AuthLayout__bg"
            alt=""
          />
        </form>
      </div>
    </>
  );
};
