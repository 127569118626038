import { FunctionComponent, ReactNode, SVGProps } from 'react';
import { Link } from 'react-router-dom';

export interface InfoCardItem {
  text: string;
  Icon: FunctionComponent<
    SVGProps<SVGSVGElement> & {
      title?: string;
    }
  >;
}

function LandingInfoCard({
  to,
  title,
  items,
  btnText,
  onClick,
}: {
  to: string;
  title: string;
  items: InfoCardItem[];
  btnText: ReactNode;
  onClick: () => void;
}) {
  return (
    <div className="LandingPage__info-card">
      <h2 className="center">{title}</h2>
      <ul>
        {items.map((i, key) => (
          <li key={key}>
            <i.Icon />
            <span>{i.text.replaceAll('&nbsp;', '\u00A0')}</span>
          </li>
        ))}
      </ul>
      <Link to={to} className="LandingPage__btn small" onClick={onClick}>
        {btnText}
      </Link>
    </div>
  );
}

export default LandingInfoCard;
