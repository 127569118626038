import React from 'react';
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar';

type Props = {
  title: string | JSX.Element;
  percentage: number;
  hidePercentage?: boolean;
  className?: string;
};

const CircularProgress: React.FC<Props> = ({ title, percentage, hidePercentage, className }) => {
  return (
    <CircularProgressbarWithChildren
      value={percentage}
      className={className}
      styles={buildStyles({
        rotation: 0.25,
        strokeLinecap: 'round',
        textSize: '16px',
        pathTransitionDuration: 0.5,
        pathColor: '#4318FF',
        textColor: '#1B2559',
        trailColor: '#E9EDF7',
        backgroundColor: '#4318FF',
      })}
    >
      <div className="flex flex-col items-center justify-center">
        <div className="text-[10px] font-medium text-secondary-dark-grey-500 md:text-xs">
          {title}
        </div>
          {!hidePercentage && (
              <div className="text-lg font-bold text-secondary-grey-900 md:text-xl">
                  {percentage}%
              </div>
          )}
      </div>
    </CircularProgressbarWithChildren>
  );
};

export default React.memo(CircularProgress);
