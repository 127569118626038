import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { MButton } from '../../../horizon-components/MButton';
import DeleteImg from '../../../assets/images/post/delete.png';
import './RemovePostModal.scss';

interface RemovePostModalProps {
  className?: string;
  onCancel?: () => void;
  onRemove?: () => void;
}

export const RemovePostModal = ({
  className,
  onCancel,
  onRemove,
}: RemovePostModalProps) => {
  const { t } = useTranslation(['translation']);

  return (
    <div className={classNames('RemovePostModal', className)}>
      <h3>{t('post.delete_a_post')}</h3>
      <img src={DeleteImg} alt="" />
      <p>{t('post.delete_post_description')}</p>
      <div className="RemovePostModal__btns">
        <MButton variant="outline" color="primary" onClick={onCancel}>
          {t('buttons.cancel')}
        </MButton>
        <MButton variant="highlighted" color="danger" onClick={onRemove}>
          {t('buttons.delete')}
        </MButton>
      </div>
    </div>
  );
};
