function getRandomInt(max: number) {
  return Math.floor(Math.random() * max);
}

export const getRandomUserImage = (hashable?: number) => {
  const imagesCount = 3;

  let imageIndex = getRandomInt(3) + 1;
  if (hashable) {
    imageIndex = (Math.abs((hashable * 31) ^ 17) % imagesCount) + 1;
  }

  return require(`../assets/images/noImageUser/anonymous${imageIndex}.jpg`);
};
