import React from 'react';
import { ReactComponent as Done } from '../../../../assets/icons/done.svg';
import { ReactComponent as Docs } from '../../../../assets/icons/docs.svg';
import classNames from 'classnames';

type Props = {
  message: string;
  time: string;
  extra: string;
  text: string;
  timecolor: string;
  file?: string;
  isMyMessage?: boolean;
  username?: string;
  isGroup?: boolean;
};

const MessageItem: React.FC<Props> = (props) => {
  const {
    message,
    time,
    extra,
    text,
    timecolor,
    file,
    isMyMessage,
    isGroup,
    username,
  } = props;

  const urlify = (text: string) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, (url) => {
      return (
        '<a style="color: ' +
        (isMyMessage ? 'white' : '#4318FF') +
        '" href="' +
        url +
        '" target="_blank">' +
        url +
        '</a>'
      );
    });
  };

  const isImage =
    !!file &&
    (file.indexOf('.jpg') > -1 ||
      file.indexOf('.png') > -1 ||
      file.indexOf('.gif') > -1);
  const arrayPath = file?.split('/');
  const fileName = arrayPath?.[arrayPath?.length - 1]?.split('?')?.[0] || '';

  return (
    <>
      <div className={`mb-3 ${extra} px-[16px] py-[12px]`}>
        {isGroup && !!username && (
          <p className={'mb-[4px] text-[12px] font-[600] text-[#00B574]'}>
            {username}
          </p>
        )}
        {!!file ? (
          isImage ? (
            <img src={file} alt={'img'} className={'mb-2 rounded-[16px]'} />
          ) : (
            <a href={file} download>
              <div
                className={classNames(
                  'mb-2 flex flex-row items-center gap-[10px] rounded-full border-[2px] border-[#E9EDF7] p-[12px]',
                  {
                    'text-white': isMyMessage,
                    'text-black': !isMyMessage,
                  }
                )}
              >
                <Docs
                  className={classNames(`min-w-[20px]`, {
                    'text-[#3311DB]': !isMyMessage,
                  })}
                />
                <p className={'w-full text-[12px]'}>{fileName}</p>
              </div>
            </a>
          )
        ) : (
          <h1
            className={`pb-2 text-[16px] text-navy-700 dark:text-white ${text}`}
            dangerouslySetInnerHTML={{ __html: urlify(message) }}
          />
        )}
        <div
          className={`${timecolor} flex flex-row items-center gap-[4px] text-[12px] font-medium`}
        >
          {isMyMessage && <Done />}
          <span>{time}</span>
        </div>
      </div>
    </>
  );
};

export default MessageItem;
