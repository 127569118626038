import classNames from 'classnames';
import React from 'react';
import './RangeSlider.scss';

interface Range {
  min: number;
  max: number;
}

interface RangeSliderProps {
  className?: string;
  min: number;
  max: number;
  value?: Range;
  step?: number;
  onChange: (value: Range) => void;
}

export const RangeSlider: React.FC<RangeSliderProps> = ({
  min,
  max,
  value,
  step = 1,
  onChange,
  className,
}) => {
  const [minValue, setMinValue] = React.useState<number>(value ? value.min : min);
  const [maxValue, setMaxValue] = React.useState<number>(value ? value.max : max);

  React.useEffect(() => {
    if (value) {
      setMinValue(value.min);
      setMaxValue(value.max);
    }
  }, [value]);

  const handleMinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const newMinVal = Math.min(+e.target.value, maxValue - step);
    if (!value) setMinValue(newMinVal);
    onChange({ min: newMinVal, max: maxValue });
  };

  const handleMaxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const newMaxVal = Math.max(+e.target.value, minValue + step);
    if (!value) setMaxValue(newMaxVal);
    onChange({ min: minValue, max: newMaxVal });
  };

  const minPos = ((minValue - min) / (max - min)) * 100;
  const maxPos = ((maxValue - min) / (max - min)) * 100;

  const generateArray = () => {
    let result = [];
    for (let i = min; i <= max; i++) {
      result.push(i);
    }

    result = result.filter((num, index) => (index + 1) % step === 0 || num === max);

    if (result[0] !== min) {
      result.unshift(min);
    }

    return result;
  };

  return (
    <div className={classNames('SliderBar', className)}>
      <div className="SliderBar__input-wrapper">
        <input
          className="SliderBar__input"
          type="range"
          value={minValue}
          min={min}
          max={max}
          step={step}
          onChange={handleMinChange}
        />
        <input
          className="SliderBar__input"
          type="range"
          value={maxValue}
          min={min}
          max={max}
          step={step}
          onChange={handleMaxChange}
        />
      </div>

      <div className="SliderBar__control-wrapper">
        <div className="SliderBar__control" style={{ left: `${minPos}%` }} />
        <div className="SliderBar__rail">
          <div
            className="SliderBar__inner-rail"
            style={{ left: `${minPos}%`, right: `${100 - maxPos}%` }}
          />
        </div>
        <div className="SliderBar__control" style={{ left: `${maxPos}%` }} />
      </div>

      <div className="SliderBar__range">
        {generateArray()?.map((num) => <span key={num}>{num}</span>)}
      </div>
    </div>
  );
};
