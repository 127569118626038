import { useCallback, useEffect, useState } from 'react';
import { TBanner, getBanner } from '../../../../../api/banner';

export const useBanner = (id: string) => {
  const [data, setData] = useState<TBanner>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchData = useCallback(() => {
    setIsLoading(true);
    getBanner(id)
      .then((res) => {
        setData(res);
        res !== undefined && setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, [id]);

  useEffect(() => fetchData(), [fetchData]);

  return { data, isLoading };
};
