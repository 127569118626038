import moment from 'moment';
import 'moment/locale/ru';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as NotifyIcon } from '../../assets/icons/menu/notify.svg';
import { ReactComponent as ERIcon } from '../../assets/icons/new-er.svg';
import { Notifications } from '../../features/Notifications';
import useClickOutside from '../../hooks/useClickOutside';
import { Modal } from '../../horizon-components/Modal';
import { MTooltip } from '../../horizon-components/MTooltip/MTooltip';
import { Languages } from '../../i18n';
import { useUserContext } from '../../providers';
import { useModal } from '../../providers/ModalProvider';
import { formatNumber } from '../../shared/utils/formatNumber';
import { DetailTransactionModal } from '../../widgets/DetailTransactionModal';
import { LanguageSelector } from './LanguageSelector';
import { ProfileDropdown } from './ProfileDropdown';
import { useNotificationsWebsocket } from '../../providers/NotificationsWebsocketProvider';

type Props = {};

const NavbarMenu: React.FC<Props> = () => {
  const { i18n, t } = useTranslation(['translation']);
  const { lastReceivedNotification } = useNotificationsWebsocket();
  const [isNewNotify, setIsNewNotify] = useState<boolean>(false);
  const [open, setRef, toggleOpen, setActivatorRef] = useClickOutside(false);
  const { wallet } = useUserContext();
  const { openModal, closeModal } = useModal();

  useEffect(() => {
    moment.locale(i18n.language);
  }, [i18n.language]);

  useEffect(
    () => localStorage.setItem('language', i18n.language as Languages),
    [i18n.language]
  );

  useEffect(() => {
    if (lastReceivedNotification) {
      setIsNewNotify(true);
    }
  }, [lastReceivedNotification]);

  const handlNotify = () => {
    toggleOpen();
    if (!open) {
      setIsNewNotify(false);
    }
  };

  return (
    <div className="relative flex items-center justify-around gap-2.5 rounded-full bg-white p-2 text-sm font-bold text-secondary-grey-900 shadow-sm shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:gap-4 md:px-4 md:py-3">
      <LanguageSelector />

      <div className="flex items-center justify-around gap-2 md:gap-4">
        <div
          className={
            'relative flex cursor-pointer items-center justify-center gap-1 self-stretch rounded-2xl md-max:bg-primary-purple-blue-100 md-max:p-2'
          }
          id={'notify'}
        >
          {isNewNotify && (
            <div className="absolute right-0.5 top-1 h-[5px] w-[5px] rounded-full bg-primary-purple-blue-500 outline outline-1 outline-white md:top-2" />
          )}
          <div ref={setActivatorRef}>
            <NotifyIcon
              onClick={handlNotify}
              className="fill-primary-purple-blue-500 md:fill-primary-purple-blue-200"
            />
          </div>
          <MTooltip anchorSelect={'#notify'} text={t('notifications.title')} />
          {open && (
            <Notifications
              className="absolute left-1/2 top-12 w-[90%] max-w-[340px] -translate-x-1/2 transform sm:max-w-[500px]"
              onClose={handlNotify}
              ref={setRef}
            />
          )}
        </div>

        <div
          className={
            'flex cursor-pointer items-center justify-center gap-1 self-stretch rounded-2xl bg-primary-purple-blue-100 px-2.5 py-2 pl-4 sm:relative'
          }
          onClick={() => openModal('details')}
          id={'tokins'}
        >
          <span className="text-xs font-semibold text-primary-purple-blue-500">
            {formatNumber(wallet?.value || '0')}
          </span>
          <ERIcon className="h-[18px] w-[18px] fill-primary-purple-blue-500" />
        </div>
      </div>

      <div className="md-max:!hidden">
        <ProfileDropdown />
      </div>
      <Modal
        modalId="details"
        title={t('buttons.Details')}
        onClose={() => closeModal('details')}
      >
        <DetailTransactionModal />
      </Modal>
    </div>
  );
};

export default NavbarMenu;
