import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { publicUsers } from '../../../api/auth';
import { addFriendship, rejectFriendship } from '../../../api/weber/friendship';
import { ReactComponent as Search } from '../../../assets/icons/search.16px.svg';
import { Loader } from '../../../components/Loader';
import { Card } from '../../../horizon-components/Card/Card';
import { MButton } from '../../../horizon-components/MButton/ui/MButton';
import { MInput } from '../../../horizon-components/MInput/MInput';
import { UserFriendship } from '../../../models/Friendship';
import { getUserData } from '../../../utils/getUserData';
import { NetworkingCard } from './NetworkingCard';

type Props = {};

export const FindConnections: React.FC<Props> = ({}) => {
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [connections, setConnections] = useState<UserFriendship[]>([]);
  const { t } = useTranslation(['translation']);

  const getData = useCallback(() => {
    setIsLoading(true);
    publicUsers(search)
      .then((data) => {
        data?.results?.length && setConnections(data?.results);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [search]);

  useEffect(() => {
    getData();
  }, [getData]);

  const add = useCallback(
    (guid: string, index: number) => {
      return addFriendship(guid)
        .then((data) => {
          if (!!data && connections?.[index]) {
            connections[index].friendship = data;
            setConnections([...connections]);
          }
          toast(t('errors.Friend_request_send_success'), {
            type: 'success',
          });
        })
        .catch((error) => {
          if (error?.response?.data?.[0]) {
            toast(error.response.data[0], {
              type: 'error',
            });
          }
        });
    },
    [connections, t]
  );

  const handleRejectClick = useCallback(
    (id: number, index: number) => {
      return rejectFriendship(id).then(() => {
        if (connections?.[index]) {
          connections[index].friendship = null;
          setConnections([...connections]);
        }
      });
    },
    [connections]
  );

  return (
    <Card
      extra={
        'shadow-none lg:!shadow-[14px_17px_40px_4px_#7090B014] !p-0 lg:!p-[16px] rounded-[30px] mb-[10px] lg:mb-[20px]'
      }
    >
      <div className={'mb-[12px] flex items-center justify-between'}>
        <MInput
          wrapperClassName={
            'w-full rounded-full !shadow-[14px_17px_40px_4px_#7090B014] lg:!shadow-none'
          }
          placeholder={t('nft.search')}
          value={search}
          onChange={setSearch}
          size={'sm'}
          inputClassName={
            'w-full !bg-[#FFFFFF] lg:!bg-[#F4F7FE] !border-[#FFFFFF] lg:!border-[#F4F7FE] !placeholder-[#8F9BBA]'
          }
          leftIcon={<Search className={'mt-[-10px]'} />}
        />
      </div>
      {isLoading ? (
        <div className={'flex items-center justify-center'}>
          <Loader />
        </div>
      ) : (
        <div className={'flex flex-wrap gap-[12px]'}>
          {connections.map((item, index) => {
            const user = getUserData(item.user);

            return (
              <NetworkingCard
                key={user.guid}
                guid={user?.guid || ''}
                username={user.username}
                name={`${user.first_name} ${user.last_name}`}
                wrapperClassName={
                  '!w-full !flex-row lg:!flex-col lg:!w-[calc(50%_-_6px)] gap-[8px]'
                }
                img={user.image}
                actions={
                  <div className={'ml-auto flex items-center justify-end gap-[8px]'}>
                    {!item.friendship && (
                      <MButton
                        variant={'highlighted'}
                        color={'primary'}
                        size={'sm'}
                        onClick={() => add(user.guid || '', index)}
                      >
                        {t('buttons.Connect')}
                      </MButton>
                    )}
                    {!!item.friendship && (
                      <MButton
                        variant={'secondary'}
                        color={'primary'}
                        size={'sm'}
                        onClick={() =>
                          handleRejectClick(item?.friendship?.id || 0, index)
                        }
                      >
                        {t('buttons.Reject')}
                      </MButton>
                    )}
                  </div>
                }
              />
            );
          })}
        </div>
      )}
    </Card>
  );
};
