import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { saveCurrentUrl } from '../../../api/sessions';
import { getReplies } from '../../../api/weber/feed';
import { Loader } from '../../../components/Loader';
import { FeedComment } from '../../../features/FeedComment';
import { LikePostComment } from '../../../features/LikePostComment';
import { useNavigate } from '../../../hooks/useNavigate';
import {
  AuthRoutes,
  MainRoutes,
  ProfileRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';
import { TFeedComment } from '../../../models/Feed';
import { useUserContext } from '../../../providers';
import { getTimeAgo } from '../../../shared/utils/getTimeAgo';
import { NoAvatar } from '../../NoAvatar';
import './FeedCommentCard.scss';
import { FeedCommentList } from './FeedCommentList';

type Props = {
  comment: TFeedComment;
  isChildComment?: boolean;
};

export const FeedCommentCard: React.FC<Props> = ({
  comment: outerComment,
  isChildComment,
}) => {
  const { t } = useTranslation(['translation']);
  const { user } = useUserContext();
  const navigate = useNavigate();

  const [comment, setComment] = useState(outerComment);
  const [showReplyInput, setShowReplyInput] = useState(false);
  const [showReplies, setShowReplies] = useState(false);
  const [replies, setReplies] = useState<TFeedComment[]>([]);

  const onReplyClick = () => {
    if (!user) {
      saveCurrentUrl(window.location.pathname);
      navigate(MainRoutes.auth + AuthRoutes.signin);
      return;
    }

    setShowReplyInput(!showReplyInput);
  };

  const fetchReplies = async () => {
    const replies = (await getReplies(comment.id)).results;
    setReplies(replies);
  };

  const onShowRepliesClick = async () => {
    setShowReplies(true);

    if (replies.length === 0) {
      await fetchReplies();
    }
  };

  const onHideRepliesClick = () => {
    setShowReplies(false);
  };

  const updateReplyCounter = () => {
    const updatedComment: TFeedComment = JSON.parse(JSON.stringify(comment));
    updatedComment.replies_count += 1;
    setComment(updatedComment);
  };

  const onSendReply = async () => {
    setShowReplyInput(false);
    setShowReplies(true);
    setReplies([]);
    await fetchReplies();
    updateReplyCounter();
  };

  const goToProfile = () =>
    navigate(
      MainRoutes.auth +
        AuthRoutes.profile +
        ProfileRoutes.user +
        `/${comment?.author?.guid}`
    );

  return (
    <div className="FeedCommentCard" key={comment?.id}>
      <div className="FeedCommentCard__wrapper !p-2">
        <div className="FeedCommentCard__img" onClick={goToProfile}>
          <NoAvatar
            src={comment?.author?.user_meta?.profile_image}
            alt={comment?.author?.user_meta?.username}
          />
        </div>
        <div className="FeedCommentCard__card">
          <div className="flex items-center justify-between pr-1 md:pr-2">
            <div className="FeedCommentCard__user">
              <h5 className="cursor-pointer" onClick={goToProfile}>
                {comment?.author?.user_meta?.username}
              </h5>
              <span>
                {getTimeAgo(new Date(comment?.created_at))?.[0]
                  ? getTimeAgo(new Date(comment?.created_at))?.[0] + ' '
                  : ''}
                {t(getTimeAgo(new Date(comment?.created_at))[1])}
              </span>
            </div>
            <LikePostComment
              reviewId={comment?.id}
              likesCount={comment?.comment_like_count}
              userReaction={comment?.is_like}
            />
          </div>
          <div className="FeedCommentCard__content">
            {comment?.reply_comment_author && (
              <b>@{comment?.reply_comment_author} </b>
            )}
            {comment?.text}
          </div>
          <div className={'FeedCommentCard__links'}>
            <div onClick={onReplyClick}>{t('weber.Reply')}</div>
            {!isChildComment && (
              <>
                {!showReplies && comment?.replies_count > 0 && (
                  <div onClick={onShowRepliesClick}>
                    {t('weber.Show_replies')} {`(${comment?.replies_count})`}
                  </div>
                )}
                {showReplies && (
                  <div
                    onClick={onHideRepliesClick}
                    className="text-primary-purple-blue-300"
                  >
                    {t('weber.Hide_replies')}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {showReplyInput && (
        <div className="FeedCommentCard__form">
          <FeedComment
            onSend={onSendReply}
            placeholder={'Reply...'}
            id={comment?.id}
            isChildComment
            reply_to_comment={comment?.id}
          />
        </div>
      )}
      {showReplies && (
        <div className="FeedCommentCard__children">
          {replies.length === 0 ? (
            <Loader />
          ) : (
            <FeedCommentList comments={replies} isChildComment />
          )}
        </div>
      )}
    </div>
  );
};
