import { useCommentLikeFeed } from '../../../entities/Review';
import { Like } from '../../../widgets/Like';

interface LikeProps {
  likesCount?: number;
  userReaction?: boolean;
  reviewId: number;
}

export const LikePostComment = ({
  likesCount = 0,
  userReaction = false,
  reviewId,
}: LikeProps) => {
  const { isLiked, onLike, likes, isLoading, onDeleteLike } = useCommentLikeFeed(
    reviewId,
    userReaction,
    likesCount
  );
  return (
    <Like
      active={isLiked}
      likes={likes}
      loading={isLoading}
      onLike={isLiked ? onDeleteLike : onLike}
      left
    />
  );
};
