import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './style.scss';

interface MainPageLearnMoreProps {
  className?: string;
}

export const SupportContactEmail = ({ className }: MainPageLearnMoreProps) => {
  const { t } = useTranslation(['translation']);
  return (
    <Link to={'mailto:support@edres.com'}>
      <div className={classNames('SupportContactEmail', className)}>
        <div className={classNames('SupportContactEmail__content')}>
          <h4>{t('support.contact_email')}</h4>
          <p>
            {t('support.contact_email_description')}{' '}
            <span>{t('support.contact_email_link')}</span>
          </p>
        </div>
      </div>
    </Link>
  );
};
