import instance, { AUTH_API_URL, WIZ_API_URL } from '../instance';
import axios from 'axios';
import { User } from '../../models';
import { WizStatusEnum } from '../../models/Wiz';
import { ResponsePagination } from '../../models/Response';

export const WIZ_TOKEN_STORAGE_KEY = 'wiz-anonymous-token';

export enum WizLevelEnum {
  EASY = 'easy',
  MEDIUM = 'medium',
  HARD = 'hard',
}

export enum WizDailyStatusEnum {
  UPCOMING = 'upcoming',
  ACTIVE = 'active',
  EXPIRED = 'expired',
}

export type Answer = {
  id: number;
  text: string;
  is_correct_answer: boolean;
};

export type Category = {
  category_name: string;
  title: string;
  id: number;
  image: string;
};

export type Question = {
  text: string;
  id: number;
  question_number: number;
  answers: Answer[];
  category: Category;
};

export type DayResult = {
  day_of_week: number;
  user_answers: Answer[];
};

export type LeaderboardUser = {
  total_reward_amount: number;
  user: User;
};

export type TStart = {
  id: number;
  category: number;
  level: string;
};

export type TAward = { success: boolean; amount: string };

export type TAnswers = {
  id: number;
  text: string;
};

export type TQuestion = {
  id: number;
  text: string;
  answers: TAnswers[];
};

export type TUserMetric = {
  user_guid: string;
  lives: number;
  consecutive_days: number;
  next_life_recovery_time: number;
};

export type TDailyMultiplierInfo = {
  day: number;
  multiplier: number;
  status: WizDailyStatusEnum;
};

export type TAnswerResult = {
  id: number;
  is_correct_answer: boolean;
  end_date_time: string;
  is_time_limited: boolean;
  correct_answers: number[];
};

export type TTestResult = {
  percentage_correct: number;
  earned_token: number;
  multiplier_token: number;
  total_token: number;
};

const getHeaderWithToken = () => {
  const wizToken = localStorage.getItem(WIZ_TOKEN_STORAGE_KEY);
  return {
    headers: { Authorization: `Token ${wizToken}` },
  };
};

export function registerInWiz(jwtToken: string | null): Promise<string> {
  return axios
    .post(`${WIZ_API_URL}/register/`, {
      user_jwt_token: jwtToken,
    })
    .then((response) => response.data.key)
    .catch((error) => {
      throw error;
    });
}

export function startTest(): Promise<{
  key: string;
}> {
  return axios
    .post(`${WIZ_API_URL}/start-test/`, undefined, getHeaderWithToken())
    .then((response) => response.data.key)
    .catch((error) => {
      throw error;
    });
}

export function getStatus(): Promise<WizStatusEnum> {
  return axios
    .get(`${WIZ_API_URL}/wiz-status/`, getHeaderWithToken())
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getQuestion(): Promise<null> {
  return axios
    .get(`${WIZ_API_URL}/get-question/`, getHeaderWithToken())
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function submitAnswer(answerId?: number): Promise<null> {
  return axios
    .post(
      `${WIZ_API_URL}/submit-answer/`,
      answerId && { answer_id: answerId },
      getHeaderWithToken()
    )
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function showResults(): Promise<DayResult[]> {
  return axios
    .get(`${WIZ_API_URL}/show-results/`, getHeaderWithToken())
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getMyIqa(): Promise<number> {
  return axios
    .get(`${WIZ_API_URL}/get-my-iqa/`, getHeaderWithToken())
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function claimReward(jwtToken: string): Promise<{ reward_amount: number }> {
  return axios
    .post(
      `${WIZ_API_URL}/claim-reward/`,
      { user_jwt_token: jwtToken },
      getHeaderWithToken()
    )
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getIsRewardClaimed(): Promise<boolean> {
  return axios
    .get(`${WIZ_API_URL}/is-reward-claimed/`, getHeaderWithToken())
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getLeaderboardData(): Promise<LeaderboardUser[]> {
  return axios
    .get(`${WIZ_API_URL}/leaderboard/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function uploadResultToShare(form: FormData): Promise<{
  image: string;
}> {
  return axios
    .post(`${WIZ_API_URL}/upload-result-image/`, form, getHeaderWithToken())
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

// NEW
export function getQuestionCategories(): Promise<ResponsePagination<Category>> {
  return axios
    .get(`${WIZ_API_URL}/common/public/question-categories/`, { headers: {} })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function setWizUnauthorizedStart(category: number): Promise<TStart> {
  return axios
    .post(
      `${WIZ_API_URL}/unauthorized/private/start/`,
      { category },
      { headers: {} }
    )
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function setWizAuthorizedStart(category: number): Promise<TStart> {
  return instance()
    .post(`${WIZ_API_URL}/authorized/private/start/`, { category })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getAuthorizedCurrentStartedQuiz(): Promise<Category> {
  return instance()
    .get(`${WIZ_API_URL}/authorized/private/get-current-started-quiz/`)
    .then((response) => response.data)
    .catch((error) => {
      console.log('error', error);
    });
}

export function getUserMetrics(): Promise<TUserMetric> {
  return instance()
    .get(`${WIZ_API_URL}/authorized/private/user-metrics/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getDailyMultiplierInfo(): Promise<TDailyMultiplierInfo[]> {
  return instance()
    .get(`${AUTH_API_URL}/private/daily-reward/wiz-reward/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getTokenMultiplicationWeek(): Promise<{ [key: number]: string }[]> {
  return instance()
    .get(`${WIZ_API_URL}/unauthorized/private/get-token-multiplication-week/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getReceivedTokens(): Promise<TUserMetric> {
  return instance()
    .get(`${WIZ_API_URL}/authorized/private/get-received-tokens/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getCommonQuestion(started_quiz_id: number): Promise<{
  id: number;
  question: TQuestion;
  current_question: number;
  total_questions: number;
}> {
  return instance()
    .post(
      `${WIZ_API_URL}/common/private/get-question/`,
      { started_quiz_id },
      { headers: {} }
    )
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function setForceCompleteQuiz(started_quiz_id: number) {
  return instance()
    .post(
      `${WIZ_API_URL}/authorized/private/force-complete-quiz/${started_quiz_id}/`
    )
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getUnauthorizedCurrentStartedQuiz(
  quiz_id: number
): Promise<Category> {
  return instance()
    .get(`${WIZ_API_URL}/unauthorized/private/get-current-started-quiz/${quiz_id}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function setSubmitAnswer(
  quiz_question_id: number,
  question_answer_id: number
): Promise<TAnswerResult> {
  return instance()
    .post(`${WIZ_API_URL}/common/private/submit-answer/`, {
      quiz_question_id,
      question_answer_id,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function setPurchaseLife(): Promise<{ success: boolean }> {
  return instance()
    .post(`${WIZ_API_URL}/authorized/private/purchase-life/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function claimRewardFromUnauthorizedTest(testId: string): Promise<Category> {
  return instance()
    .post(`${WIZ_API_URL}/authorized/private/auth-transition/`, {
      started_quiz_id: testId,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function setAuthorizedTokenAwards(started_quiz_id: number): Promise<TAward> {
  return instance()
    .post(`${WIZ_API_URL}/authorized/private/token-awards/${started_quiz_id}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getInfoEarnedTokens(started_quiz_id: number): Promise<TTestResult> {
  return instance()
    .get(`${WIZ_API_URL}/common/private/info-earned-tokens/${started_quiz_id}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}
