import { useCallback, useState } from 'react';
import { setUserCoursesReviews } from '../../../../../api/courseReview';

export const useComment = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSended, setIsSended] = useState<boolean>(false);

  const onComment = useCallback(
    (course: number, rating: number, comment?: string) => {
      setIsLoading(true);
      setUserCoursesReviews(course, rating, comment)
        .then(() => setIsSended(true))
        .catch(() => setIsSended(false))
        .finally(() => setIsLoading(false));
    },
    []
  );

  return {
    onComment,
    isLoading,
    isSended,
  };
};
