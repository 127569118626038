import { useCallback, useEffect, useState } from 'react';
import { getOwlWalletTransaction } from '../../../../../api/wallet';
import { Wallet } from '../../../../../models/Wallet';

export const useOwlWalletTransaction = () => {
  const [data, setData] = useState<Wallet[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchData = useCallback((min?: string, max?: string) => {
    setIsLoading(true);
    getOwlWalletTransaction(min, max)
      .then((res) => setData(res))
      .finally(() => setIsLoading(false));
  }, []);

  return { data, isLoading, fetchData };
};
