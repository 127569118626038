import { useCallback, useState } from 'react';
import { createReplyComment } from '../../../../../api/weber/feed';

export const usePostReply = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSended, setIsSended] = useState<boolean>(false);

  const onReply = useCallback(
    (commentId: number, comment: string, reply_to_comment?: number) => {
      setIsLoading(true);
      createReplyComment(commentId, comment, reply_to_comment)
        .then(() => setIsSended(true))
        .catch(() => setIsSended(false))
        .finally(() => setIsLoading(false));
    },
    []
  );

  return {
    onReply,
    isLoading,
    isSended,
    setIsSended,
  };
};
