/* eslint-disable @typescript-eslint/no-unused-vars */
import ERPImg from '../../assets/images/reward/erp.png';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Share } from '../../components/Share/Share';
import { MButton } from '../../horizon-components/MButton';
import './style.scss';

interface RewardModalProps {
  className?: string;
  title?: string;
  description?: string;
  onClick?: () => void;
  url?: string;
  rewardAmount?: number | string;
}

export const RewardModal = ({
  className,
  onClick,
  url = '',
  rewardAmount = 0,
  title,
  description,
}: RewardModalProps) => {
  const { t } = useTranslation(['translation']);

  return (
    <div className={classNames('RewardModal', className)}>
      <img src={ERPImg} alt="raite" />
      <div className="flex w-full flex-col gap-2 lg:gap-3">
        <h3>{title || 'Получено 200 EdRes'}</h3>
        <p>{description || 'Поделитесь своими успехами с друзьями!'}</p>
        <Share
          url={url}
          loading={!url}
          text={t('main.share.description', {
            activationRewardAmount: rewardAmount,
          })}
        />
      </div>
      <div className="flex w-full gap-2 lg:gap-6">
        <MButton
          variant="highlighted"
          color="primary"
          className="w-full lg:!px-8 lg:!py-6 lg:!text-lg"
          onClick={onClick}
        >
          {t('buttons.Continue')}
        </MButton>
      </div>
    </div>
  );
};
