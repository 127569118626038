import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getCourseReview, OrderBy } from '../../../../api/courseReview';
import {
  getPrivateCourseReviewLikedV3,
  getPrivateMyCourseReviewsV3,
  getPublicCourseReviewV3,
} from '../../../../api/courseReviewV3';
import { ButtonsFilter } from '../../../../components/ButtonsFilter';
import { CourseComment } from '../../../../components/CourseComment';
import { CourseRating } from '../../../../components/CourseRating';
import { Loader } from '../../../../components/Loader';
import { CourseFeedback } from '../../../../features/CourseFeedback';
import useFetch from '../../../../hooks/useFetch';
import { Pagination } from '../../../../horizon-components/Pagination';
import { TRating } from '../../../../models/Review';
import { useUserContext } from '../../../../providers';
import { PAGE_SIZE } from '../../../../shared/lib/const/limit';

interface CourseReviewProps {
  className?: string;
  isEnrolled: boolean;
}

export const CourseReview = ({ className, isEnrolled }: CourseReviewProps) => {
  const { id } = useParams();
  const { user } = useUserContext();
  const { t } = useTranslation(['translation']);
  const [filter, setFilter] = useState<OrderBy>('-rating');
  const [page, setPage] = useState(1);
  const [myReviews, setMyReviews] = useState<number[]>([]);
  const [isLoadingMine, setIsLoadingMine] = useState<boolean>(false);
  const [data, setData] = useState<number[]>([]);

  const { data: report, fetchData: fetchReports } = useFetch(() =>
    getCourseReview(Number(id))
  );

  const fetchLiked = useCallback(() => {
    getPrivateCourseReviewLikedV3(Number(id)).then((res) => setData(res));
  }, [id]);

  const {
    data: othersReviews,
    isLoading: isLoadingOthers,
    fetchData: refetchOthers,
  } = useFetch(getPublicCourseReviewV3);

  const fetchMine = useCallback(() => {
    setIsLoadingMine(true);
    getPrivateMyCourseReviewsV3(Number(id))
      .then((res) => setMyReviews(res))
      .finally(() => setIsLoadingMine(false));
  }, [id]);

  const getRating = useCallback(() => {
    fetchReports();
    if (user?.guid) {
      fetchMine();
      fetchLiked();
    }
  }, [fetchReports, fetchMine, user?.guid, fetchLiked]);

  useEffect(() => {
    getRating();
  }, [id, t]);

  useEffect(() => {
    refetchOthers({
      course: Number(id),
      page: page,
      review_owner: 'others',
      order_by: filter,
    });
  }, [filter, id, page, t]);

  const sort: { id: OrderBy; title: string }[] = [
    { id: '-rating', title: t('sort.positive') },
    { id: 'rating', title: t('sort.negative') },
    { id: '-created_at', title: t('sort.latest') },
  ];

  const handleFilter = (filterId: OrderBy) => {
    setFilter(filterId);
  };

  const comments = useMemo(() => {
    return (
      othersReviews?.results?.filter(
        (comment) => !myReviews?.includes(comment?.id)
      ) || []
    );
  }, [myReviews, othersReviews?.results]);

  const myComments = useMemo(() => {
    return (
      othersReviews?.results?.filter((comment) =>
        myReviews?.includes(comment?.id)
      ) || []
    );
  }, [myReviews, othersReviews?.results]);

  const generateReviewElement = (review: TRating, mine: boolean) => {
    return (
      <CourseComment
        userReaction={!!data?.find((like) => like === review?.id)}
        user={review?.user}
        reviewLikes={review?.review_reactions}
        reviewDate={review?.created_at}
        reviewRating={review?.rating}
        reviewId={review?.id}
        key={review?.id}
        mine={mine}
      >
        {review?.comment}
      </CourseComment>
    );
  };

  return (
    <div className={classNames('flex w-full flex-col gap-4 md:gap-6', className)}>
      {isLoadingMine ? (
        <Loader />
      ) : myComments?.length ? (
        generateReviewElement(myComments[0], true)
      ) : (
        isEnrolled && <CourseFeedback onSend={getRating} courseId={Number(id)} />
      )}
      <CourseRating
        totalRating={report?.average_rating}
        ratingsBreakdown={report?.ratings_breakdown}
      />

      <div className="flex flex-col gap-[8px] md:gap-[16px]">
        {!!othersReviews?.count && !!comments.length && (
          <div className="flex justify-end">
            <ButtonsFilter buttons={sort} active={filter} onFilter={handleFilter} />
          </div>
        )}
        {isLoadingOthers ? (
          <Loader />
        ) : (
          !!othersReviews?.results?.length &&
          !!comments.length && (
            <>
              {comments?.map((review) => generateReviewElement(review, false))}
              {othersReviews?.count > PAGE_SIZE && (
                <div className={'flex w-full items-center justify-between'}>
                  <p>{t('edu.Your_pagination_could_be_here')}</p>
                  <Pagination
                    currentPage={page}
                    onPageChange={(pageNumber: number) => setPage(pageNumber)}
                    totalPages={Math.ceil(othersReviews?.count / PAGE_SIZE)}
                    maxVisiblePages={3}
                    showPrevNext={false}
                  />
                </div>
              )}
            </>
          )
        )}
      </div>
    </div>
  );
};
