import { FC } from 'react';
import { ShareSocialButton } from './ShareSocialButton';
import { ReactComponent as Icon } from '../../../assets/icons/share/twitter.svg';
import { ButtonsProps } from '../types';
import { mergeTextAndUrl, shareOnSocial } from '../utils';

const ShareTwitterButton: FC<ButtonsProps> = ({ loading, onSocialClick }) => {
  const onClick = async (url: string, text?: string) => {
    const params = { text: mergeTextAndUrl(url, text) };
    return await shareOnSocial({
      web: { baseUrl: 'https://twitter.com/intent/tweet', params },
      native: { baseUrl: 'twitter://post', params },
      androidPackageName: 'com.twitter.android',
    });
  };

  return (
    <ShareSocialButton
      text="Twitter"
      Icon={Icon}
      loading={loading}
      onClick={onSocialClick('Twitter', onClick)}
    />
  );
};

export default ShareTwitterButton;
