import instance, { WEBER_API_URL } from '../instance';
import {
  Feed,
  TFeedComment,
  EFeedReaction,
  TFeedComments,
  FeedType,
  FeedStatus,
  TLikeFeedReview,
  TFavoriteFolders,
} from '../../models/Feed';
import { ResponsePagination } from '../../models/Response';
import { CommentItem, TComment } from '../../models/Comment';
import { UploadTopicImage } from '../../models/Course';
import { SelectOptions } from '../../horizon-components/MMultSelect/MMultiSelect';

export enum ResourceType {
  AttachmentsPost = 'AttachmentsPost',
  LinkPost = 'LinkPost',
  ArticlePost = 'ArticlePost',
  DoiPost = 'DoiPost',
}

export type CreateFeedForm = {
  title?: string;
  link?: string;
  doi?: string;
  doi_link?: string;
  language?: string;
  description?: string;
  group?: number;
  preview_image?: File | string;
  resourcetype?: ResourceType;
  status?: FeedStatus;
  html_body?: string;
  tags?: SelectOptions[];
  is_published_in_feed?: boolean;
};

export function createFeed(form: CreateFeedForm): Promise<Feed> {
  const body = new FormData();

  form.title && body.append('title', form.title);
  form.link && body.append('link', form.link);
  form.doi_link && body.append('doi_link', form.doi_link);
  form.language && body.append('language', form.language);
  form.description && body.append('description', form.description);
  form.html_body && body.append('html_body', form.html_body);

  if (form?.preview_image) {
    body.append('preview_image', form.preview_image);
  }
  if (form?.group) {
    body.append('group', form.group.toString());
  }
  if (form?.resourcetype) {
    body.append('resourcetype', form.resourcetype);
  }
  if (form?.status) {
    body.append('status', form.status);
  }
  if (form?.tags?.length) {
    form.tags.forEach((tag) => {
      body.append(`tags`, tag.label);
    });
  }
  if (form?.is_published_in_feed) {
    body.append('is_published_in_feed', String(form.is_published_in_feed));
  }

  return instance()
    .post(`${WEBER_API_URL}/posts/`, body)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function updateFeed(form: CreateFeedForm, id: number): Promise<Feed> {
  const body = new FormData();

  form.title && body.append('title', form.title);
  form.link && body.append('link', form.link);
  form.doi_link && body.append('doi_link', form.doi_link);
  form.language && body.append('language', form.language);
  form.description && body.append('description', form.description);
  form.html_body && body.append('html_body', form.html_body);

  if (form?.preview_image) {
    body.append('preview_image', form.preview_image);
  }
  if (form?.group) {
    body.append('group', form.group.toString());
  }
  if (form?.resourcetype) {
    body.append('resourcetype', form.resourcetype);
  }
  if (form?.status) {
    body.append('status', form.status);
  }
  if (form?.tags?.length) {
    form.tags.forEach((tag) => {
      body.append(`tags`, tag.label);
    });
  }
  if (form?.is_published_in_feed) {
    body.append('is_published_in_feed', String(form.is_published_in_feed));
  }

  return instance()
    .put(`${WEBER_API_URL}/posts/${id}/`, body)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function patchFeed(form?: CreateFeedForm, id?: number): Promise<Feed> {
  const body = new FormData();

  if (form?.title) {
    body.append('title', form.title);
  }
  if (form?.language) {
    body.append('language', form.language);
  }
  if (form?.description) {
    body.append('description', form.description);
  }
  if (form?.html_body) {
    body.append('html_body', form.html_body);
  }
  if (form?.preview_image) {
    body.append('preview_image', form.preview_image);
  }
  if (form?.group) {
    body.append('group', form.group.toString());
  }
  if (form?.resourcetype) {
    body.append('resourcetype', form.resourcetype);
  }
  if (form?.status) {
    body.append('status', form.status);
  }
  if (form?.tags?.length) {
    form.tags.forEach((tag) => {
      body.append(`tags`, tag.label);
    });
  }
  if (form?.is_published_in_feed) {
    body.append('is_published_in_feed', String(form.is_published_in_feed));
  }

  return instance()
    .patch(`${WEBER_API_URL}/posts/${id}/`, body)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function createFeedComment(post: number, text: string): Promise<CommentItem> {
  return instance()
    .post(`${WEBER_API_URL}/post-comments/`, {
      post,
      comment: { text },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getСitation(doi: string): Promise<CreateFeedForm> {
  return instance()
    .get(`${WEBER_API_URL}/citation/`, { params: { doi } })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function createFeedReplyComment(
  parent: number,
  text: string
): Promise<TFeedComment> {
  return instance()
    .post(`${WEBER_API_URL}/post-comments/`, {
      parent,
      text,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getFeedReplies(parent: number): Promise<TFeedComments[]> {
  return instance()
    .get(`${WEBER_API_URL}/post-comments/${parent}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function createReplyComment(
  parent: number,
  text: string,
  reply_to_comment?: number
): Promise<TComment> {
  return instance()
    .post(`${WEBER_API_URL}/comments/`, {
      parent,
      text,
      reply_to_comment,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function articleTextImage(
  file: Blob,
  fileName: string
): Promise<UploadTopicImage> {
  const body = new FormData();
  body.append('file', file, fileName);

  return instance()
    .post(`${WEBER_API_URL}/article-text-image/`, body)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getReplies(
  parent: number
): Promise<ResponsePagination<TFeedComment>> {
  return instance()
    .get(`${WEBER_API_URL}/comments/${parent}/get_replies/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function setReactionFeed(
  post: number,
  reaction: EFeedReaction | null
): Promise<CommentItem> {
  return instance()
    .post(`${WEBER_API_URL}/posts/${post}/set_reaction/`, {
      reaction,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getFeed(postId: number): Promise<Feed> {
  return instance()
    .get(`${WEBER_API_URL}/posts/${postId}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export enum OrderBy {
  popularity = 'Popularity',
  rating = 'Rating',
  '-created_at' = 'Latest',
  created_at = 'Oldest',
}

export type FeedRequest = {
  tags?: string[];
  page?: number;
  order_by?: keyof typeof OrderBy;
  group?: number;
  author_guid?: string;
  tags_or_title?: string;
  title?: string;
  following?: boolean;
  from_me?: boolean;
  feed_type?: FeedType;
  ordering?: string;
  language?: string;
  status?: FeedStatus;
  favourite_folder?: number;
  favourited_posts?: boolean;
};

export function getFeeds({
  tags,
  page,
  order_by,
  group,
  author_guid,
  tags_or_title,
  title,
  following,
  from_me,
  feed_type,
  ordering,
  language,
  status,
  favourite_folder,
  favourited_posts,
}: FeedRequest): Promise<ResponsePagination<Feed>> {
  return instance()
    .get(`${WEBER_API_URL}/posts/`, {
      params: {
        page,
        order_by,
        tags,
        group,
        author_guid,
        tags_or_title,
        title,
        following,
        from_me,
        feed_type,
        ordering,
        language,
        status,
        favourite_folder,
        favourited_posts,
      },
      paramsSerializer: {
        indexes: null,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getFeedById(post: number): Promise<Feed> {
  return instance()
    .get(`${WEBER_API_URL}/posts/${post}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getFeedComments(
  post: number,
  page?: number
): Promise<ResponsePagination<TFeedComments>> {
  return instance()
    .get(`${WEBER_API_URL}/post-comments/`, {
      params: { page, post },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function attachFeedImage(postId: number, file: File): Promise<void> {
  const body = new FormData();
  body.append('file', file);
  body.append('post', postId.toString());
  return instance()
    .post(`${WEBER_API_URL}/attached-files/`, body)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function attachArticleFeedImage(postId: number, file: File): Promise<void> {
  const body = new FormData();
  body.append('file', file);
  body.append('post', postId.toString());
  return instance()
    .post(`${WEBER_API_URL}/article-attached-files/`, body)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function deleteFeedImage(postId: number): Promise<void> {
  return instance()
    .delete(`${WEBER_API_URL}/attached-files/${postId}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function deleteFeed(postId: number): Promise<void> {
  return instance()
    .delete(`${WEBER_API_URL}/posts/${postId}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function changeStatus(
  status: FeedStatus,
  resourcetype: string,
  id: number
): Promise<Feed> {
  const body = new FormData();
  body.append('status', status);
  body.append('resourcetype', resourcetype);

  return instance()
    .put(`${WEBER_API_URL}/posts/${id}/`, body)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function setLikeFeedReview(commentId: number): Promise<TLikeFeedReview> {
  return instance()
    .post(`${WEBER_API_URL}/comments-likes/`, { comment: commentId })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function deleteLikeFeedReview(commentId: number): Promise<TLikeFeedReview> {
  return instance()
    .delete(`${WEBER_API_URL}/comments-likes/${commentId}/delete/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getFavouriteFolders(): Promise<{
  total_post_count: number;
  results: TFavoriteFolders[];
}> {
  return instance()
    .get(`${WEBER_API_URL}/favourite-folders/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getFavouriteFolderById(folderId: number): Promise<TFavoriteFolders> {
  return instance()
    .get(`${WEBER_API_URL}/favourite-folders/${folderId}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function createFavouriteFolder(title: string): Promise<TFavoriteFolders> {
  return instance()
    .post(`${WEBER_API_URL}/favourite-folders/`, { title })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function updateFavouriteFolderById(
  folderId: number,
  title: string
): Promise<TFavoriteFolders> {
  return instance()
    .put(`${WEBER_API_URL}/favourite-folders/${folderId}/`, { title })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function deleteFavouriteFolderById(
  folderId: number
): Promise<TFavoriteFolders> {
  return instance()
    .delete(`${WEBER_API_URL}/favourite-folders/${folderId}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getPostFavouriteFolders(
  favourite_folder: number,
  page: number
): Promise<TFavoriteFolders[]> {
  return instance()
    .get(`${WEBER_API_URL}/post-favourite-folders/`, {
      params: { favourite_folder, page },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getPostFavouriteFolderById(
  postId: number
): Promise<TFavoriteFolders> {
  return instance()
    .get(`${WEBER_API_URL}/post-favourite-folders/${postId}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function setPostFavouriteFolder(
  post: number,
  favourite_folder?: number | null
): Promise<TFavoriteFolders> {
  return instance()
    .post(`${WEBER_API_URL}/post-favourite-folders/`, { post, favourite_folder })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function updatePostFavouriteFolderById(
  currentId: number,
  postId: number,
  favourite_folder: number
): Promise<TFavoriteFolders> {
  return instance()
    .put(`${WEBER_API_URL}/post-favourite-folders/${currentId}/`, {
      post: postId,
      favourite_folder,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function deletePostFavouriteFolderById(
  folderId: number
): Promise<TFavoriteFolders> {
  return instance()
    .delete(`${WEBER_API_URL}/post-favourite-folders/${folderId}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}
