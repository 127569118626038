import CFPlayer from './LandingCFPlayer';

function LandingReviewVideo({ id, onClick }: { id: string; onClick: () => void }) {
  return (
    <CFPlayer
      title={`Review Video: ${id}`}
      videoId={id}
      posterHeight={550}
      autoplay={false}
      loop={false}
      muted={false}
      className="LandingPage__review-video"
      onClick={onClick}
    />
  );
}

export default LandingReviewVideo;
