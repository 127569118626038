import instance from './instance';
import { Wallet } from '../models/Wallet';

export function getErWallet(): Promise<{ id: number; value: number }[]> {
  return instance()
    .get(`/er-wallet/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getOwlWallet(): Promise<{ id: number; value: number }[]> {
  return instance()
    .get(`/owl-wallet/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getErWalletTransaction(
  min?: string,
  max?: string
): Promise<Wallet[]> {
  return instance()
    .get(`/er-wallet-transaction/`, {
      params: {
        created_at_min: min,
        created_at_max: max,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getOwlWalletTransaction(
  min?: string,
  max?: string
): Promise<Wallet[]> {
  return instance()
    .get(`/owl-wallet-transaction/`, {
      params: {
        created_at_min: min,
        created_at_max: max,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}
