import classNames from 'classnames';
import { TBanner } from '../../../api/banner';
import './Banners.scss';

interface BannersProps {
  className?: string;
  banners?: TBanner[];
}

export const Banners = ({ className, banners }: BannersProps) => {
  return (
    <div className={classNames('Banners', className)}>
      {banners?.map(
        (banner) =>
          !!banner.image && (
            <div
              key={banner.id}
              className={classNames('Banners__item', {
                'cursor-pointer': banner.url,
              })}
              onClick={() => banner.url && window.open(banner.url, '_blank')}
            >
              <img
                src={banner.image}
                alt="banner-desctop"
                className="!hidden w-full sm:!block"
              />
              <img
                src={banner.image_mobile}
                alt="banner-mobile"
                className="!block w-full sm:!hidden"
              />
            </div>
          )
      )}
    </div>
  );
};
