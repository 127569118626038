import React from 'react';
import { useTranslation } from 'react-i18next';

export const Rules: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className={'mb-[20px] flex items-center gap-[8px]'}>
        <div
          className={
            'flex h-[28px] w-[28px] flex-none items-center justify-center rounded-full bg-purple-100 text-[16px] font-bold text-purple-400'
          }
        >
          1
        </div>
        <p className={'text-[16px] font-[600] text-secondaryGrey-900'}>
          {t('bid.r1')}
        </p>
      </div>
      <div className={'mb-[20px] flex items-center gap-[8px]'}>
        <div
          className={
            'flex h-[28px] w-[28px] flex-none items-center justify-center rounded-full bg-purple-100 text-[16px] font-bold text-purple-400'
          }
        >
          2
        </div>
        <p className={'text-[16px] font-[600] text-secondaryGrey-900'}>
          {t('bid.r2')}
        </p>
      </div>
      <div className={'mb-[20px] flex items-center gap-[8px]'}>
        <div
          className={
            'flex h-[28px] w-[28px] flex-none items-center justify-center rounded-full bg-purple-100 text-[16px] font-bold text-purple-400'
          }
        >
          3
        </div>
        <p className={'text-[16px] font-[600] text-secondaryGrey-900'}>
          {t('bid.r3')}
        </p>
      </div>
      <div className={'mb-[20px] flex items-center gap-[8px]'}>
        <div
          className={
            'flex h-[28px] w-[28px] flex-none items-center justify-center rounded-full bg-purple-100 text-[16px] font-bold text-purple-400'
          }
        >
          4
        </div>
        <p className={'text-[16px] font-[600] text-secondaryGrey-900'}>
          {t('bid.r4')}
        </p>
      </div>
      <div className={'mb-[20px] flex items-center gap-[8px]'}>
        <div
          className={
            'flex h-[28px] w-[28px] flex-none items-center justify-center rounded-full bg-purple-100 text-[16px] font-bold text-purple-400'
          }
        >
          5
        </div>
        <p className={'text-[16px] font-[600] text-secondaryGrey-900'}>
          {t('bid.r5')}
        </p>
      </div>
    </>
  );
};
