import instance from './instance';

export type TBanner = {
  id: number;
  place_name_slug: string;
  image_mobile: string;
  image: string;
  url: string;
  order: number;
};

export function getBanners({
  place_name_slug,
  order,
  ordering,
}: {
  place_name_slug?: string;
  order?: number;
  ordering?: string;
}): Promise<TBanner[]> {
  return instance(false)
    .get(`/banners/`, {
      params: {
        place_name_slug,
        order,
        ordering,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getBanner(id: string): Promise<TBanner> {
  return instance()
    .get(`/banners/${id}`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}
