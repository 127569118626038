import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.16px.svg';
import useDebounce from '../../hooks/useDebounce';
import useHistoryParams from '../../hooks/useHistoryParams';
import { SelectOptions } from '../MMultSelect/MMultiSelect';
import { MultiSearch } from '../MultiSearch';
import './style.scss';

interface TagSearchProps {
  className?: string;
  onSearch?: (search: string) => void;
  onFilter?: (tags_or_title: string[]) => void;
  options?: SelectOptions[];
}

export const TagSearch = ({
  className,
  onSearch,
  onFilter,
  options = [],
}: TagSearchProps) => {
  const { t } = useTranslation(['translation']);
  const [search, setSearch] = useState<string>('');
  const [tags, setTags] = useState<SelectOptions[]>([]);
  const debounced = useDebounce(search, 500);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { updateAllParamsInUrl, removeParamFromUrl } = useHistoryParams();

  useEffect(() => onSearch?.(search), [debounced]);
  useEffect(() => {
    if (searchParams.get('title')) {
      setSearch(searchParams.get('title') || '');
      onSearch?.(search);
    }
  }, [t]);

  useEffect(() => {
    const arr = searchParams.get('tags_or_title')?.split(',');
    if (arr?.filter(Boolean)?.length) {
      setTags(
        arr?.map((tag) => {
          return {
            label: tag,
            value: tag,
          };
        })
      );
    }
  }, [t]);

  const hndlTags = (tags: SelectOptions[]) => {
    setTags(tags);
    if (tags.length) {
      updateAllParamsInUrl({ tags_or_title: tags.map((tag) => tag.label) });
    } else {
      removeParamFromUrl('tags_or_title');
    }
    onFilter?.(tags.map((tag) => tag.label));
  };

  return (
    <div className={classNames('TagSearch', className)}>
      <SearchIcon />
      <MultiSearch
        options={options}
        onSelect={hndlTags}
        onChange={setSearch}
        inputValue={search}
        placeholder={`${t('buttons.search')}...`}
        selectedOptions={tags}
      />
    </div>
  );
};
