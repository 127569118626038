import React from 'react';

export type SelectOptions = {
  label: string;
  value: string;
};

export type SelectProps = {
  placeholder?: string;
  className?: string;
  selectClassName?: string;
  wrapperClassName?: string;
  label?: string;
  labelRequiredChar?: boolean;
  errorText?: string;
  value?: string;
  name?: string;
  onChange?: (value: string, name?: string) => void;
  options: SelectOptions[];
  leftLabel?: boolean;
};

export const HSelect: React.FC<SelectProps> = ({
  className,
  wrapperClassName,
  selectClassName = '',
  label,
  labelRequiredChar,
  errorText,
  name,
  value,
  onChange,
  options,
  leftLabel,
}) => {
  return (
    <div className={wrapperClassName}>
      {leftLabel && (
        <label
          className={`mb-1 ml-1 text-xs font-medium text-secondary-dark-grey-900 md:ml-3
          md:text-sm`}
        >
          {labelRequiredChar ? (
            <>
              <span className={'g-label'}>{label}</span>
              &nbsp;
              <span className={'g-label--required'}>*</span>
            </>
          ) : (
            label
          )}
        </label>
      )}
      <div
        className={`flex w-full rounded-xl border border-secondary-grey-500 align-middle text-sm font-normal text-gray-600 outline-none ${className} ${
          !!errorText ? 'border-red-500' : ''
        }`}
      >
        <select
          className={`mr-[10px] h-[46px] w-full rounded-xl bg-white px-2 pr-4 text-sm text-black outline-none dark:bg-navy-800 dark:text-white ${selectClassName}`}
          name={name}
          id="currency"
          onChange={(e) => onChange?.(e.target.value, name)}
          value={value}
        >
          {options.map((item, key) => (
            <option key={key} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
      </div>
      {!!errorText && <p className={'text-sm text-red-500'}>{errorText}</p>}
    </div>
  );
};
