import React from 'react';

type Props = {
  src: string;
  size?: number;
};

export const RoundedImage: React.FC<Props> = ({ src, size = 35 }) => {
  return (
    <img
      width={size}
      height={size}
      src={src}
      className={`rounded-full object-cover !h-[${size}px] w-[${size}px] !min-w-[${size}px] !max-h-[${size}px]`}
      alt={''}
    />
  );
};
