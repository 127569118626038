import { User, UserProfile, UserType } from '../models';
import { getRandomUserImage } from './randomUserImage';

export type GetUserData = {
  username: string;
  image: string;
  bg: string;
  guid: null | string;
  first_name: string;
  last_name: string;
};

export function generateHashFromGuid(guid: string) {
  return guid.split('').reduce(function (a, b) {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);
}

export const getUserData = (user?: UserProfile | User | null): GetUserData => {
  const noAvatarSrc = require(`../assets/images/no-avatar.png`);
  const deletedUserSrc = require(`../assets/images/deleted-avatar.png`);
  const emptyData = {
    username: 'DELETED',
    image: deletedUserSrc,
    bg: getRandomUserImage(2),
    guid: null,
    first_name: '',
    last_name: '',
  };

  if (!user) return emptyData;

  const userType = user.user_type;

  if (userType === UserType.USER) {
    return {
      username: user?.user_meta?.username || 'UNNAMED',
      image: user?.user_meta?.profile_image || noAvatarSrc,
      bg:
        user?.user_meta?.cover_image ||
        getRandomUserImage(generateHashFromGuid(user.guid)),
      guid: user?.guid || null,
      first_name: user?.user_meta?.first_name || '',
      last_name: user?.user_meta?.last_name || '',
    };
  } else if (userType === UserType.INSTITUTION) {
    return {
      username: user?.institution_meta?.name || 'UNNAMED',
      image: user?.institution_meta?.logo || noAvatarSrc,
      bg:
        user?.user_meta?.cover_image ||
        getRandomUserImage(generateHashFromGuid(user.guid)),
      guid: user?.guid || null,
      first_name: user?.user_meta?.first_name || '',
      last_name: user?.user_meta?.last_name || '',
    };
  }

  return emptyData;
};
