function objectToFormData(obj: Record<string, any>): FormData {
  const formData = new FormData();

  const appendFormData = (data: Record<string, any>, parentKey = '') => {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const value = data[key];

        const newKey = parentKey
          ? `${parentKey}${!isNaN(Number(key)) ? '[' : ''}${key}${
              !isNaN(Number(key)) ? ']' : ''
            }`
          : key;

        if (value === null || value === undefined) {
          formData.append(newKey, '');
        } else if (value instanceof File) {
          formData.append(newKey, value, value.name);
        } else if (typeof value === 'object' && !(value instanceof File)) {
          appendFormData(value, newKey);
        } else {
          formData.append(newKey, value.toString());
        }
      }
    }
  };

  appendFormData(obj);

  return formData;
}

export default objectToFormData;
