import React, { useEffect, useRef, useState } from 'react';

export type Tab = {
  id: string;
  title: string;
  icon?: React.ReactNode;
  additional?: string;
};

type Props = {
  tabs: Tab[];
  onTabChanged?: (newTab: Tab) => void;
  tabsExtra?: string;
  titleExtra?: string;
  itemExtra?: string;
  active?: Tab;
};

export const Tabs: React.FC<Props> = ({
  tabs,
  onTabChanged = () => {},
  tabsExtra,
  titleExtra,
  itemExtra,
  active,
}) => {
  const [currentTab, setCurrentTab] = useState(tabs[0]);
  const tabContainerRef = useRef<HTMLDivElement | null>(null);
  const [startX, setStartX] = useState<number | null>(null);
  const [scrollLeft, setScrollLeft] = useState<number>(0);

  const handleTouchStart = (e: React.TouchEvent) => {
    const touch = e.touches[0];
    setStartX(touch.clientX);
    if (tabContainerRef.current) {
      setScrollLeft(tabContainerRef.current.scrollLeft);
    }
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (!startX || !tabContainerRef.current) return;
    const touch = e.touches[0];
    const x = touch.clientX;
    const walk = (startX - x) * 2; // Скорость прокрутки
    tabContainerRef.current.scrollLeft = scrollLeft + walk;
  };

  const toggleTab = (tab: Tab) => {
    onTabChanged(tab);
    setCurrentTab(tab);
  };

  useEffect(() => {
    active?.id && active?.id !== currentTab.id && setCurrentTab(active);
  }, [active]);

  return (
    <div
      className={`flex items-start gap-[8px] self-stretch !overflow-x-hidden md:gap-[16px] ${tabsExtra}`}
      ref={tabContainerRef}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
    >
      {tabs.map((tab) => {
        const Icon = tab.icon;
        return (
          <div
            key={tab.id}
            className={`flex cursor-pointer flex-col items-center gap-[4px] text-center font-semibold text-secondary-dark-grey-400 md:gap-[8px] md:text-[16px] ${itemExtra}`}
            onClick={() => toggleTab(tab)}
          >
            {Icon && <p>{Icon}</p>}
            <span
              className={`px-[8px] ${titleExtra} ${
                currentTab.id === tab.id ? '!text-secondary-grey-900' : ''
              }`}
            >
              {tab.title}
            </span>
            {tab.additional && (
              <p className="text-lg text-gray-600">{tab.additional}</p>
            )}
            <div
              className={`h-1 w-full rounded-3xl ${
                currentTab.id === tab.id ? 'bg-primary-purple-blue-500' : ''
              }`}
            ></div>
          </div>
        );
      })}
    </div>
  );
};
