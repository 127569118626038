import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import {
  finishExam,
  getCourse,
  getCourseVerification,
  getCourseViewById,
  getEnrolledUserCourseById,
  getSummary,
  getUnitView,
  getUnitViewTeacher,
  resetUnitExamTeacher,
  restartUnitExam,
  startUnitExam,
  startUnitExamTeacher,
} from '../../../api/course';
import { getLanguagesList } from '../../../api/dictionaries';
import { Bread, Breadcrumbs } from '../../../components/Breadcrumbs';
import { Loader } from '../../../components/Loader';
import { useNavigate } from '../../../hooks/useNavigate';
import { Link } from '../../../horizon-components/Link';
import { MButton } from '../../../horizon-components/MButton/ui/MButton';
import { SelectOptions } from '../../../horizon-components/MMultSelect/MMultiSelect';
import {
  AuthRoutes,
  EduRoutes,
  MainRoutes,
  ProfileRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';
import {
  CompletedUnit,
  Course,
  TSummary,
  Unit,
  UnitExamResult,
  UnitItem,
} from '../../../models/Course';
import { useUserContext } from '../../../providers';
import { useTitleBackContext } from '../../../providers/TitleBackProvider/TitleBackProvider';
import { getUserData } from '../../../utils/getUserData';
import { NoAvatar } from '../../../widgets/NoAvatar';
import { TabsEnum } from '../CourseView/components/CourseInfo';
import { CourseContentBody } from './components/CourseContentBody';
import { CourseExamBody } from './components/CourseExamBody';
import { CourseNavigation } from './components/CourseNavigation';
import { ExamResultInfo } from './components/ExamResultInfo';
import { PreviewWindow } from './components/PreviewWindow';
import { StaffVerification } from './components/StaffVerification';
import { SummaryResultInfo } from './components/SummaryResultInfo';
import './EnrolledCoursePage.scss';
import SEO from '../../../components/SEO/SEO';
import { clearStringFromHtmlTags } from '../../../utils/clearStringFromHtmlTags';

type Props = {
  isPreview?: boolean;
};

export const EnrolledCoursePage: React.FC<Props> = ({ isPreview }) => {
  const navigate = useNavigate();
  const { id: courseId } = useParams();
  const { updateWallet } = useUserContext();
  const { state }: { state: { isMyCourse: boolean } } = useLocation();
  const { t } = useTranslation(['translation']);
  const [isLoading, setIsLoading] = useState(false);
  const [languages, setLanguages] = useState<SelectOptions[]>([]);
  const [currentCourse, setCurrentCourse] = useState<Course | null>(null);
  const [enrolledCourse, setEnrolledCourse] = useState<Course | null>(null);
  const [currentUnit, setCurrentUnit] = useState<Unit | null>(null);
  const [currentUnitData, setCurrentUnitData] = useState<UnitItem | null>(null);
  const [currentUnitExamId, setCurrentUnitExamId] = useState<number | null>(null);
  const [isSummary, setIsSummary] = useState<boolean>(false);
  const [summary, setSummary] = useState<TSummary>();
  const [status, setStatus] = useState('');
  const { user } = useUserContext();
  const authorData = getUserData(enrolledCourse?.owner);
  const authorUrl =
    MainRoutes.auth +
    AuthRoutes.profile +
    ProfileRoutes.user +
    `/${authorData?.guid}`;
  const [breads, setBreads] = useState<Bread[]>([]);
  const [completedUnit, setCompletedUnit] = useState<CompletedUnit | null>(null);
  const { useTitleBack } = useTitleBackContext();
  const [isGradeCompleted, setIsGradeCompleted] = useState(false);
  const [isSubjectCompleted, setIsSubjectCompleted] = useState(false);

  useTitleBack(enrolledCourse?.title);

  useEffect(() => {
    if (enrolledCourse?.id) {
      setBreads([
        { title: 'EDU', url: MainRoutes.edu },
        {
          title: t('course.allCourses'),
          url: MainRoutes.edu + EduRoutes.courses,
        },
        {
          title: enrolledCourse?.subject?.title || t('course.Course'),
          url:
            MainRoutes.edu +
            EduRoutes.courses +
            `/?subject=${enrolledCourse?.subject?.slug}`,
        },
        {
          title: enrolledCourse?.title,
          url: MainRoutes.edu + EduRoutes.course + `/${courseId}`,
        },
      ]);
    }
  }, [courseId, enrolledCourse, t]);

  const isStaff = useMemo(
    () => !!user?.is_staff && !state?.isMyCourse,
    [state?.isMyCourse, user?.is_staff]
  );

  useEffect(() => {
    getLanguagesList().then((data) => {
      data?.results?.length &&
        setLanguages([
          ...data.results.map((item) => ({
            label: item.language_iso_code,
            value: item.id.toString(),
          })),
        ]);
    });
  }, []);

  useEffect(() => {
    if (!courseId) return;

    (async () => {
      setIsLoading(true);
      let enrolledCourse: Course;
      let currentCourse: Course;
      if (isPreview) {
        if (isStaff) {
          enrolledCourse = await getCourseViewById(Number(courseId));
          currentCourse = await getCourseVerification(courseId);
          setCurrentCourse(currentCourse);
        } else {
          enrolledCourse = await getCourseViewById(Number(courseId));
          currentCourse = await getCourse(courseId);
          setCurrentCourse(currentCourse);
        }
      } else {
        let course = await getEnrolledUserCourseById(Number(courseId));
        enrolledCourse = course.course;
        setStatus(course.status);
      }
      setEnrolledCourse(enrolledCourse);
      setCurrentUnit(enrolledCourse?.units?.[0]);
    })().finally(() => setIsLoading(false));
  }, [courseId, isPreview, isStaff]);

  useEffect(() => {
    if (!currentUnit) return;

    (async () => {
      setCurrentUnitData(null);
      let unitData: UnitItem;
      if (isPreview) {
        unitData = await getUnitViewTeacher(currentUnit.id);
      } else {
        unitData = await getUnitView(currentUnit.id);
      }
      setCurrentUnitData(unitData);
    })();
  }, [currentUnit, isPreview]);

  const handleResults = (unitData: UnitItem) => {
    unitData?.completed?.[0] && setCompletedUnit(unitData?.completed[0]);

    if (unitData?.started_exam && unitData?.completed?.length !== 0) {
      setCurrentUnitExamId(unitData?.started_exam);
    }
  };

  const leaveExam = () => {
    setCurrentUnitExamId(null);
  };

  const startExam = async (unitId: number) => {
    let examId;
    if (isPreview) {
      if (isStaff) {
        examId = Math.random();
      } else {
        try {
          const { id } = await startUnitExamTeacher(unitId);
          examId = id;
        } catch (e) {
          await resetUnitExamTeacher(unitId);
          const data = await startUnitExamTeacher(unitId);
          examId = data?.id;
        }
      }
    } else {
      if (!currentUnit) return;
      const unitData = await getUnitView(currentUnit.id);
      setCurrentUnitData(unitData);
      const foundUnit = enrolledCourse?.units?.find(
        (unit) => unit?.id === unitData?.id
      );
      if (foundUnit) {
        foundUnit.completed = unitData?.completed;
        setEnrolledCourse(enrolledCourse);
      }
      const startedExam = unitData?.started_exam;

      if (unitData?.completed?.length === 0 && !startedExam) {
        const { id } = await startUnitExam(unitId);
        examId = id;
      } else {
        examId = unitData?.started_exam;
      }
    }
    setCurrentUnitExamId(examId);
  };

  const restartExam = async (unitId: number) => {
    const { id } = await restartUnitExam(unitId);
    setCurrentUnitExamId(id);
    setIsSummary(false);
    setCompletedUnit(null);
  };

  const isLastUnit = () => {
    if (!enrolledCourse || !currentUnit) return false;
    return (
      enrolledCourse.units[enrolledCourse.units.length - 1].id === currentUnit.id
    );
  };

  const nextUnit = () => {
    if (!enrolledCourse) return;
    const currentUnitIndex = enrolledCourse.units.findIndex(
      (unit) => unit.id === currentUnit?.id
    );
    if (currentUnitIndex === -1) return;

    const nextUnit = enrolledCourse.units[currentUnitIndex + 1];
    if (nextUnit) {
      setCurrentUnit(nextUnit);
      setCurrentUnitExamId(null);
      setCompletedUnit(null);
      setCurrentUnitExamId(null);
    }
  };

  const goToCourse = () =>
    navigate(
      `${MainRoutes.edu}${EduRoutes.course}/${courseId}?tab=${TabsEnum.FEEDBACK}`
    );

  const handleSummary = () => {
    getSummary(Number(courseId)).then(setSummary);
    setIsSummary(true);
  };

  const submitExam = async () => {
    if (currentUnitExamId && enrolledCourse) {
      const examResult = await finishExam(currentUnitExamId);
      setCompletedUnit(examResult.completed_unit);
      setIsGradeCompleted(examResult.is_level_completed);
      setIsSubjectCompleted(examResult.is_subject_completed);

      const foundUnit = enrolledCourse?.units?.find(
        (unit) => unit?.id === currentUnitData?.id
      );
      if (foundUnit) {
        foundUnit.completed = [examResult.completed_unit];
        setEnrolledCourse({ ...enrolledCourse });
      }

      updateWallet();
    }
  };

  return (
    <div className="EnrolledCoursePage">
      <SEO
        title={enrolledCourse?.title}
        desc={clearStringFromHtmlTags(enrolledCourse?.description)}
        bannerUrl={enrolledCourse?.cover_image}
        loading={!enrolledCourse}
      />

      <Breadcrumbs breads={breads} className="hidden md:block" />
      {isLoading ? (
        <Loader />
      ) : (
        !!currentUnitData && (
          <div className="EnrolledCoursePage__content">
            <div className="EnrolledCoursePage__list">
              {/*Course author card*/}
              <div className="EnrolledCoursePage__head">
                <div className="EnrolledCoursePage__user">
                  <Link to={authorUrl}>
                    <NoAvatar src={authorData.image} alt={authorData.username} />
                  </Link>
                  <div>
                    <h4 className="hidden md:block">{enrolledCourse?.title}</h4>
                    <h4 className="block md:hidden">
                      <Link to={authorUrl} className="block md:hidden">
                        {t('course.By')} {authorData.username}
                      </Link>
                    </h4>
                    <div>
                      <span className="md-max:hidden">
                        <Link to={authorUrl}>
                          {t('course.By')} {authorData.username}
                        </Link>
                      </span>
                      <span className="!font-semibold md-max:hidden">•</span>
                      <span>
                        {moment(enrolledCourse?.created_at).format('DD.MM.YY')}
                      </span>
                    </div>
                  </div>
                </div>
                <MButton
                  onClick={goToCourse}
                  variant={'transparent'}
                  color={'primary'}
                  className="px-[16px] py-[8px] md:px-[24px] md:py-[12px]"
                >
                  {t('course.details')}
                </MButton>
              </div>
              {/* Mobile Navigation */}
              {!!enrolledCourse && (
                <div className={'block md:hidden'}>
                  <CourseNavigation
                    course={enrolledCourse}
                    currentUnit={currentUnit}
                    setCurrentUnit={setCurrentUnit}
                    startExam={startExam}
                    currentUnitExam={currentUnitExamId}
                    leaveExam={leaveExam}
                  />
                </div>
              )}
              {/*Course content*/}
              <div className="CoursePreview__info content">
                {currentUnitData &&
                currentUnit &&
                !currentUnitExamId &&
                !isSummary ? (
                  <CourseContentBody
                    startExam={startExam}
                    currentUnit={currentUnit}
                    currentUnitData={currentUnitData}
                    languages={languages}
                    isStaff={isStaff}
                    isPreview={isPreview}
                    getResults={handleResults}
                  />
                ) : completedUnit && !isSummary ? (
                  <ExamResultInfo
                    completedUnit={completedUnit}
                    nextUnit={nextUnit}
                    isLastUnit={isLastUnit}
                    restartUnit={() => restartExam(currentUnitData.id)}
                    summaryCourse={handleSummary}
                    isGradeCompleted={isGradeCompleted}
                    isSubjectCompleted={isSubjectCompleted}
                    subjectTitle={enrolledCourse?.subject.title}
                    grade={enrolledCourse?.subject_level.title}
                  />
                ) : currentUnitExamId &&
                  currentUnitData &&
                  !!enrolledCourse &&
                  !isSummary ? (
                  <CourseExamBody
                    isStaff={isStaff}
                    isPreview={!!isPreview}
                    course={enrolledCourse}
                    currentUnitExamId={currentUnitExamId}
                    unitData={currentUnitData}
                    submitExam={submitExam}
                    nextUnit={nextUnit}
                  />
                ) : !!summary?.data?.length && isSummary ? (
                  <SummaryResultInfo
                    summaryResult={summary}
                    rateCourse={goToCourse}
                    restartUnit={restartExam}
                  />
                ) : (
                  <Loader />
                )}
              </div>
            </div>
            {/* right section -navigation */}
            {!!enrolledCourse && (
              <div className="EnrolledCoursePage__aside md-max:!hidden">
                <CourseNavigation
                  course={enrolledCourse}
                  currentUnit={currentUnit}
                  setCurrentUnit={setCurrentUnit}
                  startExam={startExam}
                  currentUnitExam={currentUnitExamId}
                  leaveExam={leaveExam}
                  status={status}
                />
              </div>
            )}
          </div>
        )
      )}
      {isStaff && isPreview && !!courseId && (
        <StaffVerification courseId={courseId} />
      )}
      {isPreview && !isStaff && (
        <PreviewWindow courseId={courseId || ''} currentCourse={currentCourse} />
      )}
    </div>
  );
};
