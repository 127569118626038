type Props = {
  text: string;
  Icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string;
    }
  >;
  onClick?: () => void;
  loading?: boolean;
};

export const ShareSocialButton: React.FC<Props> = ({
  text,
  Icon,
  onClick,
  loading,
}) => {
  return (
    <button className="ShareButtons__social" onClick={onClick} disabled={loading}>
      <span className="ShareButtons__social-icon">
        <Icon className="ShareButtons__social-icon-svg" />
      </span>
      <span className="ShareButtons__social-text">{text}</span>
    </button>
  );
};
