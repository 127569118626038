import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { saveCurrentUrl } from '../../../api/sessions';
import { setReactionFeed } from '../../../api/weber/feed';
import { ReactComponent as CommentIcon } from '../../../assets/icons/feed/comment.svg';
import { ReactComponent as DislikeIcon } from '../../../assets/icons/feed/dislike.svg';
import { ReactComponent as LikeIcon } from '../../../assets/icons/feed/like.svg';
import { ReactComponent as SmileIcon } from '../../../assets/icons/feed/smile.svg';
import { ReactComponent as StarIcon } from '../../../assets/icons/feed/star.svg';
import { useNavigate } from '../../../hooks/useNavigate';
import { MButton } from '../../../horizon-components/MButton/ui/MButton';
import {
  AuthRoutes,
  MainRoutes,
  WeberRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';
import { EFeedReaction, Feed } from '../../../models/Feed';
import { useUserContext } from '../../../providers';
import { ShareModal } from '../../ShareModal';
import './FeedReaction.scss';

interface FeedReactionProps {
  className?: string;
  isSingle?: boolean;
  feed: Feed;
}

export const FeedReaction = ({
  className,
  isSingle = false,
  feed,
}: FeedReactionProps) => {
  const { t } = useTranslation(['translation']);
  const { user } = useUserContext();
  const navigate = useNavigate();
  const [isReactionLoading, setIsReactionLoading] = useState(false);
  const currentHost = window.location.host;

  const setReaction = (reaction: EFeedReaction) => {
    if (!user) {
      saveCurrentUrl(window.location.pathname);
      navigate(MainRoutes.auth + AuthRoutes.signin);
      return;
    }

    const isSameReaction = feed.my_reaction === reaction;
    const reactionToSend = isSameReaction ? null : reaction;

    setIsReactionLoading(true);
    setReactionFeed(feed.id, reactionToSend)
      .then(() => {
        switch (feed?.my_reaction) {
          case EFeedReaction.INTERESTED_IN:
            feed.reaction_count.INTERESTED_IN--;
            break;
          case EFeedReaction.DOUBT_THAT:
            feed.reaction_count.DOUBT_THAT--;
            break;
          case EFeedReaction.SUPPORT_BY:
            feed.reaction_count.SUPPORT_BY--;
            break;
        }

        if (!isSameReaction) {
          switch (reaction) {
            case EFeedReaction.INTERESTED_IN:
              feed.reaction_count.INTERESTED_IN++;
              break;
            case EFeedReaction.DOUBT_THAT:
              feed.reaction_count.DOUBT_THAT++;
              break;
            case EFeedReaction.SUPPORT_BY:
              feed.reaction_count.SUPPORT_BY++;
              break;
          }
        }
        feed.my_reaction = isSameReaction ? null : reaction;
      })
      .catch(() => {
        toast(t('errors.server_error'), {
          type: 'error',
        });
      })
      .finally(() => setIsReactionLoading(false));
  };

  return (
    <div className={classNames('FeedReaction', className)}>
      <div className="FeedReaction__buttons">
        <div
          className={classNames(
            'flex w-full items-center justify-between gap-2 md:gap-4',
            { 'w-auto md-max:w-full': isSingle }
          )}
        >
          <MButton
            color="primary"
            variant={
              feed.my_reaction === EFeedReaction.INTERESTED_IN
                ? 'highlighted'
                : 'outline'
            }
            className={
              feed.my_reaction === EFeedReaction.INTERESTED_IN ? 'active' : ''
            }
            disabled={isReactionLoading}
            onClick={(e) => setReaction(EFeedReaction.INTERESTED_IN)}
          >
            <span className="md:hidden">{feed.reaction_count.INTERESTED_IN} </span>
            <span className="md-max:hidden">{t('feed.interested')}</span>{' '}
            <SmileIcon />
          </MButton>
          <MButton
            color="primary"
            variant={
              feed.my_reaction === EFeedReaction.DOUBT_THAT
                ? 'highlighted'
                : 'outline'
            }
            className={feed.my_reaction === EFeedReaction.DOUBT_THAT ? 'active' : ''}
            disabled={isReactionLoading}
            onClick={(e) => setReaction(EFeedReaction.DOUBT_THAT)}
          >
            <span className="md:hidden">{feed.reaction_count.DOUBT_THAT} </span>
            <span className="md-max:hidden">{t('feed.doubted')}</span>{' '}
            <DislikeIcon />
          </MButton>
          <MButton
            color="primary"
            variant={
              feed.my_reaction === EFeedReaction.SUPPORT_BY
                ? 'highlighted'
                : 'outline'
            }
            className={feed.my_reaction === EFeedReaction.SUPPORT_BY ? 'active' : ''}
            disabled={isReactionLoading}
            onClick={(e) => setReaction(EFeedReaction.SUPPORT_BY)}
          >
            <span className="md:hidden">{feed.reaction_count.SUPPORT_BY} </span>
            <span className="md-max:hidden">{t('feed.supported')}</span> <LikeIcon />
          </MButton>
          {!isSingle && (
            <ShareModal
              url={currentHost + MainRoutes.weber + WeberRoutes.feed + `/${feed.id}`}
              className={classNames('!w-full')}
            />
          )}
        </div>
        {isSingle && (
          <ShareModal
            url={currentHost + MainRoutes.weber + WeberRoutes.feed + `/${feed.id}`}
            className={classNames('!w-auto', { 'md-max:!hidden': isSingle })}
          />
        )}
      </div>
      <div className={classNames('FeedReaction__wrapper', { '!hidden': isSingle })}>
        <div className="flex gap-2 md-max:hidden">
          <div className="FeedReaction__item">
            <SmileIcon /> <span>{feed.reaction_count.INTERESTED_IN}</span>
          </div>
          <div className="FeedReaction__item">
            <DislikeIcon /> <span>{feed.reaction_count.DOUBT_THAT}</span>
          </div>
          <div className="FeedReaction__item">
            <LikeIcon /> <span>{feed.reaction_count.SUPPORT_BY}</span>
          </div>
        </div>
        <MButton
          color="primary"
          variant="transparent"
          disabled
          className="!border-0 !opacity-100 md:!hidden"
        >
          <span>{t('feed.comments')}</span>{' '}
          {feed.comments_count && `(${feed.comments_count})`}
          <CommentIcon />
        </MButton>
        <div className="FeedReaction__item">
          <StarIcon />{' '}
          <span className="text-primary-purple-blue-500">
            {feed.altmetrics?.altmetric_val || 0}
          </span>
        </div>
      </div>
    </div>
  );
};
