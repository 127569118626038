import { useCallback, useState } from 'react';

interface UseFetchResult<T, P extends any[]> {
  data: T | null;
  isLoading: boolean;
  error: Error | null;
  fetchData: (...args: P) => Promise<T | undefined>;
}

function useFetch<F extends (...args: any[]) => Promise<any>>(
  fetcher: F
): UseFetchResult<Awaited<ReturnType<F>>, Parameters<F>> {
  const [data, setData] = useState<Awaited<ReturnType<F>> | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const fetchData = useCallback(
    async (...args: Parameters<F>): Promise<Awaited<ReturnType<F>> | undefined> => {
      setIsLoading(true);
      setError(null);

      try {
        const result = await fetcher(...args);
        setData(result);
        return result;
      } catch (err) {
        setError(err as Error);
        return undefined;
      } finally {
        setIsLoading(false);
      }
    },
    [fetcher]
  );

  return { data, isLoading, error, fetchData };
}

export default useFetch;
