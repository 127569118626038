/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { saveAssesTokenStorage, saveRefreshTokenStorage } from '../../api';
import {
  authMe,
  authorizeViaTelegramApp,
  setPrivateUseReferralCode,
} from '../../api/auth';
import { sendLogin, sendSignUp, setUserID } from '../../hooks/useAnalytics';
import { useNavigate } from '../../hooks/useNavigate';
import { MAIN_PAGE_ROUTE } from '../../horizon-layout/MainLayout/Routes/types/routes';
import { AuthorizationData } from '../../models';
import { useUserContext } from '../../providers';
import getErrorMessages from '../../utils/getErrorMessages';
import { Loader } from '../Loader';

export type TelegramStoryWidgetLink = {
  url: string;
  name: string;
};

export type Telegram = {
  WebApp: {
    initData: string;
    initDataUnsafe: {
      start_param?: string;
      user?: {
        is_premium?: boolean;
      };
    };
    shareToStory: (
      media_url: string,
      params?: { text?: string; widget_link?: TelegramStoryWidgetLink }
    ) => void;
  };
};

export type TelegramAppWindow = typeof window & {
  Telegram?: Telegram;
};

export const TelegramApp = () => {
  const telegramObject = (window as TelegramAppWindow).Telegram?.WebApp;

  if (!telegramObject) return null;

  const initData = telegramObject.initData;
  const refCodeTelegram = telegramObject.initDataUnsafe.start_param;
  const navigate = useNavigate();
  const { saveUser, user } = useUserContext();
  const [isTelegramAppInitializing, setIsTelegramAppInitializing] = useState(true);

  const authorize = async (initData_: string | null) => {
    if (!initData_) {
      return;
    }

    let authorizationData: null | AuthorizationData = null;
    try {
      authorizationData = await authorizeViaTelegramApp(initData_);
    } catch (error: any) {
      toast(getErrorMessages(error?.response?.data), {
        type: 'error',
      });
    }

    const {
      access,
      refresh,
      is_signin: isSignin,
    } = authorizationData as AuthorizationData;

    saveAssesTokenStorage(access);
    saveRefreshTokenStorage(refresh);
    const userData = await authMe();
    saveUser(userData);
    setUserID(userData.guid);
    if (refCodeTelegram && !isSignin) {
      const cleanedRefCodeTelegram = refCodeTelegram.replace('refid', '');
      await setPrivateUseReferralCode(cleanedRefCodeTelegram);
    }
    if (isSignin) {
      sendLogin('TelegramWebApp');
    } else {
      sendSignUp('TelegramWebApp');
    }

    navigate(MAIN_PAGE_ROUTE);
  };

  useEffect(() => {
    if (!user) {
      authorize(initData).finally(() => setIsTelegramAppInitializing(false));
    } else {
      setIsTelegramAppInitializing(false);
    }
  }, [initData]);

  if (!isTelegramAppInitializing) return null;

  return (
    <div
      className={
        'fixed left-0 top-0 z-[1000] flex h-full w-full items-center justify-center bg-white'
      }
    >
      <Loader />
    </div>
  );
};
