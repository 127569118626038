import { FC } from 'react';
import { ShareSocialButton } from './ShareSocialButton';
import { ReactComponent as Icon } from '../../../assets/icons/share/instagram.svg';
import { ButtonsProps } from '../types';
import { shareOnSocial } from '../utils';

const ShareInstagramButton: FC<ButtonsProps> = ({ loading, onSocialClick }) => {
  const onClick = async (url: string) => {
    return await shareOnSocial({
      web: { baseUrl: 'https://instagram.com/create/story', params: { link: url } },
      native: { baseUrl: 'instagram://create/story', params: { link: url } },
      androidPackageName: 'com.instagram.android',
    });
  };

  return (
    <ShareSocialButton
      text="Instagram"
      Icon={Icon}
      loading={loading}
      onClick={onSocialClick('Instagram', onClick)}
    />
  );
};

export default ShareInstagramButton;
