import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CommunitiesRequest } from '../../../api/weber/communities';
import useHistoryParams from '../../../hooks/useHistoryParams';
import { CommunitiesList } from './components/CommunitiesList';
import { CommunityFilter } from './components/CommunityFilter';
import './Communities.scss';

export const Communities: React.FC = () => {
  const { t } = useTranslation(['translation']);
  const [filters, setFilters] = useState<CommunitiesRequest>({});
  const { removeParamFromUrl, addParamToUrl } = useHistoryParams();

  useEffect(() => {
    handleFilter(filters);
  }, [t]);

  const handleFilter = (filter: CommunitiesRequest) => {
    setFilters({ ...filter, page: 1 });
  };

  const handleSearch = (search: CommunitiesRequest) => {
    if (!search.name) {
      delete filters.name;
      setFilters({ ...filters, page: 1 });
      removeParamFromUrl('name');
    } else {
      setFilters({ ...filters, ...search, page: 1 });
      addParamToUrl('name', search.name);
    }
  };

  const handleMode = (mode: boolean) => {
    setFilters({ ...filters, my_groups: mode, page: 1 });
  };

  return (
    <div className="Communities">
      <CommunityFilter
        title={t('communities.title')}
        onFilter={handleFilter}
        onSearch={handleSearch}
        onMode={handleMode}
      />

      <div className="Communities__content">
        {!!Object.keys(filters).length && <CommunitiesList filters={filters} />}
      </div>
    </div>
  );
};
