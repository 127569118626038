import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowRightIcon } from '../../assets/icons/pages/main/arrow-right.svg';
import IconImg from '../../assets/images/pages/main/book.webp';
import { Link } from '../../horizon-components/Link';
import { MButton } from '../../horizon-components/MButton';
import {
  EduRoutes,
  MainRoutes,
} from '../../horizon-layout/MainLayout/Routes/types/routes';
import './style.scss';

interface MainPageTeachProps {
  className?: string;
  hasIcon?: boolean;
}

export const MainPageTeach = ({ className, hasIcon }: MainPageTeachProps) => {
  const { t } = useTranslation(['translation']);

  return (
    <Link to={MainRoutes.edu + EduRoutes['my-courses']}>
      <div className={classNames('MainPageTeach', className)}>
        <div className={classNames('MainPageTeach__content', { hasIcon })}>
          <h4>{t('main.Teach')}</h4>

          <p>{t('main.Get_rewarded_for_creating_new_courses')}</p>
        </div>

        <img src={IconImg} alt="Зарабатывай" className="icon" />
        <MButton
          variant="secondary"
          color="primary"
          className="z-10 !flex lg:!hidden"
        >
          {t('buttons.Create_a_course')} <ArrowRightIcon />
        </MButton>
        <MButton
          variant="highlighted"
          color="primary"
          className="z-10 !hidden lg:!flex"
        >
          {t('buttons.Create_a_course')} <ArrowRightIcon />
        </MButton>
      </div>
    </Link>
  );
};
