import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { publicUsers } from '../../../api/auth';
import { ReactComponent as AddUser } from '../../../assets/icons/add-user.svg';
import { Loader } from '../../../components/Loader';
import { useNavigate } from '../../../hooks/useNavigate';
import { Card } from '../../../horizon-components/Card/Card';
import { MButton } from '../../../horizon-components/MButton/ui/MButton';
import {
  AuthRoutes,
  MainRoutes,
  ProfileRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';
import { UserFriendship } from '../../../models/Friendship';
import { getUserData } from '../../../utils/getUserData';
import { NetworkingCard } from './NetworkingCard';

type Props = {
  goToFindConnections: () => void;
  isLargeWidth: boolean;
};

export const PeopleYouMayNow: React.FC<Props> = ({
  goToFindConnections,
  isLargeWidth,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [connections, setConnections] = useState<UserFriendship[]>([]);
  const { t } = useTranslation(['translation']);
  const navigate = useNavigate();

  const getData = useCallback(() => {
    setIsLoading(true);
    publicUsers('')
      .then((data) => {
        data?.results?.length && setConnections(data?.results?.splice(0, 4));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  const goToProfile = (guid: string) =>
    navigate(MainRoutes.auth + AuthRoutes.profile + ProfileRoutes.user + `/${guid}`);

  return (
    <Card
      extra={
        'shadow-none lg:!shadow-[14px_17px_40px_4px_#7090B014] !p-0 lg:!p-[16px] rounded-[30px]'
      }
    >
      <div className={'mb-[12px] flex flex-wrap items-center justify-between'}>
        <h3 className={'text-[16px] font-[600] text-[#2B3674]'}>
          {t('networking.peopleYouMayKnow')}
        </h3>
        <MButton
          variant={'transparent'}
          color={'primary'}
          onClick={goToFindConnections}
          className="ml-auto"
        >
          {t('networking.viewAll')}
        </MButton>
      </div>
      {isLoading ? (
        <div className={'flex items-center justify-center'}>
          <Loader />
        </div>
      ) : (
        <div className={'flex flex-wrap gap-[12px]'}>
          {connections
            .filter((_, i) => (!isLargeWidth ? i < 1 : true))
            .map((item) => {
              const user = getUserData(item.user);

              return (
                <NetworkingCard
                  key={user.guid}
                  guid={user?.guid || ''}
                  username={user.username}
                  name={`${user.first_name} ${user.last_name}`}
                  img={user.image}
                  wrapperClassName={'!w-full flex-row items-center'}
                  infoWrapperClassName={'w-[calc(100%_-_50px)] overflow-hidden'}
                  actions={
                    <MButton
                      variant={'highlighted'}
                      color={'primary'}
                      size={'xs'}
                      className={'ml-auto !h-[42px] !w-[42px] !p-0'}
                      onClick={() => goToProfile(user.guid || '')}
                    >
                      <AddUser />
                    </MButton>
                  }
                />
              );
            })}
        </div>
      )}
    </Card>
  );
};
