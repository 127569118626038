import { useTranslation } from 'react-i18next';
import { TextArea } from '../../../horizon-components/TextInput/TextArea';
import { ReactComponent as Airplane } from '../../../assets/icons/airplane.svg';
import { useEffect, useState } from 'react';
import { MButton } from '../../../horizon-components/MButton/ui/MButton';
import { StarRating } from '../../../components/StarRating';
import { useComment } from '../../../entities/Review';
import './CourseFeedback.scss';
import { MTooltip } from '../../../horizon-components/MTooltip/MTooltip';

interface CourseFeedbackProps {
  onSend?: () => void;
  courseId: number;
}

export const CourseFeedback = ({ onSend, courseId }: CourseFeedbackProps) => {
  const { t } = useTranslation(['translation']);
  const { isLoading, onComment, isSended } = useComment();
  const [comment, setComment] = useState('');
  const [rating, setRating] = useState(0);

  const handleSend = () => {
    onComment(courseId, rating, comment);
  };

  useEffect(() => {
    isSended && onSend?.();
  }, [isSended]);

  return (
    <div className={'CourseFeedback'}>
      <div className={'CourseFeedback__head'}>
        <h3>{t('course.yourReview')}</h3>
        <div id="stars">
          <StarRating
            size="md"
            active={!isLoading}
            rating={rating}
            onClick={setRating}
          />
          {!rating && (
            <MTooltip
              anchorSelect={'#stars'}
              text={t('buttons.Rate_the_course_to_leave_a_review')}
            />
          )}
        </div>
      </div>
      <TextArea
        resize
        rows={1}
        cols={10}
        id="comment"
        value={comment}
        placeholder={`${t('buttons.leave_your_review')} (${t('optional')})`}
        onChange={(event) => setComment(event.target.value)}
        disabled={isLoading}
      />

      <div className={'CourseFeedback__btn'}>
        <div id="feedback">
          <MButton
            variant="highlighted"
            color="primary"
            size="md"
            onClick={handleSend}
            disabled={isLoading || !rating}
            className="!text-balance"
          >
            {!rating
              ? t('buttons.Rate_the_course_to_leave_a_review')
              : t('btnsText.send')}{' '}
            <Airplane />
          </MButton>
        </div>
      </div>
    </div>
  );
};
