import OwlActive from '../../../../assets/icons/wallet/owl.active.svg';
import OwlDefault from '../../../../assets/icons/wallet/owl.default.svg';
import ErActive from '../../../../assets/icons/wallet/er.active.svg';
import ErDefault from '../../../../assets/icons/wallet/er.default.svg';

export const MyWalletMock = [
  {
    title: 'ER',
    logoActive: ErActive,
    logoDefault: ErDefault,
    text: 'wallet.content.er',
  },
  {
    title: 'OWL',
    logoActive: OwlActive,
    logoDefault: OwlDefault,
    text: 'wallet.content.owl',
  },
];
