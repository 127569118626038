import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Card } from '../../../../horizon-components/Card/Card';

export enum CommunityNavigationTab {
  Posts = 'Posts',
  Member = 'Member',
  About = 'About',
  Tasks = 'Tasks',
}

type NetworkingNavigationTabItem = {
  tab: CommunityNavigationTab;
  title: string;
};

type Props = {
  tab: CommunityNavigationTab;
  setTab: (v: CommunityNavigationTab) => void;
};

export const CommunityNavigation: React.FC<Props> = ({ tab, setTab }) => {
  const { t } = useTranslation();
  const tabs: NetworkingNavigationTabItem[] = [
    {
      tab: CommunityNavigationTab.Posts,
      title: t('communities.posts'),
    },
    {
      tab: CommunityNavigationTab.Member,
      title: t('communities.member'),
    },
    {
      tab: CommunityNavigationTab.About,
      title: t('communities.about'),
    },
    // {
    //   tab: CommunityNavigationTab.Tasks,
    //   title: t('communities.tasks'),
    // },
  ];

  return (
    <Card
      extra={
        '!shadow-[14px_17px_40px_4px_#7090B014] p-[16px] rounded-[30px] gap-[4px]'
      }
    >
      {tabs.map((item) => (
        <div
          key={item.tab}
          onClick={() => setTab(item.tab)}
          className={classNames(
            `flex cursor-pointer items-center justify-between rounded-full p-[8px] pl-[12px]`,
            {
              'bg-[#F4F7FE]': tab === item.tab,
            }
          )}
        >
          <p
            className={classNames(`text-[16px] font-[600] text-[#485585]`, {
              'font-bold text-[#1B2559]': tab === item.tab,
            })}
          >
            {item.title}
          </p>
        </div>
      ))}
    </Card>
  );
};
