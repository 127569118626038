import { Course } from '../../../models/Course';
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { getCourse } from '../../../api/course';
import { Loader } from '../../../components/Loader';

type CourseContextValue = {
  course: Course | null;
  updateCourse: (value: Course) => void;
  fetchCourse: () => Promise<void>;
  isCourseLoading: boolean;
};

const CourseContext = createContext<CourseContextValue>({} as CourseContextValue);

const CourseProvider: React.FC<PropsWithChildren & { courseId?: string }> = ({
  children,
  courseId,
}) => {
  const [course, updateCourse] = useState<Course | null>(null);
  const [isCourseLoading, setIsCourseLoading] = useState(true);

  const fetchCourse = useCallback(() => {
    return getCourse(courseId || '')
      .then((data) => {
        updateCourse(data);
      })
      .finally(() => {
        setIsCourseLoading(false);
      });
  }, [courseId]);

  useEffect(() => {
    if (courseId && courseId !== '0') {
      setIsCourseLoading(true);
      fetchCourse();
    } else {
      setIsCourseLoading(false);
    }
  }, [courseId, fetchCourse]);

  return (
    <CourseContext.Provider
      value={{
        course,
        updateCourse,
        isCourseLoading,
        fetchCourse,
      }}
    >
      {isCourseLoading ? <Loader /> : children}
    </CourseContext.Provider>
  );
};

const useCourseContext = () => useContext(CourseContext);

export { CourseProvider, CourseContext, useCourseContext };
