import React from 'react';
import { ButtonsProps } from './types';
import { useTranslation } from 'react-i18next';
import ShareTelegramChatButton from './buttons/ShareTelegramChatButton';
import ShareTelegramStoriesButton from './buttons/ShareTelegramStoriesButton';

const ShareTelegramButtons: React.FC<ButtonsProps> = (props) => {
  const { t } = useTranslation();

  return (
    <div className="ShareButtons__tg-wrapper">
      <div className="ShareButtons__tg-text">{t('main.share.telegramShare')}</div>
      <ShareTelegramChatButton {...props} />
      <ShareTelegramStoriesButton {...props} />
    </div>
  );
};

export default ShareTelegramButtons;
