import { useCallback, useState } from 'react';
import {
  CommunitiesRequest,
  ICommunityResponse,
  getCommunities,
} from '../../../../../api/weber/communities';
import { TCommunity } from '../../../../../models/Community';
import { DEFAULT_LIMIT } from '../../../../../shared/lib/const/limit';
import { useTranslation } from 'react-i18next';

export const useCommunities = () => {
  const { t } = useTranslation(['translation']);
  const [data, setData] = useState<ICommunityResponse>();
  const [dataScroll, setDataScroll] = useState<TCommunity[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentPage, setPage] = useState(1);
  const total = data ? Math.ceil(data?.count / DEFAULT_LIMIT) : 0;

  const fetchData = useCallback(
    ({ subject, name, ordering, order_by, my_groups, page }: CommunitiesRequest) => {
      setIsLoading(true);
      getCommunities({ page, subject, name, ordering, order_by, my_groups }).then(
        (res) => {
          page && setPage(page);
          setData(res);
          res?.results?.length &&
            setDataScroll(
              page !== 1 ? [...dataScroll, ...res.results] : res.results
            );
          res !== undefined && setIsLoading(false);
        }
      );
    },
    [dataScroll, t]
  );

  return {
    dataScroll,
    data,
    currentPage,
    total,
    isLoading,
    setPage,
    fetchData,
    setDataScroll,
  };
};
