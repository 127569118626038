import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Subjects, getSubjectsList } from '../../../../api/dictionaries';
import { FeedRequest, OrderBy } from '../../../../api/weber/feed';
import { ReactComponent as Search } from '../../../../assets/icons/search.16px.svg';
import { ActionBar } from '../../../../features/ActionBar';
import { Card } from '../../../../horizon-components/Card/Card';
import { MInput } from '../../../../horizon-components/MInput/MInput';
import { useUserContext } from '../../../../providers';
import { Filter, FilterItems, FilterOption } from '../../../../widgets/Filter';
import { useLanguages } from '../../../../entities/Languages';

type Props = {
  onFilter?: (mode: any) => void;
  filters: Omit<FeedRequest, 'page'>;
  showBtn: boolean;
};

const defaultTittle = {};
export const CommunityPostFilter: React.FC<Props> = ({
  filters,
  onFilter,
  showBtn = false,
}) => {
  const { t } = useTranslation();
  const { user } = useUserContext();
  let { communityId } = useParams();
  const [title, setTitle] = useState('');
  const [subjects, setSubjects] = useState<Subjects[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { data: languages } = useLanguages();
  const defaultFilter = useMemo(
    () => ({ order_by: OrderBy.popularity.toLowerCase() }),
    []
  );

  const handleSearch = (value: string) => {
    onFilter?.({ ...filters, title: value });
    setTitle(value);
  };

  useEffect(() => {
    getSubjectsList().then((data) => {
      data?.length &&
        setSubjects([
          ...data?.map((item) => ({
            id: item.id,
            title: item.title,
            slug: item.slug,
          })),
        ]);
    });
  }, [t]);

  const orderByOptions: FilterItems[] = useMemo(
    () =>
      Object.entries(OrderBy).map((key, index) => ({
        id: index + 1,
        title: t('sort.' + key[1].toLowerCase()),
        slug: key[0].toLowerCase(),
      })),
    [t]
  );

  const languagesOptions: FilterItems[] = useMemo(
    () =>
      languages?.results.length
        ? languages?.results.map((language) => ({
            id: language.id,
            title: language.title,
            slug: language.language_iso_code,
          }))
        : [],
    [t, languages]
  );

  const filterOption: FilterOption[] = useMemo(
    () => [
      {
        id: 1,
        title: t('buttons.sort_by'),
        items: orderByOptions,
        variant: 'outline',
        type: 'single',
        component: 'badge',
        key: ['order_by'],
      },
      {
        id: 2,
        title: t('buttons.subject'),
        items: subjects,
        variant: 'primary',
        type: 'array',
        component: 'badge',
        key: ['subject'],
      },
      {
        id: 3,
        title: t('buttons.language'),
        items: [
          { id: 0, title: t('buttons.all'), slug: 'all' },
          ...languagesOptions,
        ],
        variant: 'outline',
        type: 'single',
        component: 'badge',
        key: ['language'],
      },
    ],
    [languagesOptions, orderByOptions, subjects, t]
  );

  const close = () => setIsOpen(!isOpen);

  return (
    <Card
      extra={
        'w-full rounded-[30px] !shadow-none lg:!shadow-[14px_17px_40px_4px_#7090B014] p-0 lg:!p-[24px] !flex-row justify-between gap-[12px]'
      }
    >
      <MInput
        wrapperClassName={
          'w-full lg:w-[344px] rounded-full !shadow-[14px_17px_40px_4px_#7090B014] lg:!shadow-none'
        }
        placeholder={t('nft.search')}
        value={title}
        onChange={handleSearch}
        size={'sm'}
        inputClassName={
          'w-full !bg-[#FFFFFF] lg:!bg-[#F4F7FE] !border-[#FFFFFF] lg:!border-[#F4F7FE] !placeholder-[#8F9BBA]'
        }
        leftIcon={<Search className={'mt-[-10px]'} />}
      />
      <div className={'flex justify-end gap-[12px]'}>
        {!!user && showBtn && (
          <ActionBar communityFeedId={communityId} variant="outline" />
        )}
        <Filter
          onFilter={onFilter}
          defaultFilter={defaultFilter}
          defaultTitel={defaultTittle}
          isOpen={isOpen}
          onClose={close}
          options={filterOption}
          showInMobile
        />
      </div>
    </Card>
  );
};
