import React, { forwardRef } from 'react';
import { MInput } from '../../../../horizon-components/MInput/MInput';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Close } from '../../../../assets/icons/close.18px.svg';

export type TGroupLinkItem = {
  name: string;
  link: string;
};
export type GroupLinkItemProps = {
  item: TGroupLinkItem;
  onChange: (item: TGroupLinkItem) => void;
  onClose: () => void;
  hideLabel?: boolean;
};
export type GroupLinkItemRef = {};

export const GroupLinkItem = forwardRef<GroupLinkItemRef, GroupLinkItemProps>(
  ({ item, onChange, onClose, hideLabel }, ref) => {
    const { t } = useTranslation();

    const handleChangeLink = (value: string) => {
      item.link = value;
      onChange(item);
    };

    const handleChangeName = (value: string) => {
      item.name = value;
      onChange(item);
    };

    return (
      <div className={'mb-[16px] flex justify-between gap-[8px] lg:gap-[20px]'}>
        <MInput
          placeholder={'https://'}
          label={hideLabel ? undefined : t('communities.Links')}
          value={item.link}
          wrapperClassName={'w-[50%]'}
          inputClassName={'w-full'}
          onChange={handleChangeLink}
          autoFocus
          size={'sm'}
          rightIcon={
            <div className={'cursor-pointer'} onClick={onClose}>
              <Close />
            </div>
          }
          rightIconWrapperClassName={'top-[7px]'}
        />
        <MInput
          placeholder={'example.com'}
          label={hideLabel ? undefined : t('communities.LinkName')}
          value={item.name}
          wrapperClassName={'w-[50%]'}
          inputClassName={'w-full'}
          onChange={handleChangeName}
          autoFocus
          size={'sm'}
        />
      </div>
    );
  }
);
