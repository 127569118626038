import React, { useState } from 'react';
import { Tab, Tabs } from '../../../../horizon-components/Tabs/Tabs';
import { Course } from '../../../../models/Course';
import { CourseReview } from './CourseReview';
import { useTranslation } from 'react-i18next';
import CourseUnitsView from './CourseUnitsView';
import useHistoryParams from '../../../../hooks/useHistoryParams';

export enum TabsEnum {
  CONTENT = 'content',
  DESCRIPTION = 'description',
  REQUIREMENTS = 'requirements',
  LEARNING_OUTCOMES = 'learning_outcomes',
  MESSAGE = 'message',
  FEEDBACK = 'feedback',
}

type Props = {
  course: Course;
  isEnrolled: boolean;
};

const CourseInfo: React.FC<Props> = ({ course, isEnrolled }) => {
  const { t } = useTranslation(['translation']);
  const { getParamFromUrl } = useHistoryParams();
  const [currentTab, setCurrentTab] = useState<TabsEnum>(
    window.innerWidth < 992 ? TabsEnum.CONTENT : TabsEnum.DESCRIPTION
  );

  const tabsConfig: Tab[] = [
    ...(window.innerWidth < 992
      ? [
          {
            id: TabsEnum.CONTENT,
            title: t('course.Course Content'),
          },
        ]
      : []),
    {
      id: TabsEnum.DESCRIPTION,
      title: t('course.Description'),
    },
    {
      id: TabsEnum.REQUIREMENTS,
      title: t('course.requirements'),
    },
    {
      id: TabsEnum.LEARNING_OUTCOMES,
      title: t('course.learningOutcomes'),
    },
    {
      id: TabsEnum.FEEDBACK,
      title: t('course.studentReviews'),
    },
  ];

  const toggleTab = (tab: Tab) => {
    setCurrentTab(tab.id as TabsEnum);
  };

  const config: Record<string, string> = {
    [TabsEnum.DESCRIPTION]: course.description,
    [TabsEnum.REQUIREMENTS]: course.requirements,
    [TabsEnum.LEARNING_OUTCOMES]: course.learning_outcomes,
    [TabsEnum.FEEDBACK]: '',
  };
  const tabData = config[currentTab];

  const tabFromUrl = getParamFromUrl('tab');
  if (tabFromUrl && tabFromUrl in TabsEnum) {
    setCurrentTab(tabFromUrl as TabsEnum);
  }

  return (
    <>
      <Tabs
        tabs={tabsConfig}
        onTabChanged={toggleTab}
        tabsExtra={'md:!gap-[16px] !gap-[4px] !justify-start !m-0 !overflow-auto'}
        itemExtra={'justify-center text-nowrap'}
        active={tabsConfig.find((tab) => tab.id === currentTab)}
      />
      {currentTab === TabsEnum.FEEDBACK ? (
        <CourseReview isEnrolled={isEnrolled} />
      ) : currentTab === TabsEnum.CONTENT ? (
        <CourseUnitsView course={course} isInTab />
      ) : (
        <div
          className={'w-full'}
          dangerouslySetInnerHTML={{
            __html: tabData,
          }}
        />
      )}
    </>
  );
};

export default CourseInfo;
