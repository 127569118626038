import './QuizzesQuestion.scss';

interface QuizzesQuestionProps {
  category?: string;
  question?: string;
  image?: string;
}

export const QuizzesQuestion = ({
  category,
  question,
  image,
}: QuizzesQuestionProps) => {
  return (
    <div className="QuizzesQuestion">
      <div className="QuizzesQuestion__wrapper">
        {!!category && (
          <div className="QuizzesQuestion__number">
            <span>{category}</span>
            {!!image && <img src={image} alt={''} />}
          </div>
        )}
        {!!question && <div className="QuizzesQuestion__question">{question}</div>}
      </div>

      <div className="QuizzesQuestion__image">
        {!!image && <img src={image} alt={''} />}
      </div>
    </div>
  );
};
