import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ProfileIcon } from '../../../../assets/icons/profile/profile.svg';
import { Bread, Breadcrumbs } from '../../../../components/Breadcrumbs';
import { useNavigate } from '../../../../hooks/useNavigate';
import { MButton } from '../../../../horizon-components/MButton/ui/MButton';
import {
  AuthRoutes,
  MainRoutes,
  ProfileRoutes,
} from '../../../../horizon-layout/MainLayout/Routes/types/routes';
import { useUserContext } from '../../../../providers';
import { useTitleBackContext } from '../../../../providers/TitleBackProvider/TitleBackProvider';

export const InfoHeader: React.FC = () => {
  const { t } = useTranslation(['translation']);
  const navigate = useNavigate();
  const { user } = useUserContext();
  const { useTitleBack } = useTitleBackContext();

  const button = useMemo(() => {
    return (
      <MButton
        color="white"
        variant="highlighted"
        className="!flex shadow-sm shadow-shadow-600 sm-max:h-[42px] sm-max:w-[42px] sm-max:!p-[10px]"
        onClick={() =>
          navigate(
            MainRoutes.auth +
              AuthRoutes.profile +
              ProfileRoutes.user +
              `/${user?.guid}`
          )
        }
      >
        <span className="hidden md:block">{t('buttons.Back_to_profile')}</span>{' '}
        <ProfileIcon />
      </MButton>
    );
  }, [t, user?.guid]);

  const breads: Bread[] = useMemo(
    () => [
      {
        title: t('menu.Profile'),
        url:
          MainRoutes.auth +
          AuthRoutes.profile +
          ProfileRoutes.user +
          `/${user?.guid}`,
      },
      {
        title: t('profile.settings'),
        url: MainRoutes.auth + AuthRoutes.profile + ProfileRoutes.edit,
      },
    ],
    [t, user?.guid]
  );

  useTitleBack(
    t('buttons.Edit_Profile'),

    MainRoutes.auth + AuthRoutes.profile + ProfileRoutes.user + `/${user?.guid}`,
    null,
    button
  );

  return (
    <div className="flex flex-row items-center justify-end gap-3 md:justify-between">
      <Breadcrumbs breads={breads} className="hidden md:block" />
      <div className="md-max:hidden">{button}</div>
    </div>
  );
};
