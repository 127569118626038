import React from 'react';
import { CommunityNavigationTab } from './CommunityNavigation';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

type Props = {
  tab: CommunityNavigationTab;
  setTab: (v: CommunityNavigationTab) => void;
};

const TABS = [
  CommunityNavigationTab.Posts,
  CommunityNavigationTab.Member,
  CommunityNavigationTab.About,
  // CommunityNavigationTab.Tasks,
];

export const CommunityTab: React.FC<Props> = ({ tab, setTab }) => {
  const { t } = useTranslation();

  return (
    <div className={'mb-[10px] flex lg:hidden'}>
      {TABS.map((item) => (
        <div
          key={item}
          className={'cursor-pointer pt-[2px]'}
          onClick={() => setTab(item)}
        >
          <p
            className={classNames('px-[8px] text-[14px] font-[600] text-[#B0BBD5]', {
              '!text-[#2B3674]': tab === item,
            })}
          >
            {t(`communities.${item.toLowerCase()}`)}
          </p>
          {tab === item && (
            <div className={'mt-[5px] h-[4px] w-full rounded-full bg-[#4318FF]'} />
          )}
        </div>
      ))}
    </div>
  );
};
