import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Share } from '../../../assets/icons/share-carousel.svg';
import { useNavigate } from '../../../hooks/useNavigate';
import { Auction } from '../../../models/Auction';
import { useBidContext } from '../../../providers/BidProvider/BidProvider';
import { CoinIcon } from '../../../components/CoinIcon';

type Props = {
  auction: Auction;
};

export const ActualCarouselItem: React.FC<Props> = ({ auction }) => {
  const [time, setTime] = useState<Moment>(moment());
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getMyBidForAuction } = useBidContext();

  useEffect(() => {
    const id = setInterval(() => {
      setTime(moment(moment(auction?.end_datetime).diff(moment())));
    }, 1000);
    return () => clearInterval(id);
  }, [auction?.end_datetime]);

  const goToAuction = () => {
    navigate(`/bid/view/${auction.id}`);
  };

  return (
    <div className={'w-full max-w-[365px] rounded-[24px] bg-purple-500 p-[10px]'}>
      <div className={'mb-[10px] flex items-center justify-between'}>
        <p className={'text-[16px] font-[600] text-white'}>{auction.title}</p>
        <div className={'cursor-pointer'} onClick={goToAuction}>
          <Share />
        </div>
      </div>
      <div
        className={'flex justify-between rounded-[16px] bg-[#161C5140] py-[10px]'}
      >
        <div className={'w-[30%]'}>
          <p className={'text-center text-[16px] font-[500] text-white'}>
            {time.format('HH')}
          </p>
          <p className={'text-center text-[10px] font-[500] text-white'}>
            {t('bid.hours')}
          </p>
        </div>
        <div className={'w-[5%]'}>
          <p className={'text-center text-[16px] font-[500] text-white'}>:</p>
        </div>
        <div className={'w-[30%]'}>
          <p className={'text-center text-[16px] font-[500] text-white'}>
            {time.format('mm')}
          </p>
          <p className={'text-center text-[10px] font-[500] text-white'}>
            {t('bid.minutes')}
          </p>
        </div>
        <div className={'w-[5%]'}>
          <p className={'text-center text-[16px] font-[500] text-white'}>:</p>
        </div>
        <div className={'w-[30%]'}>
          <p className={'text-center text-[16px] font-[500] text-white'}>
            {time.format('ss')}
          </p>
          <p className={'text-center text-[10px] font-[500] text-white'}>
            {t('bid.seconds')}
          </p>
        </div>
      </div>
      <div className={'mt-[8px] flex justify-between'}>
        <div className={'flex w-[50%] flex-col items-center'}>
          <p className={'text-[10px] font-[500] text-white'}>
            {t('bid.highestBid')}
          </p>
          <div className={'flex items-center gap-[6px]'}>
            <CoinIcon className={'h-[18px] w-[18px] fill-white'} />
            <p className={'text-[18px] font-[500] text-white'}>
              {auction.highest_bid || 0}
            </p>
          </div>
        </div>
        <div className={'flex w-[50%] flex-col items-center'}>
          <p className={'text-[10px] font-[500] text-white'}>{t('bid.yourBid')}</p>
          <div className={'flex items-center gap-[6px]'}>
            <CoinIcon className={'h-[18px] w-[18px] fill-white'} />
            <p className={'text-[18px] font-[500] text-white'}>
              {getMyBidForAuction(auction.id) || '0'}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
