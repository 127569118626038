import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MButton } from '../../../horizon-components/MButton';
import { MInput } from '../../../horizon-components/MInput/MInput';
import './CreateFolderModal.scss';

interface CreateFolderModalProps {
  className?: string;
  isEdit?: boolean;
  onCreate?: (title: string) => void;
  onSave?: (title: string) => void;
  onDelete?: () => void;
}

export const CreateFolderModal = ({
  className,
  isEdit = false,
  onCreate,
  onSave,
  onDelete,
}: CreateFolderModalProps) => {
  const { t } = useTranslation(['translation']);
  const [folder, setFolder] = useState('');

  return (
    <div className={classNames('CreateFolderModal', className)}>
      <MInput
        label={t('buttons.enter_a_name')}
        placeholder={t('buttons.enter_a_name')}
        value={folder}
        onChange={setFolder}
        inputClassName={'w-full'}
        wrapperClassName={'w-full'}
      />
      <div className="CreateFolderModal__btns">
        {isEdit ? (
          <>
            <MButton
              variant="outline"
              color="primary"
              className="w-full"
              onClick={onDelete}
            >
              {t('buttons.delete_folder')}
            </MButton>
            <MButton
              variant="highlighted"
              color="primary"
              disabled={!folder}
              className="sm-max:w-full"
              onClick={() => onSave?.(folder)}
            >
              {t('buttons.Save')}
            </MButton>
          </>
        ) : (
          <MButton
            variant="highlighted"
            color="primary"
            disabled={!folder}
            className="self-end"
            onClick={() => onCreate?.(folder)}
          >
            {t('buttons.create')}
          </MButton>
        )}
      </div>
    </div>
  );
};
