import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { Card } from '../../../horizon-components/Card/Card';
import { MButton } from '../../../horizon-components/MButton/ui/MButton';
import { NetworkingCard } from './NetworkingCard';
import { Friendship } from '../../../models/Friendship';
import {
  acceptFriendship,
  getFriendships,
  rejectFriendship,
} from '../../../api/weber/friendship';
import { getUserData } from '../../../utils/getUserData';
import { NotFound } from '../../../components/NotFound/NotFound';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { NetworkingNavigationTab } from './NetworkingNavigation';
import { Loader } from '../../../components/Loader';

export type Props = {
  isOutcomingRequests?: boolean;
  isLargeWidth: boolean;
  updateMyConnections?: () => void;
  onDataCount?: (count: number, isOutcomingRequests: boolean) => void;
  tab: NetworkingNavigationTab;
};

const PAGE_SIZE = 12;

export const IncomingRequests: React.FC<Props> = ({
  tab,
  isOutcomingRequests,
  isLargeWidth,
  updateMyConnections,
  onDataCount,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [connections, setConnections] = useState<Friendship[]>([]);
  const [total, setTotal] = useState(0);
  const [, setUploadedPage] = useState(0);
  const { t } = useTranslation(['translation']);

  const getData = useCallback(
    (page: number) => {
      setIsLoading(true);
      getFriendships(false, undefined, !!isOutcomingRequests, undefined, page)
        .then((data) => {
          setConnections((_data) => [...(page === 0 ? [] : _data), ...data.results]);
          setTotal(data.count);
          setUploadedPage((v) => ++v);
          onDataCount?.(data.count, !!isOutcomingRequests);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [isOutcomingRequests, onDataCount]
  );

  const scrollBottom = useCallback(
    (page: number) => {
      if (page * PAGE_SIZE < total) {
        getData(page);
      }
    },
    [total, getData]
  );

  useEffect(() => {
    getData(0);
  }, [tab]);

  const handleAcceptClick = useCallback(
    (id: number) => {
      return acceptFriendship(id).then(() => {
        getData(0);
        updateMyConnections?.();
      });
    },
    [getData, updateMyConnections]
  );

  const handleRejectClick = useCallback(
    (id: number) => {
      return rejectFriendship(id).then(() => {
        getData(0);
      });
    },
    [getData]
  );

  useLayoutEffect(() => {
    if (window) {
      window.onscroll = () => {
        if (
          !isLoading &&
          window.innerHeight + window.scrollY >= document.body.offsetHeight
        ) {
          setUploadedPage((v) => {
            scrollBottom(v);
            return v;
          });
        }
      };
    }

    return () => {
      if (window) {
        window.onscroll = null;
      }
    };
  }, [tab, scrollBottom, isLoading]);

  if (
    (tab === NetworkingNavigationTab.Incoming && isOutcomingRequests) ||
    (tab === NetworkingNavigationTab.Outcoming && !isOutcomingRequests)
  ) {
    return null;
  }

  return (
    <Card
      extra={
        'shadow-none lg:!shadow-[14px_17px_40px_4px_#7090B014] !p-0 lg:!p-[16px] rounded-[30px] mb-[10px] lg:mb-[20px]'
      }
    >
      <div className={'mb-[12px] hidden items-center justify-between lg:flex'}>
        <h3 className={'text-[24px] font-[600] text-[#2B3674]'}>
          {isLargeWidth
            ? !isOutcomingRequests
              ? t('networking.incomingRequests')
              : t('networking.outcomingRequests')
            : t('networking.requests')}{' '}
          <span className={'text-[#8F9BBA]'}>{total}</span>
        </h3>
      </div>
      <div className={'flex flex-wrap gap-[12px]'}>
        {isLoading ? (
          <Loader />
        ) : !!connections?.length ? (
          connections.map((item) => {
            const anotherUser = item?.[item.another_user_key];
            const user = getUserData(anotherUser);

            return (
              <NetworkingCard
                key={item.id}
                guid={user?.guid || ''}
                username={user.username}
                name={`${user.first_name} ${user.last_name}`}
                wrapperClassName={classNames(
                  '!w-full flex-row items-center gap-2 lg:gap-0',
                  {
                    '!flex-col !items-start': !isLargeWidth && !isOutcomingRequests,
                  }
                )}
                avatarInfoSize={'lg'}
                img={user.image}
                actions={
                  <div
                    className={classNames(
                      'ml-auto flex items-center justify-end gap-[8px]',
                      {
                        'mt-[8px] w-full': !isLargeWidth && !isOutcomingRequests,
                      }
                    )}
                  >
                    {!isOutcomingRequests && (
                      <MButton
                        variant={'highlighted'}
                        color={'primary'}
                        onClick={() => handleAcceptClick(item.id)}
                      >
                        {t('buttons.Connect')}
                      </MButton>
                    )}
                    <MButton
                      variant={'secondary'}
                      color={'primary'}
                      onClick={() => handleRejectClick(item.id)}
                    >
                      {t('buttons.Reject')}
                    </MButton>
                  </div>
                }
              />
            );
          })
        ) : (
          <NotFound isRequest />
        )}
      </div>
    </Card>
  );
};
