import React from 'react';

type Props = {
  children: JSX.Element | string;
  className?: string;
  textClassName?: string;
  onClick?: () => void;
};

export const Chip: React.FC<Props> = ({
  children,
  className = '',
  textClassName = '',
  onClick = () => {},
}) => {
  return (
    <div
      onClick={onClick}
      className={`mt-2 flex h-[22px] rounded-full bg-[#4E576E] px-2 align-middle ${className} active:bg-[#282c38]`}
    >
      <span className={`text-[12px] font-semibold leading-[22px] ${textClassName}`}>
        {children}
      </span>
    </div>
  );
};
