import { UserProfile } from './User';

export enum JoinStatus {
  NOT_JOINED = 'NOT_JOINED',
  WAITING_FOR_ACCEPT = 'WAITING_FOR_ACCEPT',
  JOINED = 'JOINED',
  OWNED = 'OWNED',
}

export enum GroupType {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
}

export type TCommunity = {
  id: number;
  author_guid: string;
  name: string;
  image: string;
  cover_image: string;
  predefined_image: {
    id: number;
    image: string;
  };
  custom_image: string;
  created_at: string;
  subject: string;
  members_count: number;
  posts_count: number;
  join_status: JoinStatus;
  type: GroupType;
  goal: string;
  chat: number;
  description: string;
  owner: UserProfile;
  group_links: {
    name: string;
    link: string;
  }[];
};
