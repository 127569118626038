import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MButton } from '../../horizon-components/MButton';
import { Modal } from '../../horizon-components/Modal';
import { useUserContext } from '../../providers';
import { QuizDayCard } from '../QuizDayCard';
import { QuizStartModal } from '../QuizStartModal';
import './style.scss';
import { Loader } from '../../components/Loader';
import { useWizContext } from '../../providers/WizProvider/WizProvider';

interface QuizStartProps {
  className?: string;
  onStart?: () => void;
}

export const QuizStart = ({ className, onStart }: QuizStartProps) => {
  const { user } = useUserContext();
  const { t } = useTranslation(['translation']);
  const [isStartOpen, setIsStartOpen] = useState(false);
  const { currentDay, currentMultiplier, dailyInfo } = useWizContext();

  const onStartButtonPressed = () => {
    const today = new Date().toLocaleDateString();
    const lastVisit = localStorage.getItem('lastWizVisit');
    if (lastVisit === today) {
      hndlStart();
    } else {
      hndlOpenStartModal();
    }
    localStorage.setItem('lastWizVisit', today);
  };

  const hndlOpenStartModal = () => {
    setIsStartOpen(!isStartOpen);
  };

  const hndlStart = () => {
    onStart?.();
    setIsStartOpen(!isStartOpen);
  };

  if (!dailyInfo) {
    return <Loader />;
  }

  return (
    <div className={classNames('QuizStart', className)}>
      <div className="QuizStart__head">
        <h1>{t('wiz.Quiz')}</h1>
        <p
          dangerouslySetInnerHTML={{
            __html: t('wiz.quiz_start_description'),
          }}
        />
      </div>
      <div className="QuizStart__days">
        {dailyInfo?.map((day) => (
          <QuizDayCard
            title={`${t('reward.day')} ${day.day}`}
            earn={`${day.multiplier}X`}
            status={day.status}
            key={day.day}
          />
        ))}
      </div>
      <MButton
        variant="highlighted"
        color="primary"
        className="sticky bottom-[65px] h-[60px] sm-max:w-full md:static md:!px-8 md:!py-6 md:!text-lg"
        onClick={onStartButtonPressed}
        size={'lg'}
      >
        {t('buttons.Start_WIZ')}
      </MButton>
      <Modal isOpen={isStartOpen} onClose={hndlOpenStartModal} showClose={false}>
        <QuizStartModal
          currentDayNumber={currentDay}
          multiplier={currentMultiplier}
          onStart={hndlStart}
        />
      </Modal>
    </div>
  );
};
