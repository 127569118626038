import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { saveAssesTokenStorage, saveRefreshTokenStorage } from '../../../api';
import {
  authMe,
  authorizeViaApple,
  setPrivateUseReferralCode,
} from '../../../api/auth';
import { getRedirectPath } from '../../../api/sessions';
import { sendLogin, sendSignUp, setUserID } from '../../../hooks/useAnalytics';
import useFetch from '../../../hooks/useFetch';
import { useNavigate } from '../../../hooks/useNavigate';
import { Card } from '../../../horizon-components/Card/Card';
import { MAIN_PAGE_ROUTE } from '../../../horizon-layout/MainLayout/Routes/types/routes';
import { useUserContext } from '../../../providers';

const getFragmentParameter = (key: string) => {
  const hash = window.location.hash.substring(1);
  const params = new URLSearchParams(hash);
  return params.get(key);
};

const AppleCallback: React.FC = () => {
  const { saveUser } = useUserContext();
  const navigate = useNavigate();
  const idToken = getFragmentParameter('id_token');
  const { t } = useTranslation(['translation']);
  const redirectPath = getRedirectPath();

  const refCode = localStorage.getItem('refCode');
  const { fetchData: fetchRefCode } = useFetch((code: string) =>
    setPrivateUseReferralCode(code)
  );

  useEffect(() => {
    (async () => {
      if (!idToken) {
        // navigate(MainRoutes.auth + AuthRoutes.signin);
        return;
      }
      const {
        access,
        refresh,
        is_signin: isSignin,
      } = await authorizeViaApple(idToken);
      saveAssesTokenStorage(access);
      saveRefreshTokenStorage(refresh);
      const userData = await authMe();
      saveUser(userData);
      setUserID(userData.guid);
      if (refCode && !isSignin) {
        await fetchRefCode(refCode);
      }
      if (isSignin) {
        sendLogin('Apple');
      } else {
        sendSignUp('Apple');
      }

      navigate(redirectPath || MAIN_PAGE_ROUTE);
    })();
  }, []);

  return (
    <Card extra="max-w-[405px] md:max-w-[510px] md:w-[510px] h-max mx-2.5 md:mx-auto mt-[50px] mb-auto py-2.5 px-4 md:!p-[50px] pt-8 md:pt-[50px]">
      <span>{t('profile.wait')}</span>
    </Card>
  );
};

export default AppleCallback;
