import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LeaderboardUser, getLeaderboardData } from '../../../api/wiz/wiz';
import { ReactComponent as UpBoardIcon } from '../../../assets/icons/upboard.svg';
import WizRainbow from '../../../assets/images/wiz-rainbow.png';
import { getUserData } from '../../../utils/getUserData';
import './Scoreboard.scss';

export const Scoreboard = () => {
  const [leaderboardUsers, setLeaderboardUsers] = useState<LeaderboardUser[]>([]);
  const { t } = useTranslation(['translation']);
  getUserData();

  useEffect(() => {
    (async () => {
      const users = await getLeaderboardData();
      setLeaderboardUsers(users);
    })();
  }, []);

  return (
    <div className="Scoreboard">
      <div className="Scoreboard__logo">
        <img
          className={'h-[63px] w-[133px] object-cover'}
          src={WizRainbow}
          alt={''}
        />
        <h1>{t('wiz.Leaderboard')}</h1>
      </div>

      <div
        className={`flex items-end ${leaderboardUsers.length === 0 ? 'mt-16' : ''}`}
      >
        <div className="flex flex-col items-center" style={{ marginRight: '-20px' }}>
          <div
            style={{
              marginBottom: '-40px',
              zIndex: '10',
            }}
          >
            {leaderboardUsers[1] && (
              <>
                <div
                  className="h-[80px] w-[80px] sm:h-[106px] sm:w-[106px]"
                  style={{
                    borderRadius: '50%',
                    border: '3px solid rgba(0, 155, 214, 1)',
                    overflow: 'hidden',
                  }}
                >
                  <img
                    src={getUserData(leaderboardUsers[1].user).image}
                    style={{
                      backgroundColor: 'white',
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                  />
                </div>
                <div
                  className="ml-[42px] sm:ml-[53px]"
                  style={{
                    width: '17px',
                    height: '17px',
                    transform: 'translate(-50%, -50%) rotate(45deg)',
                    background: '#009BD6',
                    borderRadius: '5px',
                    marginTop: '-3px',
                  }}
                >
                  <div
                    style={{
                      transform: 'translate(-50%, -50%) rotate(-45deg)',
                      position: 'relative',
                      top: '30%',
                      left: '75%',
                      color: 'white',
                      fontSize: '8px',
                    }}
                  >
                    2
                  </div>
                </div>
              </>
            )}
          </div>
          <div
            className="w-[140px] sm:w-[200px]"
            style={{
              height: '130px',
              boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.08)',
              borderRadius: '12px',
              backgroundColor: 'white',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {leaderboardUsers[1] && (
              <>
                <div className="w-full overflow-hidden text-ellipsis p-2 text-center text-[12px] font-semibold">
                  {getUserData(leaderboardUsers[1].user).username}
                </div>
                <div className="mt-1 text-[16px] font-bold">
                  {leaderboardUsers[1].total_reward_amount}
                </div>
              </>
            )}
          </div>
        </div>
        <div className="flex flex-col items-center" style={{ zIndex: '3' }}>
          <div
            style={{
              marginBottom: '-50px',
              zIndex: '10',
            }}
          >
            {leaderboardUsers[0] && (
              <>
                <div
                  className="h-[80px] w-[80px] sm:h-[106px] sm:w-[106px]"
                  style={{
                    borderRadius: '50%',
                    border: '3px solid rgba(0, 155, 214, 1)',
                    overflow: 'hidden',
                  }}
                >
                  <img
                    src={getUserData(leaderboardUsers[0].user).image}
                    style={{
                      backgroundColor: 'white',
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                  />
                </div>
                <div
                  className="ml-[42px] sm:ml-[53px]"
                  style={{
                    width: '17px',
                    height: '17px',
                    transform: 'translate(-50%, -50%) rotate(45deg)',
                    background: '#009BD6',
                    borderRadius: '5px',
                    marginTop: '-3px',
                  }}
                >
                  <div
                    style={{
                      transform: 'translate(-50%, -50%) rotate(-45deg)',
                      position: 'relative',
                      top: '30%',
                      left: '75%',
                      color: 'white',
                      fontSize: '8px',
                    }}
                  >
                    1
                  </div>
                </div>
              </>
            )}
          </div>
          <div
            className="w-[90px] sm:w-[122px]"
            style={{
              height: '194px',
              boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.08)',
              borderRadius: '12px',
              backgroundColor: 'white',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {leaderboardUsers[0] && (
              <>
                <div className="w-full overflow-hidden text-ellipsis p-2 text-center text-[12px] font-semibold">
                  {getUserData(leaderboardUsers[0].user).username}
                </div>
                <div className="mt-1 text-[16px] font-bold text-[#D9A514]">
                  {leaderboardUsers[0].total_reward_amount}
                </div>
              </>
            )}
          </div>
        </div>
        <div className="flex flex-col items-center" style={{ marginLeft: '-20px' }}>
          <div
            style={{
              marginBottom: '-40px',
              zIndex: '10',
            }}
          >
            {leaderboardUsers[2] && (
              <>
                <div
                  className="h-[80px] w-[80px] sm:h-[106px] sm:w-[106px]"
                  style={{
                    borderRadius: '50%',
                    border: '3px solid rgba(0, 155, 214, 1)',
                    overflow: 'hidden',
                  }}
                >
                  <img
                    src={getUserData(leaderboardUsers[2].user).image}
                    style={{
                      backgroundColor: 'white',
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                  />
                </div>
                <div
                  className="ml-[42px] sm:ml-[53px]"
                  style={{
                    width: '17px',
                    height: '17px',
                    transform: 'translate(-50%, -50%) rotate(45deg)',
                    background: '#009BD6',
                    borderRadius: '5px',
                    marginTop: '-3px',
                  }}
                >
                  <div
                    style={{
                      transform: 'translate(-50%, -50%) rotate(-45deg)',
                      position: 'relative',
                      top: '30%',
                      left: '75%',
                      color: 'white',
                      fontSize: '8px',
                    }}
                  >
                    3
                  </div>
                </div>
              </>
            )}
          </div>
          <div
            className="w-[140px] sm:w-[200px]"
            style={{
              height: '130px',
              boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.08)',
              borderRadius: '12px',
              backgroundColor: 'white',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {leaderboardUsers[2] && (
              <>
                <div className="w-full overflow-hidden text-ellipsis p-2 text-center text-[12px] font-semibold">
                  {getUserData(leaderboardUsers[2].user).username}
                </div>
                <div className="mt-1 text-[16px] font-bold text-[#52A31D]">
                  {leaderboardUsers[2].total_reward_amount}
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="Scoreboard__board">
        {leaderboardUsers?.slice(3)?.map((user) => (
          <div className="Scoreboard__board-item" key={user.user.guid}>
            <div className="flex items-center gap-3 md:gap-4">
              <img src={getUserData(user.user).image} />
              <div className="flex flex-col">
                <p>{getUserData(user.user).username}</p>
                <span>@{getUserData(user.user).username}</span>
              </div>
            </div>
            <span className="flex items-center gap-2 !font-semibold !text-[#1A1C1E]">
              {user.total_reward_amount} <UpBoardIcon />
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};
