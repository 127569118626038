import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Shared } from '../../../../assets/icons/shared-link.svg';
import { useNavigate } from '../../../../hooks/useNavigate';
import { Badge } from '../../../../horizon-components/Badge';
import {
  EduRoutes,
  MainRoutes,
} from '../../../../horizon-layout/MainLayout/Routes/types/routes';
import { Course } from '../../../../models/Course';
import { CourseAsideCard } from '../../../../widgets/CourseAsideCard';

type Props = {
  className?: string;
  course: Course | null;
};

export const CourseDigitalPass: React.FC<Props> = ({ course, className }) => {
  const { t } = useTranslation(['translation']);
  const navigate = useNavigate();

  const goToDP = () =>
    navigate(
      MainRoutes.edu + EduRoutes.nft + `/${course?.digital_pass?.category_id}`
    );

  return (
    <>
      <CourseAsideCard
        title={course?.subject?.title}
        data={
          !course?.subject?.is_miscellaneous_subject && (
            <Shared onClick={goToDP} className="cursor-pointer" />
          )
        }
        className={className}
      >
        <div className="flex flex-wrap gap-2">
          {!!course?.level && (
            <Badge variant="primary" className="capitalize">
              {t('course.Grade')} {course?.level}
            </Badge>
          )}
          {!!course?.digital_pass?.title && (
            <Badge variant="primary" className="capitalize">
              {course?.digital_pass?.title}
            </Badge>
          )}
          {!!course?.language && (
            <Badge variant="primary" className="capitalize">
              {course?.language}
            </Badge>
          )}
        </div>
      </CourseAsideCard>
    </>
  );
};
