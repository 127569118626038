import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { useTitleBackContext } from '../../../../providers/TitleBackProvider/TitleBackProvider';
import { SchoolCourses } from '../../NFTView/components/SchoolCourses';
import './style.scss';

interface SubjectCoursesProps {
  className?: string;
}

export const SubjectCourses = ({ className }: SubjectCoursesProps) => {
  const { schoolId, classId, categoryId } = useParams();
  const { t } = useTranslation(['translation']);
  const { state }: { state: { title: string } } = useLocation();
  const { useTitleBack } = useTitleBackContext();

  useTitleBack(state.title);

  return (
    <div className={classNames('SubjectCourses', className)}>
      {t('edu.subject.Choose_a_course')}
      <div className="w-full">
        <SchoolCourses
          selectedDPId={Number(schoolId)}
          selectedLevel={Number(classId)}
          selectedUnits={[Number(categoryId)]}
        />
      </div>
    </div>
  );
};
