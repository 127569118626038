import { useCallback, useEffect, useState } from 'react';
import {
  acceptFriendship,
  addFriendship,
  getFriendshipsWithPage,
  rejectFriendship,
} from '../../../../../api/weber/friendship';
import { Friendship } from '../../../../../models/Friendship';
import { ResponsePagination } from '../../../../../models/Response';
import { DEFAULT_LIMIT } from '../../../../../shared/lib/const/limit';
import { toast } from 'react-toastify';
import getErrorMessages from '../../../../../utils/getErrorMessages';

export const useFriendships = (
  userGuid?: string,
  accepted?: boolean,
  fromMe?: boolean,
  username?: string
) => {
  const [data, setData] = useState<ResponsePagination<Friendship>>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingButton, setIsLoadingButton] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const total = data ? Math.ceil(data?.count / DEFAULT_LIMIT) : 0;

  const fetchData = useCallback(() => {
    setIsLoading(true);
    getFriendshipsWithPage(page, accepted, fromMe, username, userGuid)
      .then((res) => {
        setData(res);
        res !== undefined && setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        toast(getErrorMessages(error?.response?.data), {
          type: 'error',
        });
      });
  }, [accepted, fromMe, page, userGuid, username]);

  useEffect(() => fetchData(), [fetchData]);

  const onFollow = (userId: string) => {
    setIsLoadingButton(true);
    addFriendship(userId)
      .then(() => {
        toast('Friend request send success!', {
          type: 'success',
        });
      })
      .catch((error) => {
        toast(getErrorMessages(error?.response?.data), {
          type: 'error',
        });
      })
      .finally(() => {
        fetchData();
        setIsLoadingButton(false);
      });
  };

  const onAccept = (friendshipId: number) => {
    setIsLoadingButton(true);
    acceptFriendship(friendshipId)
      .then(() => {
        toast('Friend request send success!', {
          type: 'success',
        });
      })
      .catch((error) => {
        toast(getErrorMessages(error?.response?.data), {
          type: 'error',
        });
      })
      .finally(() => {
        fetchData();
        setIsLoadingButton(false);
      });
  };

  const onReject = (friendshipId: number) => {
    setIsLoadingButton(true);
    rejectFriendship(friendshipId)
      .then(() => {
        toast('Friendship rejected!', {
          type: 'success',
        });
      })
      .catch((error) => {
        toast(getErrorMessages(error?.response?.data), {
          type: 'error',
        });
      })
      .finally(() => {
        fetchData();
        setIsLoadingButton(false);
      });
  };

  return {
    data,
    page,
    total,
    isLoading,
    setPage,
    onFollow,
    onAccept,
    onReject,
    isLoadingButton,
  };
};
