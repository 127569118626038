import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import FireGif from '../../assets/images/wiz/fire.gif';
import { MButton } from '../../horizon-components/MButton';
import './style.scss';

interface QuizStartModalProps {
  className?: string;
  onStart?: () => void;
  multiplier: number;
  currentDayNumber: number;
}

export const QuizStartModal = ({
  className,
  onStart,
  multiplier,
  currentDayNumber,
}: QuizStartModalProps) => {
  const { t } = useTranslation(['translation']);

  return (
    <div className={classNames('QuizStartModal', className)}>
      <img src={FireGif} alt="Fire" className={'w-[128px]'} />
      <div>
        <h3>
          {currentDayNumber} {t('wiz.days')}
        </h3>
        <p>Ваша награда увеличена Х{multiplier} </p>
      </div>
      <MButton
        variant="highlighted"
        color="primary"
        className="!px-5 !py-4 md:!px-8 md:!py-6 md:!text-lg"
        onClick={onStart}
        size="lg"
      >
        {t('buttons.Okay')}
      </MButton>
    </div>
  );
};
