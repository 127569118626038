import React, { useMemo } from 'react';
import { DayResult } from '../../../../api/wiz/wiz';
import ResultBgSrc from '../../../../assets/images/wiz-result-background.png';
import './ResultGrid.scss';

type Props = {
  results: DayResult[] | null;
};

export const ResultGrid: React.FC<Props> = ({ results }) => {
  const resultView = useMemo(() => {
    if (!results) return;

    const elements = [];
    for (let colIndex = 0; colIndex < 7; colIndex++) {
      const dayInfo = results.find((day) => day.day_of_week === colIndex + 1);
      for (let rowIndex = 0; rowIndex < 4; rowIndex++) {
        const questionInfo = dayInfo?.user_answers?.[rowIndex];

        let extra = '';
        if (!dayInfo || !questionInfo) {
          extra = 'bg-primary-purple-blue-800';
        } else if (questionInfo.is_correct_answer) {
          extra = 'bg-additional-cyan-500';
        } else {
          extra = 'bg-additional-purple-300';
        }

        elements.push(
          <div
            key={`${rowIndex}-${colIndex}`}
            className={`ResultGrid__card ${extra}`}
          />
        );
      }
    }

    return elements;
  }, [JSON.stringify(results)]);

  return (
    <div
      id={'result-grid'}
      className="ResultGrid"
      style={{
        backgroundImage: `url(${ResultBgSrc})`,
      }}
    >
      {resultView}
    </div>
  );
};
