import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { ReactComponent as NewLogo } from '../../assets/icons/new-logo.svg';
import { TitleBack } from '../../components/TitleBack';
import { useNavigate } from '../../hooks/useNavigate';
import { HButton } from '../../horizon-components/Button/HButton';
import { Link } from '../../horizon-components/Link';
import { MTooltip } from '../../horizon-components/MTooltip/MTooltip';
import { useUserContext } from '../../providers';
import NavbarMenu from './NavbarMenu';
import NavbarMenuAnonymous from './NavbarMenuAnonymous';
import { MAIN_PAGE_ROUTE, MainRoutes } from './Routes/types/routes';

const Navbar = () => {
  const { user } = useUserContext();
  const { pathname } = useLocation();
  const { t } = useTranslation(['translation']);
  const navigate = useNavigate();
  const { lang } = useParams();

  const goTo = (url: string) => () => navigate(url);

  const navbarButtons = () => (
    <div className="hidden gap-x-4 lg:flex">
      <HButton
        id={'home'}
        color={pathname === `/${lang}/` ? 'dark' : 'light'}
        className={'!rounded-[16px] !p-[12px] !text-[16px] !font-[700]'}
        onClick={goTo(`/`)}
      >
        {t('menu.home')}
        <MTooltip anchorSelect={'#home'} text={t('menu.home')} />
      </HButton>
      <HButton
        id={'edu-btn'}
        color={pathname.indexOf('edu') > -1 ? 'dark' : 'light'}
        className={'!rounded-[16px] !p-[12px] !text-[16px] !font-[700]'}
        onClick={goTo(MainRoutes.edu)}
      >
        EDU
        <MTooltip anchorSelect={'#edu-btn'} text={t('menu.educationalPlatform')} />
      </HButton>
      <HButton
        id={'wiz-btn'}
        color={pathname.indexOf('wiz') > -1 ? 'dark' : 'light'}
        className={'!rounded-[16px] !p-[12px] !text-[16px] !font-[700]'}
        onClick={goTo(MainRoutes.wiz)}
      >
        WIZ
        <MTooltip anchorSelect={'#wiz-btn'} text={t('menu.quiz')} />
      </HButton>
    </div>
  );

  return (
    <>
      <nav
        className={`duration-175 linear p2Navbar sticky top-0 z-50 flex w-full flex-row flex-wrap items-center justify-between backdrop-blur-xl transition-all md:!px-6 md:!py-3`}
      >
        <div className={'flex flex-row items-center gap-[117px]'}>
          <Link to={MAIN_PAGE_ROUTE} className={'hidden lg:block'}>
            <NewLogo />
          </Link>
          <div className={'flex items-center lg:hidden'}>
            <Link to={MAIN_PAGE_ROUTE}>
              <NewLogo className="h-5 w-[77px]" />
            </Link>
          </div>
          {navbarButtons()}
        </div>
        {!!user && <NavbarMenu />}
        {!user && <NavbarMenuAnonymous />}
        <TitleBack className="md:!hidden" />
      </nav>
    </>
  );
};

export default Navbar;
