import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  cancelJoinRequest,
  joinGroup,
  leaveGroup,
  requestJoinGroup,
} from '../../../api/weber/communities';
import { GroupType, JoinStatus } from '../../../models/Community';
import { MButton } from '../../../horizon-components/MButton/ui/MButton';

type Props = {
  type: string;
  join_status: JoinStatus;
  communityId: number;
  afterClicked?: () => void;
};

export const CommunityFollow: React.FC<Props> = ({
  type,
  join_status,
  communityId,
  afterClicked = () => {},
}) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [status, setStatus] = useState<JoinStatus>(join_status);
  const { t } = useTranslation(['translation']);

  const buttonMapping: {
    [key: string]: {
      label: string;
      action: any;
      afterActionStatus: JoinStatus | null;
    };
  } = {
    [`${GroupType.PUBLIC}${JoinStatus.NOT_JOINED}`]: {
      label: t('btnsText.JoinGroup'),
      action: joinGroup,
      afterActionStatus: JoinStatus.JOINED,
    },
    [`${GroupType.PUBLIC}${JoinStatus.JOINED}`]: {
      label: t('btnsText.Leave'),
      action: leaveGroup,
      afterActionStatus: JoinStatus.NOT_JOINED,
    },
    [`${GroupType.PUBLIC}${JoinStatus.OWNED}`]: {
      label: t('btnsText.MyGroup'),
      action: afterClicked,
      afterActionStatus: JoinStatus.OWNED,
    },
    [`${GroupType.PRIVATE}${JoinStatus.NOT_JOINED}`]: {
      label: t('btnsText.RequestJoin'),
      action: requestJoinGroup,
      afterActionStatus: JoinStatus.WAITING_FOR_ACCEPT,
    },
    [`${GroupType.PRIVATE}${JoinStatus.WAITING_FOR_ACCEPT}`]: {
      label: t('btnsText.CancelRequest'),
      action: cancelJoinRequest,
      afterActionStatus: JoinStatus.NOT_JOINED,
    },
    [`${GroupType.PRIVATE}${JoinStatus.JOINED}`]: {
      label: t('btnsText.Leave'),
      action: leaveGroup,
      afterActionStatus: JoinStatus.NOT_JOINED,
    },
    [`${GroupType.PRIVATE}${JoinStatus.OWNED}`]: {
      label: t('btnsText.MyGroup'),
      action: afterClicked,
      afterActionStatus: JoinStatus.OWNED,
    },
  };

  const {
    label: buttonLabel,
    action: buttonAction,
    afterActionStatus,
  } = buttonMapping[`${type}${status}`];

  const onCommunityButtonClicked = async () => {
    if (!buttonAction || !afterActionStatus || isButtonDisabled) return;
    setIsButtonDisabled(true);
    try {
      await buttonAction(communityId);
      setStatus(afterActionStatus);
      afterClicked();
    } finally {
      setIsButtonDisabled(false);
    }
  };

  return (
    <MButton
      onClick={onCommunityButtonClicked}
      color={'primary'}
      variant={
        afterActionStatus === JoinStatus.OWNED ||
        afterActionStatus === JoinStatus.JOINED
          ? 'highlighted'
          : 'secondary'
      }
      disabled={isButtonDisabled}
    >
      {buttonLabel}
    </MButton>
  );
};
