import classNames from 'classnames';
import './Tags.scss';

interface TagsProps {
  className?: string;
  tags?: { id: number | string; title: string }[];
  onClick?: (tagId: number | string) => void;
}

export const Tags = ({ className, tags = [], onClick }: TagsProps) => {
  if (!tags.length) return null;
  return (
    <div className={classNames('Tags', className)}>
      {tags.map((tag) => (
        <div className="Tags__title" key={tag.id} onClick={() => onClick?.(tag.id)}>
          {tag.title}
        </div>
      ))}
    </div>
  );
};
