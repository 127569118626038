import { FC } from 'react';
import { MButton } from '../../../horizon-components/MButton';
import { ReactComponent as Icon } from '../../../assets/icons/share/copy.svg';
import { Clipboard as CapacitorClipboard } from '@capacitor/clipboard';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { sendShare } from '../../../hooks/useAnalytics';
import getPlatform from '../../../utils/getPlatform';
import { PlatformsEnum } from '../../../entities/Platforms/platforms';
import { mergeTextAndUrl } from '../utils';

async function copyToClipboard(url: string, text?: string) {
  const fullText = mergeTextAndUrl(url, text);
  const isWeb = [PlatformsEnum.WEB, PlatformsEnum.TELEGRAM].includes(getPlatform());
  if (isWeb) {
    if (!navigator.clipboard) {
      if (!document.execCommand) return;
      const temp = document.createElement('textarea');
      temp.value = fullText;
      temp.style.position = 'absolute';
      temp.style.left = '-999999px';
      document.body.prepend(temp);
      temp.focus();
      temp.select();
      try {
        document.execCommand('copy');
      } catch (error) {
        throw new Error();
      } finally {
        temp.remove();
      }
      return;
    }
    return await navigator.clipboard.writeText(fullText);
  }
  return await CapacitorClipboard.write({
    string: fullText,
  });
}

type Props = {
  text?: string;
  url: string;
  loading?: boolean;
};

const ShareCopyButton: FC<Props> = ({ text, url, loading }) => {
  const { t } = useTranslation();

  const onCopyClick = async () => {
    try {
      await copyToClipboard(url, text);
      sendShare('Copy', 'text');
      toast(t('notifications.The_link_has_been_copied'), {
        type: 'success',
      });
    } catch {
      toast(t('notifications.somethingWentWrong'), {
        type: 'error',
      });
    }
  };

  return (
    <MButton
      className="ShareButtons__link"
      variant="secondary"
      size="md"
      loading={loading}
      disabled={loading}
      onClick={onCopyClick}
    >
      {!loading ? (
        <>
          <span className="ShareButtons__link-text">{url}</span>
          <Icon className="ShareButtons__link-icon" />
        </>
      ) : (
        t('loading')
      )}
    </MButton>
  );
};

export default ShareCopyButton;
