import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getPrivateEnrolledCoursesV3,
  getPrivateStartedCoursesV3,
} from '../../../api/courseV3';
import { Loader } from '../../../components/Loader';
import useFetch from '../../../hooks/useFetch';
import { CourseCardStatus } from '../../../models/Course';
import { useUserContext } from '../../../providers';
import { StartedCard } from '../../../widgets/StartedCard';
import SEO from '../../../components/SEO/SEO';

export const StartedCourses: React.FC = () => {
  const { t } = useTranslation(['translation']);
  const { user } = useUserContext();
  const {
    data,
    isLoading,
    fetchData: fetchStarted,
  } = useFetch(getPrivateStartedCoursesV3);

  const { data: enrolled, fetchData: fetchEnrolled } = useFetch(
    getPrivateEnrolledCoursesV3
  );

  useEffect(() => {
    if (user?.guid) {
      fetchStarted();
      fetchEnrolled();
    }
  }, [t]);

  return (
    <>
      <SEO
        title={t('pages.started-courses.meta.title')}
        desc={t('pages.started-courses.meta.desc')}
      />
      <div className="flex flex-col gap-4 md:gap-[20px]">
        <h3 className="self-stretch text-base font-semibold text-secondary-grey-800 md:text-2xl md:font-bold">
          {t('course.started')}
        </h3>
        {isLoading ? (
          <Loader />
        ) : (
          <div className="grid gap-x-4 gap-y-5 md:grid-cols-2 lg:grid-cols-3">
            {data?.map((course) => (
              <StartedCard
                course={course}
                key={course.id}
                status={
                  !!enrolled?.find((enroll) => enroll === course.id)
                    ? CourseCardStatus.ENROLLED
                    : CourseCardStatus.CAN_ENROLL
                }
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
};
