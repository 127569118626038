export const formatNumber = (numberString: string): string => {
  // Преобразуем строку в число
  const number = parseFloat(numberString);

  // Используем Intl.NumberFormat для форматирования числа
  const formattedNumber = new Intl.NumberFormat('ru-RU', {
    maximumFractionDigits: 0, // Убираем десятичную часть
  }).format(number);

  return formattedNumber;
};
