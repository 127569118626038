import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Category,
  getAuthorizedCurrentStartedQuiz,
  getDailyMultiplierInfo,
  getUnauthorizedCurrentStartedQuiz,
  getUserMetrics,
  setForceCompleteQuiz,
  TDailyMultiplierInfo,
  WizDailyStatusEnum,
} from '../../api/wiz/wiz';
import useFetch from '../../hooks/useFetch';
import { useUserContext } from '../UserProvider';

type WizProviderType = {
  livesCount: number;
  dailyInfo: null | TDailyMultiplierInfo[];
  currentMultiplier: number;
  currentDay: number;
  isLivesDataLoading: boolean;
  isDailyInfoLoading: boolean;
  nextLifeRecoveryTime: number;
  fetchLivesData: () => Promise<any>;
  fetchDailyInfo: () => Promise<void>;
  setLivesCount: React.Dispatch<React.SetStateAction<number>>;
  fetchCurrentAuthTest: () => Promise<void>;
  fetchCurrentUnauthTest: (quizId: number) => Promise<void>;
  fetchForceCompleteQuiz: () => Promise<void>;
  currentTest: Category | null;
  handleCloseTest: () => void;
  isCurrentAuthTestLoading: boolean;
};

const WizContext = createContext<WizProviderType>({
  livesCount: 3,
  dailyInfo: null,
  currentMultiplier: 1,
  currentDay: 1,
  isLivesDataLoading: true,
  isDailyInfoLoading: true,
  nextLifeRecoveryTime: 0,
  fetchLivesData: async () => {},
  fetchDailyInfo: async () => {},
  setLivesCount: () => {},
  fetchCurrentAuthTest: async () => {},
  fetchCurrentUnauthTest: async (quizId: number) => {},
  fetchForceCompleteQuiz: async () => {},
  currentTest: null,
  handleCloseTest: () => {},
  isCurrentAuthTestLoading: false,
});

const WizProvider: React.FC<PropsWithChildren> = (props) => {
  const { children } = props;
  const { user } = useUserContext();
  const [currentDay, setCurrentDay] = useState(1);
  const [currentMultiplier, setCurrentMultiplier] = useState(1);
  const [dailyInfo, setDailyInfo] = useState<TDailyMultiplierInfo[] | null>(null);
  const [isDailyInfoLoading, setIsDailyInfoLoading] = useState(true);
  const [livesCount, setLivesCount] = useState(3);
  const [currentTest, setCurrentTest] = useState<Category | null>(null);
  const [isCurrentAuthTestLoading, setIsCurrentAuthTestLoading] = useState(false);

  const {
    data: livesData,
    isLoading: isLivesDataLoading,
    fetchData: fetchLivesData,
  } = useFetch(getUserMetrics);

  const fetchCurrentUnauthTest = async (quizId: number) => {
    setIsCurrentAuthTestLoading(true);
    try {
      const test = await getUnauthorizedCurrentStartedQuiz(quizId);
      setCurrentTest(test);
    } finally {
      setIsCurrentAuthTestLoading(false);
    }
  };

  const fetchCurrentAuthTest = async () => {
    setIsCurrentAuthTestLoading(true);
    try {
      const test = await getAuthorizedCurrentStartedQuiz();
      setCurrentTest(test);
    } finally {
      setIsCurrentAuthTestLoading(false);
    }
  };

  const fetchDailyInfo = async () => {
    setIsDailyInfoLoading(true);
    const dailyInfo = await getDailyMultiplierInfo();
    const currentDay_ = dailyInfo.find(
      (dayInfo) => dayInfo.status === WizDailyStatusEnum.ACTIVE
    );
    setDailyInfo(dailyInfo);
    setCurrentDay(currentDay_?.day || 1);
    setCurrentMultiplier(currentDay_?.multiplier || 1);
    setIsDailyInfoLoading(false);
  };

  const fetchForceCompleteQuiz = async () => {
    try {
      currentTest?.id && user?.guid && (await setForceCompleteQuiz(currentTest?.id));
    } finally {
      handleCloseTest();
    }
  };

  useEffect(() => {
    fetchDailyInfo();
    if (user) {
      fetchLivesData();
    }
  }, []);

  useEffect(() => {
    if (!livesData) return;
    setLivesCount(livesData.lives);
  }, [livesData]);

  const handleCloseTest = () => {
    setCurrentTest(null);
  };

  return (
    <WizContext.Provider
      value={{
        livesCount,
        nextLifeRecoveryTime: livesData?.next_life_recovery_time || 0,
        currentDay,
        currentMultiplier,
        dailyInfo,
        isLivesDataLoading,
        isDailyInfoLoading,
        fetchLivesData,
        fetchDailyInfo,
        setLivesCount,
        fetchCurrentAuthTest,
        fetchCurrentUnauthTest,
        fetchForceCompleteQuiz,
        currentTest,
        handleCloseTest,
        isCurrentAuthTestLoading,
      }}
    >
      {children}
    </WizContext.Provider>
  );
};

const useWizContext = () => useContext(WizContext);

export { useWizContext, WizProvider };
