import classNames from 'classnames';
import { ReactComponent as CloseIcon } from '../../../assets/icons/feed/close.svg';
import { ReactComponent as DocIcon } from '../../../assets/icons/feed/doc.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/feed/download.svg';
import { getFileName } from '../../../shared/utils/getFileName';
import './AttachementCard.scss';

interface AttachementCardProps {
  className?: string;
  title?: string;
  file?: string;
  onRemove?: () => void;
  download?: boolean;
  isDownload?: boolean;
}

export const AttachementCard = ({
  className,
  title,
  file,
  onRemove,
  download = false,
}: AttachementCardProps) => {
  const handleDownload = async () => {
    if (file) {
      const link = document.createElement('a');
      link.href = file;
      link.target = '_blank';
      link.download = getFileName(file);
      link.click();
    }
  };
  return (
    <div className={classNames('AttachementCard', className)}>
      <DocIcon /> {!!title && <span>{decodeURIComponent(title)}</span>}{' '}
      {download && file && (
        <DownloadIcon onClick={handleDownload} className="icon cursor-pointer" />
      )}
      {!!onRemove && (
        <CloseIcon onClick={onRemove} className="icon cursor-pointer" />
      )}
    </div>
  );
};
