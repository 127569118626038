import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
} from 'react';
import { getTokenomicConfigs } from '../api/auth';
import useFetch from '../hooks/useFetch';
import { TTokenomicConfigs } from '../models/Reward';

type RewardContextValue = {
  rewards: TTokenomicConfigs[] | null;
  isLoading: boolean;
};

const RewardContext = createContext<RewardContextValue>({} as RewardContextValue);

const RewardProvider: React.FC<PropsWithChildren> = (props) => {
  const { children } = props;
  const {
    data: rewards,
    fetchData: fetchTokenomic,
    isLoading,
  } = useFetch(getTokenomicConfigs);

  useEffect(() => {
    fetchTokenomic();
  }, []);

  return (
    <RewardContext.Provider value={{ rewards, isLoading }}>
      {children}
    </RewardContext.Provider>
  );
};

const useRewardContext = () => useContext(RewardContext);

export { RewardProvider, RewardContext, useRewardContext };
