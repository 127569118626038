import { getAssesTokenStorage } from '../storages';
import { SOCKET_URL } from '../instance';

export const getChatConnect = (chatId: number): Promise<WebSocket> => {
  return new Promise((resolve, reject) => {
    const socket = new WebSocket(
      `${SOCKET_URL}/weber/ws/chat/${chatId}/?token=${getAssesTokenStorage()}`
    );

    socket.onopen = () => {
      resolve(socket);
    };

    socket.onerror = () => {
      reject();
    };
  });
};

export const getNotificationsConnect = (): Promise<WebSocket> => {
  return new Promise((resolve, reject) => {
    const socket = new WebSocket(
      `${SOCKET_URL}/auth/ws/notifications/?token=${getAssesTokenStorage()}`
    );

    socket.onopen = () => {
      resolve(socket);
    };

    socket.onerror = () => {
      reject();
    };
  });
};
