import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useNavigate } from '../../../hooks/useNavigate';
import { MButton } from '../../../horizon-components/MButton/ui/MButton';
import './AsideCard.scss';

interface AsideCardProps {
  className?: string;
  title?: string;
  url?: string;
  children: React.ReactNode;
  data?: React.ReactNode;
}

export const AsideCard = ({
  className,
  title,
  url,
  children,
  data,
}: AsideCardProps) => {
  const { t } = useTranslation(['translation']);
  const navigate = useNavigate();
  const goTo = () => url && navigate(url);

  return (
    <div className={classNames('AsideCard', className)}>
      {(title || url || data) && (
        <div className="AsideCard__head">
          <h4>{title}</h4>

          {!!url && (
            <MButton variant="highlighted" color="white" onClick={goTo}>
              {t('buttons.view_all')}
            </MButton>
          )}
          {!!data && data}
        </div>
      )}

      {children}
    </div>
  );
};
