import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import BgImg from '../../assets/images/pages/profile/ref-bg.webp';
import { useShareReferal } from '../../hooks/useShareReferal';
import { MButton } from '../../horizon-components/MButton';
import { useRewardContext } from '../../providers/RewardProvider';
import './style.scss';

interface ProfileReferalProps {
  className?: string;
}

export const ProfileReferal = ({ className }: ProfileReferalProps) => {
  const { t } = useTranslation(['translation']);
  const { handleClick } = useShareReferal();
  const { rewards, isLoading } = useRewardContext();

  const rewardAmountRaw =
    (rewards &&
      rewards
        .find((reward) => reward.tokenomic_type === 'user_invitation')
        ?.value.toLocaleString()) ||
    '0';
  const rewardAmount = parseInt(rewardAmountRaw).toLocaleString();

  return (
    <div
      className={classNames('ProfileReferal', className)}
      onClick={handleClick}
      style={{ backgroundImage: `url(${BgImg})` }}
    >
      <div className="ProfileReferal__content">
        <span>{t('profile.Referral_link')}</span>
        <p>
          {t(
            'profile.Get_EP_for_each_friend_registered_on_the_Edres_platform_using_your_link',
            { rewardAmount }
          )}
        </p>
      </div>
      <MButton
        variant="secondary"
        color="primary"
        className="ml-auto"
        loading={isLoading}
        disabled={isLoading}
      >
        <span className="ProfileReferal__btn-text">
          {t('buttons.Send_a_referral_link')}
        </span>
      </MButton>
    </div>
  );
};
