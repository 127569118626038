import classNames from 'classnames';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useNavigate } from '../../../hooks/useNavigate';
import { TColor, TVariant } from '../../../horizon-components/MButton';
import { MButton } from '../../../horizon-components/MButton/ui/MButton';
import './DropdownButton.scss';

export interface IDropdownButton {
  title?: string;
  link?: string;
  icon?: JSX.Element; // Assuming the icon is a JSX element
  icon_right?: JSX.Element; // Assuming the icon is a JSX element
  onClick?: () => void;
  type?: 'default' | 'remove';
}

interface DropdownButtonProps {
  className?: string;
  communityFeedId?: string | number;
  variant?: TVariant;
  color?: TColor;
  buttons?: IDropdownButton[];
  children?: ReactNode;
}

export const DropdownButton = ({
  className,
  communityFeedId,
  variant = 'highlighted',
  color = 'primary',
  buttons,
  children,
}: DropdownButtonProps) => {
  const navigate = useNavigate();
  const [showActions, setShowActions] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setShowActions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setShowActions]);

  const handleClick = (link: string) =>
    navigate(link, {
      state: { communityIdFeed: communityFeedId },
    });

  return (
    <div className={classNames('sm:relative')}>
      <MButton
        color={color}
        variant={variant}
        onClick={() => setShowActions(!showActions)}
        className={classNames(
          'text-nowrap sm-max:h-[42px] sm-max:w-[42px] sm-max:!rounded-[16px] sm-max:bg-purple-500 sm-max:!px-[12px] sm-max:!text-white',
          className
        )}
      >
        {children}
      </MButton>
      {showActions && (
        <div ref={ref} className={classNames('DropdownButton')}>
          {buttons?.map((button, idx) => (
            <div
              className={classNames('DropdownButton__item', button?.type)}
              onClick={() =>
                button.link ? handleClick(button.link) : button.onClick?.()
              }
              key={idx}
            >
              {!!button.icon && (
                <div className="DropdownButton__item-icon">{button.icon}</div>
              )}
              <span>{button.title}</span>
              {!!button.icon_right && (
                <div className={classNames('icon_right', button?.type)}>
                  {button.icon_right}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
