import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { updateCourseStatus } from '../../../../api/course';
import { CustomQuill } from '../../../../components/CustomQuill/CustomQuill';
import { useNavigate } from '../../../../hooks/useNavigate';
import { Card } from '../../../../horizon-components/Card/Card';
import { MButton } from '../../../../horizon-components/MButton/ui/MButton';
import { Modal } from '../../../../horizon-components/Modal';
import { CourseStatus } from '../../../../models/Course';

type Props = {
  courseId: string;
};

export const StaffVerification: React.FC<Props> = ({ courseId }) => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);
  const [isCommentModalVisible, setIsCommentModalVisible] = useState(false);
  const [comment, setComment] = useState('');
  const { t } = useTranslation(['translation']);

  const reject = () => {
    setIsRejecting(true);
    updateCourseStatus(courseId, {
      new_status: CourseStatus.Rejected,
      comment,
    })
      .then(() => {
        navigate(-1);
      })
      .finally(() => setIsRejecting(false));
  };

  const approve = () => {
    setIsSubmitting(true);
    updateCourseStatus(courseId, {
      new_status: CourseStatus.Verified,
      comment: '',
    })
      .then(() => {
        navigate(-1);
      })
      .finally(() => setIsSubmitting(false));
  };

  const closeComment = () => setIsCommentModalVisible(false);
  const handleChangeComment = (value: string) => setComment(value);
  const showRejectModal = () => setIsCommentModalVisible(true);

  return (
    <>
      <div className={'bottom-24 left-0 w-full lg:fixed lg:bottom-16'}>
        <Card
          className={
            'z-[11] mx-auto flex w-full flex-col items-start justify-between !rounded-[24px] bg-secondaryGrey-200 !p-[12px] !shadow-[14px_17px_40px_4px_#7090B014] lg:max-w-[800px] lg:flex-row lg:items-center lg:!rounded-[30px] lg:!p-[24px]'
          }
        >
          <div>
            <p className={'text-[18px] font-[600] text-gray-900'}>
              {t('course.verificationMode')}
            </p>
            <p className={'text-[12px] font-[600] text-gray-500'}>
              {t('course.verificationModeDesc')}
            </p>
          </div>
          <div
            className={
              'mt-2 flex w-full flex-col justify-end gap-2 md:flex-row md:gap-5 lg:mt-0 lg:w-auto'
            }
          >
            <MButton
              color={'danger'}
              className={'w-full md:w-auto'}
              variant={'outline'}
              onClick={showRejectModal}
            >
              {t('course.Reject')}
            </MButton>
            <MButton
              color={'primary'}
              className={'w-full md:w-auto'}
              variant={'highlighted'}
              onClick={approve}
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              {t('course.Approve')}
            </MButton>
          </div>
        </Card>
      </div>

      <Modal
        title={t('course.commentRejection')}
        isOpen={isCommentModalVisible}
        onClose={closeComment}
        bodyClassName={'!min-w-full md:!min-w-[760px]'}
      >
        <>
          <CustomQuill
            wrapperClassName={'mb-1'}
            value={comment}
            onChange={handleChangeComment}
          />
          <div className={'flex justify-end'}>
            <MButton
              color={'primary'}
              variant={'outline'}
              onClick={reject}
              disabled={!comment || isRejecting}
              loading={isRejecting}
            >
              {t('course.sendAndReject')}
            </MButton>
          </div>
        </>
      </Modal>
    </>
  );
};
