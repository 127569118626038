import React, { useState } from 'react';
import { Chip } from '../../../../horizon-components/Chip/Chip';
import { useTranslation } from 'react-i18next';

type ChatDirectory = {
  title: string;
  slug: string;
};

type Props = {
  onChangeFilter: (value: string) => void;
};

export const ChatFilter: React.FC<Props> = ({ onChangeFilter }) => {
  const { t } = useTranslation(['translation']);
  const defaultFilter: ChatDirectory[] = [
    { title: t('profile.All'), slug: '' },
    { title: t('profile.Direct'), slug: 'DIRECT' },
    { title: t('profile.Communities'), slug: 'GROUP' },
    { title: t('profile.Tasks'), slug: 'TASKS' },
  ];

  const [chatDirectory] = useState<ChatDirectory[]>(defaultFilter);
  const [selectedChat, setSelectedChat] = useState<string>('');

  return (
    <div className="flex w-full items-center gap-2 overflow-x-auto">
      {chatDirectory.map((item) => {
        const select = () => {
          setSelectedChat(item.slug);
          onChangeFilter(item.slug);
        };

        return (
          <Chip
            className={`!mt-0 h-fit cursor-pointer border-[1px] border-secondaryGrey-500 !bg-transparent !px-[12px] !py-[4px] px-3 py-[5px] ${
              selectedChat === item.slug ? '!border-gray-500' : ''
            }`}
            textClassName={`text-gray-500 text-[12px] ${
              selectedChat === item.slug ? 'text-secondaryGrey-800' : ''
            }`}
            key={item.slug}
            onClick={select}
          >
            {item.title}
          </Chip>
        );
      })}
    </div>
  );
};
