import classNames from 'classnames';
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow.svg';
import './style.scss';

interface SelectCourseProps {
  className?: string;
  onClick?: () => void;
}

export const SelectCourse = ({ className, onClick }: SelectCourseProps) => {
  return (
    <div className={classNames('SelectCourse', className)} onClick={onClick}>
      <div>Выбрать курс самостоятельно</div>
      <ArrowIcon />
    </div>
  );
};
