import classNames from 'classnames';
import { ReactComponent as FavoriteIcon } from '../../../assets/icons/feed/favorite-folder.svg';
import './FolderCard.scss';

interface FolderCardProps {
  className?: string;
  title?: string;
  count?: string;
  img?: string;
}

export const FolderCard = ({ className, title, count, img }: FolderCardProps) => {
  return (
    <div className={classNames('FolderCard', className)}>
      <div className="FolderCard__img">
        {img ? <img src={img} alt={title} /> : <FavoriteIcon />}
      </div>
      <div className="FolderCard__head">
        {title && <h3>{title}</h3>}
        {count && <p className="lowercase">{count}</p>}
      </div>
    </div>
  );
};
