import { PlatformsEnum } from '../../entities/Platforms/platforms';
import { AppLauncher } from '@capacitor/app-launcher';
import { Browser } from '@capacitor/browser';
import getPlatform from '../../utils/getPlatform';
import { ShareOnSocialParams, URLParams } from './types';

export function mergeTextAndUrl(url: string, text?: string) {
  if (!text) {
    return url;
  }
  return `${text}\n\n${url}`;
}

function objectToGetParams(object: URLParams) {
  const params = Object.entries(object)
    .filter(([, value]) => value !== undefined && value !== null)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`
    );

  return params.length > 0 ? `?${params.join('&')}` : '';
}

async function canOpenApp(url: string) {
  try {
    const { value } = await AppLauncher.canOpenUrl({ url });
    return value;
  } catch {
    return false;
  }
}

export async function shareOnSocial(params: ShareOnSocialParams) {
  const platform = getPlatform();
  const { web, native, ios = native, android = native, androidPackageName } = params;
  const webUrl = web.baseUrl + objectToGetParams(web.params);

  switch (platform) {
    case PlatformsEnum.WEB:
    case PlatformsEnum.TELEGRAM:
      window.open(webUrl, '_blank');
      break;
    case PlatformsEnum.IOS:
    case PlatformsEnum.ANDROID:
      const payload = platform === PlatformsEnum.ANDROID ? android : ios;
      const checkUrl =
        platform === PlatformsEnum.ANDROID ? androidPackageName : payload.baseUrl;
      if (!(await canOpenApp(checkUrl))) {
        await Browser.open({ url: webUrl });
      } else {
        await AppLauncher.openUrl({
          url: payload.baseUrl + objectToGetParams(payload.params),
        });
      }
      break;
    default:
      break;
  }
}

export async function shareTelegram(url: string, text?: string) {
  return await shareOnSocial({
    web: { baseUrl: 'https://t.me/share/url', params: { text, url } },
    native: { baseUrl: 'tg://msg', params: { text: mergeTextAndUrl(url, text) } },
    androidPackageName: 'org.telegram.messenger',
  });
}
