import EdresCoinImage from '../../assets/images/reward/erp.png';
import { MButton } from '../../horizon-components/MButton';
import { SimpleModal } from '../../horizon-components/SimpleModal/SimpleModal';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const CongratulationsModal = ({
  title,
  description,
  isModalOpen,
  onClose,
}: {
  title: string;
  description: string;
  isModalOpen: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation(['translation']);

  return (
    <SimpleModal isOpen={isModalOpen} onClose={onClose}>
      <div className={'flex flex-col gap-[15px]'}>
        <img src={EdresCoinImage} alt="edres coin" className={'w-[344px]'} />
        <div className={'flex flex-col gap-[13px] text-center'}>
          <span className={'text-[24px] font-bold text-primary-purple-blue-500'}>
            {title}
          </span>
          <span className={'text-[16px] font-medium text-secondary-grey-900'}>
            {description}
          </span>
        </div>
        <MButton
          color={'primary'}
          size={'sm'}
          variant={'highlighted'}
          onClick={onClose}
        >
          {t('buttons.Okay')}
        </MButton>
      </div>
    </SimpleModal>
  );
};
