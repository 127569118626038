import axios, { AxiosInstance } from 'axios';
import {
  clearStorage,
  getAssesTokenStorage,
  getRefreshTokenStorage,
  saveAssesTokenStorage,
  saveUserStorage,
} from './storages';
import { authMe, refreshToken } from './auth';
import {
  AuthRoutes,
  MainRoutes,
} from '../horizon-layout/MainLayout/Routes/types/routes';
import qs from 'qs';

export const REACT_APP_API_URL = process.env.REACT_APP_API_URL || '';
export const SOCKET_URL = REACT_APP_API_URL.replace('https:', 'wss:');
export const API_URL = `${REACT_APP_API_URL}/api/edu`;
export const AUTH_API_URL = `${REACT_APP_API_URL}/api/auth`;
export const WEBER_API_URL = `${REACT_APP_API_URL}/weber/api`;

export const WIZ_API_URL = `${REACT_APP_API_URL}/api/wiz`;

function instance(authorization = true, abort = true, url?: string): AxiosInstance {
  const axiosInstance = axios.create({
    baseURL: API_URL,
    paramsSerializer: (params) =>
      qs.stringify(params, {
        arrayFormat: 'repeat',
        skipNulls: true, // Эта опция пропускает параметры с `null` значением
        filter: (prefix, value) => {
          // Удаляем параметры с пустыми значениями
          if (value === '') {
            return undefined;
          }
          return value;
        },
      }),
  });

  if (authorization) {
    axiosInstance.interceptors.request.use(
      async (config) => {
        // Получаем значение языка из localStorage или какого-либо другого места
        const language = localStorage.getItem('language') || 'ru';
        // Добавляем заголовок Accept-Language
        config.headers['Accept-Language'] = language === 'kz' ? 'kk' : language;

        if (config.headers) {
          const token = getAssesTokenStorage();
          if (token) {
            config.headers.Authorization = `JWT ${token}`;
          }
        }

        return config;
      },
      (error) => Promise.reject(error)
    );

    axiosInstance.interceptors.response.use(
      async (response) => {
        return Promise.resolve(response);
      },
      async (error) => {
        if (axios.isCancel(error)) {
          return error;
        } else {
          const originalRequest = error.config;

          const token = getRefreshTokenStorage();
          if (
            error.response &&
            error.response.status === 401 &&
            !originalRequest._retry &&
            token
          ) {
            originalRequest._retry = true;
            try {
              const { access } = await refreshToken(token);
              saveAssesTokenStorage(access);

              const user = await authMe();

              saveUserStorage(user);
              axiosInstance.defaults.headers.Authorization = `JWT ${access}`;
              return axiosInstance(originalRequest);
            } catch (e: any) {
              if (e.response && e.response.status === 401) {
                console.error('Refresh token expired:', e);
                clearStorage();
                window.location.href = MainRoutes.auth + AuthRoutes.signin;
              }
            }
          }
          return Promise.reject(error);
        }
      }
    );
  }

  return axiosInstance;
}

function getRequestIdentifier(config: any): string {
  if (config && config?.method && config?.url) {
    return config.method + config.url;
  }
  return '';
}

export default instance;
