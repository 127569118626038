import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { TAttachedFiles } from '../../../models/Feed';
import { getFileName } from '../../../shared/utils/getFileName';
import { AttachementCard } from '../../AttachementCard';
import './FeedGallery.scss';

interface FeedGalleryProps {
  className?: string;
  files?: TAttachedFiles[];
  isFileMode?: boolean;
}
const imageExtensionsRegex = /\.(jpg|jpeg|png|gif)(\?.*)?$/i;

export const FeedGallery = ({
  className,
  files = [],
  isFileMode,
}: FeedGalleryProps) => {
  const [images, setImages] = useState<TAttachedFiles[]>([]);
  const [attaches, setAttaches] = useState<TAttachedFiles[]>([]);

  useEffect(() => {
    if (isFileMode) {
      setAttaches(files);
    } else {
      files.length &&
        setImages(files.filter((file) => imageExtensionsRegex.test(file.file)));
      files.length &&
        setAttaches(files.filter((file) => !imageExtensionsRegex.test(file.file)));
    }
  }, [files]);

  return (
    <div className={classNames('FeedGallery', className)}>
      {!!images.length && (
        <div
          className={classNames('FeedGallery__images', {
            'grid-cols-1': images.length === 1,
            'grid-cols-2': images.length === 2 || images.length === 4,
            'grid-cols-3': images.length === 3,
            'grid-cols-6': images.length === 5,
          })}
        >
          {images.map((image, idx) => (
            <div
              className={classNames('FeedGallery__image', {
                'col-span-3': images.length === 5 && idx <= 1,
                'col-span-2': images.length === 5 && idx > 1,
              })}
              key={image.id}
            >
              <img src={image.file} alt={getFileName(image.file)} />
            </div>
          ))}
        </div>
      )}
      {!!attaches.length && (
        <div className="FeedGallery__files">
          {attaches.map((file) => (
            <AttachementCard
              key={file.id}
              title={getFileName(file.file)}
              file={file.file}
              download
            />
          ))}
        </div>
      )}
    </div>
  );
};
