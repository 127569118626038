import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '../../../horizon-components/Card/Card';
import { Link } from '../../../horizon-components/Link';
import {
  AuthRoutes,
  MainRoutes,
  ProfileRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';
import { Rules } from './Rules';

export const About: React.FC = () => {
  const { t } = useTranslation(['translation']);

  return (
    <div className={'px-[8px] lg:px-0'}>
      <h1 className={'g-main-title mb-[16px] lg:mb-[24px]'}>{t('menu.About')}</h1>
      <div className={'flex flex-col gap-[20px] lg:flex-row'}>
        <Card
          extra={`rounded-[24px] !p-[24px] !shadow-[14px_17px_40px_4px_#7090B014] p-[0px] overflow-hidden cursor-pointer relative`}
        >
          <div className={'mb-0 w-full lg:mb-[170px] lg:w-[55%]'}>
            <p className={'text-[18px] font-bold text-secondaryGrey-900'}>
              {t('bid.aboutTitle')}
            </p>
            <br />
            <p className={'text-[16px] font-[600] text-secondaryGrey-900'}>
              {t('bid.desc1')} <Link to={MainRoutes.wiz}>WIZ</Link> {t('bid.desc2')}{' '}
              <Link to={MainRoutes.edu}>EDU</Link>.
            </p>
            <br />
            <p className={'text-[16px] font-[600] text-secondaryGrey-900'}>
              {t('bid.desc3')}{' '}
              <Link to={MainRoutes.auth + AuthRoutes.profile + ProfileRoutes.wallet}>
                wallet
              </Link>
              .
            </p>
          </div>
          <img
            src={require('../../../assets/images/about-bid.png')}
            className={'absolute bottom-[24px] right-[24px] hidden lg:block'}
            alt={'img'}
          />
        </Card>
        <Card
          extra={`rounded-[24px] !p-[24px] !w-full lg:!w-[35%] !shadow-[14px_17px_40px_4px_#7090B014] p-[0px] overflow-hidden cursor-pointer`}
        >
          <p className={'mb-[20px] text-[18px] font-bold text-secondaryGrey-900'}>
            {t('bid.Rules')}
          </p>
          <Rules />
        </Card>
      </div>
    </div>
  );
};
