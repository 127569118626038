import classNames from 'classnames';
import './Loader.scss';

interface LoaderProps {
  className?: string;
}

export const Loader = ({ className }: LoaderProps) => {
  return (
    <div className="Loader">
      <div className={classNames('spinner', className)}></div>
    </div>
  );
};
