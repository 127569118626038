import instance, { WEBER_API_URL } from '../instance';
import { ResponsePagination } from '../../models/Response';
import { TCommunity } from '../../models/Community';
import { User } from '../../models';
import { ImageItem } from '../../models/Course';

export interface ICommunityResponse extends ResponsePagination<TCommunity> {}

type GetCommunitiesParams = {
  subject?: string;
  name?: string;
  order_by?: string;
  page?: number;
};

export type UserJoinRequest = {
  id: number;
  created_at: string;
  user: User;
  user_guid: string;
};

export enum CommunityOrderBy {
  popularity = 'Popularity',
  '-created_at' = 'Latest',
  created_at = 'Oldest',
  name = 'A-Z',
  '-name' = 'Z-A',
}

export type CommunitiesRequest = {
  page?: number;
  subject?: string[];
  name?: string;
  ordering?: string;
  order_by?: keyof typeof CommunityOrderBy;
  my_groups?: boolean;
};

export function getCommunities({
  page,
  subject,
  name,
  ordering,
  order_by,
  my_groups,
}: CommunitiesRequest): Promise<ICommunityResponse> {
  return instance()
    .get(`${WEBER_API_URL}/groups/`, {
      params: {
        page,
        subject,
        name,
        ordering,
        order_by,
        my_groups,
      },
      paramsSerializer: {
        indexes: null,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function joinGroup(groupId: number): Promise<string> {
  return instance()
    .post(`${WEBER_API_URL}/groups/${groupId}/join_group/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function requestJoinGroup(groupId: number): Promise<string> {
  return instance()
    .post(`${WEBER_API_URL}/groups/${groupId}/request_join/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function cancelJoinRequest(groupId: number): Promise<string> {
  return instance()
    .post(`${WEBER_API_URL}/groups/${groupId}/cancel_request/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function leaveGroup(groupId: number): Promise<string> {
  return instance()
    .post(`${WEBER_API_URL}/groups/${groupId}/leave_group/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function createCommunity(form: FormData): Promise<TCommunity> {
  return instance()
    .post(`${WEBER_API_URL}/groups/`, form)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function updateCommunity(
  communityId: number,
  form: FormData
): Promise<TCommunity> {
  return instance()
    .patch(`${WEBER_API_URL}/groups/${communityId}/`, form)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getCommunity(groupId: number): Promise<TCommunity> {
  return instance()
    .get(`${WEBER_API_URL}/groups/${groupId}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getCommunityJoinRequests(
  groupId: number,
  page: number = 1
): Promise<ResponsePagination<UserJoinRequest>> {
  const params = {
    page: page,
    group: groupId,
  };

  return instance()
    .get(`${WEBER_API_URL}/group-join-requests/`, {
      params,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function acceptJoinRequest(requestId: number): Promise<null> {
  return instance()
    .post(`${WEBER_API_URL}/group-join-requests/${requestId}/approve_request/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function rejectJoinRequest(requestId: number): Promise<null> {
  return instance()
    .delete(`${WEBER_API_URL}/group-join-requests/${requestId}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function communitiesCoveredImageList(): Promise<ImageItem[]> {
  return instance()
    .get(`${WEBER_API_URL}/predefined-group-image/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}
