import { useCallback, useState } from 'react';
import { getErWalletTransaction } from '../../../../../api/wallet';
import { Wallet } from '../../../../../models/Wallet';

export const useErWalletTransaction = () => {
  const [data, setData] = useState<Wallet[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchData = useCallback((min?: string, max?: string) => {
    setIsLoading(true);
    getErWalletTransaction(min, max)
      .then((res) => setData(res))
      .finally(() => setIsLoading(false));
  }, []);

  return { data, isLoading, fetchData };
};
