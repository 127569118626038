import { useTranslation } from 'react-i18next';
import { ReactComponent as FollowIcon } from '../../../assets/icons/profile/follow.svg';
import { ReactComponent as UnfollowIcon } from '../../../assets/icons/profile/unfollow.svg';
import { useFriendships } from '../../../entities/User';
import { MButton } from '../../../horizon-components/MButton/ui/MButton';
import { FriendshipStatus } from '../../../models/Friendship';
import { useUserContext } from '../../../providers';

export const UserFollowButton = ({
  anotherUserGuid,
  isIcon = false,
}: {
  anotherUserGuid: string;
  isIcon?: boolean;
}) => {
  const { t } = useTranslation(['translation']);
  const { user } = useUserContext();

  const {
    data: friendship,
    onFollow,
    onAccept,
    onReject,
    isLoadingButton,
  } = useFriendships(anotherUserGuid);

  return (
    <>
      {anotherUserGuid !== user?.guid && (
        <div className="PublicProfile__btns">
          {friendship?.results[0]?.status === FriendshipStatus.FRIENDS ? (
            <MButton
              color="primary"
              variant="secondary"
              disabled={isLoadingButton}
              onClick={() => onReject(friendship?.results[0]?.id)}
            >
              {t('buttons.Disconnect')} {isIcon && <UnfollowIcon />}
            </MButton>
          ) : friendship?.results[0]?.status ===
            FriendshipStatus.WANTS_TO_BE_FRIENDS ? (
            <>
              <MButton
                color="primary"
                variant="secondary"
                disabled={isLoadingButton}
                onClick={() => onReject(friendship?.results[0]?.id)}
              >
                {t('buttons.Reject')}
              </MButton>
              <MButton
                color="primary"
                variant="highlighted"
                disabled={isLoadingButton}
                onClick={() => onAccept(friendship?.results[0]?.id)}
              >
                {t('buttons.Accept')}
              </MButton>
            </>
          ) : friendship?.results[0]?.status ===
            FriendshipStatus.WAITING_FOR_ACCEPT ? (
            <MButton
              color="primary"
              variant="secondary"
              disabled={isLoadingButton}
              onClick={() => onReject(friendship?.results[0]?.id)}
            >
              {t('buttons.Cancel_request')}
            </MButton>
          ) : (
            <MButton
              color="primary"
              variant="highlighted"
              disabled={isLoadingButton}
              onClick={() => anotherUserGuid && onFollow(anotherUserGuid)}
            >
              {t('buttons.Connect')} {isIcon && <FollowIcon />}
            </MButton>
          )}
        </div>
      )}
    </>
  );
};
