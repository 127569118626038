import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { MainLayout } from './horizon-layout/MainLayout/MainLayout';
import {
  MAIN_PAGE_ROUTE,
  MainRoutes,
} from './horizon-layout/MainLayout/Routes/types/routes';
import { Main } from './modules/Main';
import { Support } from './modules/Support';
import { Navigate } from './horizon-components/Navigate';
import { StudentsLanding, WizLanding } from './modules/Landing';

export const Router: React.FC = () => {
  return (
    <Suspense>
      <Routes>
        <Route path="/" element={<Navigate to={`/`} replace />} />
        <Route path={`/:lang` + MAIN_PAGE_ROUTE} element={<Main />} />
        <Route path={`/:lang` + MainRoutes.support} element={<Support />} />
        <Route
          path={`/:lang` + MainRoutes.students_landing}
          element={<StudentsLanding />}
        />
        <Route path={`/:lang` + MainRoutes.wiz_landing} element={<WizLanding />} />
        <Route path={`/:lang/*`} element={<MainLayout />} />
        <Route path="*" element={<Navigate to={MAIN_PAGE_ROUTE} replace />} />
      </Routes>
    </Suspense>
  );
};
