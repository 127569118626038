import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PinInput from 'react-pin-input';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { saveAssesTokenStorage, saveRefreshTokenStorage } from '../../../api';
import {
  authMe,
  checkCode,
  checkCodeResetPassword,
  createJWT,
  forgotPassword,
  setPrivateUseReferralCode,
  signUp,
} from '../../../api/auth';
import { getRedirectPath } from '../../../api/sessions';
import useFetch from '../../../hooks/useFetch';
import { useNavigate } from '../../../hooks/useNavigate';
import { MButton } from '../../../horizon-components/MButton/ui/MButton';
import {
  AuthRoutes,
  MAIN_PAGE_ROUTE,
  MainRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';
import { useUserContext } from '../../../providers';
import { AuthLayout } from '../../../widgets/AuthLayout';
import { sendSignUp, setUserID } from '../../../hooks/useAnalytics';

const CheckCode = () => {
  const navigate = useNavigate();
  const {
    state,
  }: {
    state: { email: string; password?: string };
  } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [isResendLoading, setResendIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [code, setCode] = useState<string>('');
  const { t } = useTranslation(['translation']);
  const [timer, setTimer] = useState(60);
  const { saveUser } = useUserContext();
  const redirectPath = getRedirectPath();
  const refCode = localStorage.getItem('refCode');

  const { fetchData: fetchRefCode } = useFetch((code: string) =>
    setPrivateUseReferralCode(code)
  );

  useEffect(() => {
    if (!!timer) {
      setTimeout(() => setTimer(timer - 1), 1000);
    }
  }, [timer]);

  useEffect(() => {
    if (!state.email) {
      navigate(-1);
    }
  }, [navigate, state]);

  const next = (completeCode: string) => {
    setError('');
    if (state.password) {
      setIsLoading(true);
      checkCode(state.email || '', completeCode)
        .then(() =>
          createJWT(state.email, state.password || '').then((data) => {
            saveAssesTokenStorage(data.access);
            saveRefreshTokenStorage(data.refresh);

            if (refCode) {
              fetchRefCode(refCode);
              localStorage.removeItem('refCode');
            }

            toast(t('profile.emailSuccessCode'), {
              type: 'success',
            });
            sendSignUp('Email');

            return authMe().then((data) => {
              saveUser(data);
              setUserID(data.guid);
              navigate(redirectPath || MAIN_PAGE_ROUTE);
            });
          })
        )
        .catch(() => setError(t('profile.notCode')))
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(true);
      checkCodeResetPassword(state.email, completeCode)
        .then(() =>
          navigate(MainRoutes.auth + AuthRoutes.change, {
            state: {
              email: state.email,
              code: completeCode,
            },
          })
        )
        .catch(() => setError(t('profile.notCode')))
        .finally(() => setIsLoading(false));
    }
  };

  const resend = () => {
    if (state.password) {
      setResendIsLoading(true);
      signUp(state.email, state.password)
        .then(() => {
          setTimer(60);
        })
        .finally(() => setResendIsLoading(false));
    } else {
      setResendIsLoading(true);
      forgotPassword(state.email)
        .then(() => {
          setTimer(60);
        })
        .finally(() => setResendIsLoading(false));
    }
  };

  const goToBack = () => navigate(-1);

  return (
    <AuthLayout
      title={state.password ? t('profile.confirmEmail') : t('profile.resetPass')}
      desc={
        state.password ? t('profile.confirmEmailInfo') : t('profile.resetPassInfo')
      }
      wrapperClassName={'lg:min-h-[600px]'}
      bgImage={state.password ? 'sign-up' : 'sign-in'}
    >
      <div className="mb-0 flex w-full items-center justify-center">
        <PinInput
          length={4}
          initialValue=""
          onChange={(value, index) => setCode(value)}
          type="numeric"
          inputMode="number"
          style={{
            width: '100%',
            maxWidth: '320px',
            margin: '0 auto',
            display: 'flex',
            justifyContent: 'space-around',
          }}
          inputStyle={{
            borderWidth: '2px',
            width: '65px',
            height: '65px',
            borderColor: '#E0E5F2',
            borderRadius: '16px',
            color: '#2B3674',
            fontSize: '36px',
          }}
          inputFocusStyle={{
            borderColor: '#4318FF',
            borderWidth: '3px',
          }}
          onComplete={(value) => next(value)}
          autoSelect={true}
          regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
          focus
        />
      </div>
      <div className="mt-4 flex flex-col gap-y-4 sm:mt-8">
        {!!error && (
          <p className={'text-center text-xs text-red-600 md:text-sm'}>{error}</p>
        )}
        <div className={'flex items-center justify-between gap-2'}>
          <MButton
            variant="secondary"
            color="grey"
            onClick={resend}
            disabled={isLoading || !!timer || isResendLoading}
            className={'w-[50%]'}
          >
            {!!timer ? `${timer}${t('s')}` : t('resend')}
          </MButton>
          <MButton
            variant="highlighted"
            color="primary"
            onClick={() => next(code)}
            disabled={isLoading}
            loading={isLoading}
            className={'w-[50%]'}
          >
            {t('profile.continue')}
          </MButton>
        </div>
        <p className={'text-[14px] font-[500] text-gray-900'}>
          {t('profile.clickToChange')}:{' '}
          <span onClick={goToBack} className={'cursor-pointer text-purple-500'}>
            {state.email}
          </span>
        </p>
      </div>
    </AuthLayout>
  );
};

export default CheckCode;
