/* eslint-disable jsx-a11y/iframe-has-title */
// @ts-nocheck
import React from 'react';
import ReactGA from "react-ga4";

export const GoogleTagManager: React.FC = () => {
  const GTMID = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;

  if (!GTMID){ // || window.hasOwnProperty('Telegram')) {
     return null;
  }
//   use same domain google analytics
//   ReactGA._loadGA(GTMID,  false, "/analytics/gtag")
  ReactGA.initialize([
    {
      trackingId: GTMID,
      gtagOptions: {
        // 'send_page_view': false,
      },
    },
  ]);

  return (
    <noscript>
      <iframe
        src={`https://www.googletagmanager.com/ns.html?id=${GTMID}`}
        style={{ display: 'none', visibility: 'hidden', height: 0, width: 0 }}
      ></iframe>
    </noscript>
  );
};
