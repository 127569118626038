import { useCallback, useState } from 'react';
import { createFeedComment } from '../../../../../api/weber/feed';

export const usePostComment = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSended, setIsSended] = useState<boolean>(false);

  const onComment = useCallback((post: number, comment: string) => {
    setIsLoading(true);
    createFeedComment(post, comment)
      .then(() => setIsSended(true))
      .catch(() => setIsSended(false))
      .finally(() => setIsLoading(false));
  }, []);

  return {
    onComment,
    isLoading,
    isSended,
    setIsSended,
  };
};
