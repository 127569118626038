import {
  AuthRoutes,
  MainRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';
import { Capacitor } from '@capacitor/core';

export const generateGoogleRedirectUri = () => {
  const redirectPage = AuthRoutes['google-signin-signup'];
  let redirectURI = `${process.env.REACT_APP_API_URL}${MainRoutes.auth}${redirectPage}/`;
  if (Capacitor.getPlatform() === 'android' || Capacitor.getPlatform() === 'ios') {
    redirectURI = `${process.env.REACT_APP_API_AUTHURL}${MainRoutes.auth}${redirectPage}/`;
  }
  return redirectURI;
};

export const generateAppleRedirectUri = () => {
  let redirectURI = `${process.env.REACT_APP_API_URL}${MainRoutes.auth}${AuthRoutes['apple-signin-signup']}/`;
  if (Capacitor.getPlatform() === 'ios') {
    redirectURI = `${process.env.REACT_APP_API_AUTHURL}${MainRoutes.auth}${AuthRoutes['apple-signin-signup']}/`;
  }
  return redirectURI;
};

export const generateTelegramRedirectUri = () => {
  // TODO
  let redirectURI = `${process.env.REACT_APP_API_URL}${MainRoutes.auth}/telegram-oauth-submit`;
  return redirectURI;
};
