import instance, { WEBER_API_URL } from '../instance';
import { TChat, TMessage } from '../../models/Chat';
import { ResponsePagination } from '../../models/Response';

export function getChats(chat_type?: string): Promise<TChat[]> {
  return instance()
    .get(`${WEBER_API_URL}/chat/`, {
      params: { chat_type },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getChatMessages(chat?: number): Promise<TMessage[]> {
  return instance()
    .get(`${WEBER_API_URL}/chat-message/`, {
      params: { chat },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function creatChatFile(chat: number, file: string): Promise<void> {
  const body = new FormData();
  body.append('chat', chat.toString());
  body.append('file', file);
  body.append('resourcetype', 'Message');
  return instance()
    .post(`${WEBER_API_URL}/chat-message/`, body)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function creatChatMessages(chat: number, body: string): Promise<void> {
  return instance()
    .post(`${WEBER_API_URL}/chat-message/`, { chat, body, resourcetype: 'Message' })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function creatChat(participant_guid: string): Promise<TChat> {
  return instance()
    .post(`${WEBER_API_URL}/chat/`, {
      participant_guid,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}
