// @ts-nocheck

import React from 'react';

export const YandexMetrica: React.FC = () => {
  const metricaId = process.env.REACT_APP_YANDEX_METRICA_ID;

  if (!metricaId){ // || window.hasOwnProperty('Telegram')) {
    return null;
  }

//   (function (m, e, t, r, i, k, a) {
//     m[i] =
//       m[i] ||
//       function () {
//         (m[i].a = m[i].a || []).push(arguments);
//       };
//     m[i].l = 1 * new Date();
//     for (var j = 0; j < document.scripts.length; j++) {
//       if (document.scripts[j].src === r) {
//         return;
//       }
//     }
//     // eslint-disable-next-line @typescript-eslint/no-unused-expressions, no-sequences
//     (k = e.createElement(t)),
//       (a = e.getElementsByTagName(t)[0]),
//       (k.async = 1),
//       (k.src = r),
//       a.parentNode.insertBefore(k, a);
//   })(window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym');

//   ym(metricaId, 'init', {
//     clickmap: true,
//     trackLinks: true,
//     accurateTrackBounce: true,
//     webvisor: true,
//   });

    (function () {
        window["ym"] = window["ym"] || function() {(window["ym"].a = window["ym"].a || []).push(arguments)};
        window["ym"].l = 1 * new Date();
        var initYMconf = function() {
            window.ym(metricaId, "init", {
                webvisor: true,
                clickmap: true,
                trackLinks: true,
                accurateTrackBounce: true,
                ecommerce: "dataLayer"
            })
        };

        var initYMconfE = function() {
            window.ym(metricaId, "init", {
                webvisor: false,
                clickmap: false,
                trackLinks: true,
                accurateTrackBounce: false,
                ecommerce: "dataLayer"
            })
        };

        function loadMCScript(src, okcallback, errcallback) {
            var s, t;
            s = document.createElement('script');
            s.async = !0;
            s.src = src;
            s.onload = function() {
                if (typeof okcallback === 'function') {
                    okcallback()
                }
            };
            s.onerror = function() {
                if (typeof errcallback === 'function') {
                    errcallback()
                }
            };
            t = document.getElementsByTagName('script')[0];
            t.parentNode.insertBefore(s, t)
        }

        setTimeout(function() {
            var cff2 = function() {
                loadMCScript("https://cfwosp.edres.com/metrika/ymtag.js", initYMconfE)
            }
            loadMCScript(`${process.env.REACT_APP_API_URL}/analytics/tag`, initYMconf, cff2)
        }, 100);
    })();

  return (
    <div>
      <img
        src={`https://mc.yandex.ru/watch/${metricaId}`}
        style={{ position: 'absolute', left: '-9999px' }}
        alt="metrika img"
      />
    </div>
  );
};
