import React from 'react';
import NoAvatar from '../../../assets/images/no-avatar.png';
import { useNavigate } from '../../../hooks/useNavigate';
import { Card } from '../../../horizon-components/Card/Card';
import {
  AuthRoutes,
  MainRoutes,
  ProfileRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';

export type Props = {
  wrapperClassName?: string;
  infoWrapperClassName?: string;
  avatarInfoSize?: 'sm' | 'lg';
  username: string;
  name: string;
  img?: string;
  actions?: React.ReactNode;
  guid: string;
};

export const NetworkingCard: React.FC<Props> = ({
  wrapperClassName,
  avatarInfoSize = 'sm',
  username,
  name,
  actions,
  infoWrapperClassName,
  img,
  guid,
}) => {
  const navigate = useNavigate();
  const goToProfile = () =>
    navigate(MainRoutes.auth + AuthRoutes.profile + ProfileRoutes.user + `/${guid}`);

  return (
    <Card
      extra={`p-[16px] rounded-[24px] flex !flex-row flex-wrap justify-between !shadow-[4px_4px_24px_4px_#7090B012] ${wrapperClassName}`}
    >
      <div className={`flex items-center ${infoWrapperClassName}`}>
        <img
          onClick={goToProfile}
          src={img || NoAvatar}
          className={`mr-[8px] cursor-pointer rounded-full object-cover ${
            avatarInfoSize === 'sm'
              ? 'h-[40px] w-[40px] min-w-[40px] lg:h-[48px] lg:w-[48px] lg:min-w-[48px]'
              : 'h-[40px] w-[40px] min-w-[40px] lg:h-[64px] lg:w-[64px] lg:min-w-[64px]'
          }`}
          alt={'av'}
        />
        <div className="truncate">
          <p
            onClick={goToProfile}
            className={`truncate ${
              avatarInfoSize === 'sm' ? 'text-[16px]' : 'text-[16px] lg:text-[18px]'
            } cursor-pointer font-bold text-[#2B3674]`}
          >
            {username}
          </p>
          <p
            onClick={goToProfile}
            className={`truncate ${
              avatarInfoSize === 'sm' ? 'text-[14px]' : 'text-[14px] lg:text-[16px]'
            } cursor-pointer font-[500] text-[#8F9BBA]`}
          >
            {name}
          </p>
        </div>
      </div>
      {!!actions && actions}
    </Card>
  );
};
