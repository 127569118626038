import React, { useState, useEffect } from 'react';

type Step = {
  stepNo: number;
  name?: string;
  highlighted?: boolean;
  selected?: boolean;
  completed?: boolean;
  description?: string;
};

type Props = { steps: Step[]; currentStep: number; action: (value: number) => void };

const Stepper: React.FC<Props> = ({ steps, currentStep, action }) => {
  const [newStep, setNewStep] = useState<Step[]>([]);

  const updateStep = (stepNumber: number, steps: Step[]) => {
    const newSteps = [...steps];
    let count = 0;
    while (count < newSteps.length) {
      //current step
      if (count === stepNumber) {
        newSteps[count] = {
          ...newSteps[count],
          highlighted: true,
          selected: true,
          completed: true,
        };
        count++;
      } else if (count < stepNumber) {
        //step completed
        newSteps[count] = {
          ...newSteps[count],
          highlighted: false,
          selected: true,
          completed: true,
        };
        count++;
      } else {
        //step pending
        newSteps[count] = {
          ...newSteps[count],
          highlighted: false,
          selected: false,
          completed: false,
        };
        count++;
      }
    }

    return newSteps;
  };

  useEffect(() => {
    const stepsState = steps.map((step, index) =>
      Object.assign(
        {},
        {
          stepNo: step.stepNo,
          description: step.name,
          completed: false,
          highlighted: index === 0,
          selected: index === 0,
        }
      )
    );

    const current = updateStep(currentStep - 1, stepsState);
    setNewStep(current);
  }, [steps, currentStep]);

  const stepsDisplay = newStep.map((step, index) => {
    return (
      <div
        key={index}
        className={index !== 0 ? 'flex w-full items-center' : 'flex items-center'}
      >
        <div
          className={`flex-auto border-t-[4px] border-navy-500 opacity-10 transition duration-500 ease-in-out`}
        />
        <div
          onClick={() => action(step.stepNo)}
          className="relative flex flex-col items-center hover:cursor-pointer"
        >
          <div
            className={`flex h-[20px] w-[20px] items-center justify-center rounded-full border-[3px] transition ${
              step.selected
                ? 'border-purple-300 bg-purple-500'
                : 'border-secondaryGrey-500 bg-secondaryGrey-600'
            }`}
          />
          <div
            className={`absolute top-0 mt-6 text-center text-xs font-semibold text-secondaryGrey-900 lg:text-base`}
          >
            {step.description}
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="mx-4 flex items-center justify-between md:p-4">
      {stepsDisplay}
    </div>
  );
};
export default Stepper;
