import React, { forwardRef, useImperativeHandle } from 'react';

type Props = {
  button: JSX.Element;
  children: JSX.Element;
  classNames?: string;
  animation?: string;
  isOpen?: boolean;
};

export type DropdownRefs = {
  close: () => void;
};

export const Dropdown = forwardRef<DropdownRefs, Props>((props, ref) => {
  const { button, children, classNames = '', animation, isOpen } = props;
  const wrapperRef = React.useRef(null);
  const [openWrapper, setOpenWrapper] = React.useState(false);

  useImperativeHandle(
    ref,
    () => ({
      close: () => {
        setOpenWrapper(false);
      },
    }),
    []
  );

  React.useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        wrapperRef.current &&
        // @ts-ignore
        !wrapperRef.current.contains(event.target)
      ) {
        setOpenWrapper(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef, setOpenWrapper]);

  React.useEffect(() => setOpenWrapper(false), [isOpen]);

  return (
    <div ref={wrapperRef} className="relative flex">
      <div className="flex" onMouseDown={() => setOpenWrapper(!openWrapper)}>
        {button}
      </div>
      <div
        className={`${classNames} absolute z-10 ${
          animation
            ? animation
            : 'origin-top-right transition-all duration-300 ease-in-out'
        } ${openWrapper ? 'scale-100' : 'scale-0'}`}
      >
        {children}
      </div>
    </div>
  );
});
