import { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { trimIfLarger } from '../../utils/trimIfLarger';
import { useLocation } from 'react-router-dom';

export interface SEOProps {
  title?: string;
  desc?: string;
  loading?: boolean;
  bannerUrl?: string;
  hideTitleSuffix?: boolean;
  canonicalParams?: string[];
}

function SEO({
  title,
  desc,
  loading,
  bannerUrl = 'https://edres.com/icons/prev.png',
  hideTitleSuffix = false,
  canonicalParams = [],
}: SEOProps) {
  const { t } = useTranslation();
  const location = useLocation();

  const href = useMemo(() => {
    const url = new URL(
      window.location.origin + location.pathname + location.search
    );
    url.searchParams.forEach((val, key) => {
      if (!canonicalParams.includes(key)) {
        url.searchParams.delete(key);
      }
    });
    return url.toString();
  }, [location, canonicalParams]);

  const loadingText = `${t('loading')}...`;

  let finalTitle = loading ? loadingText : trimIfLarger(title || '', 50);
  if (!hideTitleSuffix) {
    finalTitle = `${finalTitle} | EdRes`;
  }
  const finalDesc = loading ? loadingText : trimIfLarger(desc || '', 157);

  return (
    <>
      {/* TITLE */}
      {finalTitle && (
        <Helmet>
          <title>{finalTitle}</title>
          <meta name="apple-mobile-web-app-title" content={finalTitle} />
          <meta property="og:title" content={finalTitle} />
          <meta name="twitter:title" content={finalTitle} />
        </Helmet>
      )}

      {/* DESCRIPTION */}
      {finalDesc && (
        <Helmet>
          <meta name="description" content={finalDesc} />
          <meta property="og:description" content={finalDesc} />
          <meta name="twitter:description" content={finalDesc} />
        </Helmet>
      )}

      {/* BANNER */}
      {bannerUrl && (
        <Helmet>
          <meta property="og:image" content={bannerUrl} />
          <meta property="twitter:image" content={bannerUrl} />
        </Helmet>
      )}

      {/* CANONICAL */}
      <Helmet>
        <link rel="canonical" href={href} />
      </Helmet>
    </>
  );
}

export default SEO;
