import classNames from 'classnames';
import React from 'react';
import { ReactComponent as PrevIcon } from '../assets/icons/prev.svg';
import { ReactComponent as NextIcon } from '../assets/icons/next.svg';
import './Pagination.scss';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (pageNumber: number) => void;
  maxVisiblePages?: number;
  showPrevNext?: boolean;
  rounded?: boolean;
}

export const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
  maxVisiblePages = window.innerWidth <= 360 ? 2 : 3,
  showPrevNext = true,
  rounded = false,
}) => {
  const renderPageButtons = () => {
    const buttons = [];
    const startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        buttons.push(
          <button
            key={i}
            onClick={() => onPageChange(i)}
            className={i === currentPage ? 'active' : ''}
          >
            {i}
          </button>
        );
      }
    } else {
      for (let i = startPage; i <= endPage; i++) {
        buttons.push(
          <button
            key={i}
            onClick={() => onPageChange(i)}
            className={i === currentPage ? 'active' : ''}
          >
            {i}
          </button>
        );
      }

      if (startPage > 1) {
        if (startPage > 2) {
          buttons.unshift(<span key="ellipsis-start">...</span>);
        }
        buttons.unshift(
          <button key={1} onClick={() => onPageChange(1)}>
            1
          </button>
        );
      }

      if (endPage < totalPages) {
        if (endPage < totalPages - 1) {
          buttons.push(<span key="ellipsis-end">...</span>);
        }
        buttons.push(
          <button key={totalPages} onClick={() => onPageChange(totalPages)}>
            {totalPages}
          </button>
        );
      }
    }

    return buttons;
  };

  return (
    <div className={classNames('pagination', { rounded })}>
      {showPrevNext && (
        <button
          key="prev"
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="prev"
        >
          <PrevIcon />
        </button>
      )}
      {renderPageButtons()}
      {showPrevNext && (
        <button
          key="next"
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="next"
        >
          <NextIcon />
        </button>
      )}
    </div>
  );
};
