import classNames from 'classnames';
import { Link } from '../../../horizon-components/Link';
import './Breadcrumbs.scss';

export interface Bread {
  title?: string;
  url?: string;
}

interface BreadcrumbsProps {
  breads?: Bread[];
  className?: string;
}

export const Breadcrumbs = ({ breads, className }: BreadcrumbsProps) => {
  return (
    <div className={classNames('Breadcrumbs', className)}>
      {breads?.map(
        (bread, idx) =>
          bread.title &&
          bread.url && (
            <Link to={bread.url} key={idx}>
              {bread.title}
            </Link>
          )
      )}
    </div>
  );
};
