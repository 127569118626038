import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from '../../../horizon-components/Link';
import { MButton } from '../../../horizon-components/MButton';
import { MCheckbox } from '../../../horizon-components/MCheckbox/MCheckbox';
import {
  AuthRoutes,
  MainRoutes,
  ProfileRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';
import { UserCommunityRole } from '../../../models';
import { GetUserData } from '../../../utils/getUserData';
import { AvatarTitleTextCard } from '../../AvatarTitleTextCard';
import './EditRoleModal.scss';

interface EditRoleModalProps {
  className?: string;
  onSave?: (role: UserCommunityRole) => void;
  onDemote?: () => void;
  user?: GetUserData;
  role?: UserCommunityRole | null;
}

export const EditRoleModal = ({
  className,
  onSave,
  onDemote,
  user,
  role,
}: EditRoleModalProps) => {
  const { t } = useTranslation(['translation']);
  const [checked, setChecked] = useState<UserCommunityRole | null>(null);

  useEffect(() => {
    role && setChecked(role);
  }, [role]);

  return (
    <div className={classNames('EditRoleModal', className)}>
      <Link
        to={MainRoutes.auth + AuthRoutes.profile + ProfileRoutes.user + `/${''}`}
      >
        <AvatarTitleTextCard
          title={user?.username}
          text={[t('communities.Choose_one_of_the_roles')]}
          img={user?.image}
        />
      </Link>

      <div className="EditRoleModal__wrapper">
        <div className="flex w-full items-start gap-2">
          <MCheckbox
            type="radio"
            onChange={() => setChecked(UserCommunityRole.ADMIN)}
            checked={checked === UserCommunityRole.ADMIN}
          />
          <div>
            <h4>{t('communities.Admin')}</h4>
            <p>{t('communities.Admin_description')}</p>
          </div>
        </div>
        <div className="flex w-full items-start gap-2">
          <MCheckbox
            type="radio"
            onChange={() => setChecked(UserCommunityRole.MODERATOR)}
            checked={checked === UserCommunityRole.MODERATOR}
          />
          <div>
            <h4>{t('communities.Moderator')}</h4>
            <p>{t('communities.Moderator_description')}</p>
          </div>
        </div>
        <div className="flex w-full items-start gap-2">
          <MCheckbox
            type="radio"
            onChange={() => setChecked(UserCommunityRole.EDITOR)}
            checked={checked === UserCommunityRole.EDITOR}
          />
          <div>
            <h4>{t('communities.Editor')}</h4>
            <p>{t('communities.Editor_description')}</p>
          </div>
        </div>
      </div>

      <div className="flex gap-2">
        {role ? (
          <>
            <MButton
              variant="outline"
              color="danger"
              onClick={onDemote}
              className="w-full"
            >
              {t('buttons.Demote')}
            </MButton>
            <MButton
              variant="highlighted"
              color="primary"
              onClick={() => checked && onSave?.(checked)}
              className="w-full"
            >
              {t('buttons.Save')}
            </MButton>
          </>
        ) : (
          <MButton
            variant="highlighted"
            color="primary"
            onClick={() => checked && onSave?.(checked)}
            className="w-full"
          >
            {t('buttons.Assign')}
          </MButton>
        )}
      </div>
    </div>
  );
};
