import { useRef, useState } from 'react';
import { OrderBy } from '../../../api/courseReview';
import { HButton } from '../../../horizon-components/Button/HButton';
import './ButtonsFilter.scss';

interface ButtonsFilterProps {
  buttons: { id: OrderBy; title: string }[];
  onFilter?: (filterId: OrderBy) => void;
  active?: string;
}

export const ButtonsFilter = ({
  buttons = [],
  active = '',
  onFilter,
}: ButtonsFilterProps) => {
  const tabContainerRef = useRef<HTMLDivElement | null>(null);
  const [startX, setStartX] = useState<number | null>(null);
  const [scrollLeft, setScrollLeft] = useState<number>(0);

  const handleTouchStart = (e: React.TouchEvent) => {
    const touch = e.touches[0];
    setStartX(touch.clientX);
    if (tabContainerRef.current) {
      setScrollLeft(tabContainerRef.current.scrollLeft);
    }
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (!startX || !tabContainerRef.current) return;
    const touch = e.touches[0];
    const x = touch.clientX;
    const walk = (startX - x) * 2; // Скорость прокрутки
    tabContainerRef.current.scrollLeft = scrollLeft + walk;
  };

  return (
    <div
      className="ButtonsFilter"
      ref={tabContainerRef}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
    >
      {buttons?.map((button) => (
        <HButton
          size="sm"
          key={button.id}
          onClick={() => onFilter?.(button.id)}
          color={active === button.id ? 'secondary' : 'light'}
          variant={active === button.id ? 'outline' : 'default'}
          className="!rounded-full !text-secondary-grey-900"
        >
          {button.title}
        </HButton>
      ))}
    </div>
  );
};
