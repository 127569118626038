import React from 'react';
import {
  Navigate as LanguageNavigate,
  NavigateProps as LanguageNavigateProps,
} from 'react-router-dom';
import { useLanguage } from '../../providers/LanguageProvider';

interface NavigateProps extends Omit<LanguageNavigateProps, 'to'> {
  to: string;
}

export const Navigate: React.FC<NavigateProps> = ({ to, ...props }) => {
  const { language } = useLanguage();

  const prefixedTo = `/${language}${to.startsWith('/') ? to : `/${to}`}`;

  return <LanguageNavigate to={prefixedTo} {...props} />;
};
