import instance from './instance';
import {
  DigitalPass,
  DigitalPassCategory,
  DigitalPassCategoryDetail,
  DigitalPassLevel,
  DigitalPassSchool,
  PurchasedDigitalPass,
  PurchasedDigitalPassDetail,
  TDPGroup,
  UnitItem,
  WeeklyActivity,
} from '../models/DigitalPass';
import { ResponsePagination } from '../models/Response';
import { Grade } from '../models/Course';

export enum DigitalPassMode {
  DISCOVER = 'DISCOVER',
  MY_PASSES = 'MY_PASSES',
}

export function getDigitalPassCategories(
  page: number,
  mode: DigitalPassMode
): Promise<ResponsePagination<DigitalPassCategory>> {
  return instance()
    .get(`/v2/digital-passes/digital-pass-category/`, { params: { page, mode } })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getDigitalPassCategoryById(
  id: number
): Promise<DigitalPassCategoryDetail> {
  return instance()
    .get(`/v2/digital-passes/digital-pass-category/${id}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getDigitalPassCategoriesByCourse(
  course: number,
  ordering?: string,
  page?: number
): Promise<ResponsePagination<DigitalPassCategory>> {
  return instance()
    .get(`/v2/digital-passes/digital-pass-category-by-course/`, {
      params: { course, ordering, page },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getDigitalPassByCourseId(id: number): Promise<DigitalPass> {
  return instance()
    .get(`/digital-passes/digital-passe-by-course/${id}/`)
    .then((response) => response.data.digital_passes)
    .catch((error) => {
      throw error;
    });
}

export function subscribeFirstLevel(id: number[]): Promise<DigitalPass> {
  return instance()
    .post(`/v2/digital-passes/digital-pass-purchase/`, { digital_pass: id })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getPurchasedDigitalPassList(): Promise<
  ResponsePagination<PurchasedDigitalPass>
> {
  return instance()
    .get(`/digital-passes/purchased-digital-pass/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getCategoryLevels(categoryId: number): Promise<DigitalPassLevel[]> {
  return instance()
    .get(`/v2/digital-passes/digital-pass/${categoryId}/get-subject-levels/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getDigitalPass(
  digital_pass_category?: number
): Promise<ResponsePagination<DigitalPassSchool>> {
  return instance()
    .get(`/v2/digital-passes/digital-pass/`, {
      params: { digital_pass_category },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getUserGPA() {
  return instance()
    .get(`/v2/gpa/user/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getLevelUnits(
  digitalPassId: number,
  digitalPassCategoryId: number | null,
  subjectLevelId?: number | null
): Promise<UnitItem[]> {
  return instance()
    .get(`/get-units-list-with-progress/`, {
      params: {
        subject_level: subjectLevelId,
        digital_pass: digitalPassId,
        digital_pass_category: digitalPassCategoryId,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getPurchasedDigitalPassById(
  id: number
): Promise<PurchasedDigitalPassDetail> {
  return instance()
    .get(`/digital-passes/purchased-digital-pass/${id}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function levelUp(id: number): Promise<PurchasedDigitalPassDetail> {
  return instance()
    .post(`/digital-passes/purchased-digital-pass/${id}/level_up/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function addFavoriteDigitalPass(
  digitalPassId: number,
  digitalPassCategory: number
): Promise<void> {
  return instance()
    .post(`/digital-passes/digital-pass-category-favorites/`, {
      digital_pass: digitalPassId,
      digital_pass_category: digitalPassCategory,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function deleteFavoriteDigitalPass(digitalPassId: number): Promise<void> {
  return instance()
    .delete(`/digital-passes/digital-pass-category-favorites/${digitalPassId}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getFavoritesDigitalPass(
  page?: number
): Promise<ResponsePagination<DigitalPass>> {
  return instance()
    .get(`/digital-passes/digital-pass-category-favorites/`, { params: { page } })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getDPGroupLP(): Promise<TDPGroup[]> {
  return instance()
    .get(`/digital-passes-group/landing-page/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getWeeklyActivity(id: number): Promise<WeeklyActivity> {
  return instance()
    .get(`/weekly-activity/${id}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export type Performance = {
  average_gpa: number;
  completed_course_count: number;
  course_count: number;
};

export function getPerformanceDashboard(
  id: number,
  period: string
): Promise<Performance> {
  return instance()
    .get(`/perfomance-dashboard/${id}/`, {
      params: { period },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export type GraphData = {
  data: number[];
  labels: string[];
};

export function getGraphPerformance(id: number, period: string): Promise<GraphData> {
  return instance()
    .get(`/graph-perfomance/by-period/${id}/`, {
      params: { period },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export type ProgressDP = {
  id: number;
  name: string;
  subject_levels: {
    completed_units_count: number;
    id: number;
    is_completed: boolean;
    title: string;
    units: {
      status: { grade: Grade; is_completed: boolean };
      title: string;
    }[];
  }[];
};

export function getProgress(id: number): Promise<ProgressDP[]> {
  return instance()
    .get(`/course-creation/digital-pass/progress/`, {
      params: {
        digital_pass_category: id,
      },
    })
    .then((response) => response.data.data)
    .catch((error) => {
      throw error;
    });
}
