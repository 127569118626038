import { ReactNode, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { MobileAppBanner } from '../../components/MobileAppBanner/MobileAppBanner';
import {
  AuthRoutes,
  MainRoutes,
} from '../../horizon-layout/MainLayout/Routes/types/routes';
import CFPlayer from './LandingCFPlayer';
import InfoCard from './LandingInfoCard';
import ReviewVideo from './LandingReviewVideo';
import ReviewCard from './LandingReviewCard';
import InstagramProfile from './LandingInstagramProfile';
import TikTokProfile from './LandingTikTokProfile';
// import YouTubProfile from './StudentsLandingYouTubeProfile';
// import SocialLink from './StudentsLandingSocialLink';
import { ReactComponent as NewLogo } from '../../assets/icons/new-logo.svg';
import { ReactComponent as TokenIcon } from '../../assets/students-landing/token.svg';
import { ReactComponent as ShareIcon } from '../../assets/students-landing/share.svg';
// import { ReactComponent as EmailIcon } from '../../assets/students-landing/email.svg';
// import { ReactComponent as TelegramIcon } from '../../assets/students-landing/telegram.svg';
import { ReactComponent as BulletsRegistrationIcon } from '../../assets/students-landing/bullets/registration.svg';
import { ReactComponent as BulletsLikeIcon } from '../../assets/students-landing/bullets/like.svg';
import { ReactComponent as BulletsMoneyIcon } from '../../assets/students-landing/bullets/money.svg';
import { ReactComponent as BulletsPlatformIcon } from '../../assets/students-landing/bullets/platform.svg';
import { ReactComponent as BulletsHatIcon } from '../../assets/students-landing/bullets/hat.svg';
import { ReactComponent as BulletsCupIcon } from '../../assets/students-landing/bullets/cup.svg';
import { ReactComponent as BulletsWatchIcon } from '../../assets/students-landing/bullets/watch.svg';
import iphone from '../../assets/students-landing/iphone.png';
import avatar1 from '../../assets/students-landing/rewiew_1.jpg';
import avatar2 from '../../assets/students-landing/rewiew_2.jpg';
import avatar3 from '../../assets/students-landing/rewiew_3.jpg';
import avatar4 from '../../assets/students-landing/rewiew_4.jpg';
import './styles.scss';
import { sendLandingEvent } from '../../hooks/useAnalytics';

const DEFAULT_LINK = MainRoutes.auth + AuthRoutes.signup;
// const EMAIL = 'support@edres.com';
// const TELEGRAM = 'https://t.me/edres_com';
const INSTAGRAM = 'edres_com';
const TIKTOK = 'edreslive';
// const YOUTUBE = 'edres_com';

function useCenterScroll() {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) return;
    ref.current.scrollLeft =
      (ref.current.scrollWidth - ref.current.getBoundingClientRect().width) / 2;
  }, []);

  return ref;
}

export interface LandingProps {
  analyticsPrefix: string;
  title: string;
  subtitle: ReactNode;
  heroVideoId: string;
  howItWorks: string;
  whatYouGet1: string;
  whatYouGet2: string;
}

function Landing({
  analyticsPrefix,
  title,
  subtitle,
  heroVideoId,
  howItWorks,
  whatYouGet1,
  whatYouGet2,
}: LandingProps) {
  const socialsRef = useCenterScroll();
  const reviewRef = useCenterScroll();

  const sendAnalytics = (event: string) => sendLandingEvent(analyticsPrefix, event);
  const sendWatchReview = () => sendAnalytics('watch_review');

  return (
    <div className="LandingPage">
      <MobileAppBanner />

      <nav className="LandingPage__nav">
        <div className="LandingPage__container LandingPage__nav-wrapper">
          <Link
            to={DEFAULT_LINK}
            className="LandingPage__nav-logo"
            onClick={() => sendAnalytics('nav_logo')}
          >
            <NewLogo />
          </Link>
          <Link
            to={DEFAULT_LINK}
            className="LandingPage__btn small"
            onClick={() => sendAnalytics('nav_login')}
          >
            Войти
          </Link>
        </div>
      </nav>

      <header className="LandingPage__container LandingPage__hero">
        <div className="LandingPage__hero-text">
          <h2 className="big">{title}</h2>
          <p>{subtitle}</p>
        </div>
        <div className="LandingPage__hero-video">
          <div className="LandingPage__hero-video__wrapper">
            <img src={iphone} alt="iPhone Mock" />
            <div className="LandingPage__hero-video__iframe-wrapper">
              <CFPlayer
                title="Hero block about EdRes"
                videoId={heroVideoId}
                posterHeight={600}
                loop
                autoplay
                muted
                onClick={() => sendAnalytics('click_video')}
              />
            </div>
          </div>
        </div>
        <Link
          to={DEFAULT_LINK}
          className="LandingPage__btn big"
          onClick={() => sendAnalytics('main_btn')}
        >
          Хочу много токенов!
        </Link>
      </header>

      <section className="LandingPage__container LandingPage__info">
        <InfoCard
          to={DEFAULT_LINK}
          title="Как это работает?"
          items={[
            { text: 'Регистрация на платформе', Icon: BulletsRegistrationIcon },
            {
              text: howItWorks,
              Icon: BulletsWatchIcon,
            },
            { text: 'Получение токенов по результатам', Icon: BulletsLikeIcon },
            {
              text: 'Обмен токенов после листинга EdRes',
              Icon: BulletsMoneyIcon,
            },
          ]}
          btnText="Зарегистрироваться"
          onClick={() => sendAnalytics('info_signup')}
        />
        <InfoCard
          to={DEFAULT_LINK}
          title="Что ты получаешь?"
          items={[
            {
              text: whatYouGet1,
              Icon: BulletsHatIcon,
            },
            {
              text: 'Удобная платформа + мобильное приложение',
              Icon: BulletsPlatformIcon,
            },
            {
              text: whatYouGet2,
              Icon: BulletsCupIcon,
            },
            {
              text: 'Реальные USDT после листинга токенов EdRes, которые можно потратить на&nbsp;что&nbsp;угодно!',
              Icon: BulletsMoneyIcon,
            },
          ]}
          btnText={
            <>
              Забрать бонус 600 <TokenIcon /> за регистрацию!
            </>
          }
          onClick={() => sendAnalytics('info_bonus')}
        />
      </section>

      <section className="LandingPage__container LandingPage__review">
        <h2>Отзывы о платформе</h2>
        <div
          ref={reviewRef}
          className="LandingPage__review-videos LandingPage__no-scrollbar"
        >
          <ReviewVideo
            id="f58b0ac6032db5730dadf8ca5492c739"
            onClick={sendWatchReview}
          />
          <ReviewVideo
            id="14d8fb374e8860ac114418e07fe0eb34"
            onClick={sendWatchReview}
          />
          <ReviewVideo
            id="323b5554d94f55d9d4dd82efd66f912a"
            onClick={sendWatchReview}
          />
        </div>
        <div className="LandingPage__review-cards LandingPage__no-scrollbar">
          <ReviewCard
            name="Алимжан"
            text="EdRes просто топ! Уроки вроде как обычные, но когда понимаешь, что за это еще и токены капают – вообще кайф! Теперь коплю, жду листинга, чтобы крипту в карман положить."
            avatarUrl={avatar1}
          />
          <ReviewCard
            name="Биржан"
            text="EdRes — это прям находка! Уроки школьной программы нормальные, но как только услышала про токены, сразу стало интересней. Уже мечтаю, на что крипту потом потрачу!"
            avatarUrl={avatar3}
          />
          <ReviewCard
            name="Айдана"
            text="Не думал, что учёба может быть такой выгодной! Сидишь на уроке, смотришь видосы и параллельно токены набиваешь. Это лучше, чем просто тупо сидеть в школе!"
            avatarUrl={avatar2}
          />
          <ReviewCard
            name="Жанель"
            text="Крутая платформа! Смотрю уроки, как всегда, а тут еще и токены за это дают. Теперь учиться стало реально полезно, жду не дождусь, когда можно будет их в крипту конвертить!"
            avatarUrl={avatar4}
          />
        </div>
      </section>

      <section className="LandingPage__container LandingPage__socials">
        <div className="LandingPage__socials-sub">
          <h2 className="big">Подпишись на наши соцсети!</h2>
          <p>
            Мы постоянно развиваемся, поэтому будем рады вашим отзывам и предложениям
            по&nbsp;улучшению нашей платформы
          </p>
          <ShareIcon />
        </div>
        <div
          ref={socialsRef}
          className="LandingPage__socials-embeds LandingPage__no-scrollbar"
        >
          <InstagramProfile
            profile={INSTAGRAM}
            onClick={() => sendAnalytics('profile_inst')}
          />
          <TikTokProfile
            profile={TIKTOK}
            onClick={() => sendAnalytics('profile_tt')}
          />
          {/* <YouTubProfile
            profile={YOUTUBE}
            onClick={() => sendAnalytics('profile_yt')}
          /> */}
        </div>
        {/* <div className="LandingPage__socials-cards">
          <SocialLink href={`mailto:${EMAIL}`} Icon={EmailIcon} text="Email" />
          <SocialLink href={TELEGRAM} Icon={TelegramIcon} text="Telegram" />
        </div> */}
        <Link
          to={DEFAULT_LINK}
          className="LandingPage__btn big"
          onClick={() => sendAnalytics('footer_btn')}
        >
          Зарегистрироваться на EdRes
        </Link>
      </section>

      <footer className="LandingPage__footer"></footer>
    </div>
  );
}

export default Landing;
