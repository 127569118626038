import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/modal';
import React from 'react';
import './SimpleModal.scss';

type Props = {
  isOpen: boolean;
  onClose(): void;
  children: JSX.Element;
};

export const SimpleModal: React.FC<Props> = ({ isOpen, onClose, children }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay className="SimpleModal__bg" onClick={onClose} />
      <ModalContent className="SimpleModal__content">
        <ModalCloseButton className="SimpleModal__close" />
        <ModalBody className={'SimpleModal__body'}>{children}</ModalBody>
      </ModalContent>
    </Modal>
  );
};
