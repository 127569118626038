import React from 'react';
import { useTranslation } from 'react-i18next';
import NoAvatar from '../../../../assets/images/no-avatar.png';
import { useNavigate } from '../../../../hooks/useNavigate';
import { Card } from '../../../../horizon-components/Card/Card';
import {
  AuthRoutes,
  MainRoutes,
  ProfileRoutes,
} from '../../../../horizon-layout/MainLayout/Routes/types/routes';
import { TCommunity } from '../../../../models/Community';
import { getUserData } from '../../../../utils/getUserData';

type Props = {
  community: TCommunity;
};

export const CommunityContacts: React.FC<Props> = ({ community }) => {
  const user = getUserData(community.owner);
  const { t } = useTranslation(['translation']);
  const navigate = useNavigate();

  const goToProfile = () =>
    navigate(
      MainRoutes.auth + AuthRoutes.profile + ProfileRoutes.user + `/${user?.guid}`
    );

  return (
    <>
      <Card
        extra={`lg:p-[16px] p-[12px] lg:rounded-[30px] rounded-[24px] flex flex-wrap justify-between !shadow-[4px_4px_24px_4px_#7090B012]`}
      >
        <p className={'mb-[8px] text-[16px] font-[600] text-secondaryGrey-900'}>
          {t('communities.Contacts')}
        </p>
        <div className={`flex items-center`}>
          <img
            onClick={goToProfile}
            src={user.image || NoAvatar}
            className={`mr-[8px] h-[40px] w-[40px] min-w-[40px] cursor-pointer rounded-full object-cover`}
            alt={'av'}
          />
          <div>
            <p
              onClick={goToProfile}
              className={`cursor-pointer text-[16px] font-bold text-[#2B3674]`}
            >
              {user.username}
            </p>
            <p
              onClick={goToProfile}
              className={`cursor-pointer text-[14px] font-[500] text-[#8F9BBA]`}
            >
              {`${user.first_name} ${user.last_name}`}
            </p>
          </div>
        </div>
      </Card>
      {!!community?.group_links?.length && (
        <Card
          extra={`lg:p-[16px] p-[12px] lg:rounded-[30px] rounded-[24px] flex flex-wrap justify-between !shadow-[4px_4px_24px_4px_#7090B012]`}
        >
          <p className={'mb-[8px] text-[16px] font-[600] text-secondaryGrey-900'}>
            {t('communities.Links')}
          </p>
          <div className={'flex flex-col gap-[8px]'}>
            {community.group_links.map((item, i) => (
              <a
                className={'text-[14px] font-[600] text-purple-500'}
                href={item.link}
                key={i}
              >
                {item.name}
              </a>
            ))}
          </div>
        </Card>
      )}
    </>
  );
};
