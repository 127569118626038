import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AppleIcon } from '../../../assets/icons/apple.svg';
import { generateAppleRedirectUri } from '../SignIn/utils';

export type Props = {
  signIn: boolean;
};

export const AppleAuth: React.FC<Props> = ({ signIn }) => {
  const [appleAuthUrl, setAppleAuthUrl] = useState('');
  const { t } = useTranslation(['translation']);

  const openCapacitorSite = async () => {
    await Browser.open({
      url: appleAuthUrl,
      presentationStyle: 'popover',
    });
  };

  const clientId = process.env.REACT_APP_APPLE_OAUTH2_CLIENT_ID;

  useEffect(() => {
    (async () => {
      if (!clientId) return;
      const params = {
        client_id: clientId,
        response_type: 'code id_token',
        scope: '',
        response_mode: 'fragment',
        redirect_uri: generateAppleRedirectUri(),
      };
      let paramsString = new URLSearchParams(params)
        .toString()
        .replaceAll('+', '%20');
      let oAuthUrl = `https://appleid.apple.com/auth/authorize?${paramsString}`;
      setAppleAuthUrl(oAuthUrl);
    })();
  }, [clientId]);

  if (!clientId) {
    console.warn('Apple client id not found');
    return null;
  }
  if (Capacitor.getPlatform() === 'ios') {
    return (
      <div onClick={openCapacitorSite}>
        <div className="flex h-[44px] w-full items-center justify-center gap-2 rounded-xl bg-black-10 hover:cursor-pointer dark:bg-navy-700 md:h-[48px]">
          <div className="rounded-full text-xl">
            <AppleIcon className="h-[20px] w-[20px]" />
          </div>
          <p className="text-sm font-medium text-white">
            {signIn ? t('auth.signInApple') : t('auth.signUpApple')}
          </p>
        </div>
      </div>
    );
  }
  return (
    <a href={appleAuthUrl}>
      <div className="flex h-[44px] w-full items-center justify-center gap-2 rounded-xl bg-black-10 hover:cursor-pointer dark:bg-navy-700 md:h-[48px]">
        <div className="rounded-full text-xl">
          <AppleIcon className="h-[20px] w-[20px]" />
        </div>
        <p className="text-sm font-medium text-white">
          {signIn ? t('auth.signInApple') : t('auth.signUpApple')}
        </p>
      </div>
    </a>
  );
};
