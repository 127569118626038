import React from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from '../../../../components/Loader';
import { Card } from '../../../../horizon-components/Card/Card';
import CircularProgress from '../../../../horizon-components/CircularProgress/CircularProgress';
import { ProfitEstimation } from '../../../../models/Performance';
import './CoursePerfomanceProfit.scss';

type Props = {
  data?: ProfitEstimation;
  isLoading?: boolean;
};

export const CoursePerfomanceProfit: React.FC<Props> = ({
  data,
  isLoading = false,
}) => {
  const { t } = useTranslation(['translation']);
  return (
    <Card className="CoursePerfomanceProfit">
      <div className="CoursePerfomanceProfit__head">
        <h3>{t('edu.Profit_Estimation')}</h3>
        <p>{t('edu.Discover_your_profit_and_learn_more_about_your_business')}</p>
      </div>
      {/* Circular progressbar */}
      <div className="mx-auto flex h-32 w-32 items-center justify-center">
        {isLoading ? (
          <Loader />
        ) : (
          <CircularProgress
            title={t('edu.Conversion')}
            percentage={Number(data?.conversion)}
          />
        )}
      </div>

      {/* profit */}
      <Card className="CoursePerfomanceProfit__foot">
        <div className="CoursePerfomanceProfit__card">
          <p>{t('edu.Enrolls')}</p>
          <h3 className="!font-bold">{data?.enrolls}</h3>
        </div>
        <div className="h-full w-[1px] bg-secondary-grey-300" />
        <div className="CoursePerfomanceProfit__card">
          <p>{t('edu.Passes')}</p>
          <h3 className="!font-bold">{data?.passes}</h3>
        </div>
      </Card>
    </Card>
  );
};
