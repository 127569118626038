import { FC } from 'react';
import { ShareSocialButton } from './ShareSocialButton';
import { ReactComponent as Icon } from '../../../assets/icons/share/dots.svg';
import { ButtonsProps } from '../types';
import { mergeTextAndUrl } from '../utils';
import { useTranslation } from 'react-i18next';
import getPlatform from '../../../utils/getPlatform';
import { PlatformsEnum } from '../../../entities/Platforms/platforms';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { Share as CapacitorShare } from '@capacitor/share';

function getFileExtFromBase64(base64: string) {
  let i = 0;
  while (base64[i] !== '/') i++;
  const start = ++i;
  while (base64[i] !== ';') i++;
  return base64.slice(start, i);
}

async function saveFileBase64(base64: string) {
  const fileName = `${Date.now()}.${getFileExtFromBase64(base64)}`;
  const res = await Filesystem.writeFile({
    path: fileName,
    directory: Directory.Documents,
    data: base64,
  });
  return res.uri;
}

const ShareOtherButton: FC<ButtonsProps> = ({ loading, onSocialClick }) => {
  const { t } = useTranslation(['translation']);
  const platform = getPlatform();
  const isWeb = [PlatformsEnum.WEB, PlatformsEnum.TELEGRAM].includes(platform);

  const onClick = async (url: string, text?: string, fileBase64?: string) => {
    if (isWeb) {
      if (!navigator.share) return;
      await navigator.share({ title: 'EdRes', text, url }).catch(() => {});
    } else {
      let files;
      if (fileBase64 && platform === PlatformsEnum.ANDROID) {
        const file = await saveFileBase64(fileBase64);
        files = [];
        files.push(file);
      }
      await CapacitorShare.share({ text: mergeTextAndUrl(url, text), files }).catch(
        () => {}
      );
    }
  };

  return (
    <ShareSocialButton
      text={t('buttons.other')}
      Icon={Icon}
      loading={loading}
      onClick={onSocialClick('Other', onClick)}
    />
  );
};

export default ShareOtherButton;
