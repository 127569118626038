import React from 'react';
import { useTranslation } from 'react-i18next';
import { MButton } from '../../../../horizon-components/MButton/ui/MButton';
import { Grade, TSummary } from '../../../../models/Course';
import { ReactComponent as RestartIcon } from '../../../../assets/icons/course/restart.svg';

type Props = {
  summaryResult: TSummary;
  rateCourse: () => void;
  restartUnit: (unitId: number) => void;
};

export const SummaryResultInfo: React.FC<Props> = ({
  summaryResult,
  rateCourse,
  restartUnit,
}) => {
  const { t } = useTranslation(['translation']);
  const rowClasses = 'flex flex-row justify-between items-center';
  const topicClasses =
    'text-[14px] font-medium text-secondary-dark-grey-900 md:text-[16px]';
  const resultContainerClasses =
    'rounded-[8px] md:px-[16px] md:py-[12px] px-[12px] py-[8px] font-semibold md:text-[14px] text-[12px]';
  const resultDefaultClasses = 'bg-[#C9FBD5] text-[#009B73]';
  const hrClasses = 'border-[#E1E9F8] w-full border-1';

  const gradeContentMap: Record<Grade, string> = {
    A: 'bg-primary-green-100 text-primary-green-600',
    B: 'bg-primary-green-100 text-primary-green-600',
    C: 'bg-additional-orange-100 text-additional-orange-600',
    D: 'bg-additional-orange-100 text-additional-orange-600',
    F: 'bg-additional-pink-100 text-additional-pink-600',
  };

  return (
    <>
      <div
        className={
          'verflow-hidden overflow-ellipsis text-[16px] font-bold md:text-[20px]'
        }
      >
        {t('course.Summary')}
      </div>
      <div className={'flex flex-col gap-[16px] md:gap-[24px]'}>
        {summaryResult.data.map((summary) => (
          <div className={rowClasses} key={summary.id}>
            <div className={topicClasses}>{summary.course_unit.unit_title}</div>
            <div className="flex items-center justify-between gap-6">
              <RestartIcon
                className="cursor-pointer"
                onClick={() => restartUnit(Number(summary.course_unit.id))}
              />
              <div
                className={`${resultContainerClasses} ${
                  gradeContentMap[summary?.grade] || resultDefaultClasses
                }`}
              >
                {summary.grade}
              </div>
            </div>
          </div>
        ))}
        <hr className={hrClasses} />
        <div className={rowClasses}>
          <div className={topicClasses}>{t('course.Total')}</div>
          <div
            className={`${resultContainerClasses} ${
              gradeContentMap[summaryResult.total_grade] || resultDefaultClasses
            }`}
          >
            {summaryResult.total_grade}
          </div>
        </div>
        <hr className={hrClasses} />
        <div className={'flex justify-end'}>
          <MButton color={'primary'} variant={'outline'} onClick={rateCourse}>
            {t('course.rate_course')}
          </MButton>
        </div>
      </div>
    </>
  );
};
