const getCloudFlareURL = (
  videoId: string,
  posterHeight: number,
  options: Record<string, string | number | boolean>
) => {
  const url = new URL(
    `https://customer-4tvh9asxgv11vvwm.cloudflarestream.com/${videoId}/iframe`
  );
  for (const [key, value] of Object.entries(options)) {
    if (!value) continue;
    url.searchParams.append(key, String(value));
  }
  url.searchParams.append(
    'poster',
    `https://customer-4tvh9asxgv11vvwm.cloudflarestream.com/${videoId}/thumbnails/thumbnail.jpg?height=${posterHeight}`
  );
  return url.href;
};

function LandingCFPlayer({
  title,
  videoId,
  posterHeight,
  autoplay,
  loop,
  muted,
  className,
  onClick,
}: {
  title: string;
  videoId: string;
  posterHeight: number;
  autoplay: boolean;
  loop: boolean;
  muted: boolean;
  className?: string;
  onClick?: () => void;
}) {
  return (
    <iframe
      title={title}
      src={getCloudFlareURL(videoId, posterHeight, {
        autoplay,
        loop,
        muted,
        preload: 'auto',
        primaryColor: '#4318FF',
      })}
      loading="lazy"
      allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
      allowFullScreen
      className={className}
      onClick={onClick}
    />
  );
}

export default LandingCFPlayer;
