import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
} from 'react';
import useFetch from '../../hooks/useFetch';
import { MyBidsResponse, MyWonAuctionsResponse } from '../../models/Auction';
import { getMyBids, getWonAuctions } from '../../api/bid';
import { useUserContext } from '../UserProvider';

type BidProvider = {
  myBids: MyBidsResponse['my_bids'];
  fetchMyBids: () => Promise<MyBidsResponse | undefined>;
  getMyBidForAuction: (auction_id: number) => number | null;
  wonAuctionsData: MyWonAuctionsResponse['won_auctions'];
  fetchMyWonAuctions: () => Promise<MyBidsResponse | undefined>;
  getWonAuctionInfo: (auction_id: number) => {
    isRewardClaimed: boolean;
    isAuctionWon: boolean;
  };
};

const BidContext = createContext<BidProvider>({} as BidProvider);

const BidProvider: React.FC<PropsWithChildren> = (props) => {
  const { children } = props;
  const { user } = useUserContext();
  const { data: bidsData, fetchData: fetchMyBids } = useFetch(getMyBids);
  const { data: wonAuctionsData, fetchData: fetchMyWonAuctions } =
    useFetch(getWonAuctions);

  const fetchMyBidsSafely = async () => {
    if (!user?.guid) {
      return undefined;
    }
    await fetchMyBids();
  };

  const fetchMyWonAuctionsSafely = async () => {
    if (!user?.guid) {
      return undefined;
    }
    await fetchMyWonAuctions();
  };

  useEffect(() => {
    fetchMyBidsSafely();
    fetchMyWonAuctionsSafely();
  }, [user?.guid]);

  const getMyBidForAuction = (auction_id: number) => {
    return bidsData?.my_bids[auction_id.toString()] || null;
  };

  const getWonAuctionInfo = (auction_id: number) => {
    if (!wonAuctionsData) {
      return {
        isRewardClaimed: false,
        isAuctionWon: false,
      };
    }

    const auctionWonData = wonAuctionsData.won_auctions[auction_id.toString()];

    return {
      isRewardClaimed: auctionWonData,
      isAuctionWon:
        wonAuctionsData.won_auctions[auction_id.toString()] !== undefined,
    };
  };

  return (
    <BidContext.Provider
      value={{
        myBids: bidsData?.my_bids || {},
        fetchMyBids: fetchMyBidsSafely,
        getMyBidForAuction,
        wonAuctionsData: wonAuctionsData?.won_auctions || {},
        fetchMyWonAuctions: fetchMyWonAuctionsSafely,
        getWonAuctionInfo,
      }}
    >
      {children}
    </BidContext.Provider>
  );
};

const useBidContext = () => useContext(BidContext);

export { useBidContext, BidProvider };
