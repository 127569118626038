import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FeedRequest } from '../../../../../api/weber/feed';
import { Loader } from '../../../../../components/Loader';
import { NotFound } from '../../../../../components/NotFound/NotFound';
import { usePosts } from '../../../../../entities/Post';
import useInfiniteScroll from '../../../../../hooks/useInfiniteScroll';
import { JoinStatus } from '../../../../../models/Community';
import { FeedCard } from '../../../../../widgets/FeedCard';
import '../FeedList.scss';

type Props = {
  filters: FeedRequest;
  status?: JoinStatus;
  onCount?: (count: number) => void;
};

export const Feeds: React.FC<Props> = ({ filters, status, onCount }) => {
  const { t } = useTranslation(['translation']);
  const {
    count,
    dataScroll: posts,
    currentPage,
    total,
    isLoading,
    fetchData,
    fetchDelete,
    fetchStatus,
    setDataScroll,
  } = usePosts();

  useEffect(() => {
    filters.page === 1 && setDataScroll([]);
    Object.keys(filters).length && fetchData(filters);
  }, [filters]);

  useEffect(() => {
    onCount?.(count);
  }, [count]);

  const loadComments = useCallback(() => {
    if (total > currentPage) {
      fetchData({ ...filters, page: currentPage + 1 });
    }
  }, [total, currentPage, filters]);

  const targetRef = useRef<HTMLDivElement>(null);
  useInfiniteScroll(targetRef, loadComments);

  return (
    <>
      {posts?.length ? (
        <>
          {posts?.map((feed) => (
            <FeedCard
              feed={feed}
              key={feed.id}
              onRemove={fetchDelete}
              onStaus={fetchStatus}
              className="w-full"
              onRequest={() => fetchData(filters)}
            />
          ))}
          <div ref={targetRef} />
          {isLoading && <Loader />}
        </>
      ) : isLoading ? (
        <Loader />
      ) : (
        <NotFound
          text={`${t('feed.notFound')} ${
            status && status === JoinStatus.OWNED
              ? ' ' + t('feed.create_a_new_one')
              : ''
          }`}
        />
      )}
    </>
  );
};
