import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Cursor } from '../../../assets/icons/cursor.svg';
import { Link } from '../../../horizon-components/Link';
import {
  EduRoutes,
  MainRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';
import { DigitalPassCategory } from '../../../models/DigitalPass';
import './SubjectCard.scss';

export type Props = {
  data: DigitalPassCategory;
  className?: string;
};

export const SubjectCard: React.FC<Props> = ({ data, className }) => {
  const { t } = useTranslation(['translation']);

  return (
    <Link to={MainRoutes.edu + EduRoutes.nft + '/' + data.id}>
      <div className={classNames('SubjectCard', className)}>
        <div className="SubjectCard__icon">
          <img src={data.image_small} alt={'img'} />
        </div>
        <div className="SubjectCard__wrapper">
          <div className={'flex justify-between'}>
            <div>
              <h4>{data.subject.title}</h4>
              <p>
                {data.course_count || 0} {t('course.includedCourses')}
              </p>
            </div>
          </div>
        </div>
        <div className={'link'}>
          <Cursor />
        </div>
      </div>
    </Link>
  );
};
