import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Airplane } from '../../../assets/icons/airplane.svg';
import { Loader } from '../../../components/Loader';
import { usePostComment, usePostReply } from '../../../entities/Post';
import { MButton } from '../../../horizon-components/MButton/ui/MButton';
import { useUserContext } from '../../../providers';
import { getUserData } from '../../../utils/getUserData';
import { NoAvatar } from '../../../widgets/NoAvatar';
import './FeedComment.scss';

interface FeedCommentProps {
  className?: string;
  placeholder?: string;
  id: number;
  onSend?: () => void;
  isChildComment?: boolean;
  reply_to_comment?: number;
}

export const FeedComment = ({
  className,
  placeholder,
  id,
  onSend,
  isChildComment = false,
  reply_to_comment,
}: FeedCommentProps) => {
  const { t } = useTranslation(['translation']);
  const { user } = useUserContext();
  const myUserData = getUserData(user);
  const [comment, setComment] = useState('');
  const { isLoading, onComment, isSended, setIsSended } = usePostComment();
  const {
    isLoading: isLoadingReply,
    onReply,
    isSended: isSendedReply,
    setIsSended: setIsSendedReply,
  } = usePostReply();

  useEffect(() => {
    if (isSended || isSendedReply) {
      setComment('');
      onSend?.();
      isSended && setIsSended(false);
      isSendedReply && setIsSendedReply(false);
    }
  }, [isSendedReply, isSended, onSend, setIsSended, setIsSendedReply]);

  const hndlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComment?.(event.target.value);
  };
  const hndlSend = () => {
    if (isChildComment) {
      onReply(id, comment, reply_to_comment);
    } else {
      onComment(id, comment);
    }
  };

  return (
    <div
      className={classNames('FeedComment', className, { childe: isChildComment })}
    >
      <div className="FeedComment__img">
        <NoAvatar src={myUserData.image} alt={myUserData.username} />
      </div>
      <div className="FeedComment__input">
        <input
          placeholder={placeholder || t('weber.Write_your_comment')}
          onKeyDown={(e) => e.key === 'Enter' && hndlSend()}
          onChange={hndlChange}
          value={comment}
          type="text"
          disabled={isLoading || isLoadingReply || isSendedReply}
        />
      </div>
      <MButton
        variant="highlighted"
        color="primary"
        size="default"
        onClick={hndlSend}
        disabled={isLoading || isLoadingReply || isSendedReply}
        className={!isChildComment ? '!h-[52px] !max-h-[52px] !w-[52px]' : ''}
      >
        {isLoading || isLoadingReply || isSendedReply ? (
          <Loader className="!h-6 !w-6" />
        ) : (
          <Airplane />
        )}
      </MButton>
    </div>
  );
};
