import { useTranslation } from 'react-i18next';
import { MButton } from '../../horizon-components/MButton';
import React, { useState } from 'react';
import { Modal } from '../../horizon-components/Modal';
import { DeleteAccountModal } from '../../widgets/DeleteAccountModal';

export const DeleteAccount: React.FC = () => {
  const { t } = useTranslation(['translation']);
  const [isShowModal, setIsShowModal] = useState(false);

  const showModal = () => {
    setIsShowModal(true);
  };

  const hideModal = () => {
    setIsShowModal(false);
  };

  return (
    <div className={'flex w-full items-center justify-between'}>
      <h3 className="text-xl font-semibold text-secondary-grey-900">
        {t('buttons.delete_account')}
      </h3>
      <MButton color={'danger'} variant="highlighted" onClick={showModal}>
        {t('buttons.delete_account')}
      </MButton>
      <Modal
        title={t('buttons.delete_account')}
        isOpen={isShowModal}
        onClose={hideModal}
        showClose={true}
      >
        <DeleteAccountModal onClose={hideModal} />
      </Modal>
    </div>
  );
};
