import React from 'react';
import { useTranslation } from 'react-i18next';
import { ActionBar } from '../../../../../features/ActionBar';
import { MButton } from '../../../../../horizon-components/MButton';
import { JoinStatus, TCommunity } from '../../../../../models/Community';
import { useUserContext } from '../../../../../providers';

type FeedButtonsProps = {
  communityFeed?: TCommunity;
  title: string;
  isMy: boolean;
  onMode?: (mode: boolean) => void;
};

export const FeedButtons: React.FC<FeedButtonsProps> = ({
  communityFeed,
  title,
  isMy,
  onMode,
}) => {
  const { user } = useUserContext();
  const { t } = useTranslation(['translation']);

  return (
    <div className="flex flex-col gap-[8px] md:gap-[24px]">
      <div className="flex justify-between gap-2">
        <h1 className={'g-main-title'}>{title}</h1>

        <div className="flex gap-[10px]">
          {!!user &&
            (!communityFeed || communityFeed.join_status === JoinStatus.OWNED) && (
              <ActionBar communityFeedId={communityFeed?.id} className="md:hidden" />
            )}
        </div>
      </div>

      <div
        className={
          'relative flex w-full flex-col items-center justify-between gap-2 sm:flex-row'
        }
      >
        <div className={'flex w-full items-center gap-2 lg:w-auto'}>
          <MButton
            variant={!isMy ? 'highlighted' : 'secondary'}
            color="tab"
            size="sm"
            onClick={() => onMode?.(false)}
            className="w-full md:w-auto"
          >
            {t('feed.discover')}
          </MButton>
          {!!user && (
            <MButton
              variant={isMy ? 'highlighted' : 'secondary'}
              color="tab"
              size="sm"
              onClick={() => onMode?.(true)}
              className="w-full md:w-auto"
            >
              {t('feed.my_feed')}
            </MButton>
          )}
        </div>
        {!!user &&
          (!communityFeed || communityFeed.join_status === JoinStatus.OWNED) && (
            <ActionBar
              communityFeedId={communityFeed?.id}
              className="md-max:hidden"
            />
          )}
      </div>
    </div>
  );
};
