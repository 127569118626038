import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AddUser } from '../../../assets/icons/add-user.svg';
import { ReactComponent as ChevronLeft } from '../../../assets/icons/chevron-left.svg';
import { MButton } from '../../../horizon-components/MButton/ui/MButton';
import { FindConnections } from './FindConnections';
import { IncomingRequests } from './IncomingRequests';
import { IncomingRequestsCard } from './IncomingRequestsCard';
import { MyConnections, MyConnectionsRef } from './MyConnections';
import {
  NetworkingNavigation,
  NetworkingNavigationTab,
} from './NetworkingNavigation';
import { PeopleYouMayNow } from './PeopleYouMayNow';

export const Networking: React.FC = () => {
  const [tab, setTab] = useState<NetworkingNavigationTab>(
    NetworkingNavigationTab.MyConnections
  );
  const { t } = useTranslation(['translation']);
  const [width, setWidth] = useState(window.innerWidth);
  const myConnectionsRef = useRef<MyConnectionsRef>(null);
  const [incomingRequestsCount, setIncomingRequestsCount] = useState(0);
  const [outcomingRequestsCount, setOutcomingRequestsCount] = useState(0);

  useEffect(() => {
    window.onresize = () => setWidth(window.innerWidth);
    return () => {
      window.onresize = null;
    };
  }, []);

  const isLargeWidth = width > 992;
  const PeopleYouMayNowComponent = tab !==
    NetworkingNavigationTab.FindConnections && (
    <PeopleYouMayNow
      goToFindConnections={() => setTab(NetworkingNavigationTab.FindConnections)}
      isLargeWidth={isLargeWidth}
    />
  );

  const updateMyConnections = () => {
    myConnectionsRef.current?.update();
  };

  const handleDataCount = (count: number, isOutcomingRequests: boolean) => {
    if (isOutcomingRequests) {
      setOutcomingRequestsCount(count);
    } else {
      setIncomingRequestsCount(count);
    }
  };

  return (
    <div className={'w-full'}>
      {(isLargeWidth || tab === NetworkingNavigationTab.MyConnections) && (
        <div className={'mb-[10px] flex items-end justify-between lg:mb-[20px]'}>
          <h2 className={'text-[24px] font-bold text-[#2B3674] lg:text-[36px]'}>
            {t('networking.title')}
          </h2>
          <MButton
            variant={'highlighted'}
            color={'primary'}
            className={'!w-[42px] !px-2 lg:!hidden'}
            onClick={() => setTab(NetworkingNavigationTab.FindConnections)}
          >
            <AddUser />
          </MButton>
        </div>
      )}
      {!isLargeWidth && tab === NetworkingNavigationTab.FindConnections && (
        <div className={'mb-[10px] flex items-center gap-[8px]'}>
          <MButton
            variant={'highlighted'}
            color={'white'}
            className={'!p-[12px] !shadow-[14px_17px_40px_4px_#7090B014]'}
            onClick={() => setTab(NetworkingNavigationTab.MyConnections)}
          >
            <ChevronLeft />
          </MButton>
          <h2 className={'text-[24px] font-bold text-[#2B3674]'}>
            {t('networking.findConnections')}
          </h2>
        </div>
      )}
      {!isLargeWidth &&
        (tab === NetworkingNavigationTab.Incoming ||
          tab === NetworkingNavigationTab.Outcoming) && (
          <div>
            <div className={'mb-[10px] flex items-center gap-[8px]'}>
              <MButton
                variant={'highlighted'}
                color={'white'}
                className={'!p-[12px] !shadow-[14px_17px_40px_4px_#7090B014]'}
                onClick={() => setTab(NetworkingNavigationTab.MyConnections)}
              >
                <ChevronLeft />
              </MButton>
              <h2 className={'text-[24px] font-bold text-[#2B3674]'}>
                {t('networking.requests')}
              </h2>
            </div>
            <div className={'mb-[10px] flex'}>
              <div
                className={'cursor-pointer pt-[2px]'}
                onClick={() => setTab(NetworkingNavigationTab.Incoming)}
              >
                <p
                  className={classNames(
                    'px-[8px] text-[14px] font-[600] text-[#B0BBD5]',
                    {
                      '!text-[#2B3674]': tab === NetworkingNavigationTab.Incoming,
                    }
                  )}
                >
                  {`${t('networking.incoming')} (${incomingRequestsCount})`}
                </p>
                {tab === NetworkingNavigationTab.Incoming && (
                  <div
                    className={'mt-[5px] h-[4px] w-full rounded-full bg-[#4318FF]'}
                  />
                )}
              </div>
              <div
                className={'cursor-pointer pt-[2px]'}
                onClick={() => setTab(NetworkingNavigationTab.Outcoming)}
              >
                <p
                  className={classNames(
                    'px-[8px] text-[14px] font-[600] text-[#B0BBD5]',
                    {
                      '!text-[#2B3674]': tab === NetworkingNavigationTab.Outcoming,
                    }
                  )}
                >
                  {`${t('networking.outcoming')} (${outcomingRequestsCount})`}
                </p>
                {tab === NetworkingNavigationTab.Outcoming && (
                  <div
                    className={'mt-[5px] h-[4px] w-full rounded-full bg-[#4318FF]'}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      <div className={'flex justify-between gap-[20px]'}>
        <div className={'w-full lg:w-[70%]'}>
          {tab === NetworkingNavigationTab.MyConnections && (
            <>
              <IncomingRequestsCard
                onViewAllClick={() => setTab(NetworkingNavigationTab.Incoming)}
                isLargeWidth={isLargeWidth}
                updateMyConnections={updateMyConnections}
              />
              {!isLargeWidth && PeopleYouMayNowComponent}
              <MyConnections
                ref={myConnectionsRef}
                onAddUser={() => setTab(NetworkingNavigationTab.FindConnections)}
                isLargeWidth={isLargeWidth}
              />
            </>
          )}
          {(tab === NetworkingNavigationTab.Incoming ||
            tab === NetworkingNavigationTab.Outcoming) && (
            <>
              <IncomingRequests
                tab={tab}
                isLargeWidth={isLargeWidth}
                onDataCount={handleDataCount}
                updateMyConnections={updateMyConnections}
                isOutcomingRequests={tab === NetworkingNavigationTab.Outcoming}
              />
            </>
          )}
          {tab === NetworkingNavigationTab.FindConnections && <FindConnections />}
        </div>
        {isLargeWidth && (
          <div className={'w-[30%]'}>
            <NetworkingNavigation tab={tab} setTab={setTab} />
            {PeopleYouMayNowComponent}
          </div>
        )}
      </div>
    </div>
  );
};
