import React from 'react';
import NotFoundRequest from '../../assets/images/not-found.png';
import NotFoundBg from '../../assets/images/create.png';
import { useTranslation } from 'react-i18next';

type Props = {
  text?: string | JSX.Element;
  img?: string;
  isRequest?: boolean;
};

export const NotFound: React.FC<Props> = ({ text, isRequest, img }) => {
  const { t } = useTranslation(['translation']);
  const notFoundText = text || t('notFound');

  return (
    <div className={'flex w-full flex-col items-center justify-center lg:mt-5'}>
      {img ? (
        <img
          src={img}
          className={'h-[230px] w-auto max-w-[283px] lg:h-auto lg:w-full'}
          alt={''}
        />
      ) : isRequest ? (
        <img
          src={NotFoundRequest}
          className={'h-[230px] w-auto max-w-[283px] lg:h-auto lg:w-full'}
          alt={''}
        />
      ) : (
        <img
          src={NotFoundBg}
          className={'h-[230px] w-auto max-w-[283px] lg:h-auto lg:w-full'}
          alt={''}
        />
      )}

      <p
        className={
          'text-center text-base font-semibold text-primary-purple-blue-400 md:text-xl'
        }
      >
        {isRequest ? t('networking.notFound') : notFoundText}
      </p>
    </div>
  );
};
