import { useCallback, useState } from 'react';
import { DEFAULT_LIMIT } from '../../../../../shared/lib/const/limit';
import { publicUsers } from '../../../../../api/auth';
import { UserFriendship } from '../../../../../models/Friendship';
import { ResponsePagination } from '../../../../../models/Response';
import { useTranslation } from 'react-i18next';

export const usePublicUsers = (search: string) => {
  const { t } = useTranslation(['translation']);
  const [data, setData] = useState<ResponsePagination<UserFriendship>>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const total = data ? Math.ceil(data?.count / DEFAULT_LIMIT) : 0;

  const fetchData = useCallback(() => {
    setIsLoading(true);
    publicUsers(search)
      .then((res) => {
        setData(res);
        res !== undefined && setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, [search, t]);

  return {
    data,
    page,
    total,
    isLoading,
    setPage,
    fetchData,
  };
};
