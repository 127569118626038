import { useCallback, useEffect, useState } from 'react';
import { addFavoriteCourse, deleteFavoriteCourse } from '../../../../../api/course';

export const useCourseFavorite = (fav: boolean) => {
  const [favorite, setFavorite] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchFavorite = useCallback(
    (courseId: number): Promise<void> => {
      setIsLoading(true);
      const action = favorite
        ? deleteFavoriteCourse(courseId)
        : addFavoriteCourse(courseId);

      return action
        .then(() => {
          setFavorite(!favorite);
        })
        .finally(() => setIsLoading(false));
    },
    [favorite]
  );

  useEffect(() => {
    setFavorite(fav);
  }, [fav]);

  return {
    fetchFavorite,
    isLoading,
    favorite,
  };
};
