import classNames from 'classnames';
import './AsideMenu.scss';

export type AsideMenuItem = { id: string; title: string };

interface AsideMenuProps {
  className?: string;
  menu?: AsideMenuItem[];
  active?: string;
  onClick?: (mode: string) => void;
}

export const AsideMenu = ({ className, menu, active, onClick }: AsideMenuProps) => {
  if (!menu?.length) return null;
  return (
    <div className={classNames('AsideMenu', className)}>
      {menu.map((item) => (
        <div
          className={classNames('AsideMenu__item', { active: item.id === active })}
          onClick={() => onClick?.(item.id)}
          key={item.id}
        >
          {item.title}
        </div>
      ))}
    </div>
  );
};
