import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { MButton } from '../../../horizon-components/MButton';
import './PutFeedModal.scss';

interface PutFeedModalProps {
  className?: string;
  onCancel?: () => void;
  onPut?: () => void;
}

export const PutFeedModal = ({ className, onCancel, onPut }: PutFeedModalProps) => {
  const { t } = useTranslation(['translation']);

  return (
    <div className={classNames('PutFeedModal', className)}>
      <h3>{t('buttons.Put_it_in_the_feed')}</h3>
      <p>{t('feed.put_post_description')}</p>
      <div className="PutFeedModal__btns">
        <MButton variant="outline" color="primary" onClick={onCancel}>
          {t('buttons.cancel')}
        </MButton>
        <MButton variant="highlighted" color="primary" onClick={onPut}>
          {t('buttons.Put_it_in_the_feed')}
        </MButton>
      </div>
    </div>
  );
};
