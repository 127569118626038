import classNames from 'classnames';
import { ReactNode } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import useClickOutside from '../../../hooks/useClickOutside';
import { useNavigate } from '../../../hooks/useNavigate';
import {
  AuthRoutes,
  MainRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';
import { useUserContext } from '../../../providers';
import './MobileTab.scss';

interface MobileTabProps {
  className?: string;
  icon?: ReactNode;
  title?: string;
  url?: string;
  items?: RoutesType[];
  secondary?: ReactNode;
  component?: ReactNode;
  auth?: boolean;
}

export const MobileTab = ({
  className,
  icon,
  title,
  url,
  items,
  secondary = false,
  component,
  auth,
}: MobileTabProps) => {
  const location = useLocation();
  const { user } = useUserContext();
  const navigate = useNavigate();
  const [open, setRef, toggleOpen] = useClickOutside(false);
  const { lang } = useParams();

  const goTo = (url: string) => {
    if (auth && !user?.guid) {
      navigate(MainRoutes.auth + AuthRoutes.signin);
    } else {
      navigate(url);
    }
  };

  const activeRoute = () => {
    if (url === `/` && location.pathname === `/${lang}/`) {
      return true;
    } else {
      return url && url !== `/` && location.pathname.includes(url);
    }
  };

  return (
    <div
      ref={setRef}
      className={classNames(
        'MobileTab',
        className,
        { active: activeRoute() || open },
        { secondary: secondary }
      )}
      onClick={() => (url ? goTo(url) : toggleOpen())}
    >
      {open && (
        <div
          className={classNames('MobileTab__collapse', {
            active: activeRoute() || open,
          })}
        >
          {items?.map((item, idx) => (
            <div
              onClick={() => goTo(item.path)}
              key={idx}
              className="MobileTab__item"
            >
              <div className="icon">{item.icon}</div>
              <div className="flex flex-col">
                <h5>{item.name}</h5>
                {item.component}
              </div>
            </div>
          ))}
          {component}
        </div>
      )}
      <div
        className={classNames(
          'MobileTab__icon',
          { active: activeRoute() || open },
          { secondary: secondary }
        )}
      >
        {icon}
      </div>
      {title}
    </div>
  );
};
