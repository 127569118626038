import React from 'react';
import { PlacesType, Tooltip } from 'react-tooltip';

type Props = {
  text: string;
  anchorSelect: string;
  position?: PlacesType;
};

export const MTooltip: React.FC<Props> = ({
  anchorSelect,
  position = 'bottom',
  text,
}) => {
  return (
    <Tooltip
      anchorSelect={anchorSelect}
      place={position}
      noArrow
      className={
        '!z-[9999] !rounded-[8px] border-[1px] border-solid border-gray-100 !bg-white !px-[3px] !py-[5px] !text-[10px] font-[600] !leading-[12px] !text-secondaryGrey-700 !shadow-[14px_17px_40px_4px_#7090B014]'
      }
    >
      {text}
    </Tooltip>
  );
};
