import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useNavigate } from '../../hooks/useNavigate';
import { MButton } from '../../horizon-components/MButton';
import {
  EduRoutes,
  MainRoutes,
} from '../../horizon-layout/MainLayout/Routes/types/routes';

interface EduTabsProps {
  className?: string;
}

export const EduTabs = ({ className }: EduTabsProps) => {
  const { t } = useTranslation(['translation']);
  const navigate = useNavigate();
  const location = useLocation();

  const goTo = (url: string) => navigate(url);

  return (
    <div
      className={classNames(
        'flex items-center justify-between gap-2 side:hidden',
        className
      )}
    >
      <MButton
        variant={
          location.pathname.includes(MainRoutes.edu + EduRoutes['digital-passes'])
            ? 'highlighted'
            : 'secondary'
        }
        color={
          location.pathname.includes(MainRoutes.edu + EduRoutes['digital-passes'])
            ? 'tab'
            : 'primary'
        }
        className="w-full"
        onClick={() => goTo(MainRoutes.edu + EduRoutes['digital-passes'])}
      >
        {t('buttons.School')}
      </MButton>
      <MButton
        variant={
          location.pathname.includes(MainRoutes.edu + EduRoutes.courses)
            ? 'highlighted'
            : 'secondary'
        }
        color={
          location.pathname.includes(MainRoutes.edu + EduRoutes.courses)
            ? 'tab'
            : 'primary'
        }
        className="w-full"
        onClick={() => goTo(MainRoutes.edu + EduRoutes.courses)}
      >
        {t('buttons.Courses')}
      </MButton>
    </div>
  );
};
