import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getGroupMembers } from '../../../../api/weber/friendship';
import { ReactComponent as Search } from '../../../../assets/icons/search.16px.svg';
import { ReactComponent as EditIcon } from '../../../../assets/icons/profile/edit.svg';
import { ReactComponent as CheckIcon } from '../../../../assets/icons/nft/check.svg';
import { Loader } from '../../../../components/Loader';
import { NotFound } from '../../../../components/NotFound/NotFound';
import { Card } from '../../../../horizon-components/Card/Card';
import { MInput } from '../../../../horizon-components/MInput/MInput';
import { UserCommunity } from '../../../../models';
import useDebounce from '../../../../hooks/useDebounce';
import { MButton } from '../../../../horizon-components/MButton';
import { MemberCard } from '../../../../features/MemberCard';

type Props = {
  showBtn: boolean;
};

export const CommunityMembers: React.FC<Props> = ({ showBtn = false }) => {
  const { communityId } = useParams();
  const { t } = useTranslation(['translation']);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [search, setSearch] = useState('');
  const debounced = useDebounce<string>(search, 500);
  const [members, setMembers] = useState<UserCommunity[]>([]);

  useEffect(() => getData?.(search), [debounced]);

  const getData = useCallback(
    (search?: string) => {
      setIsLoading(true);
      getGroupMembers(communityId || '', 0, search)
        .then((data) => setMembers(data?.results?.map((item) => item)))
        .finally(() => setIsLoading(false));
    },
    [communityId]
  );

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Card
      extra={
        'w-full rounded-[30px] !shadow-[14px_17px_40px_4px_#7090B014] md:p-[24px] p-0 mb-[20px] !flex-col'
      }
    >
      <div className="flex justify-between gap-2">
        <MInput
          wrapperClassName={
            'w-[344px] rounded-full !shadow-[14px_17px_40px_4px_#7090B014] lg:!shadow-none mb-[20px]'
          }
          placeholder={t('nft.search')}
          value={search}
          onChange={setSearch}
          size={'sm'}
          inputClassName={
            'w-full !bg-[#FFFFFF] lg:!bg-[#F4F7FE] !border-[#FFFFFF] lg:!border-[#F4F7FE] !placeholder-[#8F9BBA]'
          }
          leftIcon={<Search className={'mt-[-10px]'} />}
        />
        {showBtn && (
          <MButton
            variant="highlighted"
            color={isEdit ? 'primary' : 'white'}
            onClick={() => setIsEdit(!isEdit)}
            className={`!shadow-md !shadow-shadow-700 ${
              isEdit && '!h-[42px] !w-[42px] !p-2.5'
            }`}
          >
            {isEdit ? (
              <CheckIcon />
            ) : (
              <>
                {t('buttons.Edit')} <EditIcon />
              </>
            )}
          </MButton>
        )}
      </div>
      <div>
        <div className={'flex flex-wrap gap-[12px]'}>
          {!isLoading &&
            (!!members?.length ? (
              members.map((member) => <MemberCard member={member} isEdit={isEdit} />)
            ) : (
              <NotFound isRequest />
            ))}
        </div>
        {isLoading && (
          <div className={'flex items-center justify-center'}>
            <Loader />
          </div>
        )}
      </div>
    </Card>
  );
};
