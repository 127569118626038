import React, { useCallback, useEffect, useState } from 'react';
import { MButton } from '../../../horizon-components/MButton/ui/MButton';
import { Card } from '../../../horizon-components/Card/Card';
import { NetworkingCard } from './NetworkingCard';
import { Friendship } from '../../../models/Friendship';
import {
  acceptFriendship,
  getFriendships,
  rejectFriendship,
} from '../../../api/weber/friendship';
import { getUserData } from '../../../utils/getUserData';
import { useTranslation } from 'react-i18next';

type Props = {
  onViewAllClick: () => void;
  isLargeWidth?: boolean;
  updateMyConnections?: () => void;
};

export const IncomingRequestsCard: React.FC<Props> = ({
  onViewAllClick,
  isLargeWidth,
  updateMyConnections,
}) => {
  const [connections, setConnections] = useState<Friendship[]>([]);
  const [total, setTotal] = useState(0);
  const { t } = useTranslation(['translation']);

  const getData = useCallback(() => {
    getFriendships(false, undefined, isLargeWidth ? false : undefined).then(
      (data) => {
        data?.results?.length &&
          setConnections(data?.results?.splice(0, isLargeWidth ? 4 : 1));
        data?.count && setTotal(data?.count);
      }
    );
  }, [isLargeWidth]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleAcceptClick = useCallback(
    (id: number) => {
      return acceptFriendship(id).then(() => {
        getData();
        updateMyConnections?.();
      });
    },
    [getData, updateMyConnections]
  );

  const handleRejectClick = useCallback(
    (id: number) => {
      return rejectFriendship(id).then(() => {
        getData();
      });
    },
    [getData]
  );

  if (!connections?.length) {
    return null;
  }

  return (
    <Card
      extra={
        'shadow-none lg:!shadow-[14px_17px_40px_4px_#7090B014] !p-0 lg:!p-[16px] rounded-[30px] mb-[10px] lg:mb-[20px]'
      }
    >
      <div className={'mb-[12px] flex items-center justify-between'}>
        <h3 className={'text-[16px] font-[600] text-[#2B3674] lg:text-[24px]'}>
          {isLargeWidth
            ? t('networking.incomingRequests')
            : t('networking.requests')}{' '}
          <span className={'text-[#8F9BBA]'}>{total}</span>
        </h3>
        <MButton variant={'transparent'} color={'primary'} onClick={onViewAllClick}>
          {t('networking.viewAll')}
        </MButton>
      </div>
      <div className={'flex flex-wrap gap-[12px]'}>
        {connections.map((item) => {
          const anotherUser = item?.[item.another_user_key];
          const user = getUserData(anotherUser);
          const isIncoming = item.another_user_key === 'from_user';

          return (
            <NetworkingCard
              key={item.id}
              guid={user?.guid || ''}
              username={user.username}
              name={`${user.first_name} ${user.last_name}`}
              wrapperClassName={'w-full lg:!w-[calc(50%_-_6px)]'}
              infoWrapperClassName={'w-full'}
              img={user.image}
              actions={
                <div
                  className={
                    'ml-auto mt-[8px] flex items-center justify-end gap-[8px]'
                  }
                >
                  {isIncoming && (
                    <MButton
                      variant={'highlighted'}
                      color={'primary'}
                      size={'xs'}
                      onClick={() => handleAcceptClick(item.id)}
                    >
                      {t('buttons.Connect')}
                    </MButton>
                  )}
                  <MButton
                    variant={'secondary'}
                    color={'primary'}
                    size={'xs'}
                    onClick={() => handleRejectClick(item.id)}
                  >
                    {t('buttons.Reject')}
                  </MButton>
                </div>
              }
            />
          );
        })}
      </div>
    </Card>
  );
};
