import { ReactComponent as StarFull } from '../../../assets/icons/rating/star-full.svg';
import { ReactComponent as StarEmpty } from '../../../assets/icons/rating/star-empty.svg';
import { ReactComponent as StarHalf } from '../../../assets/icons/rating/star-half.svg';
import classNames from 'classnames';
import './StarRating.scss';
import { useState } from 'react';

interface StarRatingProps {
  rating?: number;
  percent?: number;
  size?: 'sm' | 'md';
  active?: boolean;
  onClick?: (value: number) => void;
}

export const StarRating = ({
  rating = 0,
  percent = 0,
  size = 'sm',
  active = false,
  onClick,
}: StarRatingProps) => {
  const [hoveredStars, setHoveredStars] = useState(0); // Состояние для отслеживания наведения на звезды

  const generateStars = () => {
    const stars = [];

    // Округляем число до ближайшего целого
    const roundedNumber = Math.floor(rating);

    // Добавляем заполненные звездочки
    for (let i = 0; i < Math.min(roundedNumber, 5); i++) {
      stars.push(<StarFull key="star-full" />);
    }

    // Добавляем звездочку наполовину заполненную, если число не целое
    if (roundedNumber !== rating && roundedNumber < 5 && roundedNumber > 0) {
      stars.push(<StarHalf key="star-half" />);
    }

    // Добавляем пустые звездочки до 5
    for (let i = 0; i < 5 - Math.ceil(rating); i++) {
      stars.push(<StarEmpty key="star-empty" />);
    }

    return stars;
  };

  const generatePercentStars = () => {
    const stars = [];

    // Определяем количество заполненных звезд и заполненную часть последней звезды
    const numberOfStars = 6 - percent;

    // Добавляем заполненные звездочки
    for (let i = 0; i < numberOfStars; i++) {
      stars.push(<StarFull key="star-full" />);
    }

    // Добавляем пустые звездочки, чтобы итоговое количество было равно 5
    for (let i = 0; i < 5 - numberOfStars; i++) {
      stars.push(<StarEmpty key="star-empty" />);
    }

    return stars;
  };

  // Обработчик наведения курсора на звезду
  const handleStarHover = (index: number) => {
    setHoveredStars(index + 1); // Установка состояния для отслеживания наведения на звезду
  };

  // Обработчик покидания курсора с звезды
  const handleStarLeave = () => {
    setHoveredStars(0); // Сброс состояния для отслеживания наведения на звезду
  };
  return (
    <div className={classNames('StarRating', size)}>
      {(percent ? generatePercentStars() : generateStars()).map((star, idx) => (
        <span
          key={idx}
          onMouseEnter={() => handleStarHover(idx)} // Обработчик наведения курсора на звезду
          onMouseLeave={handleStarLeave} // Обработчик покидания курсора с звезды
          onClick={() => onClick?.(idx + 1)}
          className={classNames({ 'cursor-pointer': active })}
        >
          {/* Отображение StarFull при наведении курсора на эту звезду */}
          {hoveredStars > idx && active ? (
            <StarFull key={`star-full-${idx}`} />
          ) : (
            star
          )}
        </span>
      ))}
    </div>
  );
};
