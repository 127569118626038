import React, { useRef } from 'react';
import { LANG_KEY, Languages, LANGUAGES } from '../../i18n';
import { Dropdown, DropdownRefs } from '../../horizon-components/Dropdown/Dropdown';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../../providers/LanguageProvider';

export const LanguageSelector: React.FC = () => {
  const { i18n } = useTranslation();
  const langRef = useRef<DropdownRefs>(null);
  const { language, setLanguage } = useLanguage();

  const setLang = (lang: Languages) => {
    localStorage.setItem(LANG_KEY, lang);
    i18n.changeLanguage(lang);
    setLanguage(lang);
    langRef.current?.close();
  };

  return (
    <Dropdown
      ref={langRef}
      button={
        <p
          className={
            'cursor-pointer text-sm font-semibold text-secondary-grey-900 sm-max:text-primary-purple-blue-500 md:text-base md:font-medium md-max:px-3'
          }
        >
          {LANGUAGES[language]}
        </p>
      }
      children={
        <div className="ml-1 mt-4 gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 md:-ml-2">
          {Object.keys(LANGUAGES).map((key) => (
            <p
              key={key}
              className={'cursor-pointer text-sm !font-medium md:text-base'}
              onClick={() => setLang(key as Languages)}
            >
              {LANGUAGES[key as Languages]}
            </p>
          ))}
        </div>
      }
    />
  );
};
