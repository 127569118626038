export const getBase64Extension = (base64String: string) => {
  // Регулярное выражение для извлечения типа изображения из base64-строки
  const regex = /^data:image\/(\w+);base64,/;

  // Ищем совпадение регулярного выражения в строке base64
  const match = base64String.match(regex);

  // Если совпадение найдено
  if (match && match[1]) {
    return match[1]; // Возвращаем расширение файла
  } else {
    return null; // Если расширение не найдено, возвращаем null
  }
};
