import React, { useEffect, useMemo, useState } from 'react';
import { MCarousel } from '../MCarousel/MCarousel';
import './styles.scss';

type Props = {
  sources: string[];
};

export const MGallery: React.FC<Props> = ({ sources }) => {
  const [activeImage, setActiveImage] = useState(sources?.[0]);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.onresize = () => setWidth(window.innerWidth);
    return () => {
      window.onresize = null;
    };
  }, []);

  const items = useMemo(
    () =>
      sources.map((src) => (
        <img
          src={src}
          alt={'img'}
          className={'aspect-square h-auto w-full rounded-[16px] object-cover'}
        />
      )),
    [sources]
  );

  if (width > 640) {
    return (
      <div className={'flex flex-col'}>
        <img
          src={activeImage}
          alt={'img'}
          className={'aspect-square h-auto w-full rounded-[15px] object-cover'}
        />
        <div className={'mt-[16px] flex flex-wrap gap-[16px]'}>
          {sources.map((src, i) => (
            <img
              key={i}
              src={src}
              alt={'img'}
              onClick={() => setActiveImage(src)}
              className={`h-[94px] w-[94px] rounded-[15px] object-cover ${
                activeImage === src ? 'border-[2px] border-purple-300' : ''
              }`}
            />
          ))}
        </div>
      </div>
    );
  }

  return <MCarousel items={items} className={'m-gallery-carousel'} />;
};
