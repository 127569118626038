import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowRightIcon } from '../../assets/icons/pages/main/arrow-right.svg';
import IconBigImg from '../../assets/images/pages/main/ER-big.webp';
import IconSmallImg from '../../assets/images/pages/main/ER-small.webp';
import BgImg from '../../assets/images/pages/main/new-earn-bg.webp';
import { Link } from '../../horizon-components/Link';
import { MButton } from '../../horizon-components/MButton';
import { MainRoutes } from '../../horizon-layout/MainLayout/Routes/types/routes';
import './style.scss';

interface MainPageEarnProps {
  className?: string;
  hasIcon?: boolean;
}

export const MainPageEarn = ({ className, hasIcon }: MainPageEarnProps) => {
  const { t } = useTranslation(['translation']);

  return (
    <Link to={MainRoutes.wiz}>
      <div
        className={classNames('MainPageEarn', className)}
        style={{ backgroundImage: `url(${BgImg})` }}
      >
        <div className={classNames('MainPageEarn__content', { hasIcon })}>
          <h4>{t('main.Earn_money')}</h4>

          <p>{t('main.Play_WIZ_and_get_our_ERP_points')}</p>
        </div>

        <img
          src={IconBigImg}
          alt={t('main.Earn_money')}
          className="icon hidden lg:block"
        />
        <img
          src={IconSmallImg}
          alt={t('main.Earn_money')}
          className="icon lg:hidden"
        />
        <MButton variant="secondary" color="primary" className="z-10">
          {t('buttons.play')} <ArrowRightIcon />
        </MButton>
      </div>
    </Link>
  );
};
