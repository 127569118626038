function LandingReviewCard({
  name,
  text,
  avatarUrl,
}: {
  name: string;
  text: string;
  avatarUrl: string;
}) {
  return (
    <div className="LandingPage__review-card">
      <div className="LandingPage__review-card__profile">
        <div
          className="LandingPage__review-card__avatar"
          style={{ backgroundImage: `url("${avatarUrl}")` }}
        />
        <div className="LandingPage__review-card__name">{name}</div>
      </div>
      <p className="LandingPage__review-card__text">{text}</p>
    </div>
  );
}

export default LandingReviewCard;
