import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getCommonQuestion, setSubmitAnswer, TQuestion } from '../../api/wiz/wiz';
import { ReactComponent as CalcIcon } from '../../assets/icons/wiz/calc.svg';
import { Loader } from '../../components/Loader';
import useFetch from '../../hooks/useFetch';
import { useNavigate } from '../../hooks/useNavigate';
import { Modal } from '../../horizon-components/Modal';
import { ProgressBar } from '../../horizon-components/ProgressBar';
import {
  EduRoutes,
  MainRoutes,
} from '../../horizon-layout/MainLayout/Routes/types/routes';
import { useUserContext } from '../../providers';
import { useWizContext } from '../../providers/WizProvider/WizProvider';
import { QuizEndModal } from '../QuizEndModal';
import './style.scss';

interface QuizTestProps {
  className?: string;
  onStart?: () => void;
}

const TIME = 30;
const TOTAL_PERCENT = 100;

export const QuizTest = ({ className, onStart }: QuizTestProps) => {
  const { user, updateWallet } = useUserContext();
  const navigate = useNavigate();
  const { t } = useTranslation(['translation']);
  const [selected, setSelected] = useState<number | null>(null);
  const [isStartOpen, setIsStartOpen] = useState(false);
  const [isCorrect, setIsCorrect] = useState<boolean | null>(null);
  const [inorrect, setInorrect] = useState<number | null>(null);
  const [isSubmittingAnswer, setIsSubmittingAnswer] = useState(false);
  const [isFetchingNextAnswer, setIsFetchingNextAnswer] = useState(false);
  const [question, setQuestion] = useState<null | {
    id: number;
    current_question: number;
    total_questions: number;
    question: TQuestion;
  }>(null);
  const [isQuestionLoading, setIsQuestionLoading] = useState(false);
  const { data: answer, fetchData: fetchAnswer } = useFetch(
    (quiz_question_id: number, question_answer_id: number) =>
      setSubmitAnswer(quiz_question_id, question_answer_id)
  );
  const { currentTest, handleCloseTest } = useWizContext();

  const [seconds, setSeconds] = useState<number>(TIME);
  const [isTimerStopped, setIsTimerStopped] = useState(true);
  const [percentage, setPercentage] = useState(TOTAL_PERCENT);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const startTimer = () => {
    timerRef.current = setTimeout(async () => {
      setSeconds((prevSeconds) => (prevSeconds !== 0 ? prevSeconds - 1 : 0));
    }, 1000);
  };

  const getQuestion = async () => {
    if (currentTest?.id) {
      try {
        const question = await getCommonQuestion(currentTest?.id);
        question?.id && setQuestion(question);
        if (!question?.id) {
          hndlOpenStartModal();
          if (user) {
            updateWallet();
          }
        }
      } catch (e) {
        hndlOpenStartModal();
        if (user) {
          updateWallet();
        }
      }
    }
  };

  useEffect(() => {
    setIsQuestionLoading(true);
    getQuestion().finally(() => setIsQuestionLoading(false));
  }, [currentTest?.id]);

  useEffect(() => {
    if (isTimerStopped) {
      return;
    }

    startTimer();
    if (seconds === 0) {
      if (question) {
        hndlAnswer(question.id, question.question.answers[0].id);
      }
    }
  }, [seconds, isTimerStopped, question]);

  useEffect(() => {
    if (!question?.id) {
      return;
    }
    onStartTest();
  }, [question?.id]);

  useEffect(() => {
    setPercentage((seconds / TIME) * TOTAL_PERCENT);
  }, [seconds]);

  useEffect(() => {
    !!answer && setIsCorrect(answer?.is_correct_answer);
    !answer?.is_correct_answer &&
      answer?.correct_answers?.length &&
      setInorrect(answer?.correct_answers?.[0]);
  }, [answer]);

  const hndlSelect = (questionId: number, selectId: number) => {
    setSelected(selectId);
    hndlAnswer(questionId, selectId);
  };

  const hndlOpenStartModal = () => {
    setIsStartOpen(!isStartOpen);
  };

  const hndlNextQuestion = async () => {
    setIsFetchingNextAnswer(true);
    try {
      await getQuestion();
    } finally {
      setIsFetchingNextAnswer(false);
    }
  };

  const hndlAnswer = async (questionId: number, answerId: number) => {
    setIsTimerStopped(true);
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    setIsSubmittingAnswer(true);
    try {
      await fetchAnswer(questionId, answerId);
      setTimeout(() => {
        hndlNextQuestion();
      }, 1000);
    } catch (e) {
      onStart?.();
    } finally {
      setIsSubmittingAnswer(false);
    }
  };

  const onStartTest = () => {
    setIsCorrect(null);
    setInorrect(null);
    setSelected(null);
    setSeconds(TIME);
    setIsTimerStopped(false);
    setPercentage(TOTAL_PERCENT);
  };

  const goTo = () => {
    handleCloseTest();
    navigate(MainRoutes.edu + EduRoutes['digital-passes']);
  };

  const handleStartTest = () => {
    handleCloseTest();
    onStart?.();
  };

  return (
    <div className={classNames('QuizTest', className)}>
      {isQuestionLoading ? (
        <Loader />
      ) : (
        <>
          <div className="QuizTest__head">
            <div className="flex flex-col gap-2">
              <p>
                {t('wiz.Category')}: {currentTest?.title}
              </p>
              <h1>
                <span className={'text-purple-600'}>
                  {question?.current_question}/{question?.total_questions}
                </span>{' '}
                {t('wiz.Question')}: {question?.question.text}
              </h1>
            </div>
            <CalcIcon className="h-11 w-11 flex-none self-start fill-primary-purple-blue-500 lg:h-[60px] lg:w-[60px]" />
          </div>
          <div className="QuizTest__content">
            {question?.question.answers.map((answer) => (
              <div
                onClick={() => hndlSelect(question.id, answer.id)}
                className={classNames('relative', {
                  selected: selected === answer.id && isCorrect === null,
                  success:
                    (!!isCorrect && selected === answer.id) ||
                    inorrect === answer.id,
                  error: !isCorrect && isCorrect !== null && selected === answer.id,
                  'pointer-events-none': !!selected,
                  loading: isSubmittingAnswer && selected === answer.id,
                })}
                key={answer.id}
              >
                <span>
                  {answer.text}
                  {isSubmittingAnswer && selected === answer.id && (
                    <Loader className="absolute right-1 top-1 -mb-5 !h-5 !w-5 lg:!h-7 lg:!w-7" />
                  )}
                </span>
              </div>
            ))}
          </div>

          <div className="QuizTest__foot">
            <div
              className={classNames('QuizTest__bar', {
                ending: seconds < 6 && seconds > 0,
              })}
            >
              <div
                className={classNames('QuizTest__sec', {
                  '!text-primary-red-500': seconds < 6 && seconds > 0,
                })}
              >
                {seconds}
              </div>{' '}
              <ProgressBar size="md" rating={percentage} />
            </div>
          </div>
          <Modal isOpen={isStartOpen} onClose={handleStartTest} showClose={false}>
            <QuizEndModal onStart={handleStartTest} onClick={goTo} />
          </Modal>
        </>
      )}
    </div>
  );
};
