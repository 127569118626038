import React from 'react';

type Props = {
  variant?: string;
  extra?: string;
  children?: JSX.Element | any[];
  ref?: (node: HTMLElement | null) => void;
  style?: Record<string, string | undefined>;
};

export const Card: React.FC<
  Props &
    React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = (props) => {
  const { variant, extra, children, ...rest } = props;

  return (
    <div
      className={`!z-5 relative flex flex-col rounded-[20px] bg-white bg-clip-border shadow-2xl dark:!bg-navy-800 dark:text-white  ${extra}`}
      {...rest}
    >
      {children}
    </div>
  );
};
