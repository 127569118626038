import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DeletedUser from '../../../../assets/images/deleted-user.png';
import { Bread, Breadcrumbs } from '../../../../components/Breadcrumbs';
import {
  AuthRoutes,
  EduRoutes,
  MainRoutes,
  ProfileRoutes,
} from '../../../../horizon-layout/MainLayout/Routes/types/routes';
import { useTitleBackContext } from '../../../../providers/TitleBackProvider/TitleBackProvider';

export const DeletedProfile: React.FC = () => {
  const { t } = useTranslation(['translation']);
  const { useTitleBack } = useTitleBackContext();

  useTitleBack(t('menu.Profile'));

  const breads: Bread[] = useMemo(
    () => [
      {
        title: t('Edu'),
        url: MainRoutes.edu + EduRoutes.courses,
      },
      {
        title: t('Deleted User'),
        url:
          MainRoutes.auth + AuthRoutes.profile + ProfileRoutes.user + `/undefined`,
      },
    ],
    [t]
  );

  return (
    <div className="PublicProfile m-auto xl:container">
      <Breadcrumbs breads={breads} className="hidden md:block" />
      <div
        className={'flex flex-col items-center justify-center pt-[50px] lg:pt-0 '}
      >
        <img
          src={DeletedUser}
          className={'h-auto w-full sm:h-[500px] sm:w-[500px]'}
          alt={t('User has been deleted')}
        />
        <p className={'text-[20px] font-[600] text-purple-400'}>
          {t('User has been deleted')}
        </p>
      </div>
    </div>
  );
};
