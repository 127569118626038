import React from 'react';
import { ReactComponent as PenIcon } from '../../../assets/icons/profile/pen.svg';
import { useDropzone } from 'react-dropzone';

interface DropImgProps {
  onDrop?: (files: File[]) => void;
  multiple?: boolean;
  className?: string;
  children?: React.ReactNode;
}

export const DropImg = ({
  onDrop,
  multiple = true,
  className,
  children,
}: DropImgProps) => {
  const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple });

  return (
    <div {...getRootProps()} className="h-full w-full">
      {children}
      <div className={className}>
        <input {...getInputProps()} multiple={false} />
        <PenIcon />
      </div>
    </div>
  );
};
