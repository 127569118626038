import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Loader } from '../../../components/Loader';
import { NotFound } from '../../../components/NotFound/NotFound';
import { ProfileTeacherMyCourseCard } from '../../../components/ProfileTeacherMyCourseCard/ProfileTeacherMyCourseCard';
import { useMyCourses } from '../../../entities/Courses';
import { ActionBarCourse } from '../../../features/ActionBarCourse';
import { useNavigate } from '../../../hooks/useNavigate';
import { CourseCardItem, CourseStatus } from '../../../models/Course';
import { useUserContext } from '../../../providers';
import { CourseCard } from '../../../widgets/CourseCard';
import './styles.scss';
import SEO from '../../../components/SEO/SEO';

export const CourseList: React.FC = () => {
  const { t } = useTranslation(['translation']);
  const { user } = useUserContext();
  const navigate = useNavigate();
  const {
    data: rejectedCourses,
    isLoading: isRejectedLoading,
    fetchData: fetchRejectedData,
    fetchDelete: fetchRejectedDelete,
  } = useMyCourses();

  const {
    data: draftCourses,
    isLoading: isDraftLoading,
    fetchData: fetchDraftData,
    fetchDelete: fetchDraftDelete,
  } = useMyCourses();

  const {
    data: verifiedCourses,
    isLoading: isVerifiedLoading,
    fetchData: fetchVerifiedData,
  } = useMyCourses();

  const {
    data: unverifiedCourses,
    isLoading: isUnverifiedLoading,
    fetchData: fetchUnverifiedData,
    fetchDelete: fetchUnverifiedDelete,
  } = useMyCourses();

  useEffect(() => {
    if (user?.guid) {
      fetchRejectedData(CourseStatus.Rejected);
      fetchDraftData(CourseStatus.Draft);
      fetchVerifiedData(CourseStatus.Verified);
      fetchUnverifiedData(CourseStatus.Unverified);
    }
  }, [t]);

  const isMobile = useCallback(() => {
    const userAgent = navigator.userAgent || navigator.vendor;
    return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
      userAgent
    );
  }, []);

  useEffect(() => {
    if (isMobile()) {
      navigate(-1);

      toast(t('course.Course_creation_is_available_only_from_a_computer'), {
        type: 'info',
      });
    }
  }, [isMobile, t]);

  return (
    <>
      <SEO
        title={t('pages.create-course.meta.title')}
        desc={t('pages.create-course.meta.desc')}
      />

      <div className={'b-course-list'}>
        <div className={'flex items-center justify-between gap-x-2'}>
          <h1 className={'g-main-title text-[20px] md:text-[36px]'}>
            {t('menu.MyCourses')}
          </h1>
          {!!user && <ActionBarCourse />}
        </div>
        {isRejectedLoading ||
        isDraftLoading ||
        isVerifiedLoading ||
        isUnverifiedLoading ? (
          <Loader />
        ) : !rejectedCourses?.length &&
          !draftCourses?.length &&
          !verifiedCourses?.length &&
          !unverifiedCourses?.length ? (
          <NotFound />
        ) : (
          <>
            {!!rejectedCourses?.length && (
              <div className={'flex w-full flex-wrap gap-2 md:gap-5'}>
                <h3 className={'subtitle'}>{t('edu.Rejected')}</h3>
                <div
                  className={
                    'grid w-full grid-cols-1 flex-wrap gap-4 md:grid-cols-2 lg:grid-cols-3 4xl:grid-cols-4'
                  }
                >
                  {rejectedCourses?.map((course, i) => (
                    <ProfileTeacherMyCourseCard
                      course={course}
                      key={course.id}
                      onRemove={fetchRejectedDelete}
                    />
                  ))}
                </div>
              </div>
            )}
            {!!draftCourses?.length && (
              <div className={'flex w-full flex-wrap gap-2 md:gap-5'}>
                <h3 className={'subtitle'}>{t('edu.Drafts')}</h3>
                <div
                  className={
                    'grid w-full grid-cols-1 flex-wrap gap-4 md:grid-cols-2 lg:grid-cols-3 4xl:grid-cols-4'
                  }
                >
                  {draftCourses?.map((course, i) => (
                    <ProfileTeacherMyCourseCard
                      course={course}
                      key={course.id}
                      onRemove={fetchDraftDelete}
                    />
                  ))}
                </div>
              </div>
            )}
            {!!verifiedCourses?.length && (
              <div className={'flex w-full flex-wrap gap-2 md:gap-5'}>
                <h3 className={'subtitle'}>{t('edu.Verified')}</h3>
                <div
                  className={
                    'grid w-full grid-cols-1 flex-wrap gap-4 md:grid-cols-3 lg:grid-cols-4 4xl:grid-cols-5'
                  }
                >
                  {verifiedCourses?.map((course, i) => (
                    <CourseCard
                      course={course as unknown as CourseCardItem}
                      inCreateCourse
                      key={course.id}
                    />
                  ))}
                </div>
              </div>
            )}
            {!!unverifiedCourses?.length && (
              <div className={'flex w-full flex-wrap gap-2 md:gap-5'}>
                <h3 className={'subtitle'}>{t('edu.Unverified')}</h3>
                <div
                  className={
                    'grid w-full grid-cols-1 flex-wrap gap-4 md:grid-cols-2 lg:grid-cols-3 4xl:grid-cols-4'
                  }
                >
                  {unverifiedCourses?.map((course, i) => (
                    <ProfileTeacherMyCourseCard
                      course={course}
                      key={course.id}
                      onRemove={fetchUnverifiedDelete}
                    />
                  ))}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};
