import { useCallback, useState } from 'react';
import { ResponsePagination } from '../../../../../models/Response';
import { DEFAULT_LIMIT } from '../../../../../shared/lib/const/limit';
import {
  FeedRequest,
  deleteFeed,
  getFeeds,
  changeStatus,
} from '../../../../../api/weber/feed';
import { Feed, FeedStatus } from '../../../../../models/Feed';
import { useTranslation } from 'react-i18next';

export const usePosts = () => {
  const { t } = useTranslation(['translation']);
  const [data, setData] = useState<ResponsePagination<Feed>>();
  const [count, setCount] = useState(0);
  const [dataScroll, setDataScroll] = useState<Feed[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentPage, setPage] = useState(1);
  const total = data ? Math.ceil(data?.count / DEFAULT_LIMIT) : 0;

  const fetchData = useCallback(
    ({
      tags,
      order_by,
      group,
      author_guid,
      tags_or_title,
      title,
      following,
      feed_type,
      from_me,
      status,
      ordering,
      language,
      page,
      favourite_folder,
      favourited_posts,
    }: FeedRequest) => {
      setIsLoading(true);
      getFeeds({
        tags,
        page,
        order_by,
        group,
        author_guid,
        tags_or_title,
        title,
        following,
        feed_type,
        from_me,
        status,
        ordering,
        language,
        favourite_folder,
        favourited_posts,
      })
        .then((res) => {
          page && setPage(page);
          setCount(res?.count || 0);
          setData(res);
          res?.results?.length
            ? setDataScroll(
                page !== 1 ? [...dataScroll, ...res.results] : res.results
              )
            : setDataScroll([]);
          res !== undefined && setIsLoading(false);
        })
        .catch(() => setIsLoading(false));
    },
    [dataScroll, t]
  );

  // Функция для удаления элемента из массива по его id
  const removeItemById = (array: Feed[], id: number) => {
    const index = array.findIndex((item) => item.id === id);
    if (index !== -1) {
      // Создаем новый массив без удаленного элемента
      return [...array.slice(0, index), ...array.slice(index + 1)];
    }
    return array; // Возвращаем исходный массив, если элемент не найден
  };

  const fetchDelete = (id: number) => {
    deleteFeed(id).then(() => {
      setDataScroll((prevData) => removeItemById(prevData, id));
    });
  };

  const fetchStatus = (status: FeedStatus, resourcetype: string, id: number) => {
    changeStatus(status, resourcetype, id).then(() => {
      setDataScroll((prevData) => removeItemById(prevData, id));
    });
  };

  return {
    count,
    dataScroll,
    data,
    currentPage,
    total,
    isLoading,
    setPage,
    fetchData,
    fetchDelete,
    fetchStatus,
    setDataScroll,
  };
};
