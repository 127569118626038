import { User, UserProfile } from './User';

export type Friendship = {
  id: number;
  from_user_guid: string;
  to_user_guid: string;
  created_at: string;
  accepted: boolean;
  status: FriendshipStatus;
  another_user_key: 'from_user' | 'to_user';
  from_user: UserProfile | null;
  to_user: UserProfile | null;
};

export type UserFriendship = { user: User; friendship: Friendship | null };

export enum FriendshipStatus {
  FRIENDS = 'FRIENDS',
  WAITING_FOR_ACCEPT = 'WAITING_FOR_ACCEPT',
  WANTS_TO_BE_FRIENDS = 'WANTS_TO_BE_FRIENDS',
}
