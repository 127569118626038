import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { Capacitor } from '@capacitor/core';
import getPlatform from '../utils/getPlatform';

const yandexMetrikaId = process.env.REACT_APP_YANDEX_METRICA_ID || '';
const facebookPixelID = process.env.REACT_APP_FACEBOOK_PIXEL_ID || '';
declare global {
  interface Window {
    fbq: any;
    ym: any;
  }
}
interface EventParams {
  [key: string]: any;
}

export const setUserID = (userID: string = '') => {
  // if (window.hasOwnProperty('Telegram')) {
  //   return
  // }
  ReactGA.set({ userId: userID });

  if (window.ym) {
    window.ym(yandexMetrikaId, 'setUserID', userID);
  }
  if (window.fbq) {
    window.fbq('init', facebookPixelID, {
      external_id: userID,
    });
  }
};

export const usePageTracking = () => {
  const location = useLocation();
  useEffect(() => {
    // if (window.hasOwnProperty('Telegram')) {
    //   return
    // }
    // ReactGA.send({ hitType: "pageview", page: window.location.href});
    if (window.ym) {
      window.ym(yandexMetrikaId, 'hit', window.location.href);
    }
    if (window.fbq) {
      window.fbq('track', 'PageView');
    }
  }, [location]);
};

export const sendShare = (
  method: string = '',
  content_type: string = '',
  item_id: string = ''
) => {
  // if (window.hasOwnProperty('Telegram')) {
  //   return
  // }
  if (window.gtag) {
    ReactGA.gtag('event', 'share', {
      method: method,
      content_type: content_type,
      item_id: item_id,
    });
  }
  if (window.ym) {
    window.ym(yandexMetrikaId, 'reachGoal', 'share');
  }
  if (window.fbq) {
    window.fbq('trackCustom', 'share', {
      method: method,
      content_type: content_type,
      item_id: item_id,
    });
  }
};

export const sendSignUp = (method: string = '') => {
  // if (window.hasOwnProperty('Telegram')) {
  //   return
  // }
  ReactGA.gtag('event', 'sign_up', {
    method: method,
  });
  if (window.ym) {
    window.ym(yandexMetrikaId, 'reachGoal', 'sign_up');
  }
  if (window.fbq) {
    window.fbq('trackCustom', 'sign_up', {
      method: method,
    });
  }
};

export const sendLogin = function (method: string = '') {
  // if (window.hasOwnProperty('Telegram')) {
  //   return
  // }
  ReactGA.gtag('event', 'login', {
    method: method,
  });
  if (window.ym) {
    window.ym(yandexMetrikaId, 'reachGoal', 'login');
  }
  if (window.fbq) {
    window.fbq('trackCustom', 'login', {
      method: method,
    });
  }
};
// send information that mobile app was opened
export const sendOpenMobile = function (platform: string = '') {
  // if (window.hasOwnProperty('Telegram')) {
  //   return
  // }
  ReactGA.gtag('event', 'open_mobile', {
    platform: platform,
  });
  if (window.ym) {
    window.ym(yandexMetrikaId, 'reachGoal', 'open_mobile');
  }
  if (window.fbq) {
    window.fbq('trackCustom', 'open_mobile', {
      platform: platform,
    });
  }
};
export const sendOpenMobileIfNative = () => {
  if (Capacitor.isNativePlatform()) {
    sendOpenMobile(Capacitor.getPlatform());
  }
};
export const sendCreationComplite = function (
  unitId: any = undefined,
  unitTitle: string = ''
) {
  // if (window.hasOwnProperty('Telegram')) {
  //   return
  // }
  ReactGA.gtag('event', 'course_creation_complete', {
    unitId: unitId,
    unitTitle: unitTitle,
  });
  if (window.ym) {
    window.ym(yandexMetrikaId, 'reachGoal', 'course_creation_completet');
  }
  if (window.fbq) {
    window.fbq('trackCustom', 'course_creation_complete', {
      unitId: unitId,
      unitTitle: unitTitle,
    });
  }
};
export const sendCreationStart = function () {
  // if (window.hasOwnProperty('Telegram')) {
  //   return
  // }
  ReactGA.gtag('event', 'course_creation_start', {});
  if (window.ym) {
    window.ym(yandexMetrikaId, 'reachGoal', 'course_creation_start');
  }
  if (window.fbq) {
    window.fbq('trackCustom', 'course_creation_start', {});
  }
};
// TODO add course info to function values
export const sendCoursePurchase = function () {
  // if (window.hasOwnProperty('Telegram')) {
  //   return
  // }
  ReactGA.gtag('event', 'course_purchase', {});
  if (window.ym) {
    window.ym(yandexMetrikaId, 'reachGoal', 'course_purchase');
  }
  if (window.fbq) {
    window.fbq('trackCustom', 'course_purchase', {});
  }
};
// TODO add course info to function values
export const sendCourseCompletion = function () {
  // if (window.hasOwnProperty('Telegram')) {
  //   return
  // }
  ReactGA.gtag('event', 'course_completion', {});
  if (window.ym) {
    window.ym(yandexMetrikaId, 'reachGoal', 'course_completion');
  }
  if (window.fbq) {
    window.fbq('trackCustom', 'course_completion', {});
  }
};
export const sendEarnEDRES = function (value: number = 0) {
  // if (window.hasOwnProperty('Telegram')) {
  //   return
  // }
  ReactGA.gtag('event', 'earn_virtual_currency', {
    virtual_currency_name: 'EdRes',
    value: value,
  });
  if (window.ym) {
    window.ym(yandexMetrikaId, 'reachGoal', 'earn_virtual_currency');
  }
  if (window.fbq) {
    window.fbq('trackCustom', 'earn_virtual_currency', {
      virtual_currency_name: 'EdRes',
      value: value,
    });
  }
};
export const sendSpendEDRES = function (value: number = 0, item_name: string = '') {
  // if (window.hasOwnProperty('Telegram')) {
  //   return
  // }
  ReactGA.gtag('event', 'spend_virtual_currency', {
    virtual_currency_name: 'EdRes',
    value: value,
    item_name: item_name,
  });
  if (window.ym) {
    window.ym(yandexMetrikaId, 'reachGoal', 'spend_virtual_currency');
  }
  if (window.fbq) {
    window.fbq('trackCustom', 'spend_virtual_currency', {
      virtual_currency_name: 'EdRes',
      value: value,
      item_name: item_name,
    });
  }
};
export const sendSearch = function (search_term: string = '') {
  // if (window.hasOwnProperty('Telegram')) {
  //   return
  // }
  ReactGA.gtag('event', 'search', {
    search_term: search_term,
  });
  if (window.ym) {
    window.ym(yandexMetrikaId, 'reachGoal', 'search');
  }
  if (window.fbq) {
    window.fbq('trackCustom', 'search', {
      search_term: search_term,
    });
  }
};
export const sendLevelStart = function (level_name: string = '') {
  // if (window.hasOwnProperty('Telegram')) {
  //   return
  // }
  ReactGA.gtag('event', 'level_start', {
    level_name: level_name,
  });
  if (window.ym) {
    window.ym(yandexMetrikaId, 'reachGoal', 'level_start');
  }
  if (window.fbq) {
    window.fbq('trackCustom', 'level_start', {
      level_name: level_name,
    });
  }
};
export const sendLevelEnd = function (
  level_name: string = '',
  success: boolean = true
) {
  // if (window.hasOwnProperty('Telegram')) {
  //   return
  // }
  ReactGA.gtag('event', 'level_end', {
    level_name: level_name,
    success: success,
  });
  if (window.ym) {
    window.ym(yandexMetrikaId, 'reachGoal', 'level_end');
  }
  if (window.fbq) {
    window.fbq('trackCustom', 'level_end', {
      level_name: level_name,
      success: success,
    });
  }
};
export const waitForAnalytics = () => {
  return new Promise((resolve) => {
    const intervalId = setInterval(() => {
      if (ReactGA.isInitialized && window.ym && window.fbq) {
        resolve(undefined);
        clearInterval(intervalId);
      }
    }, 100);
  });
};

const IS_INSTALL_SENT_KEY = 'is_install_analytics_sent';
export const sendInstallIfFirstLaunch = () => {
  if (localStorage.getItem(IS_INSTALL_SENT_KEY)) return;
  const event = `install_${getPlatform()}`;

  ReactGA.gtag('event', event);
  if (window.ym) {
    window.ym(yandexMetrikaId, 'reachGoal', event);
  }
  if (window.fbq) {
    window.fbq('trackCustom', event);
  }
  localStorage.setItem(IS_INSTALL_SENT_KEY, 'YES');
};

export const sendLandingEvent = (prefix: string, eventName: string) => {
  const event = `${prefix}_${eventName}`;
  ReactGA.gtag('event', event);
  if (window.ym) {
    window.ym(yandexMetrikaId, 'reachGoal', event);
  }
  if (window.fbq) {
    window.fbq('trackCustom', event);
  }
};

const useAnalytics = () => {
  // Отправка события в Google Analytics
  const sendEventToGoogleAnalytics = (
    eventName: string,
    eventParams: EventParams = {}
  ) => {
    // if (window.hasOwnProperty('Telegram')) {
    //   return
    // }
    if (window.gtag) {
      window.gtag('event', eventName, eventParams);
    }
  };

  // Отправка события в Яндекс Метрику
  const sendEventToYandexMetrika = (
    eventName: string,
    eventParams: EventParams = {}
  ) => {
    // if (window.hasOwnProperty('Telegram')) {
    //   return
    // }
    const counter = (window as any)[`yaCounter${yandexMetrikaId}`];
    if (counter) {
      counter.reachGoal(eventName, eventParams);
    }
  };

  // Отправка события в обе системы аналитики
  const sendEvent = (eventName: string, eventParams: EventParams = {}) => {
    sendEventToGoogleAnalytics(eventName, eventParams);
    sendEventToYandexMetrika(eventName, eventParams);
  };

  return { sendEvent };
};

export default useAnalytics;
