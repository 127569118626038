import { ReactNode } from 'react';
import classNames from 'classnames';
import './NotificationCard.scss';

interface NotificationCardProps {
  className?: string;
  title?: string;
  time?: string;
  icon?: ReactNode;
}

export const NotificationCard = ({
  className,
  title,
  time,
  icon,
}: NotificationCardProps) => {
  return (
    <div className={classNames('NotificationCard', className)}>
      {icon && <div className="NotificationCard__icon">{icon}</div>}
      <div className="NotificationCard__content">
        {title && <h4>{title}</h4>}
        {time && <span>{time}</span>}
      </div>
    </div>
  );
};
