export const generateRandomLetter = () => {
  var alphabet = 'abcdefghijklmnopqrstuvwxyz';
  var randomIndex = Math.floor(Math.random() * alphabet.length);
  return alphabet[randomIndex];
};

export const generateUniqueName = (expansion: string) => {
  var timestamp = new Date().getTime();
  var randomLetters = '';
  for (var i = 0; i < 3; i++) {
    randomLetters += generateRandomLetter().toUpperCase(); // Заглавные буквы
    randomLetters += generateRandomLetter(); // Прописные буквы
  }
  var random = Math.floor(Math.random() * 10000); // Генерируем случайное число от 0 до 9999
  return 'img_' + timestamp + '_' + randomLetters + '_' + random + '.' + expansion;
};
