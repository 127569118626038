import React from 'react';
import classNames from 'classnames';
import './styles.scss';

type Props = {
  id?: string;
  text?: string;
  checked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type?: 'checkbox' | 'radio' | 'rounded-checkbox';
  size?: 'lg' | 'sm';
  name?: string;
  disabled?: boolean;
};

export const MCheckbox: React.FC<Props> = ({
  id,
  text,
  checked,
  onChange,
  type = 'checkbox',
  name,
  size = 'sm',
  disabled,
}) => {
  return (
    <div className={'flex items-center'}>
      <input
        id={id}
        checked={checked}
        onChange={onChange}
        style={{ borderStyle: 'solid' }}
        type="checkbox"
        className={classNames(
          'defaultCheckbox border-[1px] border-secondary-grey-500 text-white/0 checked:border-purple-500',
          `relative flex h-[20px] min-h-[20px] w-[20px] min-w-[20px] appearance-none items-center justify-center outline-none transition duration-[0.2s]`,
          {
            'small-checkbox rounded-[4px] checked:bg-purple-500 checked:text-white hover:cursor-pointer':
              type === 'checkbox' && size === 'sm',
            'rounded-[5px] checked:bg-purple-500 checked:text-white hover:cursor-pointer':
              type === 'checkbox' && size === 'lg',
            'radioCheck rounded-full checked:text-white hover:cursor-pointer':
              type === 'radio' && size === 'sm',
            'radioCheck-lg rounded-full checked:text-white hover:cursor-pointer':
              type === 'radio' && size === 'lg',
            'small-checkbox rounded-full checked:bg-purple-500 checked:text-white hover:cursor-pointer':
              type === 'rounded-checkbox' && size === 'sm',
            'rounded-full checked:bg-purple-500 checked:text-white hover:cursor-pointer':
              type === 'rounded-checkbox' && size === 'lg',
          }
        )}
        name={name}
        disabled={disabled}
      />
      {!!text && (
        <label htmlFor={id} className={'ml-[10px] cursor-pointer'}>
          {text}
        </label>
      )}
    </div>
  );
};
