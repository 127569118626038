import { useCallback, useState } from 'react';
import {
  setPostFavouriteFolder,
  getPostFavouriteFolderById,
  deletePostFavouriteFolderById,
  updatePostFavouriteFolderById,
} from '../../../../../api/weber/feed';
import { TFavoriteFolders } from '../../../../../models/Feed';
import { useTranslation } from 'react-i18next';

export const usePostFavorite = () => {
  const { t } = useTranslation(['translation']);
  const [data, setData] = useState<TFavoriteFolders | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchData = useCallback(
    (postId: number) => {
      setIsLoading(true);
      getPostFavouriteFolderById(postId).then((res) => {
        setData(res);
        res?.id && setIsLoading(false);
      });
    },
    [t]
  );

  const addToFavorite = useCallback(
    async (post: number, favourite_folder?: number | null) => {
      setIsLoading(true);
      await setPostFavouriteFolder(post, favourite_folder).then((res) => {
        setData(res);
        res?.id && setIsLoading(false);
      });
    },
    [t]
  );

  const updateFavorite = useCallback(
    async (currentId: number, postId: number, favourite_folder: number) => {
      setIsLoading(true);
      await updatePostFavouriteFolderById(currentId, postId, favourite_folder).then(
        (res) => {
          setData(res);
          res?.id && setIsLoading(false);
        }
      );
    },
    [t]
  );

  const deleteFromFavorite = useCallback(
    async (folderId: number) => {
      setIsLoading(true);
      await deletePostFavouriteFolderById(folderId).then((res) => {
        setData(res);
        res?.id && setIsLoading(false);
      });
    },
    [t]
  );

  return {
    data,
    isLoading,
    fetchData,
    addToFavorite,
    deleteFromFavorite,
    updateFavorite,
  };
};
