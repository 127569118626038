import { useCallback, useEffect, useState } from 'react';
import { UserMeta } from '../../../../../models';
import { getUserMeta } from '../../../../../api/auth';

export const useUserMeta = (guid: string) => {
  const [data, setData] = useState<UserMeta | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchData = useCallback(() => {
    if (guid === 'undefined') return;
    setIsLoading(true);
    getUserMeta(guid)
      .then((res) => {
        setData(res);
        res !== undefined && setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, [guid]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { data, isLoading };
};
