import { ResponsePagination } from '../../models/Response';
import instance, { WEBER_API_URL } from '../instance';
import { Friendship } from '../../models/Friendship';
import { UserCommunity, UserCommunityRole, UserProfile } from '../../models';

export function getFriendshipsWithPage(
  page?: number,
  accepted?: boolean,
  from_me?: boolean,
  search?: string,
  user_guid?: string
): Promise<ResponsePagination<Friendship>> {
  return instance()
    .get(`${WEBER_API_URL}/friendship/`, {
      params: {
        accepted,
        from_me,
        search,
        user_guid,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getFriendships(
  accepted?: boolean,
  search?: string,
  from_me?: boolean,
  user_guid?: string,
  page?: number
): Promise<ResponsePagination<Friendship>> {
  return instance()
    .get(`${WEBER_API_URL}/friendship/`, {
      params: {
        accepted,
        from_me,
        search,
        user_guid,
        page: (page || 0) + 1,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function addFriendship(to_user_guid: string): Promise<Friendship> {
  return instance()
    .post(`${WEBER_API_URL}/friendship/`, {
      to_user_guid,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function acceptFriendship(id: number): Promise<void> {
  return instance()
    .post(`${WEBER_API_URL}/friendship/${id}/accept/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function rejectFriendship(id: number): Promise<void> {
  return instance()
    .delete(`${WEBER_API_URL}/friendship/${id}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function acceptAllFriendship(): Promise<void> {
  return instance()
    .post(`${WEBER_API_URL}/friendship/accept_all/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function rejectAllFriendship(): Promise<void> {
  return instance()
    .post(`${WEBER_API_URL}/friendship/reject_all/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getPublicFriendships(
  page?: number,
  user_guid?: string,
  accepted?: boolean,
  from_user?: boolean,
  username?: string
): Promise<ResponsePagination<Friendship>> {
  return instance()
    .get(`${WEBER_API_URL}/public-friendship/`, {
      params: {
        page,
        accepted,
        from_user,
        user_guid,
        username,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getGroupMembers(
  group?: string,
  page?: number,
  search?: string
): Promise<ResponsePagination<UserCommunity>> {
  return instance()
    .get(`${WEBER_API_URL}/group-member/`, {
      params: {
        group,
        page: (page || 0) + 1,
        search,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getGroupMembersSetRole(
  id: number,
  role: UserCommunityRole
): Promise<{ role: UserCommunityRole }> {
  return instance()
    .post(`${WEBER_API_URL}/group-member/${id}/set_role/`, { role })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getGroupMembersRemoveRole(
  id: number
): Promise<{ role: UserCommunityRole }> {
  return instance()
    .post(`${WEBER_API_URL}/group-member/${id}/remove_role/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}
