import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  className?: string;
};

export const Footer: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation(['translation']);
  return (
    <div
      className={`flex w-full flex-col items-center justify-between px-1 pb-8 pt-3 md:flex-row lg:px-8 ${className}`}
    >
      <div className="text-[10px] font-medium text-gray-400">
        Edres ©{new Date().getFullYear()}. {t('main.footer')}
      </div>
    </div>
  );
};
