import { useCallback, useEffect, useRef, useState } from 'react';
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';
import { toast } from 'react-toastify';
import { getRefreshTokenStorage, saveAssesTokenStorage } from '../../../api';
import { refreshToken } from '../../../api/auth';
import {
  Question,
  WIZ_TOKEN_STORAGE_KEY,
  getQuestion,
  getStatus,
  registerInWiz,
  startTest,
  submitAnswer,
} from '../../../api/wiz/wiz';
import { Loader } from '../../../components/Loader';
import { useNavigate } from '../../../hooks/useNavigate';
import {
  MainRoutes,
  WizRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';
import { WizStatusEnum } from '../../../models/Wiz';
import { useUserContext } from '../../../providers';
import { Start } from '../Start/Start';
import './Quizzes.scss';
import { QuizzesAnswers } from './QuizzesAnswers/QuizzesAnswers';
import { QuizzesQuestion } from './QuizzesQuestion/QuizzesQuestion';

const TIME = 60;

export const Quizzes = () => {
  const navigate = useNavigate();
  const { user } = useUserContext();
  const [seconds, setSeconds] = useState(TIME);
  const [question, setQuestion] = useState<null | Question>(null);
  const [selectedAnswer, setSelectedAnswer] = useState<null | number>(null);
  const [status, setStatus] = useState<null | WizStatusEnum>(null);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const wizToken = localStorage.getItem(WIZ_TOKEN_STORAGE_KEY);

  const register = async () => {
    let jwtToken = null;
    if (user) {
      const refreshJwtToken = getRefreshTokenStorage() as string;
      const { access } = await refreshToken(refreshJwtToken);
      saveAssesTokenStorage(access);
      jwtToken = access;
    }

    const wizToken = await registerInWiz(jwtToken);
    localStorage.setItem(WIZ_TOKEN_STORAGE_KEY, wizToken);
    checkStatus();
  };

  useEffect(() => {
    (async () => {
      if (wizToken) {
        checkStatus();
      } else {
        await register();
      }
    })();
  }, [wizToken]);

  useEffect(() => {
    (async () => {
      if (status === WizStatusEnum.TEST_STARTED) {
        const question = await getQuestion();
        setQuestion(question);
      }
    })();
  }, [status]);

  const checkStatus = async () => {
    const status = await getStatus();
    setStatus(status);
  };

  const onSubmitButtonClick = useCallback(
    async (selected?: number) => {
      setSelectedAnswer(selected || null);
      try {
        await submitAnswer(selected);
        if (timerRef.current) {
          clearInterval(timerRef.current);
        }
        if (question?.question_number === 4) {
          navigate(MainRoutes.wiz + WizRoutes.performance);
        } else {
          const question = await getQuestion();
          setQuestion(question);
          setSelectedAnswer(null);
          setSeconds(TIME);
        }
      } catch (e) {
        toast('An error occured. Please, reload page or try again later.', {
          type: 'error',
        });
      }
    },
    [question?.question_number]
  );

  useEffect(() => {
    if (status === WizStatusEnum.TEST_STARTED) {
      timerRef.current = setInterval(() => {
        if (seconds === 0 && onSubmitButtonClick) {
          clearInterval(timerRef.current!);
          onSubmitButtonClick();
        } else {
          setSeconds((prevSeconds) => prevSeconds - 1);
        }
      }, 1000);
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [seconds, onSubmitButtonClick, status]);

  const onStartClicked = async () => {
    setIsLoading(true);
    try {
      await register();
      if (status === WizStatusEnum.NOT_STARTED) {
        await startTest();
        setStatus(WizStatusEnum.TEST_STARTED);
        checkStatus();
      }
    } catch (e) {
      localStorage.removeItem(WIZ_TOKEN_STORAGE_KEY);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <Loader />;

  return status && status !== WizStatusEnum.TEST_STARTED ? (
    <Start statusTest={status} onStart={onStartClicked} />
  ) : (
    <div className="Quizzes">
      <div className="Quizzes__wrapper">
        {!question && !status && <Loader />}
        {question && (
          <>
            <QuizzesQuestion
              category={question?.category?.category_name}
              question={question?.text}
              image={question?.category?.image}
            />
            <QuizzesAnswers
              answers={question.answers}
              onSelect={onSubmitButtonClick}
              selected={selectedAnswer}
            />
            <div className={'flex justify-end'}>
              <div className="Quizzes__timer">
                <CircularProgressbarWithChildren
                  value={seconds}
                  styles={buildStyles({
                    rotation: 0,
                    pathTransitionDuration: 0.5,
                    pathColor: `#4318FF`,
                    trailColor: '#FAFCFE',
                  })}
                  maxValue={TIME}
                >
                  <span className="text-xl md:text-[40px]">{seconds}</span>
                </CircularProgressbarWithChildren>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
