import { Course, CourseCardItem } from '../models/Course';
import { ResponsePagination } from '../models/Response';
import { CourseRequest } from './course';
import instance from './instance';

export function getPublicCoursesV3(
  params: CourseRequest
): Promise<ResponsePagination<CourseCardItem>> {
  return instance()
    .get(`/v3/course/public/course/`, { params: { ...params } })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getPublicCourseIdV3(id: number): Promise<Course> {
  return instance()
    .get(`/v3/course/public/course/${id}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getPrivateEnrolledCoursesV3(): Promise<number[]> {
  return instance()
    .get(`/v3/course/private/enrolled-courses/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getPrivateFavouriteCoursesV3(): Promise<number[]> {
  return instance()
    .get(`/v3/course/private/favourite-courses/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getPrivateCoursesV3(
  params: CourseRequest
): Promise<ResponsePagination<CourseCardItem>> {
  return instance()
    .get(`/v3/course/private/course/`, { params: { ...params } })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getPrivateStartedCoursesV3(): Promise<CourseCardItem[]> {
  return instance()
    .get(`/v3/course/private/started-courses/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getPublicTagsAutocompleteV3(): Promise<
  ResponsePagination<{
    name: 'string';
  }>
> {
  return instance()
    .get(`/v3/course-creation/public/tags-autocomplete/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getPrivateGetCoursebyAccordingProgramV3(
  subject_id: number
): Promise<Course> {
  return instance()
    .get(`/v3/course/private/get-course/by-according-program/${subject_id}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export function getMaxTokenAmountByCourseV3(
  course_id: number
): Promise<{ max_token_amount: number }> {
  return instance()
    .get(`/v3/course/public/get-max-token-amount-by-course/${course_id}/`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}
