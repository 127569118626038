import React from 'react';
import { HButton } from '../../../../horizon-components/Button/HButton';
import { useTranslation } from 'react-i18next';
import { MButton } from '../../../../horizon-components/MButton/ui/MButton';

type Props = {
  testResult: boolean[];
  setTestResult: (testResult: null) => void;
  onNextClick: () => void;
};

export const TopicTestResult: React.FC<Props> = ({
  testResult,
  setTestResult,
  onNextClick,
}) => {
  const { t } = useTranslation(['translation']);
  const rowClasses = 'flex flex-row justify-between items-center';
  const topicClasses =
    'text-[14px] font-medium text-secondary-dark-grey-900 md:text-[16px]';
  const resultContainerClasses =
    'rounded-[8px] md:px-[16px] md:py-[12px] px-[12px] py-[8px] font-semibold md:text-[14px] text-[12px]';
  const resultDefaultClasses = 'bg-[#C9FBD5] text-[#009B73]';
  const resultWrongClasses = 'bg-[#FDD0D7] text-[#C31322]';
  const resultTotalClasses = 'bg-[#E9E3FF] text-[#3311DB]';
  const hrClasses = 'border-[#E1E9F8] w-full border-1';

  return (
    <div className={'flex flex-col gap-[28px]'}>
      <div className={'text-[20px] font-bold'}>{t('course.topicTestResults')}</div>
      <div className={'flex flex-col gap-[16px] md:gap-[24px]'}>
        {testResult.map((result, index) => (
          <div key={index} className={rowClasses}>
            <div className={topicClasses}>
              {t('course.question')} {index + 1}
            </div>
            <div
              className={`${resultContainerClasses} ${
                result ? resultDefaultClasses : resultWrongClasses
              }`}
            >
              {t(result ? 'course.correct' : 'course.incorrect')}
            </div>
          </div>
        ))}
        <hr className={hrClasses} />
        <div className={rowClasses}>
          <div className={topicClasses}>{t('course.total')}</div>
          <div
            className={`${resultContainerClasses} ${resultTotalClasses} uppercase`}
          >
            {testResult.filter((result) => result).length} / {testResult.length}{' '}
            {t('course.correct')}
          </div>
        </div>
        {
          <>
            <hr className={hrClasses} />
            <div className={'flex justify-between'}>
              <MButton
                onClick={() => setTestResult(null)}
                variant="outline"
                color="primary"
              >
                {t('course.tryAgain')}
              </MButton>
              <MButton color="primary" variant="highlighted" onClick={onNextClick}>
                {t('course.next')}
              </MButton>
            </div>
          </>
        }
      </div>
    </div>
  );
};
