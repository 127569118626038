import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ResourceType } from '../../../api/weber/feed';
import { Communities } from '../../../modules/Weber/Communities/Communities';
import { CommunitiesForm } from '../../../modules/Weber/Communities/CommunitiesForm/CommunitiesForm';
import { CommunityDetail } from '../../../modules/Weber/CommunityDetail/CommunityDetail';
import { EditFeed } from '../../../modules/Weber/Feed/EditFeed/EditFeed';
import { FavoriteView } from '../../../modules/Weber/Feed/FavoriteView/FavoriteView';
import { FeedList } from '../../../modules/Weber/Feed/FeedList/FeedList';
import { FeedView } from '../../../modules/Weber/Feed/FeedView/FeedView';
import { MainRoutes, WeberRoutes } from './types/routes';
import { Navigate } from '../../../horizon-components/Navigate';

export const WeberRouter: React.FC = () => {
  return (
    <div className="mx-auto w-full grow sm-max:p-2">
      <Routes>
        <Route
          path="/"
          element={<Navigate to={MainRoutes.weber + WeberRoutes.feeds} replace />}
        />
        <Route path={WeberRoutes.feeds} element={<FeedList />} />
        <Route path={WeberRoutes.feed + '/:id'} element={<FeedView />} />
        <Route path={WeberRoutes.favorites + '/:id'} element={<FavoriteView />} />
        <Route
          path={WeberRoutes['feed-create-attachments']}
          element={<EditFeed resourcetype={ResourceType.AttachmentsPost} />}
        />
        <Route
          path={WeberRoutes['feed-create-link']}
          element={<EditFeed resourcetype={ResourceType.LinkPost} />}
        />
        <Route
          path={WeberRoutes['feed-create-article']}
          element={<EditFeed resourcetype={ResourceType.ArticlePost} />}
        />
        <Route
          path={WeberRoutes['feed-edit'] + '/:id'}
          element={<EditFeed isEdit />}
        />

        <Route path={WeberRoutes.communities} element={<Communities />} />
        <Route
          path={WeberRoutes.community + '/:communityId'}
          element={<CommunityDetail />}
        />
        <Route
          path={WeberRoutes['community-create']}
          element={<CommunitiesForm />}
        />
        <Route
          path={WeberRoutes['community-edit'] + '/:id'}
          element={<CommunitiesForm isEdit />}
        />
      </Routes>
    </div>
  );
};
