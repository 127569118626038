import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useCommunities } from '../../../../entities/Communities';
import { CommunityCard } from '../../../../widgets/CommunityCard';
import { Loader } from '../../../../components/Loader';
import { NotFound } from '../../../../components/NotFound/NotFound';
import { CommunitiesRequest } from '../../../../api/weber/communities';
import useInfiniteScroll from '../../../../hooks/useInfiniteScroll';

type Props = {
  filters: CommunitiesRequest;
};

export const CommunitiesList: React.FC<Props> = ({ filters }) => {
  const { t } = useTranslation(['translation']);
  const {
    dataScroll: communities,
    isLoading,
    currentPage,
    total,
    fetchData,
    setDataScroll,
  } = useCommunities();

  useEffect(() => {
    filters.page === 1 && setDataScroll([]);
  }, [filters]);

  const loadComments = useCallback(() => {
    if (total > currentPage) {
      fetchData({ ...filters, page: currentPage + 1 });
    }
  }, [total, currentPage]);

  const targetRef = useRef<HTMLDivElement | null>(null);
  useInfiniteScroll(targetRef, loadComments);

  useEffect(() => {
    Object.keys(filters).length && fetchData(filters);
  }, [filters]);

  return (
    <>
      {communities?.length ? (
        <>
          <div className="grid gap-[8px] md:grid-cols-2 md:gap-[16px] lg:grid-cols-3">
            {communities?.map((community) => (
              <CommunityCard
                key={community.id}
                id={community.id}
                name={community.name}
                image={community.custom_image || community.predefined_image.image}
                members_count={community.members_count}
                join_status={community.join_status}
                subject={community.subject}
                type={community.type}
                chat={community.chat}
                isFollow={filters.my_groups}
              />
            ))}
          </div>
          <div ref={targetRef} />
          {isLoading && <Loader className="mt-4" />}
        </>
      ) : isLoading ? (
        <Loader className="mt-4" />
      ) : (
        <NotFound text={t('communities.notFound')} />
      )}
    </>
  );
};
