import { useDropzone } from 'react-dropzone';
import React from 'react';

type Props = {
  content: JSX.Element;
  onDrop: (acceptedFiles: File[]) => void;
};

export const DropZonefile: React.FC<Props> = ({ content, onDrop }) => {
  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  return (
    <div
      className="dropzone flex h-full !w-full cursor-pointer items-center justify-center rounded-xl border-dashed border-navy-700"
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <button className="flex h-full !w-full justify-center">{content}</button>
    </div>
  );
};
