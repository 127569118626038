export const getAllImages = (str: string) => {
  const regex = /<img.*?src="(data:image\/\w+;base64,.*?)".*?>/g;
  const images = [];
  let match;

  while ((match = regex.exec(str)) !== null) {
    images.push(match[1]);
  }

  return images;
};
