import React from 'react';
import { Modal } from '../../../../horizon-components/Modal';
import { useTranslation } from 'react-i18next';

type Props = {
  isOpen: boolean;
  onClose(): void;
};

export const RulesModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const { t } = useTranslation(['translation']);
  return (
    <Modal isOpen={isOpen} onClose={onClose} title={t('wiz.How_to_Play')}>
      <>
        <ul className={'mt-3 flex flex-col gap-2 text-[16px]'}>
          <li>- {t('wiz.Answer_questions_about_AI_Blockchain_Math_Games')}</li>
          <li>
            - {t('wiz.Earn_ERs_points_and_buy_products_and_more_on_our_platform')}
          </li>
          <li>- {t('wiz.Login_or_create_a_free_account')}</li>
          <li>- {t('wiz.Share_the_results_in_social_media')}</li>
        </ul>
      </>
    </Modal>
  );
};
