import classNames from 'classnames';
import './style.scss';
import { WizDailyStatusEnum } from '../../api/wiz/wiz';

interface QuizDayCardProps {
  className?: string;
  title?: string;
  earn?: string;
  status?: WizDailyStatusEnum;
}

export const QuizDayCard = ({
  className,
  title,
  earn,
  status,
}: QuizDayCardProps) => {
  return (
    <div className={classNames('QuizDayCard', className, status)}>
      <h3>{title}</h3>
      <p>{earn}</p>
    </div>
  );
};
