import React from 'react';
import { useTranslation } from 'react-i18next';
import { UserType } from '../../../models';
import { useUserContext } from '../../../providers';
import { InfoInstitution } from './Info/InfoInstitution';
import { InfoUser } from './Info/InfoUser';
import SEO from '../../../components/SEO/SEO';

export const EditProfile: React.FC = () => {
  const { t } = useTranslation(['translation']);
  const { user } = useUserContext();

  if (!user) return null;

  return (
    <div className={'w-full'}>
      <SEO
        title={t('pages.profile-settings.meta.title')}
        desc={t('pages.profile-settings.meta.desc')}
      />

      {user.user_type === UserType.INSTITUTION ? <InfoInstitution /> : <InfoUser />}
    </div>
  );
};
