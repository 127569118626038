import React, { useEffect, useState } from 'react';
import { AiResponse, fetchAiAssistantV3 } from '../../../../api/course';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Coursor } from '../../../../assets/icons/click-coursor.svg';
import { MButton } from '../../../../horizon-components/MButton/ui/MButton';
import { TaxonomyEnum } from '../../../../models/AiAssistant';
import { Loader } from '../../../../components/Loader';
import { toast } from 'react-toastify';
import getErrorMessages from '../../../../utils/getErrorMessages';

type Props = {
  topicId: number;
};

export const AiAssistant: React.FC<Props> = ({ topicId }) => {
  const [aiResults, setAiResults] = useState<Record<string, AiResponse>>({});
  const { t } = useTranslation(['translation']);
  const [selectedTaxonomy, setSelectedTaxonomy] = useState<TaxonomyEnum | null>(
    null
  );

  const onGenerateButtonClicked = async (_selectedTaxonomy: TaxonomyEnum) => {
    setSelectedTaxonomy(_selectedTaxonomy);
    const localTaxonomyData = aiResults[_selectedTaxonomy];
    if (!localTaxonomyData) {
      try {
        const results = await fetchAiAssistantV3(topicId, _selectedTaxonomy);
        setAiResults({ ...aiResults, [_selectedTaxonomy]: results });
      } catch (error: any) {
        toast(getErrorMessages(error?.response?.data), {
          type: 'error',
        });
      }
    }
  };

  const pollAiResults = async () => {
    console.log(aiResults);
    for (const [taxonomy, aiResponse] of Object.entries(aiResults)) {
      if (aiResponse.status !== 202) continue;

      const results = await fetchAiAssistantV3(topicId, taxonomy as TaxonomyEnum);
      setAiResults({ ...aiResults, [taxonomy]: results });
    }
  };

  useEffect(() => {
    const intervalId = setInterval(pollAiResults, 1000);
    return () => clearInterval(intervalId);
  }, [aiResults]);

  return (
    <div className="flex w-full flex-col gap-[8px]">
      <div className="flex w-full flex-col flex-wrap items-start justify-between gap-[10px] rounded-[20px] bg-secondary-grey-300 p-[8px] md:flex-row md:p-[16px]">
        <MButton
          className={'w-full md:w-auto'}
          color={'primary'}
          variant={'outline'}
        >
          {t('course.ai')}
          <Coursor />
        </MButton>
        <div
          className={
            'flex w-full items-center justify-between self-stretch overflow-x-auto md:w-auto md:gap-[8px]'
          }
        >
          <MButton
            size="sm"
            onClick={() => onGenerateButtonClicked(TaxonomyEnum.EVALUATOR)}
            variant="highlighted"
            color={selectedTaxonomy === TaxonomyEnum.EVALUATOR ? 'primary' : 'white'}
          >
            {t(`course.Evaluator`)}
          </MButton>
          <MButton
            size="sm"
            onClick={() => onGenerateButtonClicked(TaxonomyEnum.ANALYST)}
            variant="highlighted"
            color={selectedTaxonomy === TaxonomyEnum.ANALYST ? 'primary' : 'white'}
          >
            {t(`course.Analyst`)}
          </MButton>
          <MButton
            size="sm"
            onClick={() => onGenerateButtonClicked(TaxonomyEnum.PRACTITIONER)}
            variant="highlighted"
            color={
              selectedTaxonomy === TaxonomyEnum.PRACTITIONER ? 'primary' : 'white'
            }
          >
            {t(`course.Practitioner`)}
          </MButton>
        </div>
      </div>
      {selectedTaxonomy !== null && (
        <div className={'b-course-content__unit'}>
          {aiResults[selectedTaxonomy] &&
            aiResults[selectedTaxonomy].status === 200 &&
            aiResults[selectedTaxonomy].data.result}
          {(!aiResults[selectedTaxonomy] ||
            aiResults[selectedTaxonomy].status === 202) && <Loader />}
        </div>
      )}
    </div>
  );
};
