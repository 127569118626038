import classNames from 'classnames';
import { ReactComponent as PlayIcon } from '../assets/icons/play.svg';
import { ReactComponent as CheckIcon } from '../assets/icons/check.svg';
import { useTranslation } from 'react-i18next';
import './GradeCard.scss';
import { Grade } from '../../../models/Course';

interface GradeCardProps {
  style?: {};
  className?: string;
  title?: string;
  description?: string;
  completed?: number;
  units?: number;
  isActive?: boolean;
  isEnrolled?: boolean;
  isCompleted?: boolean;
  onActive?: () => void;
  grade?: Grade;
  courseCount?: number;
}

export const GradeCard = ({
  style,
  className,
  title,
  description,
  completed,
  units,
  isActive = false,
  isEnrolled = false,
  isCompleted = false,
  onActive,
  grade,
  courseCount,
}: GradeCardProps) => {
  const { t } = useTranslation(['translation']);
  return (
    <div
      className={classNames('GradeCard', className, {
        active: isActive,
        enrolled: isCompleted || completed,
      })}
      onClick={onActive}
      style={style || {}}
    >
      <div>
        <h4>{title}</h4>
        {description}
      </div>
      <div className="flex items-center gap-1">
        {!!units && (
          <span>
            {completed ? completed + '/' + units : units + ' ' + t('course.units')}
          </span>
        )}
        {!grade && courseCount !== undefined && (
          <span>
            {courseCount} {t('course.units_courses')}
          </span>
        )}
        {!!grade && (
          <>
            <div className={'text-p text-primary-green-700'}>{grade}</div>
            <CheckIcon />
          </>
        )}
        {isActive && !isEnrolled && <PlayIcon />}
      </div>
    </div>
  );
};
