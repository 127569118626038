import classNames from 'classnames';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArticleIcon } from '../../../assets/icons/feed/article.svg';
import { ReactComponent as LinkIcon } from '../../../assets/icons/feed/link.svg';
import { ReactComponent as Plus } from '../../../assets/icons/plus.svg';
import { useNavigate } from '../../../hooks/useNavigate';
import { MButton } from '../../../horizon-components/MButton';
import {
  EduRoutes,
  MainRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';
import './ActionBarCourse.scss';

export interface ActionButton {
  title: string;
  link?: string;
  icon: JSX.Element; // Assuming the icon is a JSX element
  description: string;
  onClick?: () => void;
}

interface ActionBarCourseProps {
  className?: string;
  variant?: 'highlighted' | 'outline';
}

export const ActionBarCourse = ({
  className,
  variant = 'highlighted',
}: ActionBarCourseProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation(['translation']);
  const [showActions, setShowActions] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setShowActions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setShowActions]);

  const buttons: ActionButton[] = useMemo(
    () => [
      {
        title: t('edu.The_course_according_to_the_school_curriculum'),
        link: MainRoutes.edu + EduRoutes.edit + `/0/upload-content`,
        icon: <LinkIcon />,
        description: t('edu.Create_a_course_for_school_subjects_and_get_20_000_ERP'),
      },
      {
        title: t('edu.A_course_on_a_free_topic'),
        link:
          MainRoutes.edu +
          EduRoutes.edit +
          `/0/upload-content?is_miscellaneous_subject=true`,
        icon: <ArticleIcon />,
        description: t(
          'edu.Publish_your_course_on_any_topic_and_receive_regular_rewards'
        ),
      },
    ],
    [t]
  );

  const handleClick = (link: string) =>
    navigate(link, {
      state: { communityIdFeed: true },
    });

  return (
    <div className={classNames('sm:relative', className)}>
      <MButton
        color="primary"
        variant={variant}
        onClick={() => setShowActions(!showActions)}
        id={'anc'}
        className={'md-max:!h-[42px] md-max:!w-[42px] md-max:!p-3.5'}
      >
        <span className="hidden sm:block">{t('course.createCourse')}</span> <Plus />
      </MButton>
      {showActions && (
        <div ref={ref} className={classNames('ActionBarCourse', className)}>
          {buttons?.map((button, idx) => (
            <div
              className="ActionBarCourse__item"
              onClick={() =>
                button?.link ? handleClick(button.link) : button?.onClick?.()
              }
              key={idx}
            >
              {!!button.icon && (
                <div className="ActionBarCourse__icon">{button.icon}</div>
              )}
              <div className="ActionBarCourse__content">
                <h6>{button.title}</h6>
                <span>{button.description}</span>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
