import classNames from 'classnames';
import React, {
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  getDigitalPassCategoryById,
  getGraphPerformance,
  getPerformanceDashboard,
  getProgress,
  getWeeklyActivity,
  GraphData,
  Performance,
  ProgressDP,
} from '../../../api/digitalPass';
import { ReactComponent as FinishedIcon } from '../../../assets/icons/learning/finished.svg';
import { ReactComponent as SadIcon } from '../../../assets/icons/learning/sad.svg';
import { ReactComponent as CheckIcon } from '../../../assets/icons/nft/check.svg';
import { Bread, Breadcrumbs } from '../../../components/Breadcrumbs';
import { Loader } from '../../../components/Loader';
import { Mailing } from '../../../features/Mailing';
import CircularProgress from '../../../horizon-components/CircularProgress/CircularProgress';
import {
  EduRoutes,
  MainRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';
import { Grade } from '../../../models/Course';
import {
  DigitalPassCategoryDetail,
  WeeklyActivity,
} from '../../../models/DigitalPass';
import { useUserContext } from '../../../providers';
import { useTitleBackContext } from '../../../providers/TitleBackProvider/TitleBackProvider';

const Chart = lazy(() => import('react-apexcharts'));

export enum Period {
  week = 'week',
  month = 'month',
  year = 'year',
  quarter = 'quarter',
}

export const LearningProgress: React.FC = () => {
  const { id, lang } = useParams();
  const { user } = useUserContext();
  const { t } = useTranslation();
  const [digitalPass, setDigitalPass] = useState<DigitalPassCategoryDetail | null>(
    null
  );
  const [period, setPeriod] = useState<Period>(Period.week);
  const [progress, setProgress] = useState<ProgressDP[]>([]);
  const [activeLevel, setActiveLevel] = useState(0);
  const [weeklyActivity, setWeeklyActivity] = useState<null | WeeklyActivity>(null);
  const [performance, setPerformance] = useState<null | Performance>(null);
  const [graphData, setGraphData] = useState<null | GraphData>(null);
  const { useTitleBack } = useTitleBackContext();

  useTitleBack(
    t('breadcrumbs.learning_progress'),
    MainRoutes.edu + EduRoutes.nft + `/${digitalPass?.id}`
  );

  const dpId = useMemo(() => progress?.[0]?.id || null, [progress]);

  const loadDigitalPassCategory = useCallback(() => {
    return getDigitalPassCategoryById(Number(id || '0')).then((data) => {
      setDigitalPass(data);
    });
  }, [id]);

  const getProgressData = useCallback(() => {
    getProgress(Number(id || '0')).then((data) => setProgress(data));
  }, [id]);

  useEffect(() => {
    loadDigitalPassCategory();
    user?.guid && getProgressData();
  }, [getProgressData, loadDigitalPassCategory, user?.guid]);

  useEffect(() => {
    if (dpId) {
      getWeeklyActivity(dpId).then((data) => setWeeklyActivity(data));
    }
  }, [dpId]);

  useEffect(() => {
    if (dpId) {
      getPerformanceDashboard(dpId, period as string).then((data) =>
        setPerformance(data)
      );
      getGraphPerformance(dpId, period as string).then((data) => setGraphData(data));
    }
  }, [dpId, period]);

  const breads: Bread[] = useMemo(
    () => [
      { title: 'EDU', url: MainRoutes.edu },
      {
        title: t('menu.DigitalPass'),
        url: MainRoutes.edu + EduRoutes['digital-passes'],
      },
      {
        title: digitalPass?.subject?.title || '',
        url: MainRoutes.edu + EduRoutes.nft + `/${digitalPass?.id}`,
      },
      {
        title: 'Learning progress',
        url: MainRoutes.edu + EduRoutes.progress + `/${digitalPass?.id}`,
      },
    ],
    [lang, t, digitalPass?.subject?.title, digitalPass?.id]
  );

  const options = useMemo(
    () => ({
      chart: {
        toolbar: {
          show: false,
        },
      },
      tooltip: {
        style: {
          fontSize: '12px',
        },
        theme: 'dark',
      },
      stroke: {
        curve: 'smooth',
        colors: ['#4318FF'],
      },
      colors: ['#4318FF'],
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: graphData?.labels || [],
        show: false,
        labels: {
          show: true,
          style: {
            colors: '#707EAE',
            fontSize: '14px',
            fontWeight: '600',
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        show: true,
        opposite: 'right',
        labels: {
          show: true,
          style: {
            colors: '#707EAE',
            fontSize: '14px',
            fontWeight: '500',
          },
        },
      },
    }),
    [graphData?.labels]
  );

  const data = useMemo(
    () => [
      {
        name: 'Успеваемость',
        data: graphData?.data || [],
      },
    ],
    [graphData?.data, t]
  );

  const gradeContentMap: Record<Grade, string> = {
    A: '!text-primary-green-600',
    B: '!text-primary-green-600',
    C: '!text-additional-orange-600',
    D: '!text-additional-orange-600',
    F: '!text-additional-pink-600',
  };

  return (
    <div>
      <div className="container hidden md:block">
        <Breadcrumbs breads={breads} />
      </div>
      <div
        className={
          'mt-2 flex h-auto flex-col justify-between gap-2 md:mt-[20px] md:gap-5 lg:flex-row'
        }
      >
        <div
          className={
            'flex flex-row-reverse items-center justify-between gap-4 rounded-[16px] bg-[#4318FF] p-3 md:flex-col md:justify-center md:rounded-[30px] md:p-4'
          }
        >
          <p className={'text-[30px] font-bold text-white lg:text-[60px]'}>
            {weeklyActivity?.completed_unit_count || 0}
          </p>
          <p className={'text-[16px] font-bold text-white'}>Разделов изучено</p>
        </div>
        <div
          className={
            'flex flex-1 flex-col justify-between gap-4 rounded-[30px] p-4 !shadow-[14px_17px_40px_4px_#7090B014] md:p-6'
          }
        >
          <div className={'flex items-end justify-between'}>
            <p className={'text-[12px] font-[600] text-gray-900 lg:text-[18px]'}>
              Активность за неделю
            </p>
            {(weeklyActivity?.regular_activity_days || 0) >
              (weeklyActivity?.regular_missed_days || 0) && (
              <p className={'text-[12px] font-[600] text-purple-500 lg:text-[16px]'}>
                Регулярность - {weeklyActivity?.regular_activity_days} дня
              </p>
            )}
            {(weeklyActivity?.regular_missed_days || 0) >
              (weeklyActivity?.regular_activity_days || 0) && (
              <p className={'text-[12px] font-[600] text-red-500 lg:text-[16px]'}>
                Пропущено - {weeklyActivity?.regular_missed_days} дня
              </p>
            )}
          </div>
          <div className={'flex items-start justify-between'}>
            {weeklyActivity?.activity_status?.map((item) => (
              <div className={'flex flex-col items-center'} key={item.day}>
                {(item.status === 'missed' || item.status === 'pass_missed') && (
                  <SadIcon />
                )}
                {item.status === 'upcoming' && (
                  <div
                    className={classNames(
                      'h-[32px] min-w-[32px] rounded-full bg-purple-100'
                    )}
                  />
                )}
                {item.status === 'active' && (
                  <div
                    className={classNames(
                      'flex h-[32px] min-w-[32px] items-center justify-center rounded-full border-2 border-primary-purple-blue-400'
                    )}
                  >
                    <CheckIcon className="fill-primary-purple-blue-400" />
                  </div>
                )}
                {item.status === 'pass_active' && <FinishedIcon />}
                <p
                  className={classNames(
                    'mt-[8px] text-center text-[14px] font-[600] text-purple-500',
                    {
                      'text-red-500':
                        item.status === 'missed' || item.status === 'pass_missed',
                    }
                  )}
                >
                  {item.day}
                </p>
              </div>
            ))}
          </div>
        </div>
        <Mailing />
      </div>
      <div className={'flex flex-col justify-between gap-2 md:gap-5 lg:flex-row'}>
        <div
          className={
            'mt-2 flex w-full flex-col gap-[5px] rounded-[30px] p-[16px] !shadow-[14px_17px_40px_4px_#7090B014] md:mt-[20px] lg:w-[50%]'
          }
        >
          {progress?.[0]?.subject_levels?.map((item) => (
            <React.Fragment key={item.id}>
              <div
                onClick={() => setActiveLevel(item.id)}
                className={classNames(
                  `w-full cursor-pointer rounded-[16px] border-[1px] border-solid bg-secondaryGrey-200 px-[16px] py-[12px]`,
                  {
                    'border-green-500': activeLevel === item.id,
                    '!bg-green-100':
                      item?.completed_units_count === item?.units?.length,
                  }
                )}
              >
                <div className={'flex items-start justify-between'}>
                  <p className={'text-[16px] font-[600] text-black-10'}>
                    {item.title}
                  </p>
                  <p className={'text-514px text-font-[600] text-gray-500'}>
                    {item.completed_units_count}/{item.units?.length}
                  </p>
                </div>
                <div
                  className={'relative h-[2px] rounded-full bg-secondaryGrey-500'}
                >
                  <div
                    className={`absolute left-0 top-0 h-[2px] rounded-full bg-green-500`}
                    style={{
                      width: `${
                        item.units?.length
                          ? (item.completed_units_count * 100) / item.units?.length
                          : 0
                      }%`,
                    }}
                  />
                </div>
              </div>
              {activeLevel === item.id && (
                <div
                  className={
                    'rounded-[16px] border-[1px] border-solid border-secondaryGrey-500 !shadow-[14px_17px_40px_4px_#7090B014]'
                  }
                >
                  <div
                    className={
                      'flex w-full justify-between border-b-[1px] border-solid border-secondaryGrey-500 p-[12px]'
                    }
                  >
                    <div className={'text-[14px] font-bold text-gray-900'}>
                      {item.units.length} Разделов
                    </div>
                    <div className={'text-[12px] font-[500] text-gray-500'}>
                      Посмотрите все курсы
                    </div>
                  </div>
                  {item.units?.map((unit) => (
                    <div
                      key={unit.title}
                      className={
                        'flex w-full justify-between border-b-[1px] border-solid border-secondaryGrey-500 p-[12px]'
                      }
                    >
                      <div className={'flex'}>
                        <p
                          className={'text-[14px] font-[600] text-secondaryGrey-900'}
                        >
                          {unit.title}
                        </p>
                      </div>
                      {unit?.status?.grade && (
                        <div
                          className={`text-[12px] font-semibold text-gray-500 ${
                            gradeContentMap[unit?.status?.grade]
                          }`}
                        >
                          {unit?.status?.grade || null}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
        <div className={'flex w-full flex-col gap-2 md:gap-5 lg:w-[50%]'}>
          <div
            className={
              'mt-2 gap-[5px] rounded-[30px] p-[16px] !shadow-[14px_17px_40px_4px_#7090B014] md:mt-[20px]'
            }
          >
            <p className={'mb-[10px] text-[18px] font-[600] text-gray-900'}>
              Выберите период
            </p>
            <div className={'flex gap-[8px]'}>
              <div
                className={classNames(
                  'cursor-pointer rounded-full px-[16px] py-[8px] text-[14px] text-secondaryGrey-900',
                  {
                    'border-[1px] border-solid border-secondaryGrey-500':
                      period === Period.week,
                    'bg-secondaryGrey-300': period !== Period.week,
                  }
                )}
                onClick={() => setPeriod(Period.week)}
              >
                Неделя
              </div>
              <div
                className={classNames(
                  'cursor-pointer rounded-full px-[16px] py-[8px] text-[14px] text-secondaryGrey-900',
                  {
                    'border-[1px] border-solid border-secondaryGrey-500':
                      period === Period.month,
                    'bg-secondaryGrey-300': period !== Period.month,
                  }
                )}
                onClick={() => setPeriod(Period.month)}
              >
                Месяц
              </div>
              <div
                className={classNames(
                  'cursor-pointer rounded-full px-[16px] py-[8px] text-[14px] text-secondaryGrey-900',
                  {
                    'border-[1px] border-solid border-secondaryGrey-500':
                      period === Period.quarter,
                    'bg-secondaryGrey-300': period !== Period.quarter,
                  }
                )}
                onClick={() => setPeriod(Period.quarter)}
              >
                Четверть
              </div>
              <div
                className={classNames(
                  'cursor-pointer rounded-full px-[16px] py-[8px] text-[14px] text-secondaryGrey-900',
                  {
                    'border-[1px] border-solid border-secondaryGrey-500':
                      period === Period.year,
                    'bg-secondaryGrey-300': period !== Period.year,
                  }
                )}
                onClick={() => setPeriod(Period.year)}
              >
                Год
              </div>
            </div>
          </div>
          <div className={'flex flex-col gap-2 md:flex-row md:gap-5'}>
            <div
              className={
                'flex flex-col items-center justify-center rounded-[30px] p-[24px] !shadow-[14px_17px_40px_4px_#7090B014] md:w-[35%]'
              }
            >
              <p
                className={
                  'text-center text-[14px] font-bold text-secondaryGrey-900'
                }
              >
                Средний балл за выбранный период
              </p>
              <p className={'text-[72px] font-bold text-purple-500'}>
                {performance?.average_gpa}
              </p>
            </div>
            <div
              className={
                'flex items-center justify-center gap-[20px] rounded-[30px] p-[24px] !shadow-[14px_17px_40px_4px_#7090B014] md:w-[65%]'
              }
            >
              <CircularProgress
                title={`${performance?.course_count} курсов`}
                percentage={performance?.completed_course_count || 0}
                hidePercentage
                className={'max-w-[128px]'}
              />
              <div className={'w-[50%]'}>
                <div className={'flex items-center gap-[5px]'}>
                  <div
                    className={'h-[10px] min-w-[10px] rounded-full bg-purple-100'}
                  />
                  <p className={'text-[20px] font-bold text-gray-900'}>
                    {performance?.course_count}
                  </p>
                  <p className={'text-[12px] text-gray-500'}>Добавлено</p>
                </div>
                <div className={'flex items-center gap-[5px]'}>
                  <div
                    className={'h-[10px] min-w-[10px] rounded-full bg-purple-500'}
                  />
                  <p className={'text-[20px] font-bold text-gray-900'}>
                    {performance?.completed_course_count}
                  </p>
                  <p className={'text-[12px] text-gray-500'}>Завершено</p>
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              'rounded-[30px] p-[24px] !shadow-[14px_17px_40px_4px_#7090B014]'
            }
          >
            <p className={'text-[20px] font-bold text-secondaryGrey-900'}>
              Успеваемость
            </p>
            <Suspense fallback={<Loader />}>
              <Chart
                options={options as any}
                series={data as any}
                type="area"
                width="100%"
              />
            </Suspense>
          </div>
        </div>
      </div>
    </div>
  );
};
