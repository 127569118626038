import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TAnswerItem, Topic, TTopicQuestionItem } from '../../../../models/Course';
import { TopicTestResult } from './TopicTestResult';
import { MButton } from '../../../../horizon-components/MButton/ui/MButton';
import { MCheckbox } from '../../../../horizon-components/MCheckbox/MCheckbox';

type Props = {
  topic: Topic;
  onNextClick: () => void;
  isStaff?: boolean;
  isPreview?: boolean;
};

export const TopicTest: React.FC<Props> = ({
  topic,
  onNextClick,
  isStaff,
  isPreview,
}) => {
  const { t } = useTranslation(['translation']);
  const [userAnswers, setUserAnswers] = useState<Record<string, number[]>>({});
  const [testResult, setTestResult] = useState<boolean[] | null>(null);

  const updateLocalState = (
    question: TTopicQuestionItem,
    answer: TAnswerItem,
    checked: boolean
  ) => {
    let updatedAnswers;

    let answers = userAnswers[question.id] as number[];
    if (!answers) answers = [];

    if (checked) {
      if (!question.is_multiple_answers) {
        updatedAnswers = {
          ...userAnswers,
          [question.id]: [answer.id],
        };
      } else {
        updatedAnswers = {
          ...userAnswers,
          [question.id]: [...answers, answer.id],
        };
      }
    } else {
      updatedAnswers = {
        ...userAnswers,
        [question.id]: answers.filter((id) => id !== answer.id),
      };
    }

    setUserAnswers(updatedAnswers);
    return updatedAnswers;
  };

  const onChangeSelect = async (
    question: TTopicQuestionItem,
    answer: TAnswerItem,
    checked: boolean
  ) => {
    updateLocalState(question, answer, checked);
  };

  const submitExam = async () => {
    const result = [];
    for (const [questionId, answers] of Object.entries(userAnswers)) {
      const questionData = topic.questions?.find(
        (question) => question.id?.toString() === questionId
      );
      const correctAnswers =
        questionData?.topic_answers
          .filter((answer) => answer.correct)
          .map((answer) => answer.id) || [];

      result.push(
        correctAnswers.length === answers.length &&
          [...correctAnswers].every((value) => answers.includes(value as number))
      );
    }

    setTestResult(result);
  };

  if (testResult) {
    return (
      <TopicTestResult
        testResult={testResult}
        setTestResult={setTestResult}
        onNextClick={onNextClick}
      />
    );
  }

  return (
    <>
      {/* Header */}
      <div className="flex items-center justify-between gap-2">
        <div className={'text-[14px] font-bold md:text-[18px]'}>
          {t('edu.Topic_test')} ({t('optional')})
        </div>
      </div>
      {/*Content*/}
      <div className={'flex flex-col gap-[32px]'}>
        {topic.questions?.map((question, index) => (
          <div className={'flex flex-col gap-[12px]'}>
            <div
              className={
                'text-[14px] font-semibold text-secondary-dark-grey-900 md:text-[16px]'
              }
            >
              <span className="font-semibold">{index + 1}. </span>
              <span className={'font-medium'}>{question.text}</span>
            </div>
            {question.topic_answers.map((answer) => (
              <MCheckbox
                key={answer.id}
                checked={
                  isPreview && isStaff
                    ? answer.correct
                    : !!(userAnswers[question.id as number] as number[])?.find(
                        (id) => answer.id === id
                      )
                }
                onChange={(e) => {
                  if (isPreview && isStaff) return;
                  onChangeSelect(question, answer, e.target.checked);
                }}
                type={question.is_multiple_answers ? 'checkbox' : 'radio'}
                text={answer.text}
                id={`answer${answer.id}index${index + 1}`}
              />
            ))}
          </div>
        ))}
        {!(isStaff && isPreview) && (
          <div className={'flex w-full justify-between gap-3'}>
            <div
              className={
                'text-[14px] font-normal text-secondary-dark-grey-500 md:text-[16px]'
              }
            >
              {t('course.noteInfo')}
            </div>
            <MButton color="primary" variant="highlighted" onClick={submitExam}>
              {t('course.submit')}
            </MButton>
          </div>
        )}
      </div>
    </>
  );
};
