import { useCallback, useState } from 'react';
import {
  createFavouriteFolder,
  deleteFavouriteFolderById,
  getFavouriteFolderById,
  updateFavouriteFolderById,
} from '../../../../../api/weber/feed';
import { TFavoriteFolders } from '../../../../../models/Feed';
import { useTranslation } from 'react-i18next';

export const useFolder = () => {
  const { t } = useTranslation(['translation']);
  const [data, setData] = useState<TFavoriteFolders | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchData = useCallback(
    (postId: number) => {
      setIsLoading(true);
      getFavouriteFolderById(postId).then((res) => {
        setData(res);
        res?.id && setIsLoading(false);
      });
    },
    [t]
  );

  const fetchCreate = useCallback(
    async (title: string) => {
      setIsLoading(true);
      await createFavouriteFolder(title).then((res) => {
        setData(res);
        res?.id && setIsLoading(false);
      });
    },
    [t]
  );

  const fetchUpdate = useCallback(
    async (folderId: number, title: string) => {
      setIsLoading(true);
      await updateFavouriteFolderById(folderId, title).then((res) => {
        setData(res);
        res?.id && setIsLoading(false);
      });
    },
    [t]
  );

  const fetchDelete = useCallback(
    async (folderId: number) => {
      setIsLoading(true);
      await deleteFavouriteFolderById(folderId).then((res) => {
        setData(res);
        res?.id && setIsLoading(false);
      });
    },
    [t]
  );

  return { data, isLoading, fetchData, fetchCreate, fetchUpdate, fetchDelete };
};
