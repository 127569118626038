import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getPublicTagsAutocompleteV3 } from '../../../api/courseV3';
import {
  getPurchasableSubjectsIdV3,
  getPurchasedSubjectsV3,
} from '../../../api/digitalPassV3';
import { Bread, Breadcrumbs } from '../../../components/Breadcrumbs';
import { StartLearn } from '../../../features/StartLearn';
import useFetch from '../../../hooks/useFetch';
import { Badge } from '../../../horizon-components/Badge';
import { Link } from '../../../horizon-components/Link';
import { MButton } from '../../../horizon-components/MButton';
import {
  EduRoutes,
  MainRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';
import { useUserContext } from '../../../providers';
import { useTitleBackContext } from '../../../providers/TitleBackProvider/TitleBackProvider';
import { SchoolCard } from '../../../widgets/SchoolCard';
import { SelectCourse } from '../../../widgets/SelectCourse';
import { IncludedCourses } from './IncludedCourses';
import './styles.scss';
import SEO from '../../../components/SEO/SEO';

export const NFTView: React.FC = () => {
  const { id } = useParams();
  const { user } = useUserContext();
  const { t } = useTranslation(['translation']);
  const [selected, setSelected] = useState<
    {
      id: number;
      title: string;
      price: number;
      selected: boolean;
      purchased: boolean;
    }[]
  >([]);
  const [breads, setBreads] = useState<Bread[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [orderList, setOrderList] = useState<string[]>([]);
  const { useTitleBack } = useTitleBackContext();
  const { data, fetchData: fetchPurchasable } = useFetch(() =>
    getPurchasableSubjectsIdV3(id ? parseInt(id) : 0)
  );
  const { data: purchasedList, fetchData: fetchPurchased } = useFetch(
    getPurchasedSubjectsV3
  );
  const { data: tags, fetchData: fetchTagsAutocomplete } = useFetch(
    getPublicTagsAutocompleteV3
  );

  const purchased = useMemo(
    () => purchasedList?.results?.find((purch) => purch.id === data?.id),
    [data?.id, purchasedList]
  );

  useTitleBack(data?.subject?.title);

  useEffect(() => {
    user?.guid && fetchPurchased();
    fetchPurchasable();
    fetchTagsAutocomplete();
  }, [t, user?.guid]);

  useEffect(() => {
    if (data?.id) {
      setBreads([
        { title: 'EDU', url: MainRoutes.edu },
        {
          title: t('nft.digitalPass'),
          url: MainRoutes.edu + EduRoutes['digital-passes'],
        },
        {
          title: data?.subject?.title,
          url: MainRoutes.edu + EduRoutes.nft + `/${id}`,
        },
      ]);
    }
  }, [data, id, t]);

  useEffect(() => {
    if (data) {
      setSelected(
        data?.digital_passes?.map((item) => {
          return {
            id: item.id,
            title: item.name,
            price: Number(item.price),
            selected: !purchased?.digital_passes?.find(
              (purch) => purch.purchase_id && purch.id === item.id
            )?.id,
            purchased: !!purchased?.digital_passes?.find(
              (purch) => purch.purchase_id && purch.id === item.id
            )?.id,
          };
        })
      );
    }
  }, [data, purchased]);

  const handleSelectDP = (digitalPassId: number) => {
    setSelected(
      selected.map((selected) => {
        return {
          ...selected,
          selected:
            selected.id === digitalPassId ? !selected.selected : selected.selected,
        };
      })
    );
  };

  const hndlSelectTag = (name: string) => {
    if (selectedTags.find((tag) => tag === name)) {
      setSelectedTags([...selectedTags.filter((tag) => tag !== name)]);
    } else {
      selectedTags.push(name);
      setSelectedTags([...selectedTags]);
    }
  };

  const hndlOrder = () => {
    setOrderList(selectedTags);
  };

  return (
    <div>
      <SEO
        title={t('pages.subject-view.meta.title', { subject: data?.subject?.title })}
        desc={t('pages.subject-view.meta.desc', { subject: data?.subject?.title })}
        loading={!data?.subject?.title}
      />

      <Breadcrumbs breads={breads} className="md-max:hidden" />
      <div className={'NFTView'}>
        <div className={'NFTView__img_wrap'}>
          <img className={'NFTView__img'} src={data?.image_medium} alt={''} />
        </div>
        <div className="NFTView__school">
          <h1 className="md-max:hidden">{data?.subject?.title}</h1>

          <div className="NFTView__school lg:!hidden">
            <Link to={MainRoutes.edu + EduRoutes.nft + `/${id}` + EduRoutes.shcools}>
              <SelectCourse />
            </Link>
            <StartLearn subjectId={data?.subject.id} />
          </div>

          <div className="NFTView__school md-max:!hidden">
            {data?.digital_passes?.map((item, idx) => (
              <SchoolCard
                key={item.id}
                title={item.name}
                description={item.description}
                variant={`${item.level} ${t('course.Grade')}, ${item.units_count} ${t(
                  'course.units'
                )}, ${item.course_count} ${t('course.Courses')}`}
                onClick={() => handleSelectDP(item.id)}
                selected={
                  selected.findIndex(
                    (selected) => selected.id === item.id && selected.selected
                  ) > -1
                }
                purchased={
                  !!purchased?.digital_passes?.find(
                    (purch) => purch.purchase_id && purch.id === item.id
                  )?.id
                }
                link={
                  MainRoutes.edu +
                  EduRoutes.nft +
                  `/${id}` +
                  EduRoutes.shcool +
                  `/?school=${item.id}`
                }
                first={idx === 0}
              />
            ))}
          </div>

          {!!data?.subject.is_miscellaneous_subject && (
            <>
              <p className="self-stretch text-lg font-medium md-max:hidden">
                {t('edu.subject.detail.description')}
              </p>

              <div className="NFTView__tags">
                <h4>{t('edu.subject.detail.title')}</h4>
                <div className="flex flex-wrap gap-2">
                  {tags?.results.map((tag) => (
                    <Badge
                      active={Boolean(
                        selectedTags.find((select) => select === tag.name)
                      )}
                      variant="primary"
                      onClick={() => hndlSelectTag(tag.name)}
                      size="lg"
                    >
                      {tag.name}
                    </Badge>
                  ))}
                </div>
                <MButton
                  variant="highlighted"
                  className="ml-auto"
                  onClick={hndlOrder}
                >
                  {t('buttons.Find_courses')}
                </MButton>
              </div>
            </>
          )}
        </div>
      </div>

      <div className="NFTView__list md-max:!hidden">
        <IncludedCourses tags={orderList} />
      </div>
    </div>
  );
};
