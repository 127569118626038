import { useState, useEffect, useRef } from 'react';

function useClickOutside(
  initialState: boolean = false
): [
  boolean,
  (node: HTMLElement | null) => void,
  () => void,
  (node: HTMLElement | null) => void,
] {
  const [open, setOpen] = useState<boolean>(initialState);
  const ref = useRef<HTMLElement | null>(null);
  const activatorRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const isClickOutside =
        ref.current && !ref.current.contains(event.target as Node);
      const isClickOnActivator =
        activatorRef.current && activatorRef.current.contains(event.target as Node);

      // If click on activator - let activator change state
      if (isClickOutside && !isClickOnActivator) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const setRef = (node: HTMLElement | null) => {
    ref.current = node;
  };

  const setActivatorRef = (node: HTMLElement | null) => {
    activatorRef.current = node;
  };

  const toggleOpen = () => {
    setOpen(!open);
  };

  return [open, setRef, toggleOpen, setActivatorRef];
}

export default useClickOutside;
