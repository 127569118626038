import { useCallback, useState } from 'react';
import { getFavouriteFolders } from '../../../../../api/weber/feed';
import { TFavoriteFolders } from '../../../../../models/Feed';
import { useTranslation } from 'react-i18next';

export const useFolders = () => {
  const { t } = useTranslation(['translation']);
  const [count, setCount] = useState<number>(0);
  const [data, setData] = useState<TFavoriteFolders[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchData = useCallback(() => {
    setIsLoading(true);
    getFavouriteFolders()
      .then((res) => {
        setData(res.results);
        setCount(res.total_post_count);
        res !== undefined && setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, [t]);

  return { count, data, isLoading, fetchData };
};
